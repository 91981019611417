.customCard.reviewCard{
  margin-bottom: 1rem !important;
  height: auto;

  .customCardHeader {
    cursor: pointer;
    button {
      padding: 0px !important;
      color: none !important;
      border: none !important;
      border-style: none !important;
      font-weight: bold;
    }
    .bg-none{
      background-color: transparent !important;
    }
  }

  .card-body{    
    overflow: hidden;
    overflow-y: auto;
    max-height: 250px;
  }
  .reviewDetails{
    padding: 15px 0;
    margin: 0px;
    border-bottom: 1px solid #ffffff1a;
    .review_image_crown {
      position: relative;
      img.is_premium_customer {
        position: absolute;
        left: -6px;
        top: 0;
      }
      .online-small-icon {
        width: 13px !important;
        height: 13px !important;
        display: inline-block;
        position: absolute;
        top: 29px;
        right: 2px;
        background-color: #2eb130;
        border-radius: 50%;
        border: 1px solid #ddd;
      }
      .offline-small-icon {
        width: 13px !important;
        height: 13px !important;
        display: inline-block;
        position: absolute;
        top: 29px;
        right: 2px;
        background-color: #9fa09f;
        border-radius: 50%;
        border: 1px solid #ddd;
      }
      .online-away-icon {
        width: 13px !important;
        height: 13px !important;
        display: inline-block;
        position: absolute;
        top: 29px;
        right: 2px;
        background-color: #ECAC19;
        border-radius: 50%;
        border: 1px solid #ddd;
      }
    }
    
    .reviewHead{
      font-size: 1rem;
      font-weight: 300;
      text-transform: capitalize;
      h6{
        font-size: 1rem;
      font-weight: 300;
      }
    }
    .reviewRating{
      padding-right: 20px;

      svg {
        .cls-1 {
          fill-rule: evenodd;
        }
        height: 15px;
        width: 15px;
        margin-right: 4px;
      }
    }
    .reviewDesc{
      color: #fff;
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 18px;
      opacity: 0.6;
    }
  }
  .no-data-available{
    display: flex;
    min-height: 250px;
    align-items: center;
    justify-content: center;
  }
  .mr-10{
    margin-right: 10px;
  }
  .pl-12{
    padding-left: 12px;
  }
}

.review-profile{
  border-radius: 50%;
  border: 1px solid #504d4e;
}