.sharePrivateMedia-modal.forwardMediaModal{
    max-width: 520px !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 auto !important;
    width: 100% !important;
    position: absolute !important;
    .text-stronger{
        font-weight: bold !important;
    }
    .audioPlayer{
        width: 100%;
        height: 46px;
    }
    audio{
        &::-webkit-media-controls-panel{
            background: #1E2020 !important;
            border-radius: 10px !important;
        }
        &::-webkit-media-controls-enclosure {
            border-radius: 10px;
            background-color: #1E2020;
        }
    }
    .modal-content{
        background: #333739;
        border-radius: 12px;
        height: auto;
        .modal-body {
            width: 100%;
            padding: 0 30px 30px;
            .media-share-pop-title {
                font-weight: 600;
                font-size: 18px;
                line-height: 17px;
                text-align: center;
                letter-spacing: -0.05em;
                color: #FFFFFF;
                display: block;
                margin: 20px 0 24px;
            }
            .checkbox-container {
                display: block;
                label {
                    margin-bottom: 0px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: -0.05em;
                    color: #B9B9B9;
                }
                input {
                    padding: 0;
                    height: initial;
                    width: initial;
                    margin-bottom: 0;
                    display: none;
                    cursor: pointer;
                  }
                  label {
                    position: relative;
                    cursor: pointer;
                  }
                  label:before {
                    content:'';
                    -webkit-appearance: none;
                    background-color: transparent;
                    border: 1px solid #ff7167;
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
                    padding: 7px;
                    display: inline-block;
                    position: relative;
                    vertical-align: middle;
                    cursor: pointer;
                    margin-right: 5px;
                    border-radius: 4px;
                    top: -1px;
                  }
                  input:checked + label:before {
                    background-color: #ff7167;
                    border-color: #ff7167;
                  }
                  input:checked + label:after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 2px;
                    left: 6px;
                    width: 5px;
                    height: 11px;
                    border: solid #fff;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                  }
              }

            .input-edit-icon-wrap {
                position: relative;
                img {
                    position: absolute;
                    top: 6px;
                    right: 10px;
                    cursor: pointer;
                }
            }
            .cost-to-customer {
                span {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 14px;
                    letter-spacing: -0.05em;
                    color: #FFFFFF;
                }
                span.credit-usd {
                    color: #fd7663;
                    margin-left: 4px;
                    font-weight: 700;
                }
            }
            &.paid-pic-modal{
                .image-wrap{
                    border-radius: 8px;
                    background: rgba(21, 21, 22, 0.15);
                    padding: 20px;
                    img{
                        max-width: 360px;
                        width:100%;
                        max-height:300px;
                        object-fit: contain;
                        border-radius: 8px;
                    }
                    video{
                        max-height:300px;
                    }
                }
                .modal-btn{
                    margin: 0 !important;
                    border: none;
                    padding: 17px 20px;
                    text-decoration: none;
                    display: inline-block;
                    position: relative;
                    top: 0;
                    width: 100%;
                    background: #FD7663;
                    border-radius: 10px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 12px !important;
                    text-align: center;
                    color: #FFFFFF;
                    height: 50px;
                }
                #creditsLabel{
                    border: unset;
                    background: rgba(21, 21, 22, 0.7);
                    border-radius: 10px;
                    height: 46px;
                    padding: 2px 0 0 17px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: -0.05em;
                    color: #FFFFFF;
                }
                input#creditsLabel::-webkit-outer-spin-button,
                input#creditsLabel::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input#creditsLabel[type=number] {
                    -moz-appearance: textfield;
                }
                input#creditsLabel::-webkit-outer-spin-button,
                input#creditsLabel::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                /* Firefox */
                input#creditsLabel[type=number] {
                    -moz-appearance: textfield;
                }
                .custom-control-label{
                    box-shadow: unset;
                    left: -16px;
                    &:before{
                        left: -2.25rem;
                        width: 50px;
                        height: 24px;
                        pointer-events: all;
                        border-radius: 15px;
                        top: 2px;
                        border-color: transparent;
                        transition: unset !important;
                        box-shadow: unset !important;
                    }
                    &:after{
                        height: 20px;
                        width: 20px;
                        top: 4px;
                        border-radius: 50%;
                        transition: unset !important;
                    }
                }
                .custom-control-input:checked ~ .custom-control-label::after{
                    transform: translateX(1.5rem);
                }
                .custom-control-input:checked ~ .custom-control-label::before{
                    background: #3BA520;
                }
            }
        }
        .camModel {            
            .chat-modal-body {
                position: relative;
            }
            .canvas {
                position: absolute;
                top: 16px;
                left: 20px;
                width: 333px;
                max-height: 250px;
                #canvas.active {
                    visibility: visible;
                }
            }
            .videoElement {
                width: 100%;
                max-height: 250px;
            }
            .no-camera {
                width: 100%;
                max-height: 250px;
                text-align: center;
            }
            // .modal-btn {
            //     background: #3B4149;
            //     color: #fff;
            //     border: none;
            //     min-width: 120px;
            //     padding: 10px;
            //     border-radius: 15px;
            //     margin: 0 auto;
            //     display: block;
            //     position: relative;
            //     top: 30px;
            //     box-shadow: 2px 14px 28px 1px rgba(0, 0, 0, 0.28);
            //     text-transform: uppercase;
            //     font-weight: 500;
            //     letter-spacing: 1px;
            //     cursor: pointer;
            // }
        }
        .camHeader {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom: none;
            padding: 30px 30px;
            .close{
                margin-top: 0rem !important;
                color: #5D6569;
                font-weight: 300;
                font-size: 35px;
                position: absolute;
                top: 35px;
                right: 40px;
                padding: 0;
                box-shadow: none;
                text-shadow: none;
                height: 25px;
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 1;
                opacity: 1;
                span{
                    line-height: 1;
                }
            }
        }
        .camFooter {
            background-color: transparent;
            height: auto;
            border-bottom-right-radius: 1rem;
            border-bottom-left-radius: 1rem;
            border: none;
            padding: 0;
        }
        .btn-group{
          display: flex;
          width: 100%;
          margin: 25px auto 0;
          justify-content: center;
          flex-wrap: nowrap;
        }
        .ar_active_customer_list {
            max-height: 300px;
            overflow-y: auto;
            overflow-x: hidden;
            .ar_active_customer_single{
                padding: 10px 0;
                border-bottom: 1px solid rgba(130, 130, 130, 0.1);
            }
        }
        .ar-session-title{
            color: #FFF;
            font-family: Overpass;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: -0.7px;
        }
        .ar-label-select-all{
            color: #FFF;
            font-family: Overpass;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: -0.6px;
            margin: 0;
            opacity: 0.8;
            display: flex;
            align-items: center;
        }
        .ar-session-host-name{
            margin-left: 12px;
            color: #FFF;
            font-family: Overpass;
            font-style: normal;
            font-weight: 600;
            line-height: 12px;
            letter-spacing: -0.36px;
            text-transform: capitalize;
        }
        .ar-checkbox{
            margin: 0;
            input[type="checkbox"] {
                -webkit-appearance: none;
                appearance: none;
                cursor: pointer;
                height: 16px;
                position: relative;
                width: 16px;
                border-radius: 4px;
                border: 1px solid rgba(255, 255, 255, 0.50);
                margin-left: 10px;
                top: -2px;
                &::before {
                    color: #fff;
                    content: "\2713";
                    font-size: 1em;
                    position: absolute;
                    right: -1px;
                    top: -3px;
                    visibility: hidden;
                    width: 16px;
                    height: 16px;
                    text-align: center;
                    transform: rotate(8deg);
                }
                &:checked {
                    background: #FD7663;
                    border-color: #FD7663;
                    &::before {
                        visibility: visible;
                    }
                }
            }
        }
    }
    .enter-credit-label {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 3px;
    }
    @media(max-width:767px){
            .modal-content {
                .modal-body.paid-pic-modal {
                    .image-wrap {
                        img{
                            object-fit: unset;
                            width: 250px;
                            height: 300px;
                            max-width: 300px;
                            max-height: 300px;
                        }
                    }
                }
            }
    }
  }