.edit-info {
  ul {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
  }

  .list_content {
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 25%;
    margin-right: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333739;
    cursor: pointer;
    &.active_list {
      border: 1px solid #f07563;
      pointer-events: none;
    }
  }
  
  li {
    margin-right: 15px;
    display: flex;
    justify-content: space-between;
    img {
      margin-right: 5px;
      margin-top: 3px;
    }
  }
  i{
    background-color: #45494B;
    padding: 12px;
    border-radius: 5px;
    cursor: pointer;
    &:before{
      color: #FD7663;
    }
  }
}
.EditInfo-Modal {
  max-width: 360px !important;
  .chat-modal-body{
    padding-top: 40px;
    input.form-control{
      background: #35393e;
      border: 1px solid #35393e;
      color: white;
    }
    .flag-dropdown {
      background-color: #35393e;
      border: 1px solid #35393e;
      .selected-flag{
        width: 30px;
        &:hover,&.open{
          background-color: #35393e;
        }
        .arrow{
          border-top: 4px solid #fff;
          left: 25px;
        }
      }
      .country-list {
        .country{
          &:hover{
            background-color: #292a2b;
          }
          &.highlight{
            background-color: #000000;
          }
        }
      }
    }
    .doneBtn {
      background-color: #f07563 !important;
      width: 100% !important;
      font-weight: 800 !important;
      color: white !important;
      border-radius: 5px !important;
      text-transform: capitalize !important;
      padding:10px !important ;
      height: unset !important;
      margin-bottom: 20px;
    }
  }
}
.otp-input-form{
  &>div{
    justify-content: center;
  }
  .otp-input{
    input{
    width: 50px !important;
    height: 50px;
    color: white;
    font-size: 20px;
    text-align: center;
    border-radius: 8px;
    margin: 3px 4px;
    border: unset;
    background-color: #1E1F20;
    }
  }
  .resend-text{
    color: #FD7663;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 12px;
    margin-left: 10px;
  }
}
