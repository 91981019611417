#sidebar {
  min-width: 260px;
  max-width: 260px;
  background: #1F1F1F;
  color: #fff;
  transition: all 0.3s;
  >.active {
    margin-left: -250px;
  }
  .sidebar-header {
    padding: 20px 10px;
    .media{
      .media-left{
        position: relative;
        img{
          height: 75px;
          width: 75px;
          min-width: 75px;
          min-height: 75px;
          max-width: 75px;
          max-height: 75px;
          background: #fff;
          border-radius: 50%;
          object-fit: cover;
          border: 2px solid #333739;
          overflow: hidden;
          box-sizing: content-box;
        }
        a{
          background: var(--white);
          height: 25px;
          width: 25px;
          border-radius: 50%;
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          align-items: center;
          -webkit-justify-content: center;
          justify-content: center;
          border: 2px solid #fff;
          position: absolute;
          bottom: 0;
          right: 0;
          svg{
            width: 12px;
            height: 12px;
          }
        }
      }
      .media-body{
        margin-top: 10px;
        p,.media-heading{
          margin-bottom: 0;
          text-align: center;
        }
        h4{
            font-size: 1.125rem;
        }
        p:first-child{
            font-size: 16px;
            font-weight: 400;
        }
        p:last-child{
          font-size: 0.813rem;
          margin-top: 3px;
          color: #CBCBCB;
        }
        .online{
          color: #26b934
        }
        .offline{
          color: #991c20;
        }
      }
    }
  }
  .balaceLogo{
    svg{
      max-width: unset;
      width: 27px;
      height: 27px;
    }
  }
  .dashBoard{
    padding: 10px;
    font-size: 1.1em;
    display: block;
    color: #878c8f;
  }
}

#sidebar ul p {
  color: #fff;
  padding: 10px;
}
.whiteBadge{
  background-color: var(--white) !important;
  color: var(--primary) !important;
  min-width: 23px;
  min-height: 23px;
  line-height: 0 !important;
  padding: 2px !important;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  padding-top: 5px !important;
}
#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block;
}

#sidebar ul li a:hover {
  color: #fff;
  background: #33363d;
  text-decoration: none;
  span{
    fill:#fff;
  }
}


a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386D5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

.p-0 {
  padding: 0px;
}
// .p-0 a{
//   color: #BBBCBE;
// }

.sidebar-body {
  a:hover{
    text-decoration: none;
  }
  &> a{
    max-height: 54px;
  }
  .row{
    align-items: center;
    max-height: 60px;
  }
  .stroke{
    svg{
      fill: none !important;
      stroke: #595E68;
    }
  }
  .phonebooksvg,.testsetupsvg{
    svg{
      fill: none !important;
      path{
        stroke: #595E68 !important;
      }
    }
  }
  .settingicon{
    svg{
      fill: none !important;
      stroke: none !important;
    }
  }
  .row.active {
    color: #fff;
    font-weight: 600;
    background-color: var(--primary);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    &:hover{
    background-color: var(--primary);
    }
    svg {
        fill: var(--white);
    }  
    .phonebooksvg,.testsetupsvg{
      svg{
        fill: var(--white);
        path{
          stroke: #fff !important;
        }
      }
    }
    .settingicon{
      svg{
        fill: var(--white);
        stroke: unset;
        path{
          fill: #fff !important;
        }
      }
    }
  }
  .row {
    padding: 15px 0;
    color: #595E68;
    margin-right: 0;
    padding-left: 10px;
    svg {
      fill: #595E68;
      height: auto;
      max-width: 25px;
    }
    .arrow {
      width: 8px;
    }
    a{
      color: #BBBCBE;
    }
    .cursor-pointer{
      padding-left: 6px !important;
      margin-top: 3px;
      line-height: 1;
    }
  }
  .row:hover {
    svg {
      fill: #fff;
    }
    .phonebooksvg,.testsetupsvg{
      svg{
        fill: var(--white);
        path{
          stroke: #fff !important;
        }
      }
    }
   .settingicon{
      svg{
        fill: var(--white);
        path{
          fill: #fff !important;
        }
      }
    }
    .stroke{
      svg{
        stroke: #fff;
      }
    }
    a{
      color: #FFFFFF;
      text-decoration: none;
    }
    color: #fff;
    background: #33363d;
    text-decoration: none;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px
  }
}
.cursor-pointer {
  cursor: pointer;
}
.ar_position_badge{
  position: absolute;
  right: 10px;
  min-width: 20px;
  min-height: 20px;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
      margin-left: -250px;
  }
  #sidebar.active {
      margin-left: 0;
  }
  #sidebarCollapse span {
      display: none;
  }
}