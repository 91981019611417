.support {
  min-height: 700px;
  .faq_text {
    font-size: 14px;
    a {
      text-decoration: underline;
    }
  }
  .card-body {
    width: 100%;
    div {
      // align-self: flex-start;
      position: relative;
    }

    .help_text {
      color: #868B91;
      padding-left: 0;
    }

    .support-description {
      textarea.form-control {
        min-height: 279px;
        border-radius: 10px;
        height: 100%;
        padding-top: 22px;
        padding-left: 24px;
        border: unset;
      }
      .form-group{
        height: 100%;
      }
      .edit_icon{
        align-self: baseline;
      }
    }

    .form-group {
      margin-top: 1rem;
    }

    .row_detail {
      margin-bottom: 15px;
    }

    .setting_details {
      margin: 3px auto;
    }
  }
.removeSpacing{
  .form-group{
    margin-top: 0;
    select{
      min-height: 44px;
    }
    & > label.force_mb-5{
      display: none;
    }
  }
}
.dropArea{
  .container{
    padding-left: 0;
    padding-right: 0;
    height: 100%;
    .dropzone{
      height: 100%;
    }
  }
}
  .fs-20 {
    font-size: 18px;
    line-height: 1.3;
    padding-left: 0;
  }
  .setting_description {
    // align-self: baseline;

    .form-group {
      margin-bottom: 0px;

      input, textarea{
        border-color: #39424A;
      }
      input {
       visibility: hidden; 
       position: absolute;
      }
    }

    svg {
      height: 15px;
      width: 15px;
      cursor: pointer;
    }
  }
  .issue-textarea {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    .form-group{
      margin-top: 0;
    }
  }
  .supportForm{
    select,textarea {
      background-color: var(--dark);
      border-color: var(--dark);
      font-size: 0.857rem;
    }
    select::placeholder,select{
      color: var(--white);
    }
    textarea{
      color: white;
    }
  }
  .button-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sendBtn{
      color: var(--white);
      
    }
    button {
      flex: 0 0 48%;
      line-height: normal;
      border-radius: 7px;
      // min-height: 50px;
      padding: 17px 6px 15px 6px;
     
      .support .button-panel button
      &:first-child {
        margin-right: 10px;
      }
    }
  }
  .file-inputgroup div:first-child {
    width: 60%;
    flex:initial !important;
  }
  .ellipse-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 93% !important;
    height: 23px;
    display: block;
    flex: initial;
  }
  .send-btn {
    // color: #39424A;
    // background-color: #fff !important;
  }
  .send-btn:hover {
    color: #fff;
    background-color: #39424A !important;
  }
  .borderBottom {
    border-bottom: 2px solid #F5F6FA;
    margin-bottom: 15px;
  }
}
.cld-arr-icon{
  .cloude-icon {
    svg {
      height: 38px;
      width: 50px;
    }
  }
  .arrow-icon {
    svg {
      height: 30px;
      width: 18px;
    }
  }
}