/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}


/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.Toastify__toast-container.Toastify__toast-container--top-right a {
    color: #fff;
    text-decoration: none;
}

#content {
    width: 100%;
    padding: 0px 20px 0;
    min-height: calc(100vh - 90px);;
    transition: all 0.3s;
    background: #000;
}

.BandWidthMessagetoast {
    background: #e74c3c;
    padding: 10px;
    position: fixed;
    top: 10px;
    right: 50px;
    z-index: 9;
    width: 300px;
    box-shadow: 0 3px 12px rgba(0px, 0px, 5px, 0.7);
    border-radius: 8px;
  }
  .BandWidthMessagetoast.dis-none {
    display: none;
  }
.modal{
    .video_chat_modal{
        .modal-content {
            border-radius: 10px;
            background: #FFF;
            box-shadow: -10px 10px 40px 0px rgba(0, 0, 0, 0.60);
        }
        .modal-footer{
            .button-panel{
                margin: 0;
            }
        }
        .arg-modal-title {
            color: #292A2B;
            font-size: 16px;
            font-weight: 800;
            margin: 0;
        }
        .arg-modal-desc {
            color: #292A2B;
            font-size: 14px;
            line-height: 18px;
            opacity: 0.6000000238418579;
        }
        .arousr_logo {
            width: 50px;
            height: 50px;
            border-radius: 50px;
            background: #333739;
            padding: 5px;
        }
        .button-panel{
            button {
                width: 110px;
                height: 36px;
                margin: 0 5px;
                border-radius: 8px;
                padding: 12px 0;
                color: #FD7663;
                text-align: center;
                font-size: 14px;
                font-weight: 600;
                line-height: 12px !important;
            }
            .btn-outline-primary{
                &:hover{
                    color: #fff;
                }
            }
        }
        .chat-customer-review{
            border-radius: 6px;
            background: #E8E8E8;
            padding: 6px 3px;
            .chat-reviews{
                border-color: #BCBCBC;
                .bar-seprate-line-wrap{
                    top: 20px;
                    .bar-seprate-line{
                        background: #CCCCCC;
                    }
                }
                .title_container{
                    top: -10px;
                }
                span{
                    color: #333739;
                    font-weight: 600;
                    line-height: 7.946px;
                    letter-spacing: -0.255px;
                }
                .semicircle-container{
                    svg{
                        circle:first-child {
                            stroke: #cccccc;
                        }
                    }
                }        
            }    
        }    
    }
}