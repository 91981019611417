.performer-img{
    width:250px;
    height:250px;
    box-shadow: 0 10px 20px rgba(252, 252, 252, 0.19), 0 6px 6px rgba(0,0,0,0.23);
}
.my-info-table{
    width:100%;
    tr{
        td{
            width:50%;
            border-bottom:1px solid #a6a6a694;
            text-align: left;
            padding: 8px;
        }
    }
}
.download-btn{
    background: #f07563 !important;
    padding: 14px !important;
    border-radius: 12px !important;
    border: unset !important;
}

.customCardHeaderList:not(:last-child) {
    border-bottom: none !important;
}