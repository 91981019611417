.text-align-right {
    text-align: right;
}

/* ---------------------------------------------------
    CARD CONTENT STYLE
----------------------------------------------------- */

.card{
    border-radius: 0;    
  
  }

  .card-body{
    padding-left: 10px;
    .screen-name, .description-name {
      svg ,img{
        margin-right: 1rem;
      }
    }
  }
  .customCard{
    .customCardHeader {
      padding-bottom: 0;
      background: transparent;
        h2{
        // border-left: 3px solid darkred;
        font-size: 1.125rem;
        line-height: 30px;
        font-weight: bold
      }
      .badge-secondary{
        background: #9d2731;
        border-radius: 25px;
        min-width: 18px;
        height: 18px;
        margin-left: 5px;
      }
    }
    .card-body{
      padding: 0 1.25rem;
      .customCardHeaderList{
        border-top: 1px solid #e5e5e5;
          .custom-switch .custom-control-label::after{
          height: 20px;
          width: 20px;
          top: 4px;
          border-radius: 50%;
        }
        .custom-switch .custom-control-label::before{
          width: 50px;
          height: 24px;
          pointer-events: all;
          border-radius: 15px;
          top: 2px;
        }
        .custom-control-input:checked~.custom-control-label::before {
          color: #fff;
          border-color: #3BA520;
          background-color: #3BA520;
      }
      }
    }
  }
  .customCard.Credits-customCard{
    .btn{
      line-height: normal;
      border-radius: 5px;
      min-width: 180px;
      padding: 12px 6px;
      margin: 0 0 10px;
    }
    .Credits_btn{
      margin-top: -30px;
      .btn{
        &:hover{
          background: #3a414b;
        }
      }
      .btn-secondary{
        background: #3a414b;
      }
    }
    .card-body{
        padding: 0 15px;
        .credits-no{
          background: #eee;
          padding: 4px 8px;
          margin-bottom: 15px;
          margin-left: -5px;
        }
    }
    .creditsHeader{
      img{
        margin-left: 5px;
        width: 14px;
        transform: scaleX(-1) rotate(60deg);
      }
    }
  }
  .mb-10{
    margin-bottom: 10px !important;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }

  .flexOne{
    flex: 0 0 60%;
  }
  .flexOne2Row{
    flex:0 0 60%;    
  }
  .flexTwo{
    flex:1;    
  }
  .flexSecondOne{
    flex:0 0 85%;
    padding-right:20px;
  }
  .border-bottom{
    border-bottom:1px solid #EBECF1;
  }
  .align-bottom{
    align-self: flex-end;
  }
  .bg-color-lightGrey{
    background-color: #EBECF1;    
  }
  .headerBorderLeft{
    font-size: 16px;
    line-height: 30px;
    margin-left: -16px !important ;
  }

  .svgbalance svg{
    width:25px;
    height:25px;
    margin-right: 10px;
    fill:#2D313A;
  }
  .headingH2Balance{
    font-size:20px;
  }
  .color-light-grey{
    color:#747C8C;
  }
  .color-dark-grey{
    color:#2D313A;
  }
  .react-bootstrap-table table th{ 
    color:#2D313A;
  } 
  .react-bootstrap-table table td{ 
    color:#747C8C
  } 
  .bottomPagination .page-link{
    color:#747C8C;
  }
  
  .react-bootstrap-table th.sortable span{
    float:right;
  }
  .tableBorderRadius{
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    thead{
      th{
        &:first-child{
          padding-left: 0 !important;
      }
      }
    }
    tbody{
      td{
        &:first-child{
          padding-left: 0 !important;
      }
      }
    }
  }
  .tableWidthController, .tableWidthController2{
    // width: 1015px;
    /* border: 1px solid red; */
    width: calc(100vw - 352px) !important;
    overflow: hidden;
    overflow-x: auto;
  }
  .tableWidthController2  .react-bootstrap-table .table{
    width: auto;
    white-space: nowrap;
  }
  .tableWidthController2  .react-bootstrap-table .table td, .tableWidthController2  .react-bootstrap-table .table .table th{
     width: max-content;
     min-width:120px;    
  }
  .tableWidthController2  .react-bootstrap-table .table th:nth-child(8), .tableWidthController2  .react-bootstrap-table .table .table th:nth-child(8){
    min-width:160px; 
  }
 
  .tableWidthController .table{
    // width:1400px;
    width:1014px;
  }

  .tableWidthController .table.table-striped thead th,
    .controller2 .table.table-striped thead th{
    border-left: 2px solid #dee2e6;   
  }
  .tableWidthController .table.table-striped thead th:last-child, 
  .controller2 .table.table-striped thead th:last-child {
    border-right: 2px solid #dee2e6
  }
  .tableWidthController .table.table-striped thead th:nth-child(1),    
    .tableWidthController .table.table-striped thead th:nth-child(2),    
    .tableWidthController .table.table-striped tbody td:nth-child(1),    
    .tableWidthController .table.table-striped tbody td:nth-child(2)    
    {
      width:12%;   
    }
    .tableWidthController .table.table-striped thead th:nth-child(3),
    .tableWidthController .table.table-striped tbody td:nth-child(3)
  .tableWidthController .table.table-striped thead th:nth-child(4),    
    .tableWidthController .table.table-striped thead th:nth-child(5),    
    .tableWidthController .table.table-striped tbody td:nth-child(4),
    .tableWidthController .table.table-striped tbody td:nth-child(5)    
    {
    width:10%;   
  }
  .tableWidthController .table.table-striped tbody td:nth-child(6),    
  .tableWidthController .table.table-striped thead th:nth-child(6) {
    width:8%;
  }

  // .tableWidthController .table.table-striped thead th:nth-child(7),
  // .tableWidthController .table.table-striped tbody td:nth-child(7){
  //   width:8%;
  // }
  .tableWidthController .table.table-striped thead th:nth-child(8),
  .tableWidthController .table.table-striped tbody td:nth-child(8){
    width:12%;
  }
  .tableWidthController .table.table-striped thead th:last-child,
  .tableWidthController .table.table-striped tbody td:last-child{
    width:10%;
  }

  .tableWidthController .table.table-striped tbody tr:nth-of-type(odd),
  .controller2 .table.table-striped tbody tr:nth-of-type(odd){
    background-color: #F5F6FA;
  }

.height-600 {
  height: 600px;
}
.table.table-striped{ 
  thead{
    th{
      border: unset !important;
      color: #fff;
      opacity: 0.5;
      font-size: 0.857rem;
    }
  }
  tbody {
    tr{
      &:nth-of-type(odd){
        background-color: transparent !important;
      }
      td{
        border-color: #ffffff1a;
        color: #fff;
        font-size: 0.875rem;
        vertical-align: middle;
        .screen-name {
          svg{
            width: 30px;
            height: 30px;
          }
          img{
            // border: 1px solid #686174;
            border-radius: 50%;
          }
          .profile-with-crown {
            position: relative;
            img.is_premium_customer {
              position: absolute;
              top: -3px;
              left: -7px;
            }
          }
        }
        .link{
          color: var(--primary);
        }
        & > div.text-center >svg{
          fill: var(--white);
        }
        .text-center {
          text-align: left !important;
        }
      }
    }
  } 
 }
 @media only screen and (min-width : 1429px) {
  .tableWidthController .table{
    width:100%;
  }
}
@media only screen and (min-width : 1367px) {
  .tableWidthController{
    width: 100%;      
  }

  // .tableWidthController .table{
  //   width:100%;
  // }
  .table-bordered thead td:nth-child(7), .table-bordered thead th:nth-child(7){
    width:8%;
  }
  .table-bordered thead td:last-child, .table-bordered thead th:last-child{
    width:10%;
  }  
}

