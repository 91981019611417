.height-700 .text-center {
  min-height: 700px;
  padding: 100px; }

.wrapper {
  position: relative; }
  .wrapper .overlay {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000; }
    .wrapper .overlay.is-loading {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(230, 230, 230, 0.5); }
    .wrapper .overlay .loader .spinner-wave-margin-correction div {
      margin-left: 1px;
      margin-right: 1px; }

/*
 *  Usage:
 *
 *    <div class="sk-spinner sk-spinner-rotating-plane"></div>
 *
 */
.sk-spinner-rotating-plane.sk-spinner {
  width: 30px;
  height: 30px;
  background-color: darkred;
  margin: 0 auto;
  animation: sk-rotatePlane 1.2s infinite ease-in-out; }

@keyframes sk-rotatePlane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

/*
   *  Usage:
   *
   *    <div class="sk-spinner sk-spinner-double-bounce">
   *      <div class="sk-double-bounce1"></div>
   *      <div class="sk-double-bounce2"></div>
   *    </div>
   *
   */
.sk-spinner-double-bounce.sk-spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 auto; }

.sk-spinner-double-bounce .sk-double-bounce1, .sk-spinner-double-bounce .sk-double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: darkred;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-doubleBounce 2s infinite ease-in-out; }

.sk-spinner-double-bounce .sk-double-bounce2 {
  animation-delay: -1s; }

@keyframes sk-doubleBounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

/*
   *  Usage:
   *
   *    <div class="sk-spinner sk-spinner-wave">
   *      <div class="sk-rect1"></div>
   *      <div class="sk-rect2"></div>
   *      <div class="sk-rect3"></div>
   *      <div class="sk-rect4"></div>
   *      <div class="sk-rect5"></div>
   *    </div>
   *
   */
.sk-spinner-wave.sk-spinner {
  margin: 0 auto;
  width: 50px;
  height: 30px;
  text-align: center;
  font-size: 10px; }

.sk-spinner-wave div {
  background-color: darkred;
  height: 100%;
  width: 6px;
  display: inline-block;
  animation: sk-waveStretchDelay 1.2s infinite ease-in-out; }

.sk-spinner-wave .sk-rect2 {
  animation-delay: -1.1s; }

.sk-spinner-wave .sk-rect3 {
  animation-delay: -1s; }

.sk-spinner-wave .sk-rect4 {
  animation-delay: -0.9s; }

.sk-spinner-wave .sk-rect5 {
  animation-delay: -0.8s; }

@keyframes sk-waveStretchDelay {
  0%, 40%, 100% {
    transform: scaleY(0.4); }
  20% {
    transform: scaleY(1); } }

/*
   *  Usage:
   *
   *    <div class="sk-spinner sk-spinner-wandering-cubes">
   *      <div class="sk-cube1"></div>
   *      <div class="sk-cube2"></div>
   *    </div>
   *
   */
.sk-spinner-wandering-cubes.sk-spinner {
  margin: 0 auto;
  width: 32px;
  height: 32px;
  position: relative; }

.sk-spinner-wandering-cubes .sk-cube1, .sk-spinner-wandering-cubes .sk-cube2 {
  background-color: darkred;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-wanderingCubeMove 1.8s infinite ease-in-out; }

.sk-spinner-wandering-cubes .sk-cube2 {
  animation-delay: -0.9s; }

@keyframes sk-wanderingCubeMove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5); }
  50% {
    /* Hack to make FF rotate in the right direction */
    transform: translateX(42px) translateY(42px) rotate(-179deg); }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg); }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5); }
  100% {
    transform: rotate(-360deg); } }

/*
   *  Usage:
   *
   *    <div class="sk-spinner sk-spinner-pulse"></div>
   *
   */
.sk-spinner-pulse.sk-spinner {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  background-color: darkred;
  border-radius: 100%;
  animation: sk-pulseScaleOut 1s infinite ease-in-out; }

@keyframes sk-pulseScaleOut {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1);
    opacity: 0; } }

/*
   *  Usage:
   *
   *    <div class="sk-spinner sk-spinner-chasing-dots">
   *      <div class="sk-dot1"></div>
   *      <div class="sk-dot2"></div>
   *    </div>
   *
   */
.sk-spinner-chasing-dots.sk-spinner {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;
  animation: sk-chasingDotsRotate 2s infinite linear; }

.sk-spinner-chasing-dots .sk-dot1, .sk-spinner-chasing-dots .sk-dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: darkred;
  border-radius: 100%;
  animation: sk-chasingDotsBounce 2s infinite ease-in-out; }

.sk-spinner-chasing-dots .sk-dot2 {
  top: auto;
  bottom: 0;
  animation-delay: -1s; }

@keyframes sk-chasingDotsRotate {
  100% {
    transform: rotate(360deg); } }

@keyframes sk-chasingDotsBounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

/*
   *  Usage:
   *
   *    <div class="sk-spinner sk-spinner-three-bounce">
   *      <div class="sk-bounce1"></div>
   *      <div class="sk-bounce2"></div>
   *      <div class="sk-bounce3"></div>
   *    </div>
   *
   */
.sk-spinner-three-bounce.sk-spinner {
  margin: 0 auto;
  width: 70px;
  text-align: center; }

.sk-spinner-three-bounce div {
  width: 18px;
  height: 18px;
  background-color: darkred;
  border-radius: 100%;
  display: inline-block;
  animation: sk-threeBounceDelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  animation-fill-mode: both; }

.sk-spinner-three-bounce .sk-bounce1 {
  animation-delay: -0.32s; }

.sk-spinner-three-bounce .sk-bounce2 {
  animation-delay: -0.16s; }

@keyframes sk-threeBounceDelay {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

/*
   *  Usage:
   *
   *    <div class="sk-spinner sk-spinner-circle">
   *      <div class="sk-circle1 sk-circle"></div>
   *      <div class="sk-circle2 sk-circle"></div>
   *      <div class="sk-circle3 sk-circle"></div>
   *      <div class="sk-circle4 sk-circle"></div>
   *      <div class="sk-circle5 sk-circle"></div>
   *      <div class="sk-circle6 sk-circle"></div>
   *      <div class="sk-circle7 sk-circle"></div>
   *      <div class="sk-circle8 sk-circle"></div>
   *      <div class="sk-circle9 sk-circle"></div>
   *      <div class="sk-circle10 sk-circle"></div>
   *      <div class="sk-circle11 sk-circle"></div>
   *      <div class="sk-circle12 sk-circle"></div>
   *    </div>
   *
   */
.sk-spinner-circle.sk-spinner {
  margin: 0 auto;
  width: 22px;
  height: 22px;
  position: relative; }

.sk-spinner-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.sk-spinner-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 20%;
  height: 20%;
  background-color: darkred;
  border-radius: 100%;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  animation-fill-mode: both; }

.sk-spinner-circle .sk-circle2 {
  transform: rotate(30deg); }

.sk-spinner-circle .sk-circle3 {
  transform: rotate(60deg); }

.sk-spinner-circle .sk-circle4 {
  transform: rotate(90deg); }

.sk-spinner-circle .sk-circle5 {
  transform: rotate(120deg); }

.sk-spinner-circle .sk-circle6 {
  transform: rotate(150deg); }

.sk-spinner-circle .sk-circle7 {
  transform: rotate(180deg); }

.sk-spinner-circle .sk-circle8 {
  transform: rotate(210deg); }

.sk-spinner-circle .sk-circle9 {
  transform: rotate(240deg); }

.sk-spinner-circle .sk-circle10 {
  transform: rotate(270deg); }

.sk-spinner-circle .sk-circle11 {
  transform: rotate(300deg); }

.sk-spinner-circle .sk-circle12 {
  transform: rotate(330deg); }

.sk-spinner-circle .sk-circle2:before {
  animation-delay: -1.1s; }

.sk-spinner-circle .sk-circle3:before {
  animation-delay: -1s; }

.sk-spinner-circle .sk-circle4:before {
  animation-delay: -0.9s; }

.sk-spinner-circle .sk-circle5:before {
  animation-delay: -0.8s; }

.sk-spinner-circle .sk-circle6:before {
  animation-delay: -0.7s; }

.sk-spinner-circle .sk-circle7:before {
  animation-delay: -0.6s; }

.sk-spinner-circle .sk-circle8:before {
  animation-delay: -0.5s; }

.sk-spinner-circle .sk-circle9:before {
  animation-delay: -0.4s; }

.sk-spinner-circle .sk-circle10:before {
  animation-delay: -0.3s; }

.sk-spinner-circle .sk-circle11:before {
  animation-delay: -0.2s; }

.sk-spinner-circle .sk-circle12:before {
  animation-delay: -0.1s; }

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1); } }

/*
   *  Usage:
   *
   *    <div class="sk-spinner sk-spinner-cube-grid">
   *      <div class="sk-cube"></div>
   *      <div class="sk-cube"></div>
   *      <div class="sk-cube"></div>
   *      <div class="sk-cube"></div>
   *      <div class="sk-cube"></div>
   *      <div class="sk-cube"></div>
   *      <div class="sk-cube"></div>
   *      <div class="sk-cube"></div>
   *      <div class="sk-cube"></div>
   *    </div>
   *
   */
.sk-spinner-cube-grid {
  /*
     * Spinner positions
     * 1 2 3
     * 4 5 6
     * 7 8 9
     */ }

.sk-spinner-cube-grid.sk-spinner {
  width: 30px;
  height: 30px;
  margin: 0 auto; }

.sk-spinner-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: darkred;
  float: left;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; }

.sk-spinner-cube-grid .sk-cube:nth-child(1) {
  animation-delay: 0.2s; }

.sk-spinner-cube-grid .sk-cube:nth-child(2) {
  animation-delay: 0.3s; }

.sk-spinner-cube-grid .sk-cube:nth-child(3) {
  animation-delay: 0.4s; }

.sk-spinner-cube-grid .sk-cube:nth-child(4) {
  animation-delay: 0.1s; }

.sk-spinner-cube-grid .sk-cube:nth-child(5) {
  animation-delay: 0.2s; }

.sk-spinner-cube-grid .sk-cube:nth-child(6) {
  animation-delay: 0.3s; }

.sk-spinner-cube-grid .sk-cube:nth-child(7) {
  animation-delay: 0s; }

.sk-spinner-cube-grid .sk-cube:nth-child(8) {
  animation-delay: 0.1s; }

.sk-spinner-cube-grid .sk-cube:nth-child(9) {
  animation-delay: 0.2s; }

@keyframes sk-cubeGridScaleDelay {
  0%, 70%, 100% {
    transform: scale3D(1, 1, 1); }
  35% {
    transform: scale3D(0, 0, 1); } }

/*
   *  Usage:
   *
   *    <div class="sk-spinner sk-spinner-wordpress">
   *      <span class="sk-inner-circle"></span>
   *    </div>
   *
   */
.sk-spinner-wordpress.sk-spinner {
  background-color: darkred;
  width: 30px;
  height: 30px;
  border-radius: 30px;
  position: relative;
  margin: 0 auto;
  animation: sk-innerCircle 1s linear infinite; }

.sk-spinner-wordpress .sk-inner-circle {
  display: block;
  background-color: #fff;
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 8px;
  top: 5px;
  left: 5px; }

@keyframes sk-innerCircle {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

/*
   *  Usage:
   *
   *    <div class="sk-spinner sk-spinner-fading-circle">
   *      <div class="sk-circle1 sk-circle"></div>
   *      <div class="sk-circle2 sk-circle"></div>
   *      <div class="sk-circle3 sk-circle"></div>
   *      <div class="sk-circle4 sk-circle"></div>
   *      <div class="sk-circle5 sk-circle"></div>
   *      <div class="sk-circle6 sk-circle"></div>
   *      <div class="sk-circle7 sk-circle"></div>
   *      <div class="sk-circle8 sk-circle"></div>
   *      <div class="sk-circle9 sk-circle"></div>
   *      <div class="sk-circle10 sk-circle"></div>
   *      <div class="sk-circle11 sk-circle"></div>
   *      <div class="sk-circle12 sk-circle"></div>
   *    </div>
   *
   */
.sk-spinner-fading-circle.sk-spinner {
  margin: 0 auto;
  width: 22px;
  height: 22px;
  position: relative; }

.sk-spinner-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.sk-spinner-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 18%;
  height: 18%;
  background-color: darkred;
  border-radius: 100%;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  animation-fill-mode: both; }

.sk-spinner-fading-circle .sk-circle2 {
  transform: rotate(30deg); }

.sk-spinner-fading-circle .sk-circle3 {
  transform: rotate(60deg); }

.sk-spinner-fading-circle .sk-circle4 {
  transform: rotate(90deg); }

.sk-spinner-fading-circle .sk-circle5 {
  transform: rotate(120deg); }

.sk-spinner-fading-circle .sk-circle6 {
  transform: rotate(150deg); }

.sk-spinner-fading-circle .sk-circle7 {
  transform: rotate(180deg); }

.sk-spinner-fading-circle .sk-circle8 {
  transform: rotate(210deg); }

.sk-spinner-fading-circle .sk-circle9 {
  transform: rotate(240deg); }

.sk-spinner-fading-circle .sk-circle10 {
  transform: rotate(270deg); }

.sk-spinner-fading-circle .sk-circle11 {
  transform: rotate(300deg); }

.sk-spinner-fading-circle .sk-circle12 {
  transform: rotate(330deg); }

.sk-spinner-fading-circle .sk-circle2:before {
  animation-delay: -1.1s; }

.sk-spinner-fading-circle .sk-circle3:before {
  animation-delay: -1s; }

.sk-spinner-fading-circle .sk-circle4:before {
  animation-delay: -0.9s; }

.sk-spinner-fading-circle .sk-circle5:before {
  animation-delay: -0.8s; }

.sk-spinner-fading-circle .sk-circle6:before {
  animation-delay: -0.7s; }

.sk-spinner-fading-circle .sk-circle7:before {
  animation-delay: -0.6s; }

.sk-spinner-fading-circle .sk-circle8:before {
  animation-delay: -0.5s; }

.sk-spinner-fading-circle .sk-circle9:before {
  animation-delay: -0.4s; }

.sk-spinner-fading-circle .sk-circle10:before {
  animation-delay: -0.3s; }

.sk-spinner-fading-circle .sk-circle11:before {
  animation-delay: -0.2s; }

.sk-spinner-fading-circle .sk-circle12:before {
  animation-delay: -0.1s; }

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0; }
  40% {
    opacity: 1; } }

.custom-file-input-new {
  border: 1px solid #39424A;
  display: inline-block;
  padding: 6px 5rem;
  cursor: pointer;
  border-radius: 5px;
  color: #39424A; }

.custom-file-input-new:hover {
  border: 1px solid #fff;
  color: #fff;
  background-color: #39424A; }

.file-inputgroup {
  justify-content: flex-end;
  align-items: center !important;
  border: 1px solid #39424A;
  border-radius: 5px;
  color: #39424A; }
  .file-inputgroup label {
    margin-bottom: 0;
    flex: 1 1 60%;
    width: 100%;
    text-indent: 10px; }
  .file-inputgroup div:first-child {
    flex: 1 1 40%; }
    .file-inputgroup div:first-child label {
      width: 100%; }

.custom-file-inputgroup-label {
  border-left: 1px solid #39424A;
  display: inline-block;
  padding: 5px 2rem;
  cursor: pointer;
  color: #39424A;
  margin-bottom: 0px; }

.custom-file-inputgroup-label:hover {
  color: #fff;
  background-color: #39424A; }

.validation-error {
  border-color: #dc3545 !important; }

@charset "UTF-8";
.modal-dialog.end-Premium-Modal {
  max-width: 450px; }

.modal-content {
  width: auto !important;
  height: auto !important; }

.modal-body {
  width: 75%;
  margin: 0 auto; }

.radioButton {
  text-align: left;
  white-space: nowrap; }
  .radioButton .inputReportInput {
    margin-right: 5px; }

.end-Premium-Modal .modal-content, .tip-Modal .modal-content {
  position: relative !important;
  height: auto !important;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-family) !important;
  background-color: var(--dark);
  border-radius: 1em; }

.end-Premium-Modal .chat-modal-header, .tip-Modal .chat-modal-header {
  border-bottom: none;
  padding: 0 1rem; }
  .end-Premium-Modal .chat-modal-header img, .tip-Modal .chat-modal-header img {
    opacity: 1 !important; }
  .end-Premium-Modal .chat-modal-header .close, .tip-Modal .chat-modal-header .close {
    margin-top: 0rem !important;
    color: #C4C4C4;
    font-weight: 300;
    font-size: 28px;
    padding-top: 7px;
    position: absolute;
    top: 0px;
    right: 15px; }

.end-Premium-Modal .modal-center-img, .tip-Modal .modal-center-img {
  width: 115px;
  margin: 15px auto 0px auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.end-Premium-Modal .modal-title, .tip-Modal .modal-title {
  flex: 1 1 100%;
  text-align: center;
  margin: 0 !important; }

.end-Premium-Modal h4, .tip-Modal h4 {
  font-size: 18px;
  font-weight: 700 !important;
  margin-bottom: 0.3em; }

.end-Premium-Modal p, .tip-Modal p {
  font-weight: 400;
  opacity: 0.8; }

.end-Premium-Modal .chat-modal-body .double_buttons, .tip-Modal .chat-modal-body .double_buttons {
  display: flex;
  position: relative;
  top: 32px; }
  .end-Premium-Modal .chat-modal-body .double_buttons button, .tip-Modal .chat-modal-body .double_buttons button {
    position: static !important;
    width: 100% !important;
    font-size: 12px; }

.end-Premium-Modal .chat-modal-body button, .tip-Modal .chat-modal-body button {
  line-height: 26px !important;
  z-index: 99;
  position: absolute;
  width: 70% !important;
  background-color: #3B4149;
  font-weight: 800 !important;
  color: white !important;
  border-radius: 20px !important;
  height: 35px;
  bottom: -17.5px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 1px;
  text-align: center;
  padding: 5px;
  text-transform: uppercase;
  box-shadow: 2px 14px 28px 1px rgba(0, 0, 0, 0.28);
  font-family: var(--font-family) !important;
  font-weight: 400; }

.end-Premium-Modal .chat-modal-body .form-group, .tip-Modal .chat-modal-body .form-group {
  margin-bottom: 35px !important; }

.end-Premium-Modal .chat-modal-footer, .tip-Modal .chat-modal-footer {
  background-color: var(--primary);
  min-height: 60px;
  position: relative;
  padding: 0 1rem;
  justify-content: center;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em; }
  .end-Premium-Modal .chat-modal-footer p, .tip-Modal .chat-modal-footer p {
    cursor: pointer;
    color: white;
    margin-top: 25px;
    font-size: 12px !important;
    text-decoration: underline;
    font-family: var(--font-family) !important;
    width: 100%;
    text-align: center;
    font-weight: 600; }

.tip-Modal .modal-content {
  height: 242px !important; }

.modal-dialog.tip-Modal {
  margin: 1.75rem auto; }

.tip-Modal .form-group input[type=number] {
  margin: 0 auto;
  width: 80px; }

.tip-Modal .form-group input[type=number]::-webkit-inner-spin-button,
.tip-Modal .form-group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.tip-Modal .alert {
  width: 95%;
  margin: 10px auto; }

.tip-Modal .alert.alert-danger {
  padding: 0;
  border-radius: 5px;
  background-color: #FDF1F4;
  color: #E5A0A9;
  border-color: #FDF1F4; }

.modal-backdrop.show {
  opacity: 0.3 !important; }

.modal {
  background: transparent !important; }

body.modal-open #root {
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); }

.tip-Modal .chat-modal-footer {
  border-bottom-right-radius: 0.7em !important;
  border-bottom-left-radius: 0.7em !important; }

.tip-Modal .btn {
  font-family: var(--font-family) !important;
  font-weight: 400; }

.tip-Modal .alert.alert-danger {
  color: #CD5664 !important;
  font-size: 12px; }

.online-user-details .back_btn {
  display: none;
  align-self: center; }
  .online-user-details .back_btn .btn {
    border: none;
    color: grey;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px; }
  .online-user-details .back_btn .btn:before {
    content: "\276E";
    color: #d7d7d7;
    font-size: 19px; }

.leftCol {
  height: 100vh;
  background-image: url("../../static/media/arousrgirls-splash(1).54da282e.jpg");
  background-size: cover;
  background-repeat: no-repeat; }

.performer-login-base {
  height: 100vh !important;
  overflow-y: auto; }

.performer-login {
  max-width: 492px; }
  .performer-login .loginBtn {
    border-radius: 10px;
    color: #fff;
    font-size: 0.875;
    font-weight: 900;
    height: 50px;
    text-transform: capitalize; }
  .performer-login .perf-login-head h3 {
    font-size: 1.875rem;
    line-height: 46px;
    opacity: 0.85;
    font-weight: 700; }
  .performer-login .perf-login-head p {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 31px;
    opacity: 0.6; }
  .performer-login .formWraper .form-control {
    background-color: var(--dark);
    border-color: var(--dark);
    font-weight: 800;
    font-size: 0.875rem;
    line-height: 21px;
    color: var(--white); }
  .performer-login .formWraper .form-group {
    padding-bottom: 19px;
    margin-bottom: 0;
    position: relative; }
    .performer-login .formWraper .form-group .form-control + div {
      position: absolute;
      bottom: 0;
      left: 0; }
    .performer-login .formWraper .form-group label {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #B9B9B9; }
  .performer-login .formWraper .perf-login-password {
    position: relative; }
    .performer-login .formWraper .perf-login-password .passwordEye {
      position: absolute;
      top: 40px;
      right: 10px;
      color: #f07563; }

.top_separator {
  border-top: 1px;
  border-top-style: outset; }

.status_row {
  padding: 10px; }

.customCardHeaderList:not(:last-child) {
  border-bottom: 1px solid #ffffff1a; }

.customCard.reviewCard {
  margin-bottom: 1rem !important;
  height: auto; }
  .customCard.reviewCard.engagementCard .engage-score-head {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    .customCard.reviewCard.engagementCard .engage-score-head h2 {
      margin-bottom: 0; }
    .customCard.reviewCard.engagementCard .engage-score-head svg {
      margin-left: 10px;
      position: relative;
      top: -1px; }
  .customCard.reviewCard.engagementCard .card-body {
    padding: 0;
    max-height: max-content;
    height: auto; }
  .customCard.reviewCard.engagementCard .column-heads {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    letter-spacing: -0.03em;
    text-transform: capitalize;
    color: #FD8876; }
    .customCard.reviewCard.engagementCard .column-heads span.board {
      margin-right: 15px; }
    .customCard.reviewCard.engagementCard .column-heads span.bonus {
      margin-right: 35px; }
  .customCard.reviewCard.engagementCard .selfRankingCard {
    position: unset;
    border-radius: 0; }
  .customCard.reviewCard.engagementCard .ranking-note {
    position: unset; }
  .customCard.reviewCard .customCardHeader {
    cursor: pointer; }
    .customCard.reviewCard .customCardHeader button {
      padding: 0px !important;
      color: none !important;
      border: none !important;
      border-style: none !important;
      font-weight: bold; }
    .customCard.reviewCard .customCardHeader .bg-none {
      background-color: transparent !important; }
  .customCard.reviewCard .card-body {
    overflow: hidden;
    overflow-y: auto;
    max-height: 250px; }
  .customCard.reviewCard .reviewDetails {
    padding: 15px 0;
    margin: 0px;
    border-bottom: none !important;
    padding-bottom: 0 !important; }
    .customCard.reviewCard .reviewDetails .ranks {
      margin-right: 10px;
      background: transparent;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center; }
      .customCard.reviewCard .reviewDetails .ranks span {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 1;
        letter-spacing: -0.03em;
        color: #FF6D56;
        margin-top: 3px; }
    .customCard.reviewCard .reviewDetails .reviewHead {
      font-size: 1rem;
      font-weight: 300;
      text-transform: capitalize; }
      .customCard.reviewCard .reviewDetails .reviewHead h6 {
        font-size: 1rem;
        font-weight: 300; }
    .customCard.reviewCard .reviewDetails .reviewRating {
      padding-right: 20px; }
      .customCard.reviewCard .reviewDetails .reviewRating svg {
        height: 15px;
        width: 15px;
        margin-right: 4px; }
        .customCard.reviewCard .reviewDetails .reviewRating svg .cls-1 {
          fill-rule: evenodd; }
    .customCard.reviewCard .reviewDetails .reviewDesc {
      color: #fff;
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 18px;
      opacity: 0.6; }
    .customCard.reviewCard .reviewDetails.engagementListRow {
      padding: 6px 0px;
      margin: 0 25px; }
      .customCard.reviewCard .reviewDetails.engagementListRow .hostName {
        font-size: 14px;
        line-height: 16px; }
      .customCard.reviewCard .reviewDetails.engagementListRow .reviewHead h6 {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: -0.02em;
        color: #F2F2F2; }
      .customCard.reviewCard .reviewDetails.engagementListRow .reviewHead span {
        font-size: 10px;
        line-height: 13px;
        letter-spacing: -0.03em;
        color: #BDBDBD; }
      .customCard.reviewCard .reviewDetails.engagementListRow:last-child {
        border-bottom: none;
        padding-bottom: 0; }
  .customCard.reviewCard .info_section {
    position: absolute;
    background: #333739;
    border: 1px solid rgba(89, 94, 104, 0.8);
    box-sizing: border-box;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 20px;
    left: -35px;
    width: 300px;
    z-index: 99;
    top: 50px; }
    .customCard.reviewCard .info_section .winner-text {
      font-size: 12px;
      line-height: 17px;
      color: #FFFFFF;
      opacity: 0.9;
      margin-bottom: 6px; }
    .customCard.reviewCard .info_section .dots {
      font-size: 12px;
      line-height: 15px;
      color: #B9B9B9;
      position: relative;
      margin-bottom: 6px; }
      .customCard.reviewCard .info_section .dots:before {
        content: "";
        height: 5px;
        width: 5px;
        position: absolute;
        background: #f07563;
        border-radius: 50%;
        left: -12px;
        top: 4px; }
  .customCard.reviewCard .no-data-available {
    display: flex;
    min-height: 250px;
    align-items: center;
    justify-content: center; }
  .customCard.reviewCard .no-data-available.engagement_No_data {
    min-height: 100px; }
  .customCard.reviewCard .mr-10 {
    margin-right: 10px; }
  .customCard.reviewCard .pl-12 {
    padding-left: 12px; }

.review-profile {
  border-radius: 50%;
  border: 1px solid #504d4e;
  width: 40px;
  height: 40px; }

.ranking-note {
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  padding: 6px;
  margin: 0;
  letter-spacing: -0.03em;
  color: #333333;
  background-color: #F2F0F3;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  position: relative;
  top: 16px; }

.selfRankingCard {
  display: flex;
  justify-content: space-between;
  background: #f07563;
  margin: 0px !important;
  padding: 5px 20px !important;
  position: absolute;
  width: 100%;
  bottom: 20px;
  color: #333;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-weight: bolder; }

.no-rank-para {
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0;
  background: #f07563;
  color: #333; }

.no-data-available .col {
  margin: 30px; }

.customCard.Credits-customCard .btn {
  line-height: normal;
  border-radius: 30px !important;
  min-width: 180px;
  padding: 8px 6px;
  margin: 0 0 10px; }

.customCard.Credits-customCard .Credits_btn {
  margin-top: -30px; }
  .customCard.Credits-customCard .Credits_btn .btn:hover {
    background: #3a414b; }
  .customCard.Credits-customCard .Credits_btn .btn-secondary {
    background: #3a414b; }

.customCard.Credits-customCard .card-body {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .customCard.Credits-customCard .card-body .credits-no {
    background: #eee;
    padding: 4px 8px;
    margin-bottom: 15px;
    margin-left: -5px; }

.customCard.Credits-customCard .credit-update-info {
  font-size: 12px;
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 12px; }

.customCard.Credits-customCard .pl-40 {
  padding-left: 40px; }

.customCard.Credits-customCard .customCardHeader h2 img {
  margin-left: 5px;
  width: 14px;
  transform: scaleX(-1) rotate(60deg); }

.amtTitle {
  font-size: 1rem;
  opacity: 0.8; }

.refreshBtn {
  font-size: 0.75rem !important;
  display: flex !important;
  justify-content: center !important;
  vertical-align: middle !important;
  align-items: center;
  right: 0; }

.mb-10 {
  margin-bottom: 10px !important; }

.creditBtn {
  color: #fff !important;
  background-color: transparent !important;
  border-color: #fff !important;
  min-width: unset !important;
  margin-left: auto !important; }

.cursor-pointer {
  cursor: pointer; }

.amount {
  font-size: 1.89rem;
  font-weight: 800;
  position: relative; }
  .amount sub {
    position: absolute;
    bottom: 15px;
    margin-left: 2px; }
    .amount sub small {
      font-weight: bold; }
  @media (max-width: 1230px) {
    .amount {
      font-size: 1.49rem; } }

.creditamount {
  font-weight: 800; }

.css-loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 1px solid #292a2b;
  width: 15px;
  height: 15px;
  /* Safari */
  animation: spin 2s linear infinite; }

/* Safari */

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.customCard.systemMsg {
  height: auto; }
  .customCard.systemMsg svg {
    height: 36px;
    vertical-align: text-bottom;
    width: 36px; }
    .customCard.systemMsg svg .cls-1 {
      fill-rule: evenodd; }
  .customCard.systemMsg .card-body {
    overflow: hidden;
    overflow-y: auto;
    max-height: 572px;
    min-height: 572px; }
    .customCard.systemMsg .card-body .notificationBox {
      border-bottom: 1px solid #ffffff1a; }
      .customCard.systemMsg .card-body .notificationBox .notificationMnotificationBox sg {
        line-height: 300;
        padding: 6px 10px 9px; }
      .customCard.systemMsg .card-body .notificationBox .pera {
        font-size: 0.875rem;
        opacity: 0.6;
        font-weight: 300;
        margin-top: 3px;
        margin-bottom: 0; }
        .customCard.systemMsg .card-body .notificationBox .pera p {
          margin-bottom: 0;
          line-height: 18px; }
      .customCard.systemMsg .card-body .notificationBox h6 {
        font-size: 1rem;
        font-weight: 300;
        text-transform: capitalize; }
  .customCard.systemMsg .pl-12 {
    padding-left: 12px;
    max-width: calc(100% - 48px); }
  .customCard.systemMsg .mr-10 {
    margin-right: 10px; }
  .customCard.systemMsg .notificationBox {
    margin-left: 0;
    margin-right: 0; }
    .customCard.systemMsg .notificationBox > div {
      padding-left: 0 !important;
      padding-right: 0 !important; }

.customBadge {
  width: 26px !important;
  height: 26px !important;
  border-radius: 50% !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: #FBE4E7 !important;
  margin-left: 10px !important; }

.customCard.reviewCard {
  margin-bottom: 1rem !important;
  height: auto; }
  .customCard.reviewCard .customCardHeader {
    cursor: pointer; }
    .customCard.reviewCard .customCardHeader button {
      padding: 0px !important;
      color: none !important;
      border: none !important;
      border-style: none !important;
      font-weight: bold; }
    .customCard.reviewCard .customCardHeader .bg-none {
      background-color: transparent !important; }
  .customCard.reviewCard .card-body {
    overflow: hidden;
    overflow-y: auto;
    max-height: 250px; }
  .customCard.reviewCard .reviewDetails {
    padding: 15px 0;
    margin: 0px;
    border-bottom: 1px solid #ffffff1a; }
    .customCard.reviewCard .reviewDetails .review_image_crown {
      position: relative; }
      .customCard.reviewCard .reviewDetails .review_image_crown img.is_premium_customer {
        position: absolute;
        left: -6px;
        top: 0; }
      .customCard.reviewCard .reviewDetails .review_image_crown .online-small-icon {
        width: 13px !important;
        height: 13px !important;
        display: inline-block;
        position: absolute;
        top: 29px;
        right: 2px;
        background-color: #2eb130;
        border-radius: 50%;
        border: 1px solid #ddd; }
      .customCard.reviewCard .reviewDetails .review_image_crown .offline-small-icon {
        width: 13px !important;
        height: 13px !important;
        display: inline-block;
        position: absolute;
        top: 29px;
        right: 2px;
        background-color: #9fa09f;
        border-radius: 50%;
        border: 1px solid #ddd; }
      .customCard.reviewCard .reviewDetails .review_image_crown .online-away-icon {
        width: 13px !important;
        height: 13px !important;
        display: inline-block;
        position: absolute;
        top: 29px;
        right: 2px;
        background-color: #ECAC19;
        border-radius: 50%;
        border: 1px solid #ddd; }
    .customCard.reviewCard .reviewDetails .reviewHead {
      font-size: 1rem;
      font-weight: 300;
      text-transform: capitalize; }
      .customCard.reviewCard .reviewDetails .reviewHead h6 {
        font-size: 1rem;
        font-weight: 300; }
    .customCard.reviewCard .reviewDetails .reviewRating {
      padding-right: 20px; }
      .customCard.reviewCard .reviewDetails .reviewRating svg {
        height: 15px;
        width: 15px;
        margin-right: 4px; }
        .customCard.reviewCard .reviewDetails .reviewRating svg .cls-1 {
          fill-rule: evenodd; }
    .customCard.reviewCard .reviewDetails .reviewDesc {
      color: #fff;
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 18px;
      opacity: 0.6; }
  .customCard.reviewCard .no-data-available {
    display: flex;
    min-height: 250px;
    align-items: center;
    justify-content: center; }
  .customCard.reviewCard .mr-10 {
    margin-right: 10px; }
  .customCard.reviewCard .pl-12 {
    padding-left: 12px; }

.review-profile {
  border-radius: 50%;
  border: 1px solid #504d4e; }

.topHeaderRanking svg {
  height: 35px;
  vertical-align: text-bottom;
  width: 35px;
  margin-right: 6px; }

.topHeaderRanking .cls-1 {
  fill-rule: evenodd; }

.topHeaderRanking span {
  font-size: 0.75rem;
  opacity: 0.8; }

.topHeaderRanking h5 {
  margin-top: 2px;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 1rem; }

.topHeaderRanking > .col:last-child {
  border: none; }

.topHeaderRanking .iconDiv {
  width: 32px; }

.topHeaderRanking .iconTextWrp {
  width: calc(100% - 32px);
  padding-left: 15px; }

/* ---------------------------------------------------
    CARD CONTENT STYLE
----------------------------------------------------- */
.card {
  border-radius: 0; }

.customCard {
  height: 100%; }
  .customCard .customCardHeader {
    padding-bottom: 0;
    background: transparent;
    border: 0; }
    .customCard .customCardHeader h2 {
      font-size: 1.125rem;
      line-height: 30px;
      font-weight: bold; }
    .customCard .customCardHeader .badge-secondary {
      background: #9d2731;
      border-radius: 25px;
      min-width: 18px;
      height: 18px;
      margin-left: 5px; }
  .customCard .card-body {
    padding: 0 1.25rem; }
    .customCard .card-body .customCardHeaderList {
      border-top: 0px !important;
      padding: 10px 0;
      margin-left: 0;
      margin-right: 0; }
      .customCard .card-body .customCardHeaderList > div {
        padding-left: 0;
        padding-right: 0; }
      .customCard .card-body .customCardHeaderList:last-child {
        padding-bottom: 0;
        margin-bottom: 15px; }
      .customCard .card-body .customCardHeaderList .custom-switch .custom-control-label::after {
        height: 20px;
        width: 20px;
        top: 4px;
        background-color: #fff; }
      .customCard .card-body .customCardHeaderList .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
        transform: translateX(1.5rem); }
      .customCard .card-body .customCardHeaderList .custom-switch .custom-control-label::after {
        left: calc(-2.25rem + 3px); }
      .customCard .card-body .customCardHeaderList .custom-switch .custom-control-label::before {
        width: 50px;
        height: 24px;
        pointer-events: all;
        border-radius: 15px;
        top: 2px;
        background-color: #545758; }
      .customCard .card-body .customCardHeaderList .custom-switch .custom-control-label {
        cursor: pointer;
        width: 14px; }
      .customCard .card-body .customCardHeaderList .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: #3BA520;
        background-color: #3BA520; }
      .customCard .card-body .customCardHeaderList .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: none; }
      .customCard .card-body .customCardHeaderList .custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
      .customCard .card-body .customCardHeaderList .custom-control-label::before {
        border-color: #545758; }

.topHeaderRanking {
  margin-right: -10px !important;
  margin-left: -10px !important; }
  .topHeaderRanking > div {
    padding-right: 10px;
    padding-left: 10px; }
  .topHeaderRanking .rounded {
    border-radius: 10px !important; }

.dashboard-notify-alert {
  color: #fd7663 !important;
  background-color: #f2f0f3 !important;
  border-color: #f2f0f3 !important; }

.text-danger.text-underline {
  text-decoration: underline; }

.paginationComponent .page-item.disabled .page-link {
  color: #C6C6C6 !important; }
  .paginationComponent .page-item.disabled .page-link svg {
    stroke: #C6C6C6; }

.paginationComponent .page-item.active .page-link {
  background-color: #3A414A;
  border-color: #3A414A; }

.paginationComponent .page-link {
  color: #3A414A;
  padding: 2px 0 0 0;
  min-width: 22px;
  min-height: 22px;
  line-height: 1; }

.paginationComponent .page-link:hover {
  color: #3A414A; }

.paginationComponent .page-item:last-child .page-link {
  border-top-right-radius: .50rem;
  border-bottom-right-radius: .50rem; }

.paginationComponent button[name="previous"] {
  border-top-left-radius: .50rem;
  border-bottom-left-radius: .50rem; }

.searchCustomers {
  margin-top: 12px; }
  .searchCustomers input {
    background-color: var(--dark);
    border-radius: 10px;
    font-size: 0.875rem;
    padding: 12px 15px;
    border-color: var(--dark);
    height: 44px;
    width: 274px;
    color: var(--white);
    background-image: url(/static/media/magnify.70aaf4c8.svg);
    background-repeat: no-repeat;
    background-position: left 10px center;
    text-indent: 20px;
    background-size: 18px; }
    .searchCustomers input:focus {
      background-color: var(--dark);
      border-color: var(--dark);
      color: var(--white); }

.noteTextArea {
  width: 100%; }
  .noteTextArea textarea {
    margin-bottom: 10px;
    resize: none; }

.phoneBookCard {
  background-color: #292a2b !important;
  min-height: 500px; }
  .phoneBookCard ul {
    padding: 20px;
    overflow: auto; }
    .phoneBookCard ul .customer-section {
      display: flex;
      border-bottom: 1px solid #3E3F40;
      padding-bottom: 20px;
      margin-bottom: 20px;
      justify-content: space-between; }
      .phoneBookCard ul .customer-section .customerImg {
        display: flex;
        align-items: center;
        position: relative; }
        .phoneBookCard ul .customer-section .customerImg .customerImgInner {
          position: relative; }
        .phoneBookCard ul .customer-section .customerImg .online-small-icon {
          width: 13px !important;
          height: 13px !important;
          display: inline-block;
          position: absolute;
          top: 25px;
          left: 25px;
          background-color: #2eb130;
          border-radius: 50%;
          border: 1px solid #ddd; }
        .phoneBookCard ul .customer-section .customerImg .online-away-icon {
          width: 13px !important;
          height: 13px !important;
          display: inline-block;
          position: absolute;
          top: 25px;
          left: 25px;
          background-color: #ECAC19;
          border-radius: 50%;
          border: 1px solid #ddd; }
        .phoneBookCard ul .customer-section .customerImg .offline-small-icon {
          width: 13px !important;
          height: 13px !important;
          display: inline-block;
          position: absolute;
          top: 25px;
          left: 25px;
          background-color: #9fa09f;
          border-radius: 50%;
          border: 1px solid #ddd; }
        .phoneBookCard ul .customer-section .customerImg img {
          width: 38px;
          height: 38px;
          border: 2px solid #f07563;
          border-radius: 50%;
          margin-right: 15px;
          cursor: pointer; }
        .phoneBookCard ul .customer-section .customerImg img.is_premium_customer {
          position: absolute;
          top: -3px;
          left: -6px;
          width: 20px !important;
          height: 20px !important;
          border: none !important; }
        .phoneBookCard ul .customer-section .customerImg .customerInfo {
          display: flex;
          flex-direction: column; }
          .phoneBookCard ul .customer-section .customerImg .customerInfo a {
            font-family: Overpass;
            font-style: normal;
            font-weight: 800;
            font-size: 16px;
            line-height: 25px;
            -webkit-text-decoration-line: none;
                    text-decoration-line: none;
            color: #FFFFFF;
            text-transform: capitalize; }
          .phoneBookCard ul .customer-section .customerImg .customerInfo p {
            font-family: Overpass;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: #FFFFFF;
            opacity: 0.6;
            margin-bottom: 0;
            max-width: calc(100% - 50px);
            width: 100%;
            min-width: -moz-fit-content;
            min-width: fit-content; }
      .phoneBookCard ul .customer-section .customerImg.customerImgPhonebook {
        max-width: calc(100% - 440px); }
      .phoneBookCard ul .customer-section .phonebook-option {
        justify-content: end;
        display: flex;
        align-items: center;
        max-width: 450px;
        width: 100%; }
        .phoneBookCard ul .customer-section .phonebook-option button {
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: #FD7663;
          padding: 10px;
          min-width: 110px;
          margin-right: 20px;
          height: 42px; }
          .phoneBookCard ul .customer-section .phonebook-option button svg {
            margin-right: 5px; }
          .phoneBookCard ul .customer-section .phonebook-option button:hover {
            color: #fff; }
            .phoneBookCard ul .customer-section .phonebook-option button:hover svg path {
              fill: #fff; }
          .phoneBookCard ul .customer-section .phonebook-option button:nth-child(3) {
            margin-right: 0; }

.phonebook-wrap .card-body {
  background-color: #242628;
  border-radius: 0; }

.phonebook-wrap .nav-tabs {
  border-bottom: none; }

.phonebook-wrap .nav-item {
  max-width: 200px;
  margin-bottom: -2px; }

.phonebook-wrap .nav-item .nav-link {
  padding: 20px 4px 15px;
  border: none;
  cursor: pointer;
  color: #fff; }

.phonebook-wrap .nav-item .nav-link:hover {
  color: #fd7663; }

.phonebook-wrap .nav-item .nav-link.active {
  color: #fd7663;
  background-color: #242628;
  border: unset;
  padding: 20px 4px 15px; }

@charset "UTF-8";
.modal-content {
  width: auto !important;
  height: auto !important; }

.view-able-info-modal .modal-body {
  width: 100%;
  padding: 0px 15px; }

.view-able-info-modal .modal-content {
  position: relative !important;
  height: auto !important;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-family) !important;
  background-color: #333739;
  border-radius: 1em; }

.view-able-info-modal .chat-modal-header {
  border-bottom: none;
  padding: 0 1rem; }
  .view-able-info-modal .chat-modal-header img {
    opacity: 1 !important; }
  .view-able-info-modal .chat-modal-header .close {
    margin-top: 0rem !important;
    color: #C4C4C4;
    font-weight: 300;
    font-size: 28px;
    padding-top: 7px;
    position: absolute;
    top: 0px;
    right: 15px; }

.view-able-info-modal .modal-center-img {
  width: 115px;
  margin: 15px auto 0px auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.view-able-info-modal .modal-title {
  flex: 1 1 100%;
  text-align: left;
  margin: 15px 0 15px 0px !important; }

.view-able-info-modal h4 {
  font-weight: 700;
  font-size: 17px;
  line-height: 13px;
  color: #FFFFFF; }

.view-able-info-modal p {
  font-weight: 400;
  opacity: 0.8;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #D9D9D9; }

.view-able-info-modal h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF; }

.view-able-info-modal .chat-modal-footer {
  background-color: var(--primary);
  min-height: 60px;
  position: relative;
  padding: 0 1rem;
  justify-content: center;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em; }
  .view-able-info-modal .chat-modal-footer p {
    cursor: pointer;
    color: white;
    margin-top: 25px;
    font-size: 12px !important;
    text-decoration: underline;
    font-family: var(--font-family) !important;
    width: 100%;
    text-align: center;
    font-weight: 600; }

.view-able-info-modal .viewable-info-table .view-info-table-wrap {
  margin-bottom: 15px; }

.view-able-info-modal .viewable-info-table table {
  background: transparent;
  border-radius: 6px;
  margin-bottom: 20px;
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  margin: auto;
  padding: 2px;
  border-collapse: separate;
  border-spacing: 0; }

.view-able-info-modal .viewable-info-table tr {
  border: 1px solid #E0607E; }

.view-able-info-modal .viewable-info-table th {
  border: 0.5px solid #393939;
  padding: 5px;
  background-color: #303030;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF; }

.view-able-info-modal .viewable-info-table td {
  border: 0.5px solid #393939;
  font-size: 10px;
  padding: 5px;
  background: #1E1F21; }

.view-able-info-modal .viewable-info-table tr:first-child th:first-child {
  border-top-left-radius: 10px; }

.view-able-info-modal .viewable-info-table tr:first-child th:last-child {
  border-top-right-radius: 10px; }

.view-able-info-modal .viewable-info-table tr:first-child td:first-child {
  border-top-left-radius: 10px; }

.view-able-info-modal .viewable-info-table tr:last-child td:first-child {
  border-bottom-left-radius: 10px; }

.view-able-info-modal .viewable-info-table tr:first-child td:last-child {
  border-top-right-radius: 10px; }

.view-able-info-modal .viewable-info-table tr:last-child td:last-child {
  border-bottom-right-radius: 10px; }

.view-able-info-modal .modal-content {
  height: auto !important; }

.modal-dialog.view-able-info-modal {
  margin: 1.75rem auto; }

.view-able-info-modal .form-group input[type=number] {
  margin: 0 auto;
  width: 80px; }

.view-able-info-modal .form-group input[type=number]::-webkit-inner-spin-button,
.view-able-info-modal .form-group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.view-able-info-modal .alert {
  width: 95%;
  margin: 10px auto; }

.view-able-info-modal .alert.alert-danger {
  padding: 0;
  border-radius: 5px;
  background-color: #FDF1F4;
  color: #E5A0A9;
  border-color: #FDF1F4; }

.modal-backdrop.show {
  opacity: 0.3 !important; }

.modal {
  background: transparent !important; }

body.modal-open #root {
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); }

.view-able-info-modal .chat-modal-footer {
  border-bottom-right-radius: 0.7em !important;
  border-bottom-left-radius: 0.7em !important; }

.view-able-info-modal .btn {
  font-family: var(--font-family) !important;
  font-weight: 400; }

.view-able-info-modal .alert.alert-danger {
  color: #CD5664 !important;
  font-size: 12px; }

.online-user-details .back_btn {
  display: none;
  align-self: center; }
  .online-user-details .back_btn .btn {
    border: none;
    color: grey;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px; }
  .online-user-details .back_btn .btn:before {
    content: "\276E";
    color: #d7d7d7;
    font-size: 19px; }

.customCard.Credits-customCard {
  /* Safari */ }
  .customCard.Credits-customCard .cardAmount {
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 35px;
    position: relative; }
    .customCard.Credits-customCard .cardAmount sub {
      position: absolute;
      bottom: 15px;
      margin-left: 2px; }
      .customCard.Credits-customCard .cardAmount sub small {
        font-weight: bold; }
    @media (max-width: 1280px) {
      .customCard.Credits-customCard .cardAmount {
        font-size: 1.3rem; } }
  .customCard.Credits-customCard .cardPera {
    font-weight: 500;
    font-size: 1rem;
    line-height: 12px; }
    @media (max-width: 1280px) {
      .customCard.Credits-customCard .cardPera {
        font-size: 0.8rem; } }
  .customCard.Credits-customCard .cardSubAmnt {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 20px; }
  .customCard.Credits-customCard .balanceCard {
    min-height: 100px;
    border-radius: 10px !important;
    max-width: 520px;
    margin-left: auto; }
    .customCard.Credits-customCard .balanceCard .flex-1 {
      flex: 1 1; }
    .customCard.Credits-customCard .balanceCard .balance-card-row {
      position: relative;
      top: -15px; }
    .customCard.Credits-customCard .balanceCard .how-wepay-link {
      position: absolute;
      right: 10px;
      bottom: -15px;
      cursor: pointer;
      font-size: 12px; }
    .customCard.Credits-customCard .balanceCard .cardBtn {
      padding-left: 0 !important;
      padding-right: 0 !important;
      text-align: center;
      width: 100%;
      max-width: 130px; }
      .customCard.Credits-customCard .balanceCard .cardBtn.request-pay {
        position: relative;
        left: -30px;
        top: -2px;
        margin-bottom: 5px !important; }
  .customCard.Credits-customCard .refreshIcon {
    position: absolute;
    right: 10px;
    top: 10px; }
  .customCard.Credits-customCard .css-loader {
    position: absolute;
    right: 10px;
    top: 10px;
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 1px solid #292a2b;
    width: 15px;
    height: 15px;
    /* Safari */
    animation: spin 2s linear infinite; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
  .customCard.Credits-customCard .btn {
    line-height: normal;
    border-radius: 5px;
    padding: 8px 6px;
    margin: 0 0 10px;
    min-width: unset !important;
    margin-bottom: 0 !important; }
  .customCard.Credits-customCard .cardBtn {
    font-size: 0.875rem !important;
    font-weight: normal !important; }
    @media (max-width: 1350px) {
      .customCard.Credits-customCard .cardBtn {
        font-size: 0.775rem !important;
        padding-left: 14px !important;
        padding-right: 14px !important; } }
  .customCard.Credits-customCard .Credits_btn {
    margin-top: -30px; }
    .customCard.Credits-customCard .Credits_btn .btn:hover {
      background: #3a414b; }
    .customCard.Credits-customCard .Credits_btn .btn-secondary {
      background: #3a414b; }
  .customCard.Credits-customCard .card-body {
    padding: 0 15px; }
    .customCard.Credits-customCard .card-body .credits-no {
      background: #eee;
      padding: 4px 8px;
      margin-bottom: 15px;
      margin-left: -5px; }
  .customCard.Credits-customCard .creditsHeader img {
    margin-left: 5px;
    width: 14px;
    transform: scaleX(-1) rotate(60deg); }

.mb-10 {
  margin-bottom: 10px !important; }

.width-15 {
  width: 15px; }

.cursor-pointer {
  cursor: pointer; }

ul.tabbedLink {
  display: inline-flex;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 0;
  font-weight: 500; }
  ul.tabbedLink li {
    margin-right: 10px;
    font-size: 1rem; }
  ul.tabbedLink :hover {
    cursor: pointer;
    color: #9d2731;
    text-decoration: underline; }
  ul.tabbedLink .active {
    cursor: pointer;
    font-weight: 600; }

.bottomPagination .page-link:hover {
  background-color: #3a414b;
  color: var(--white); }

.tableWidthController {
  width: 100% !important; }
  .tableWidthController .react-bootstrap-table {
    overflow-x: scroll;
    max-width: calc(100vw - 342px); }

.balanceTabs {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: -8px; }
  .balanceTabs > ul > li {
    height: 55px;
    display: inline-flex;
    align-items: center;
    padding: 0 13px;
    color: var(--white);
    opacity: 0.3;
    min-width: 165px;
    justify-content: center; }
    .balanceTabs > ul > li.active {
      background-color: var(--dark);
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      opacity: 1;
      color: var(--white);
      text-decoration: none; }
    .balanceTabs > ul > li:hover {
      color: var(--white);
      text-decoration: none; }

.pagination li {
  margin: 0 5px; }
  .pagination li button {
    background-color: transparent !important;
    border: none;
    color: var(--white) !important; }
    .pagination li button[name="previous"], .pagination li button[name="next"] {
      color: var(--primary) !important; }
  .pagination li.active {
    border: 1.5px solid var(--primary);
    border-radius: 3px;
    background-color: transparent !important;
    overflow: hidden;
    color: var(--primary) !important;
    font-weight: 500 !important; }

.payout-bg-white {
  background: #ffffff;
  border-radius: 10px;
  font-weight: 700;
  color: #292a2b;
  padding: 12px 10px;
  margin-bottom: 20px; }
  .payout-bg-white .border-bottom-style {
    border-bottom: 1px solid #d9d9d9;
    padding: 5px;
    margin-bottom: 10px; }
    .payout-bg-white .border-bottom-style:nth-child(3) {
      border-bottom: unset;
      margin-bottom: 0px; }

.modal-dialog.chat-modal.tip-Modal.height-disabled .modal-content {
  height: unset !important; }

.link {
  color: blue;
  cursor: pointer; }

@charset "UTF-8";
.modal-dialog.end-Premium-Modal {
  max-width: 450px; }

.modal-content {
  width: auto !important;
  height: auto !important; }

.modal-body {
  width: 75%;
  margin: 0 auto; }

.radioButton {
  text-align: left;
  white-space: nowrap; }
  .radioButton .inputReportInput {
    margin-right: 5px; }

.EditInfo-Modal .err_Msg {
  display: block;
  color: #FD7663; }

.EditInfo-Modal .modal-body {
  text-align: center; }
  .EditInfo-Modal .modal-body .fa.fa-phone {
    font-size: 50px;
    color: #f07563; }

.EditInfo-Modal .modal-content {
  position: relative !important;
  height: auto !important;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-family) !important;
  background-color: var(--dark);
  border-radius: 1em; }

.EditInfo-Modal .chat-modal-header {
  border-bottom: none;
  padding: 0 1rem; }
  .EditInfo-Modal .chat-modal-header img {
    opacity: 1 !important; }
  .EditInfo-Modal .chat-modal-header .close {
    margin-top: 0rem !important;
    color: #C4C4C4;
    font-weight: 300;
    font-size: 28px;
    padding-top: 7px;
    position: absolute;
    top: 0px;
    right: 15px; }

.EditInfo-Modal .modal-center-img {
  width: 115px;
  margin: 15px auto 0px auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.EditInfo-Modal .modal-title {
  flex: 1 1 100%;
  text-align: center;
  margin: 0 !important; }

.EditInfo-Modal h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 10px; }

.EditInfo-Modal .verification-code-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #C8C8C8;
  margin-bottom: 5px; }

.EditInfo-Modal .verification-num-text {
  margin-bottom: 20px; }

.EditInfo-Modal .recieve-code-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.5);
  opacity: 1; }
  .EditInfo-Modal .recieve-code-text span {
    color: #FD7663;
    margin-left: 6px;
    cursor: pointer; }

.EditInfo-Modal .chat-modal-body button {
  line-height: 26px !important;
  z-index: 1;
  position: relative;
  width: 70% !important;
  background-color: #3B4149;
  font-weight: 800 !important;
  color: white !important;
  border-radius: 20px !important;
  height: 35px;
  bottom: auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 1px;
  text-align: center;
  padding: 5px;
  text-transform: uppercase;
  box-shadow: 2px 14px 28px 1px rgba(0, 0, 0, 0.28);
  font-family: var(--font-family) !important;
  font-weight: 400; }

.EditInfo-Modal .chat-modal-body .form-group {
  margin-bottom: 35px !important; }

.EditInfo-Modal .chat-modal-footer {
  background-color: var(--primary);
  min-height: 60px;
  position: relative;
  padding: 0 1rem;
  justify-content: center;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em; }
  .EditInfo-Modal .chat-modal-footer p {
    cursor: pointer;
    color: white;
    margin-top: 25px;
    font-size: 12px !important;
    text-decoration: underline;
    font-family: var(--font-family) !important;
    width: 100%;
    text-align: center;
    font-weight: 600; }

.tip-Modal .modal-content {
  height: 300px !important; }

.modal-dialog.tip-Modal {
  margin: 1.75rem auto; }

.tip-Modal .form-group input[type=number] {
  margin: 0 auto;
  width: 80px; }

.tip-Modal .form-group input[type=number]::-webkit-inner-spin-button,
.tip-Modal .form-group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.tip-Modal .alert {
  width: 95%;
  margin: 10px auto; }

.tip-Modal .alert.alert-danger {
  padding: 0;
  border-radius: 5px;
  background-color: #FDF1F4;
  color: #E5A0A9;
  border-color: #FDF1F4; }

.modal-backdrop.show {
  opacity: 0.3 !important; }

.modal {
  background: transparent !important; }

body.modal-open #root {
  -moz-filter: unset !important;
  -o-filter: unset !important;
  -ms-filter: unset !important;
  filter: unset !important; }

.tip-Modal .chat-modal-footer {
  border-bottom-right-radius: 0.7em !important;
  border-bottom-left-radius: 0.7em !important; }

.tip-Modal .btn {
  font-family: var(--font-family) !important;
  font-weight: 400; }

.tip-Modal .alert.alert-danger {
  color: #CD5664 !important;
  font-size: 12px; }

.online-user-details .back_btn {
  display: none;
  align-self: center; }
  .online-user-details .back_btn .btn {
    border: none;
    color: grey;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px; }
  .online-user-details .back_btn .btn:before {
    content: "\276E";
    color: #d7d7d7;
    font-size: 19px; }

.delete-number-modal button.btn-primary {
  background-color: #f07563 !important;
  border-color: #f07563 !important;
  border-radius: 6px !important;
  margin: 0 10px;
  font-size: 14px;
  text-transform: capitalize !important;
  font-weight: 500 !important; }

.EditInfo-Modal.RefundConfirmationModal {
  max-width: 500px !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  margin: 0 !important; }
  .EditInfo-Modal.RefundConfirmationModal .modal-content {
    padding: 20px; }
    .EditInfo-Modal.RefundConfirmationModal .modal-content h4 {
      margin: 15px 0; }

.text-align-right {
  text-align: right; }

/* ---------------------------------------------------
    CARD CONTENT STYLE
----------------------------------------------------- */
.card {
  border-radius: 0; }

.card-body {
  padding-left: 10px; }
  .card-body .screen-name svg, .card-body .screen-name img, .card-body .description-name svg, .card-body .description-name img {
    margin-right: 1rem; }

.customCard .customCardHeader {
  padding-bottom: 0;
  background: transparent; }
  .customCard .customCardHeader h2 {
    font-size: 1.125rem;
    line-height: 30px;
    font-weight: bold; }
  .customCard .customCardHeader .badge-secondary {
    background: #9d2731;
    border-radius: 25px;
    min-width: 18px;
    height: 18px;
    margin-left: 5px; }

.customCard .card-body {
  padding: 0 1.25rem; }
  .customCard .card-body .customCardHeaderList {
    border-top: 1px solid #e5e5e5; }
    .customCard .card-body .customCardHeaderList .custom-switch .custom-control-label::after {
      height: 20px;
      width: 20px;
      top: 4px;
      border-radius: 50%; }
    .customCard .card-body .customCardHeaderList .custom-switch .custom-control-label::before {
      width: 50px;
      height: 24px;
      pointer-events: all;
      border-radius: 15px;
      top: 2px; }
    .customCard .card-body .customCardHeaderList .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: #3BA520;
      background-color: #3BA520; }

.customCard.Credits-customCard .btn {
  line-height: normal;
  border-radius: 5px;
  min-width: 180px;
  padding: 12px 6px;
  margin: 0 0 10px; }

.customCard.Credits-customCard .Credits_btn {
  margin-top: -30px; }
  .customCard.Credits-customCard .Credits_btn .btn:hover {
    background: #3a414b; }
  .customCard.Credits-customCard .Credits_btn .btn-secondary {
    background: #3a414b; }

.customCard.Credits-customCard .card-body {
  padding: 0 15px; }
  .customCard.Credits-customCard .card-body .credits-no {
    background: #eee;
    padding: 4px 8px;
    margin-bottom: 15px;
    margin-left: -5px; }

.customCard.Credits-customCard .creditsHeader img {
  margin-left: 5px;
  width: 14px;
  transform: scaleX(-1) rotate(60deg); }

.mb-10 {
  margin-bottom: 10px !important; }

.cursor-pointer {
  cursor: pointer; }

.flexOne {
  flex: 0 0 60%; }

.flexOne2Row {
  flex: 0 0 60%; }

.flexTwo {
  flex: 1 1; }

.flexSecondOne {
  flex: 0 0 85%;
  padding-right: 20px; }

.border-bottom {
  border-bottom: 1px solid #EBECF1; }

.align-bottom {
  align-self: flex-end; }

.bg-color-lightGrey {
  background-color: #EBECF1; }

.headerBorderLeft {
  font-size: 16px;
  line-height: 30px;
  margin-left: -16px !important; }

.svgbalance svg {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  fill: #2D313A; }

.headingH2Balance {
  font-size: 20px; }

.color-light-grey {
  color: #747C8C; }

.color-dark-grey {
  color: #2D313A; }

.react-bootstrap-table table th {
  color: #2D313A; }

.react-bootstrap-table table td {
  color: #747C8C; }

.bottomPagination .page-link {
  color: #747C8C; }

.react-bootstrap-table th.sortable span {
  float: right; }

.tableBorderRadius {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; }
  .tableBorderRadius thead th:first-child {
    padding-left: 0 !important; }
  .tableBorderRadius tbody td:first-child {
    padding-left: 0 !important; }

.tableWidthController, .tableWidthController2 {
  /* border: 1px solid red; */
  width: calc(100vw - 352px) !important;
  overflow: hidden;
  overflow-x: auto; }

.tableWidthController2 .react-bootstrap-table .table {
  width: auto;
  white-space: nowrap; }

.tableWidthController2 .react-bootstrap-table .table td, .tableWidthController2 .react-bootstrap-table .table .table th {
  width: max-content;
  min-width: 120px; }

.tableWidthController2 .react-bootstrap-table .table th:nth-child(8), .tableWidthController2 .react-bootstrap-table .table .table th:nth-child(8) {
  min-width: 160px; }

.tableWidthController .table {
  width: 1014px; }

.tableWidthController .table.table-striped thead th,
.controller2 .table.table-striped thead th {
  border-left: 2px solid #dee2e6; }

.tableWidthController .table.table-striped thead th:last-child,
.controller2 .table.table-striped thead th:last-child {
  border-right: 2px solid #dee2e6; }

.tableWidthController .table.table-striped thead th:nth-child(1),
.tableWidthController .table.table-striped thead th:nth-child(2),
.tableWidthController .table.table-striped tbody td:nth-child(1),
.tableWidthController .table.table-striped tbody td:nth-child(2) {
  width: 12%; }

.tableWidthController .table.table-striped thead th:nth-child(3),
.tableWidthController .table.table-striped tbody td:nth-child(3)
.tableWidthController .table.table-striped thead th:nth-child(4),
.tableWidthController .table.table-striped thead th:nth-child(5),
.tableWidthController .table.table-striped tbody td:nth-child(4),
.tableWidthController .table.table-striped tbody td:nth-child(5) {
  width: 10%; }

.tableWidthController .table.table-striped tbody td:nth-child(6),
.tableWidthController .table.table-striped thead th:nth-child(6) {
  width: 8%; }

.tableWidthController .table.table-striped thead th:nth-child(8),
.tableWidthController .table.table-striped tbody td:nth-child(8) {
  width: 12%; }

.tableWidthController .table.table-striped thead th:last-child,
.tableWidthController .table.table-striped tbody td:last-child {
  width: 10%; }

.tableWidthController .table.table-striped tbody tr:nth-of-type(odd),
.controller2 .table.table-striped tbody tr:nth-of-type(odd) {
  background-color: #F5F6FA; }

.height-600 {
  height: 600px; }

.table.table-striped thead th {
  border: unset !important;
  color: #fff;
  opacity: 0.5;
  font-size: 0.857rem; }

.table.table-striped tbody tr:nth-of-type(odd) {
  background-color: transparent !important; }

.table.table-striped tbody tr td {
  border-color: #ffffff1a;
  color: #fff;
  font-size: 0.875rem;
  vertical-align: middle; }
  .table.table-striped tbody tr td .screen-name svg {
    width: 30px;
    height: 30px; }
  .table.table-striped tbody tr td .screen-name img {
    border-radius: 50%; }
  .table.table-striped tbody tr td .screen-name .profile-with-crown {
    position: relative; }
    .table.table-striped tbody tr td .screen-name .profile-with-crown img.is_premium_customer {
      position: absolute;
      top: -3px;
      left: -7px; }
  .table.table-striped tbody tr td .link {
    color: var(--primary); }
  .table.table-striped tbody tr td > div.text-center > svg {
    fill: var(--white); }
  .table.table-striped tbody tr td .text-center {
    text-align: left !important; }

@media only screen and (min-width: 1429px) {
  .tableWidthController .table {
    width: 100%; } }

@media only screen and (min-width: 1367px) {
  .tableWidthController {
    width: 100%; }
  .table-bordered thead td:nth-child(7), .table-bordered thead th:nth-child(7) {
    width: 8%; }
  .table-bordered thead td:last-child, .table-bordered thead th:last-child {
    width: 10%; } }

.rating svg {
  height: 15px;
  width: 15px;
  margin-right: 4px; }
  .rating svg .cls-1 {
    fill-rule: evenodd; }

.screen-name {
  display: flex;
  align-items: center;
  flex: 1 1;
  position: relative; }
  .screen-name img.is_premium_customer {
    position: absolute;
    top: 0;
    left: -8px; }
  .screen-name .online-small-icon {
    width: 10px !important;
    height: 10px !important;
    display: inline-block;
    position: absolute;
    top: 22px;
    left: 20px;
    background-color: #2eb130;
    border-radius: 50%;
    border: 1px solid #ddd; }
  .screen-name .offline-small-icon {
    width: 10px !important;
    height: 10px !important;
    display: inline-block;
    position: absolute;
    top: 22px;
    left: 20px;
    background-color: #9fa09f;
    border-radius: 50%;
    border: 1px solid #ddd; }
  .screen-name .online-away-icon {
    width: 10px !important;
    height: 10px !important;
    display: inline-block;
    position: absolute;
    top: 22px;
    left: 20px;
    background-color: #ECAC19;
    border-radius: 50%;
    border: 1px solid #ddd; }
  .screen-name svg {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background: #f5f5f5;
    overflow: hidden;
    margin-right: 10px; }
  .screen-name .cust-profile-pic {
    border-radius: 50%;
    height: 30px;
    width: 30px; }

.commentBody {
  font-weight: 200 !important; }

.headTitle {
  font-size: 1.25rem;
  font-weight: 600; }

.customCardHeader {
  padding-bottom: 0;
  background: #fff !important; }
  .customCardHeader .headerBorderBottom {
    min-height: 2px;
    background: #F5F6FA;
    margin-left: 20px; }
  .customCardHeader h2 {
    font-size: 16px;
    line-height: 30px; }
    .customCardHeader h2 svg {
      width: 25px;
      height: 25px;
      margin-right: 10px;
      fill: #2D313A;
      margin-bottom: 10px; }
    .customCardHeader h2 .headTitle {
      font-size: 20px;
      align-self: flex-end; }
  .customCardHeader .badge-secondary {
    background: #9d2731;
    border-radius: 25px;
    min-width: 18px;
    height: 18px;
    margin-left: 5px; }

.bgPlaceholder {
  width: 120px;
  height: 102px;
  position: absolute;
  top: 93px;
  left: 0;
  right: 0;
  margin: auto; }

.updateBtn {
  border-radius: 10px !important;
  color: var(--white) !important;
  opacity: 1 !important;
  width: 230px;
  height: 50px; }

.btn-outline-secondary {
  color: #6c757d !important;
  background: #fff !important;
  border-color: #6c757d !important; }

.btn-outline-secondary:hover {
  color: #fff !important;
  background: #6c757d !important;
  border-color: #6c757d !important; }

.chatRateContainer {
  position: relative;
  min-height: 50px;
  background-color: #292A2B;
  border-color: #292A2B;
  color: var(--white);
  font-size: 0.875rem; }

.chatRateContainer span {
  position: absolute;
  top: 15px;
  left: 10px;
  opacity: 0.6; }

.chatRateContainer img {
  position: absolute;
  top: 11px;
  right: 8px; }

#editPrimaryPicModal .modal-header {
  background-color: #33363d;
  color: #fff;
  padding: 0.3rem; }
  #editPrimaryPicModal .modal-header .modal-title {
    margin-left: 15px;
    font-weight: 400; }

#editPrimaryPicModal .modal-content {
  background-color: var(--dark); }
  #editPrimaryPicModal .modal-content .modal-body {
    text-align: center; }
    #editPrimaryPicModal .modal-content .modal-body .badge {
      margin-left: -330px;
      margin-top: 240px;
      opacity: 0.8;
      position: absolute;
      padding: 5px;
      border: 1px solid #6c757d;
      color: #fff;
      background-color: #6c757d80; }
      #editPrimaryPicModal .modal-content .modal-body .badge i {
        padding: 3px; }
  #editPrimaryPicModal .modal-content .modal-footer .fa.fa-minus, #editPrimaryPicModal .modal-content .modal-footer .fa.fa-plus {
    cursor: pointer;
    font-size: 10px;
    line-height: 10px;
    border: 2px solid grey;
    color: grey;
    border-radius: 50%;
    padding: 2px 3px;
    display: inline-table; }
  #editPrimaryPicModal .modal-content .modal-footer .btn-secondary {
    padding: 4px 10px;
    font-size: 15px;
    background-color: #3B414A; }
  #editPrimaryPicModal .modal-content .modal-footer .ml120 {
    margin-left: 120px; }
  #editPrimaryPicModal .modal-content .modal-footer .input-range {
    width: 100px;
    margin: 0 12px; }
    #editPrimaryPicModal .modal-content .modal-footer .input-range .input-range__label {
      display: none; }
    #editPrimaryPicModal .modal-content .modal-footer .input-range .input-range__slider {
      background: none;
      border: 3.5px solid grey; }
    #editPrimaryPicModal .modal-content .modal-footer .input-range .input-range__track--active {
      background: grey; }

.profile {
  display: block; }
  .profile .card-body {
    width: 100%;
    padding: 1rem; }
    .profile .card-body div {
      align-self: baseline; }
    .profile .card-body .help_text {
      color: #868B91;
      padding-left: 0; }
    .profile .card-body .user-name {
      padding-left: 15px; }
    .profile .card-body .profile-description textarea.form-control {
      height: 150px; }
    .profile .card-body .profile-description .edit_icon {
      align-self: baseline; }
    .profile .card-body .row_detail {
      margin-left: -7.5px !important;
      margin-right: -7.5px !important; }
      .profile .card-body .row_detail > div {
        padding-left: 7.5px;
        padding-right: 7.5px; }
      .profile .card-body .row_detail input {
        min-height: 50px;
        background-color: #292A2B;
        border-color: #292A2B;
        color: var(--white);
        font-size: 0.875rem; }
      .profile .card-body .row_detail select {
        min-height: 50px;
        background-color: #292A2B;
        border-color: #292A2B;
        color: var(--white);
        font-size: 0.875rem; }
      .profile .card-body .row_detail textarea {
        background-color: #292A2B;
        border-color: #292A2B;
        color: var(--white);
        min-height: 102px;
        font-size: 0.875rem; }
      .profile .card-body .row_detail label {
        margin-bottom: 5px;
        font-size: 0.75rem;
        opacity: 0.6; }
    .profile .card-body .setting_details {
      margin: 3px auto; }
    .profile .card-body .imagePanel {
      margin-bottom: 15px;
      border-radius: 15px;
      background-color: transparent; }
      .profile .card-body .imagePanel .card-header {
        color: #F5F6F9;
        width: 100%;
        justify-content: center; }
        .profile .card-body .imagePanel .card-header canvas {
          position: absolute;
          left: 0px;
          top: 0px;
          border-radius: 15px; }
      .profile .card-body .imagePanel .card-body {
        padding: 0.6rem; }
        .profile .card-body .imagePanel .card-body button {
          padding: 4px 10px;
          font-size: 15px; }
        .profile .card-body .imagePanel .card-body .form-group {
          margin: 0; }
        .profile .card-body .imagePanel .card-body .backgroundPic {
          border: 1px solid #fff;
          width: 154px;
          height: 40px;
          cursor: pointer;
          font-size: 14px;
          padding: 8px 1px;
          display: inline-flex;
          align-items: center;
          justify-content: space-evenly;
          color: #fff;
          border-radius: 30px;
          margin-bottom: 0;
          position: absolute;
          right: 20px;
          bottom: 20px;
          font-weight: 600; }
          .profile .card-body .imagePanel .card-body .backgroundPic label {
            margin-bottom: 0; }
          .profile .card-body .imagePanel .card-body .backgroundPic svg {
            height: 20px; }
        .profile .card-body .imagePanel .card-body .absoluteImgGroup {
          position: absolute;
          right: 20px;
          bottom: 20px; }
          .profile .card-body .imagePanel .card-body .absoluteImgGroup button {
            padding: 10px 14px !important;
            font-size: 14px; }
        .profile .card-body .imagePanel .card-body .profilePic {
          width: 144px;
          height: 144px;
          border: 4px solid #2D313A;
          border-radius: 50%;
          position: absolute;
          background: #F5F6F9;
          bottom: 0;
          left: 60px;
          transform: translateY(50%); }
          .profile .card-body .imagePanel .card-body .profilePic .fa.fa-spinner {
            margin-top: 55px;
            margin-right: 55px;
            font-size: 30px;
            color: #fff; }
          .profile .card-body .imagePanel .card-body .profilePic .primaryPic {
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 50%;
            background-size: cover !important;
            background-repeat: no-repeat; }
          .profile .card-body .imagePanel .card-body .profilePic .primaryPic.blurImage {
            opacity: 0.5; }
          .profile .card-body .imagePanel .card-body .profilePic .profile-placeholder {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            background-size: contain;
            left: 0;
            background: url(../../static/media/profile_pic_placeholder.2f9b0609.svg) center center no-repeat; }
          .profile .card-body .imagePanel .card-body .profilePic .updateProfilePic label {
            cursor: pointer;
            bottom: 0;
            margin: auto;
            right: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0px;
            background: var(--primary);
            width: 37px;
            height: 37px;
            border-radius: 50%;
            color: #fff;
            position: absolute; }
            .profile .card-body .imagePanel .card-body .profilePic .updateProfilePic label svg {
              position: absolute;
              margin: auto;
              height: 20px;
              left: 0;
              right: 0;
              transition: all 0.3s ease-in-out;
              top: 9px; }
            .profile .card-body .imagePanel .card-body .profilePic .updateProfilePic label span {
              font-size: 14px;
              margin-top: 20px;
              transition: all 0.3s ease-in-out; }
            .profile .card-body .imagePanel .card-body .profilePic .updateProfilePic label * {
              transition: all 0.2s ease-in-out; }
      .profile .card-body .imagePanel .picture {
        text-align: center;
        background-size: cover;
        height: 300px; }
        .profile .card-body .imagePanel .picture::after {
          content: "";
          background: #00000066;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          pointer-events: none; }
        .profile .card-body .imagePanel .picture .backgroundPic {
          height: 300px;
          background-size: contain !important;
          background-repeat: no-repeat;
          border-radius: 15px;
          background-position: center;
          background-color: #fff; }
        .profile .card-body .imagePanel .picture .fa.fa-spinner {
          bottom: 150px;
          position: relative;
          font-size: 30px; }
        .profile .card-body .imagePanel .picture .backgroundPic.blurImage {
          opacity: 0.5; }
        .profile .card-body .imagePanel .picture .badge {
          opacity: 0.8;
          position: relative;
          margin-left: -65px;
          padding: 5px;
          margin-top: 130px;
          border: 1px solid #6c757d;
          color: #fff;
          background-color: #6c757d80; }
          .profile .card-body .imagePanel .picture .badge i {
            padding: 3px; }
        .profile .card-body .imagePanel .picture .placeholder svg {
          display: inline-flex;
          object-fit: contain;
          justify-content: center;
          align-items: center;
          background-repeat: no-repeat;
          background-position: center;
          height: 140px;
          width: 120px; }
  .profile .borderBottom {
    border-bottom: 2px solid #F5F6FA;
    margin-bottom: 15px; }
  .profile .fs-20 {
    font-size: 18px;
    line-height: 1.3;
    padding-left: 0; }
  .profile .setting_description {
    align-self: baseline; }
    .profile .setting_description .form-group {
      margin-bottom: 0px; }
      .profile .setting_description .form-group input, .profile .setting_description .form-group textarea {
        border-color: #39424A; }
    .profile .setting_description .edit_icon {
      text-align: end; }
      .profile .setting_description .edit_icon span {
        padding: 0.4rem; }
    .profile .setting_description .bg_picture img {
      max-height: 80px; }
    .profile .setting_description svg {
      height: 15px;
      width: 15px;
      cursor: pointer; }
    .profile .setting_description .btn-outline-secondary {
      width: 200px; }

.profile-wrap .nav.nav-tabs {
  border: unset; }
  .profile-wrap .nav.nav-tabs .nav-item {
    max-width: 200px;
    margin-bottom: -2px; }
    .profile-wrap .nav.nav-tabs .nav-item .nav-link {
      padding-top: 16px; }
      .profile-wrap .nav.nav-tabs .nav-item .nav-link:hover {
        border: unset !important;
        color: #f07563 !important;
        cursor: pointer; }
    .profile-wrap .nav.nav-tabs .nav-item .nav-link.active {
      color: white;
      background-color: #242628;
      border: unset;
      padding: 20px 4px 15px; }

.profile-wrap .card-body {
  background-color: #242628;
  border-radius: 8px; }

.profile-wrap .customCard {
  padding: 0 !important; }
  .profile-wrap .customCard .updateBtn.addBtn {
    background-color: unset;
    padding: 8px !important;
    color: #f07563 !important; }

@charset "UTF-8";
.modal-dialog.end-Premium-Modal {
  max-width: 450px; }

.modal-content {
  width: auto !important;
  height: auto !important; }

.modal-body {
  width: 75%;
  margin: 0 auto; }

.radioButton {
  text-align: left;
  white-space: nowrap; }
  .radioButton .inputReportInput {
    margin-right: 5px; }

.EditInfo-Modal .react-tel-input .form-control {
  width: 100%; }

.EditInfo-Modal .react-tel-input .country-list {
  top: 24px;
  left: 0; }
  .EditInfo-Modal .react-tel-input .country-list .country {
    text-align: left; }

.EditInfo-Modal .contactErrorMsg {
  text-align: left;
  display: block;
  padding: 5px 0 0;
  color: #cc4646; }

.EditInfo-Modal .modal-body {
  text-align: center; }
  .EditInfo-Modal .modal-body .fa.fa-phone {
    font-size: 50px;
    color: #f07563; }

.EditInfo-Modal .modal-content {
  position: relative !important;
  height: auto !important;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-family) !important;
  background-color: var(--dark);
  border-radius: 1em; }

.EditInfo-Modal .chat-modal-header {
  border-bottom: none;
  padding: 0 1rem; }
  .EditInfo-Modal .chat-modal-header img {
    opacity: 1 !important; }
  .EditInfo-Modal .chat-modal-header .close {
    margin-top: 0rem !important;
    color: #C4C4C4;
    font-weight: 300;
    font-size: 28px;
    padding-top: 7px;
    position: absolute;
    top: 0px;
    right: 15px; }

.EditInfo-Modal .modal-center-img {
  width: 115px;
  margin: 15px auto 0px auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.EditInfo-Modal .modal-title {
  flex: 1 1 100%;
  text-align: center;
  margin: 0 !important; }

.EditInfo-Modal h4 {
  font-size: 18px;
  font-weight: 700 !important;
  margin-bottom: 0.3em; }

.EditInfo-Modal p {
  font-weight: 400;
  opacity: 0.8; }

.EditInfo-Modal .chat-modal-body button {
  line-height: 26px !important;
  z-index: 1;
  position: relative;
  width: 70% !important;
  background-color: #3B4149;
  font-weight: 800 !important;
  color: white !important;
  border-radius: 20px !important;
  height: 35px;
  bottom: auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 1px;
  text-align: center;
  padding: 5px;
  text-transform: uppercase;
  box-shadow: 2px 14px 28px 1px rgba(0, 0, 0, 0.28);
  font-family: var(--font-family) !important;
  font-weight: 400; }

.EditInfo-Modal .chat-modal-body .form-group {
  margin-bottom: 35px !important; }

.EditInfo-Modal .chat-modal-footer {
  background-color: var(--primary);
  min-height: 60px;
  position: relative;
  padding: 0 1rem;
  justify-content: center;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em; }
  .EditInfo-Modal .chat-modal-footer p {
    cursor: pointer;
    color: white;
    margin-top: 25px;
    font-size: 12px !important;
    text-decoration: underline;
    font-family: var(--font-family) !important;
    width: 100%;
    text-align: center;
    font-weight: 600; }

.tip-Modal .modal-content {
  height: 242px !important; }

.modal-dialog.tip-Modal {
  margin: 1.75rem auto; }

.tip-Modal .form-group input[type=number] {
  margin: 0 auto;
  width: 80px; }

.tip-Modal .form-group input[type=number]::-webkit-inner-spin-button,
.tip-Modal .form-group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.tip-Modal .alert {
  width: 95%;
  margin: 10px auto; }

.tip-Modal .alert.alert-danger {
  padding: 0;
  border-radius: 5px;
  background-color: #FDF1F4;
  color: #E5A0A9;
  border-color: #FDF1F4; }

.modal-backdrop.show {
  opacity: 0.3 !important; }

.modal {
  background: transparent !important; }

body.modal-open #root {
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); }

.tip-Modal .chat-modal-footer {
  border-bottom-right-radius: 0.7em !important;
  border-bottom-left-radius: 0.7em !important; }

.tip-Modal .btn {
  font-family: var(--font-family) !important;
  font-weight: 400; }

.tip-Modal .alert.alert-danger {
  color: #CD5664 !important;
  font-size: 12px; }

.online-user-details .back_btn {
  display: none;
  align-self: center; }
  .online-user-details .back_btn .btn {
    border: none;
    color: grey;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px; }
  .online-user-details .back_btn .btn:before {
    content: "\276E";
    color: #d7d7d7;
    font-size: 19px; }

@charset "UTF-8";
.modal-dialog.end-Premium-Modal {
  max-width: 450px; }

.modal-content {
  width: auto !important;
  height: auto !important; }

.modal-body {
  width: 75%;
  margin: 0 auto; }

.radioButton {
  text-align: left;
  white-space: nowrap; }
  .radioButton .inputReportInput {
    margin-right: 5px; }

.EditInfo-Modal .err_Msg {
  display: block;
  color: #FD7663; }

.EditInfo-Modal .modal-body {
  text-align: center; }
  .EditInfo-Modal .modal-body .fa.fa-phone {
    font-size: 50px;
    color: #f07563; }

.EditInfo-Modal .modal-content {
  position: relative !important;
  height: auto !important;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-family) !important;
  background-color: var(--dark);
  border-radius: 1em; }

.EditInfo-Modal .chat-modal-header {
  border-bottom: none;
  padding: 0 1rem; }
  .EditInfo-Modal .chat-modal-header img {
    opacity: 1 !important; }
  .EditInfo-Modal .chat-modal-header .close {
    margin-top: 0rem !important;
    color: #C4C4C4;
    font-weight: 300;
    font-size: 28px;
    padding-top: 7px;
    position: absolute;
    top: 0px;
    right: 15px; }

.EditInfo-Modal .modal-center-img {
  width: 115px;
  margin: 15px auto 0px auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.EditInfo-Modal .modal-title {
  flex: 1 1 100%;
  text-align: center;
  margin: 0 !important; }

.EditInfo-Modal h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 10px; }

.EditInfo-Modal .verification-code-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #C8C8C8;
  margin-bottom: 5px; }

.EditInfo-Modal .verification-num-text {
  margin-bottom: 20px; }

.EditInfo-Modal .recieve-code-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.5);
  opacity: 1; }
  .EditInfo-Modal .recieve-code-text span {
    color: #FD7663;
    margin-left: 6px;
    cursor: pointer; }

.EditInfo-Modal .chat-modal-body button {
  line-height: 26px !important;
  z-index: 1;
  position: relative;
  width: 70% !important;
  background-color: #3B4149;
  font-weight: 800 !important;
  color: white !important;
  border-radius: 20px !important;
  height: 35px;
  bottom: auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 1px;
  text-align: center;
  padding: 5px;
  text-transform: uppercase;
  box-shadow: 2px 14px 28px 1px rgba(0, 0, 0, 0.28);
  font-family: var(--font-family) !important;
  font-weight: 400; }

.EditInfo-Modal .chat-modal-body .form-group {
  margin-bottom: 35px !important; }

.EditInfo-Modal .chat-modal-footer {
  background-color: var(--primary);
  min-height: 60px;
  position: relative;
  padding: 0 1rem;
  justify-content: center;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em; }
  .EditInfo-Modal .chat-modal-footer p {
    cursor: pointer;
    color: white;
    margin-top: 25px;
    font-size: 12px !important;
    text-decoration: underline;
    font-family: var(--font-family) !important;
    width: 100%;
    text-align: center;
    font-weight: 600; }

.tip-Modal .modal-content {
  height: 242px !important; }

.modal-dialog.tip-Modal {
  margin: 1.75rem auto; }

.tip-Modal .form-group input[type=number] {
  margin: 0 auto;
  width: 80px; }

.tip-Modal .form-group input[type=number]::-webkit-inner-spin-button,
.tip-Modal .form-group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.tip-Modal .alert {
  width: 95%;
  margin: 10px auto; }

.tip-Modal .alert.alert-danger {
  padding: 0;
  border-radius: 5px;
  background-color: #FDF1F4;
  color: #E5A0A9;
  border-color: #FDF1F4; }

.modal-backdrop.show {
  opacity: 0.3 !important; }

.modal {
  background: transparent !important; }

body.modal-open #root {
  -moz-filter: unset !important;
  -o-filter: unset !important;
  -ms-filter: unset !important;
  filter: unset !important; }

.tip-Modal .chat-modal-footer {
  border-bottom-right-radius: 0.7em !important;
  border-bottom-left-radius: 0.7em !important; }

.tip-Modal .btn {
  font-family: var(--font-family) !important;
  font-weight: 400; }

.tip-Modal .alert.alert-danger {
  color: #CD5664 !important;
  font-size: 12px; }

.online-user-details .back_btn {
  display: none;
  align-self: center; }
  .online-user-details .back_btn .btn {
    border: none;
    color: grey;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px; }
  .online-user-details .back_btn .btn:before {
    content: "\276E";
    color: #d7d7d7;
    font-size: 19px; }

.delete-number-modal button.btn-primary {
  background-color: #f07563 !important;
  border-color: #f07563 !important;
  border-radius: 6px !important;
  margin: 0 10px;
  font-size: 14px;
  text-transform: capitalize !important;
  font-weight: 500 !important; }

.edit-info ul {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  flex-wrap: wrap; }

.edit-info .list_content {
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 25%;
  margin-right: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333739;
  cursor: pointer; }
  .edit-info .list_content.active_list {
    border: 1px solid #f07563;
    pointer-events: none; }

.edit-info li {
  margin-right: 15px;
  display: flex;
  justify-content: space-between; }
  .edit-info li img {
    margin-right: 5px;
    margin-top: 3px; }

.edit-info i {
  background-color: #45494B;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer; }
  .edit-info i:before {
    color: #FD7663; }

.EditInfo-Modal {
  max-width: 360px !important; }
  .EditInfo-Modal .chat-modal-body {
    padding-top: 40px; }
    .EditInfo-Modal .chat-modal-body input.form-control {
      background: #35393e;
      border: 1px solid #35393e;
      color: white; }
    .EditInfo-Modal .chat-modal-body .flag-dropdown {
      background-color: #35393e;
      border: 1px solid #35393e; }
      .EditInfo-Modal .chat-modal-body .flag-dropdown .selected-flag {
        width: 30px; }
        .EditInfo-Modal .chat-modal-body .flag-dropdown .selected-flag:hover, .EditInfo-Modal .chat-modal-body .flag-dropdown .selected-flag.open {
          background-color: #35393e; }
        .EditInfo-Modal .chat-modal-body .flag-dropdown .selected-flag .arrow {
          border-top: 4px solid #fff;
          left: 25px; }
      .EditInfo-Modal .chat-modal-body .flag-dropdown .country-list .country:hover {
        background-color: #292a2b; }
      .EditInfo-Modal .chat-modal-body .flag-dropdown .country-list .country.highlight {
        background-color: #000000; }
    .EditInfo-Modal .chat-modal-body .doneBtn {
      background-color: #f07563 !important;
      width: 100% !important;
      font-weight: 800 !important;
      color: white !important;
      border-radius: 5px !important;
      text-transform: capitalize !important;
      padding: 10px !important;
      height: unset !important;
      margin-bottom: 20px; }

.otp-input-form > div {
  justify-content: center; }

.otp-input-form .otp-input input {
  width: 50px !important;
  height: 50px;
  color: white;
  font-size: 20px;
  text-align: center;
  border-radius: 8px;
  margin: 3px 4px;
  border: unset;
  background-color: #1E1F20; }

.otp-input-form .resend-text {
  color: #FD7663;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  margin-left: 10px; }

.ar_head_text {
  color: #FFF;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; }

.payout-card {
  border: unset !important; }
  .payout-card .card-body {
    padding-bottom: 0; }
    .payout-card .card-body .card-header {
      border: unset;
      padding: 0; }
      .payout-card .card-body .card-header h2 {
        margin: 0; }
  .payout-card .btn-outline-primary {
    padding: 16px 40px;
    border-radius: 10px;
    margin-left: 20px;
    background: transparent; }
    .payout-card .btn-outline-primary:hover {
      background: #FD7663; }

.custom-dropdown div[class*="-control"] {
  background: #2F3134;
  border: unset;
  height: 50px; }
  .custom-dropdown div[class*="-control"] span[class*="-indicatorSeparator"] {
    display: none; }

.custom-dropdown div[class*="-menu"] {
  background: #2F3134;
  border: unset;
  height: 50px; }
  .custom-dropdown div[class*="-menu"] > div {
    background: #2F3134;
    border-radius: 10px;
    padding: 0px; }
    .custom-dropdown div[class*="-menu"] > div div[class$=-option] {
      background-color: transparent;
      border-bottom: 1px solid #666666;
      font-family: 'Overpass';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.05em;
      color: #B9B9B9;
      padding: 15px 15px;
      cursor: pointer; }
      .custom-dropdown div[class*="-menu"] > div div[class$=-option]:last-child {
        border-bottom: unset; }
      .custom-dropdown div[class*="-menu"] > div div[class$=-option]:hover {
        background-color: #666666; }

.custom-dropdown div[class*="-ValueContainer"] {
  align-self: center; }
  .custom-dropdown div[class*="-ValueContainer"] div[class*="-Input"] {
    height: 50px; }
  .custom-dropdown div[class*="-ValueContainer"] div[class*="-singleValue"] {
    color: white;
    align-self: center; }

.custom-dropdown span[class*="-indicatorSeparator"] {
  display: none; }

.custom-dropdown div[class*="-singleValue"] {
  color: white; }

.ar_host_payment_radio_box {
  margin-top: -15px; }
  .ar_host_payment_radio_box .form__radio-group {
    max-width: 234px;
    width: 100%; }
    .ar_host_payment_radio_box .form__radio-group .form__radio-input {
      display: none; }
      .ar_host_payment_radio_box .form__radio-group .form__radio-input:checked + .form__radio-label .form__radio-button:after {
        opacity: 1; }
    .ar_host_payment_radio_box .form__radio-group .form__radio-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      max-width: 220px;
      background: #333739;
      padding: 17px 16px;
      border-radius: 10px;
      margin: 0 14px 0 0;
      border: 1px solid transparent;
      transition: .3s; }
      .ar_host_payment_radio_box .form__radio-group .form__radio-label:hover {
        border-color: #FD7663;
        transition: .3s; }
      .ar_host_payment_radio_box .form__radio-group .form__radio-label:focus {
        border-color: #FD7663;
        transition: .3s; }
      .ar_host_payment_radio_box .form__radio-group .form__radio-label .form__radio-label-text {
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px; }
    .ar_host_payment_radio_box .form__radio-group .form__radio-button {
      height: 16px;
      width: 16px;
      border: 1px solid #FD7663;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      transform: translateY(-2px); }
      .ar_host_payment_radio_box .form__radio-group .form__radio-button::after {
        content: "";
        display: block;
        height: 10px;
        width: 10px;
        position: absolute;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transition: opacity 0.1s;
        transform: translate(-50%, -50%);
        background-color: #FD7663;
        opacity: 0; }

.ar_payment_form_box {
  margin-top: -20px; }
  .ar_payment_form_box .ar_payment_form_title {
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal; }
  .ar_payment_form_box .ar_payment_form_label {
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    opacity: 0.6;
    margin-bottom: 0; }
  .ar_payment_form_box .ar_payment_form_input {
    border-radius: 5px;
    background: rgba(51, 55, 57, 0.7);
    border: none;
    max-width: 480px;
    width: 100%;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    height: 50px;
    padding-left: 20px; }
    .ar_payment_form_box .ar_payment_form_input:focus {
      background-color: rgba(51, 55, 57, 0.7);
      border-color: transparent;
      outline: 0;
      box-shadow: none;
      color: #fff; }
  .ar_payment_form_box .text-danger {
    font-size: 12px; }
  .ar_payment_form_box .btn-outline-primary {
    padding: 16px 40px;
    border-radius: 10px;
    margin-left: 20px;
    background: transparent; }
    .ar_payment_form_box .btn-outline-primary:hover {
      background: #FD7663; }

.modal-dialog.end-Premium-Modal {
  max-width: 450px; }

.modal-content {
  width: auto !important;
  height: auto !important; }

.modal-body {
  width: 75%;
  margin: 0 auto; }

.radioButton {
  text-align: left;
  white-space: nowrap; }
  .radioButton .inputReportInput {
    margin-right: 5px; }

.modal-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  position: absolute !important;
  min-width: 350px; }

.EditInfo-Modal .react-tel-input .form-control {
  width: 100%; }

.EditInfo-Modal .react-tel-input .country-list {
  top: 24px;
  left: 0; }
  .EditInfo-Modal .react-tel-input .country-list .country {
    text-align: left; }

.EditInfo-Modal .modal-body {
  text-align: center; }
  .EditInfo-Modal .modal-body .fa.fa-phone {
    font-size: 50px;
    color: #f07563; }

.EditInfo-Modal .modal-content {
  position: relative !important;
  height: auto !important;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-family) !important;
  background-color: var(--dark);
  border-radius: 1em; }

.EditInfo-Modal .chat-modal-header {
  border-bottom: none;
  padding: 0 1rem; }
  .EditInfo-Modal .chat-modal-header img {
    opacity: 1 !important; }
  .EditInfo-Modal .chat-modal-header .close {
    margin-top: 0rem !important;
    color: #C4C4C4;
    font-weight: 300;
    font-size: 28px;
    padding-top: 7px;
    position: absolute;
    top: 0px;
    right: 15px; }

.EditInfo-Modal .modal-center-img {
  width: 115px;
  margin: 15px auto 0px auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.EditInfo-Modal .modal-title {
  flex: 1 1 100%;
  text-align: center;
  margin: 0 !important; }

.EditInfo-Modal h4 {
  font-size: 18px;
  font-weight: 700 !important;
  margin-bottom: 0.3em; }

.EditInfo-Modal p {
  font-weight: 400;
  opacity: 0.8; }

.EditInfo-Modal .chat-modal-body button {
  line-height: 26px !important;
  z-index: 1;
  position: relative;
  width: 70% !important;
  background-color: #3B4149;
  font-weight: 800 !important;
  color: white !important;
  border-radius: 20px !important;
  height: 35px;
  bottom: auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 1px;
  text-align: center;
  padding: 5px;
  text-transform: uppercase;
  box-shadow: 2px 14px 28px 1px rgba(0, 0, 0, 0.28);
  font-family: var(--font-family) !important;
  font-weight: 400; }

.EditInfo-Modal .chat-modal-body .form-group {
  margin-bottom: 0px !important; }

.EditInfo-Modal .chat-modal-footer {
  background-color: var(--primary);
  min-height: 60px;
  position: relative;
  padding: 0 1rem;
  justify-content: center;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em; }
  .EditInfo-Modal .chat-modal-footer p {
    cursor: pointer;
    color: white;
    margin-top: 25px;
    font-size: 12px !important;
    text-decoration: underline;
    font-family: var(--font-family) !important;
    width: 100%;
    text-align: center;
    font-weight: 600; }

.modal-backdrop.show {
  opacity: 0.3 !important; }

.modal {
  background: transparent !important; }

body.modal-open #root {
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); }

.hideMeBtn {
  font-size: 0.938rem !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
  padding: 0.89em 2.9em !important; }

.clearAllBtn {
  font-size: 0.875rem !important;
  font-weight: 700 !important;
  border-radius: 10px !important;
  padding: 0.79em 1.6em !important; }

.exc-cntry-tag {
  padding: 0 10px;
  width: 230px;
  margin-bottom: 20px; }
  .exc-cntry-tag .innerTag {
    border-radius: 10px;
    padding: 12px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .exc-cntry-tag .innerTag span {
      font-weight: 700;
      font-size: 14px;
      line-height: 21px; }
    .exc-cntry-tag .innerTag .cursorPointer {
      cursor: pointer; }

.taglistROw {
  margin-left: -10px !important;
  margin-right: -10px !important; }

.country-list {
  background-color: #333739 !important;
  position: absolute !important;
  min-width: 350px !important;
  top: 11px;
  left: 154px;
  z-index: 1; }
  .country-list .ftBtn {
    border-radius: 10px;
    height: 50px; }
  .country-list .card-header {
    border-bottom: 0; }
    .country-list .card-header h6 {
      font-weight: 700; }
    .country-list .card-header i {
      cursor: pointer;
      color: #5D6569; }
    .country-list .card-header .searchRegionField {
      border-radius: 30px;
      background-color: #333739;
      border-color: #66666680;
      font-size: 0.875rem;
      font-weight: normal;
      color: #fff; }
      .country-list .card-header .searchRegionField:focus {
        background-color: #333739; }
  .country-list .checkBoxList {
    max-height: 150px;
    overflow-y: auto; }
    .country-list .checkBoxList .list-group-item {
      background: transparent;
      border-width: 0 0 1px 0;
      border-color: #66666680;
      padding-left: 0; }
      .country-list .checkBoxList .list-group-item .customCheckbox {
        position: relative;
        padding-left: 28px; }
        .country-list .checkBoxList .list-group-item .customCheckbox input {
          opacity: 0; }
        .country-list .checkBoxList .list-group-item .customCheckbox input:checked + label {
          color: var(--primary); }
          .country-list .checkBoxList .list-group-item .customCheckbox input:checked + label:before {
            border-color: var(--primary); }
          .country-list .checkBoxList .list-group-item .customCheckbox input:checked + label::after {
            opacity: 1; }
        .country-list .checkBoxList .list-group-item .customCheckbox > label:before {
          border: 1px solid #666;
          content: "";
          height: 16px;
          width: 16px;
          position: absolute;
          left: 0;
          border-radius: 3px;
          transition: .3s; }
        .country-list .checkBoxList .list-group-item .customCheckbox > label:after {
          transform: rotate(45deg);
          height: 8px;
          width: 4px;
          border-bottom: 2px solid var(--primary);
          border-right: 2px solid var(--primary);
          content: "";
          position: absolute;
          left: 6px;
          top: 5px;
          opacity: 0;
          transition: .3s; }
      .country-list .checkBoxList .list-group-item label {
        margin-bottom: 0;
        font-size: 0.875rem;
        font-weight: 600;
        color: #B9B9B9; }

.loderWraper .height-700 .text-center {
  min-height: unset !important;
  padding-top: 17px; }

.subheadingText {
  color: #fff;
  opacity: 0.8;
  font-size: 16px; }

.subText {
  color: #fff;
  font-size: 14px;
  opacity: 0.6; }

.topHead {
  margin-top: 25px;
  font-size: 20px; }

.headingWrap {
  display: flex; }
  .headingWrap img {
    position: relative;
    top: 8px;
    left: 15px; }

.back-btn-wrap {
  margin-top: 25px;
  cursor: pointer;
  padding: 0 0 0 8px; }

.profileHead {
  display: flex;
  align-items: center;
  padding: 8px; }
  .profileHead .profile-content-wrap {
    margin-left: 20px; }
    .profileHead .profile-content-wrap .profile-username {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 10px;
      letter-spacing: -0.02em;
      color: #FFFFFF;
      text-transform: capitalize; }
    .profileHead .profile-content-wrap .profile-user-about {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.02em;
      color: #FFFFFF;
      opacity: 0.7; }
  .profileHead .profileImage {
    width: 130px;
    height: 130px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #484C54; }
  .profileHead .card-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    text-transform: capitalize;
    margin: 0; }
  .profileHead .card-text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    opacity: 0.7;
    max-width: 350px; }
  .profileHead .like-btn-wrap {
    display: flex;
    flex-direction: column; }
    .profileHead .like-btn-wrap .like-all-btn {
      background: transparent;
      color: #f07563;
      width: 135px;
      height: 45px;
      border: 1px solid #f07563;
      margin: 10px 0; }
      .profileHead .like-btn-wrap .like-all-btn:hover, .profileHead .like-btn-wrap .like-all-btn:focus {
        color: var(--white);
        background-color: #f07563;
        border-color: #f07563; }
        .profileHead .like-btn-wrap .like-all-btn:hover svg path, .profileHead .like-btn-wrap .like-all-btn:focus svg path {
          fill: white; }
      .profileHead .like-btn-wrap .like-all-btn a {
        margin-right: 10px; }
      .profileHead .like-btn-wrap .like-all-btn span {
        position: relative;
        top: 2px; }
      .profileHead .like-btn-wrap .like-all-btn svg {
        margin-right: 10px;
        margin-top: 2px; }

.profile-feed-card-ul .feed-card-li .card {
  border: unset; }

.profile-feed-card-ul .feed-card-li .feedCardImage {
  object-fit: cover; }

.video-call {
  background-color: black; }
  .video-call .cust-voice-media {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }
    .video-call .cust-voice-media .img-with-crown .profile-img {
      width: 100px; }
    .video-call .cust-voice-media .img-with-crown .is_premium_customer {
      left: 12px; }
  .video-call .img-with-crown {
    position: relative; }
    .video-call .img-with-crown img.is_premium_customer {
      position: absolute;
      left: 0;
      top: 0; }
  .video-call .cust-img-rating-wrap {
    background-color: #212222;
    border-radius: 10px;
    padding: 7px; }
  .video-call .rating-error {
    font-size: 14px !important;
    color: red;
    margin: 5px 0;
    text-align: center; }
  .video-call .chat-videocall-customer-review {
    display: flex;
    align-items: center;
    padding: 0px 0px 10px; }
    .video-call .chat-videocall-customer-review .chat-reviews {
      border-right: 0.6px solid #505050;
      padding: 0 10px;
      height: 45px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative; }
      .video-call .chat-videocall-customer-review .chat-reviews .rating_title, .video-call .chat-videocall-customer-review .chat-reviews .rating_number {
        font-size: 12px; }
      .video-call .chat-videocall-customer-review .chat-reviews .rating_number {
        position: relative;
        top: 4px; }
      .video-call .chat-videocall-customer-review .chat-reviews .semicircle-container {
        position: relative;
        top: 5px; }
      .video-call .chat-videocall-customer-review .chat-reviews .title_container {
        flex-direction: column;
        display: flex;
        align-items: center;
        position: relative;
        top: -14px; }
      .video-call .chat-videocall-customer-review .chat-reviews:last-child {
        border-right: none; }
      .video-call .chat-videocall-customer-review .chat-reviews .rating-title {
        margin-right: 10px;
        font-family: 'Overpass';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 9px;
        letter-spacing: -0.03em;
        color: rgba(255, 255, 255, 0.8); }
      .video-call .chat-videocall-customer-review .chat-reviews span svg {
        margin-right: 2px; }
  .video-call .report_underage_btn {
    position: fixed;
    left: 150px;
    bottom: 45px;
    border: 1px solid white !important;
    padding: 10px 15px;
    border-radius: 25px;
    font-size: 18px;
    background-color: transparent;
    color: #fff; }
  .video-call .report_underage_btn.normal-call-btn {
    left: 20px; }
  .video-call .skip_Call_wrap {
    position: fixed;
    bottom: 20px;
    left: 20px;
    display: flex;
    color: white;
    z-index: 999;
    align-items: flex-end; }
    .video-call .skip_Call_wrap.skip-btn-wrap {
      bottom: 45px !important; }
    .video-call .skip_Call_wrap .skip-wrap {
      margin-right: 20px; }
      .video-call .skip_Call_wrap .skip-wrap .skip-btn {
        cursor: pointer;
        display: flex;
        background: #333739;
        width: 99px;
        padding-top: 22px;
        padding-bottom: 22px;
        justify-content: center;
        border-radius: 38px;
        border: 1px solid #fff; }
        .video-call .skip_Call_wrap .skip-wrap .skip-btn img {
          margin-right: 10px; }
      .video-call .skip_Call_wrap .skip-wrap p {
        font-family: Overpass;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 10px;
        text-align: center;
        color: #FFFFFF;
        margin: 0;
        position: relative;
        top: 2px; }
        @media (max-width: 900px) {
          .video-call .skip_Call_wrap .skip-wrap p {
            font-size: 14px; } }
    .video-call .skip_Call_wrap .callWrap {
      color: white;
      z-index: 999;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: flex-start; }
      .video-call .skip_Call_wrap .callWrap .paidCall {
        display: flex;
        padding: 16px 15px;
        background: #333739;
        font-size: 12px;
        background: #FD7663;
        border-radius: 10px;
        font-size: 12px;
        align-items: center;
        justify-content: center; }
        @media (max-width: 900px) and (orientation: landscape) {
          .video-call .skip_Call_wrap .callWrap .paidCall {
            font-size: 10px;
            padding: 10px 6px; } }
        .video-call .skip_Call_wrap .callWrap .paidCall img {
          margin-right: 6px; }
      .video-call .skip_Call_wrap .callWrap .freeCall {
        padding: 16px 19px;
        background: #333739;
        font-size: 12px;
        background: #333739;
        border-radius: 10px;
        position: absolute;
        left: 0;
        top: -70px; }
        @media (max-width: 900px) and (orientation: landscape) {
          .video-call .skip_Call_wrap .callWrap .freeCall {
            font-size: 10px;
            padding: 12px 6px; } }
        .video-call .skip_Call_wrap .callWrap .freeCall p {
          font-size: 12px; }
          @media (max-width: 900px) and (orientation: landscape) {
            .video-call .skip_Call_wrap .callWrap .freeCall p {
              font-size: 10px; } }
      .video-call .skip_Call_wrap .callWrap p {
        margin: 10px 0 0 0;
        text-align: center;
        font-size: 12px;
        margin: 0; }
        @media (max-width: 900px) {
          .video-call .skip_Call_wrap .callWrap p {
            font-size: 10px; } }
  .video-call .payment-info-text {
    font-size: 12px;
    height: 25px;
    padding-top: 10px;
    display: flex;
    align-content: space-between;
    justify-content: space-around;
    align-items: stretch; }
  .video-call .performer-screen .volumeIcon {
    cursor: pointer;
    width: 60px;
    height: 60px;
    text-align: center;
    color: #fff;
    font-size: 25px;
    background-color: #0000007a;
    padding: 8px;
    border-radius: 50%;
    transform: none;
    position: unset;
    bottom: 25px;
    left: 47%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px; }
    @media (max-width: 900px) {
      .video-call .performer-screen .volumeIcon {
        width: 35px;
        height: 35px;
        font-size: 18px;
        bottom: 3%; } }
  .video-call .performer-screen .volumeIcon.voice-call {
    cursor: pointer;
    width: 60px;
    height: 60px;
    text-align: center;
    color: #fff;
    font-size: 25px;
    background-color: #0000007a;
    padding: 8px;
    border-radius: 50%;
    transform: none;
    position: unset;
    bottom: 25px;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 900px) {
      .video-call .performer-screen .volumeIcon.voice-call {
        width: 35px;
        height: 35px;
        font-size: 18px;
        bottom: 3%; } }
  .video-call .performer-screen .fa-microphone-slash {
    color: #92A9B5; }
  .video-call .performer-screen .fullScreenIconWrap {
    cursor: pointer;
    width: 60px;
    height: 60px;
    text-align: center;
    color: #fff;
    font-size: 25px;
    background-color: #0000007a;
    padding: 8px;
    border-radius: 50%;
    transform: none;
    position: unset;
    bottom: 25px;
    left: 53%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px; }
    @media (max-width: 900px) {
      .video-call .performer-screen .fullScreenIconWrap {
        width: 35px;
        height: 35px;
        font-size: 18px;
        bottom: 3%;
        left: 55%; } }
    .video-call .performer-screen .fullScreenIconWrap img {
      width: 60%; }
  .video-call .performer-screen .fa.fa-volume-down.volumeIcon {
    padding: 8px 12px; }
  .video-call .performer-screen .fa.fa-volume-off.volumeIcon {
    padding: 8px 14px; }
  .video-call .performer-screen .input-range {
    height: 1rem;
    position: fixed;
    bottom: 40px;
    width: 15%;
    left: 48%;
    border: 1px solid #333;
    background-color: #333;
    padding: 10px;
    border-radius: 15px; }
    .video-call .performer-screen .input-range .input-range__label {
      display: none; }
    .video-call .performer-screen .input-range .input-range__track--background {
      left: 16px;
      right: 10px; }
    .video-call .performer-screen .input-range .input-range__track {
      background-color: #888;
      height: 0.2rem; }
      .video-call .performer-screen .input-range .input-range__track .input-range__track--active {
        background-color: #fff; }
    .video-call .performer-screen .input-range .input-range__slider {
      background-color: #fff;
      border-color: #fff;
      height: 0.8rem;
      width: 0.8rem;
      margin-top: -0.4rem; }
  .video-call #remote-media-div video {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    object-fit: contain;
    background-color: #1f1f1f; }
  .video-call .chat-header {
    position: fixed;
    width: 100%;
    padding: 0px;
    z-index: 1;
    top: 20px;
    align-items: end; }
    @media (max-width: 900px) {
      .video-call .chat-header {
        padding: 0; } }
  .video-call .no-camera {
    margin: 20px;
    text-align: center; }
  .video-call .customerName {
    text-transform: capitalize;
    color: #fff;
    font-size: 20px;
    margin: 20px 0; }
    @media (max-width: 900px) {
      .video-call .customerName {
        margin: 10px 0;
        font-size: 14px; } }
  .video-call .closeChatIcon {
    cursor: pointer;
    padding: 0;
    font-size: 20px;
    border: 2px solid lightgrey;
    background: lightgrey;
    color: #fff;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center; }
  .video-call .videoElement {
    background: aliceblue;
    position: fixed;
    width: 200px;
    right: 25px;
    bottom: 25px; }
    @media (max-width: 900px) {
      .video-call .videoElement {
        width: 120px; } }
  .video-call .arousr_text_logo {
    border: 2px solid white;
    border-radius: 50%; }
  .video-call .performer-name {
    margin: 0 0 0 15px;
    text-transform: capitalize;
    color: #fff;
    font-size: 18px; }
    @media (max-width: 900px) {
      .video-call .performer-name {
        margin: 0 0 0 10px;
        font-size: 14px; } }
  .video-call .counterWrap {
    position: relative;
    right: 45px;
    text-align: right; }
    @media (max-width: 900px) {
      .video-call .counterWrap {
        right: 35px; } }
    .video-call .counterWrap .close-btn {
      background: transparent;
      border: unset;
      outline: none; }
  .video-call .timer-wrap {
    letter-spacing: 3px;
    padding: 10px 15px;
    border: 1px solid white;
    border-radius: 25px;
    color: #fff;
    margin: 20px 0 0 0; }
    @media (max-width: 900px) {
      .video-call .timer-wrap {
        padding: 5px 8px;
        font-size: 14px; } }
    .video-call .timer-wrap .timer-icon {
      margin-right: 10px; }
  .video-call #counter {
    color: #fff; }
  .video-call .arousr_logo {
    width: 180px;
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .video-call .profile-img {
    border: 2px solid white;
    border-radius: 50%;
    width: 60px; }
    @media (max-width: 900px) {
      .video-call .profile-img {
        width: 45px; } }
  .video-call .call-loading-text {
    text-align: center !important; }
    .video-call .call-loading-text h3 {
      margin-top: 20px;
      font-weight: 300; }
    .video-call .call-loading-text p {
      font-size: 18px;
      font-weight: 300; }
  .video-call .arousr_logo_big {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 0; }
    .video-call .arousr_logo_big .arousr_logo {
      height: auto; }
  .video-call .close-chat-button {
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    background: #535353;
    border-radius: 2rem;
    border: white 2px solid;
    line-height: 40px;
    font-size: 20px;
    text-transform: uppercase;
    width: 25%;
    justify-content: center;
    color: #fd7663;
    background: transparent;
    border: 2px solid #fd7663;
    color: #fd7663;
    text-align: center;
    padding: 15px;
    width: 100%; }
  .video-call .close-chat-button:hover {
    background: #fd7663;
    color: white; }
  .video-call .duration-info {
    text-align: end;
    border-right: white 1px solid;
    padding: 5px 15px; }
  .video-call .earned-info {
    text-align: start;
    padding: 5px 15px; }
  .video-call .mt-20 {
    margin: 20px 0px;
    justify-content: center; }

.loading-screen {
  z-index: 9;
  background-color: #292929;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center; }
  .loading-screen .call-loading-text img {
    position: relative;
    top: -4px;
    height: 57px; }
  .loading-screen .call-loading-text p {
    margin-bottom: 0;
    color: #dcdcdc;
    font-size: 14px; }
  .loading-screen .call-loading-text h3 {
    color: #dcdcdc;
    opacity: 1; }

.arousr_logo {
  width: 40px;
  height: 40px; }

.c-888 {
  color: #888; }

.cross-icon {
  width: 20px;
  height: 20px;
  opacity: 0.5;
  cursor: pointer; }

.stop-icon {
  cursor: pointer; }

.bar-seprate-line-wrap.videocall {
  transform: rotate(109deg) !important;
  z-index: 1;
  position: absolute;
  left: 76% !important;
  top: 20px !important; }
  .bar-seprate-line-wrap.videocall.creativity {
    left: 74% !important; }
  .bar-seprate-line-wrap.videocall .bar-seprate-line {
    height: 7px;
    width: 0.8px;
    background: #3E3E3E;
    position: absolute; }
  .bar-seprate-line-wrap.videocall .bar-line-1 {
    left: 11px;
    transform: rotate(186deg);
    top: 29.4px !important; }
  .bar-seprate-line-wrap.videocall .bar-line-2 {
    left: 1px !important;
    transform: rotate(43deg);
    top: 25px !important; }
  .bar-seprate-line-wrap.videocall .bar-line-3 {
    left: -4.55px;
    transform: rotate(88deg);
    top: 11px; }
  .bar-seprate-line-wrap.videocall .bar-line-4 {
    left: -1px;
    transform: rotate(125deg);
    top: 1px; }

.icons-container {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  bottom: 39px;
  left: 0;
  flex-direction: row-reverse; }

.fav-icon-wrap-call {
  background: #0000008a;
  width: 60px;
  height: 60px;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  z-index: 999999;
  position: unset;
  bottom: 25px;
  left: 41%;
  transform: none; }

.enable-fav svg path {
  fill-opacity: 1;
  stroke: #fd7663; }

.anderAge-Modal {
  margin: 50px auto !important;
  transform: translate(-50%, -50%) !important;
  left: 50%;
  position: absolute !important;
  top: 35%; }
  .anderAge-Modal .modal-content {
    background-color: #333739;
    max-width: 380px !important;
    margin: 0 auto; }
    .anderAge-Modal .modal-content .modal-title {
      font-size: 1rem;
      line-height: 25px; }
    .anderAge-Modal .modal-content .modal-header {
      justify-content: center;
      position: relative;
      border-bottom: 0; }
      .anderAge-Modal .modal-content .modal-header button {
        padding: 10px;
        margin: 0;
        position: absolute;
        right: 0;
        top: 0; }
        .anderAge-Modal .modal-content .modal-header button span {
          color: #5D6569;
          text-shadow: none; }
    .anderAge-Modal .modal-content .modal-body {
      width: 100%;
      padding-left: 30px;
      padding-right: 30px; }
      .anderAge-Modal .modal-content .modal-body h4 {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 25px;
        text-align: center;
        color: #FFFFFF; }
      .anderAge-Modal .modal-content .modal-body .modal-center-img {
        margin-bottom: 1rem; }
      .anderAge-Modal .modal-content .modal-body figure {
        text-align: center; }
        .anderAge-Modal .modal-content .modal-body figure img {
          width: 110px !important;
          height: 110px !important;
          object-fit: cover;
          border-radius: 5px; }
      .anderAge-Modal .modal-content .modal-body .doneBtn {
        width: 230px;
        border-radius: 10px;
        height: 50px;
        margin-top: 0em; }

.customer_reviews {
  background: #373737;
  border-radius: 10px;
  padding: 15px 20px; }

.customer_reviews h4 {
  font-size: 16px; }

.customer_reviews ul {
  list-style: none;
  margin: 0;
  padding: 0; }

.customer_reviews ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ccc; }

.customer_reviews ul li:last-child {
  border-bottom: none; }

.yellowStar {
  color: #ffa500; }

.greyStar {
  color: #c3bfbf; }

.ChatSection {
  min-height: 515px; }

.ChatSection.ChatSectionMsgArea .height-700 .text-center {
  min-height: 395px;
  padding: 0;
  max-height: 395px;
  display: flex;
  justify-content: center;
  align-items: center; }

.chat-card {
  padding: 8px 0; }

.active-bg-color {
  background-color: #000; }

.conversion-header .img_with_crown {
  position: relative; }
  .conversion-header .img_with_crown img.is_premium_customer {
    position: absolute;
    left: -5px;
    top: 0; }
  .conversion-header .img_with_crown .online-small-icon {
    width: 13px !important;
    height: 13px !important;
    display: inline-block;
    position: absolute;
    top: 60px;
    right: 5px;
    background-color: #2eb130;
    border-radius: 50%;
    border: 1px solid #ddd; }
  .conversion-header .img_with_crown .offline-small-icon {
    width: 13px !important;
    height: 13px !important;
    display: inline-block;
    position: absolute;
    top: 60px;
    right: 5px;
    background-color: #9fa09f;
    border-radius: 50%;
    border: 1px solid #ddd; }
    @media (max-width: 767px) {
      .conversion-header .img_with_crown .offline-small-icon {
        top: 40px; } }
  .conversion-header .img_with_crown .online-away-icon {
    width: 13px !important;
    height: 13px !important;
    display: inline-block;
    position: absolute;
    top: 60px;
    right: 5px;
    background-color: #ECAC19;
    border-radius: 50%;
    border: 1px solid #ddd; }

.conversion-header svg.avatar-image {
  margin: 15px 0 15px 10px; }
  @media (max-width: 767px) {
    .conversion-header svg.avatar-image {
      margin-left: 0;
      width: 40px;
      height: 40px; } }

.conversion-header img {
  margin-left: 10px;
  margin: 15px 0 15px 10px;
  border-radius: 50%; }

@media (max-width: 767px) {
  .conversion-header .switch {
    position: absolute;
    right: 18px;
    top: 33px; } }

.online-small-icon {
  width: 13px !important;
  height: 13px !important;
  display: inline-block;
  position: absolute;
  top: 40px;
  right: 17px;
  background-color: #2eb130;
  border-radius: 50%;
  border: 1px solid #ddd; }

.offline-small-icon {
  width: 13px !important;
  height: 13px !important;
  display: inline-block;
  position: absolute;
  top: 40px;
  right: 17px;
  background-color: #9fa09f;
  border-radius: 50%;
  border: 1px solid #ddd; }

.online-away-icon {
  width: 13px !important;
  height: 13px !important;
  display: inline-block;
  position: absolute;
  top: 40px;
  right: 17px;
  background-color: #ECAC19;
  border-radius: 50%;
  border: 1px solid #ddd; }

.customerSearch {
  border-color: #333739 !important;
  border-radius: 7px !important;
  background-color: #333739 !important;
  color: #BCBCBC !important;
  background-image: url(../../static/media/magnify.70aaf4c8.svg);
  background-repeat: no-repeat;
  background-position: left 10px center;
  padding-left: 37px !important;
  font-size: 14px !important;
  font-weight: 400 !important; }

.CustomerListHeader {
  text-align: center;
  margin: auto;
  padding: 18px 0px !important; }

.chat_date {
  font-size: 11px;
  float: right;
  color: #afafaf;
  font-weight: 300;
  min-width: 90px; }

.chat_img {
  position: relative; }
  .chat_img svg {
    border-radius: 50%; }
  .chat_img img {
    border-radius: 50%; }
  .chat_img img.is_premium_customer {
    position: absolute;
    left: 6px;
    top: 0; }

.numberOfMsg {
  background-color: var(--white) !important;
  color: var(--primary) !important;
  min-width: 1rem;
  min-height: 1rem;
  padding: 0 !important;
  justify-content: center;
  align-items: center;
  display: inline-flex !important;
  line-height: 1 !important;
  padding-top: 4px !important; }

.lastmsg {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 19px; }

.SenderName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 19px; }

.cust-list {
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden; }
  @media screen and (max-width: 767px) {
    .cust-list {
      height: calc(100vh - 225px) !important;
      min-height: calc(100vh - 225px) !important;
      max-height: calc(100vh - 225px) !important; } }

.chat-list {
  width: 60px;
  margin: 0 auto; }
  .chat-list.chat_img img.is_premium_customer {
    left: -1px; }
  .chat-list .offline-small-icon {
    right: 8px; }
  .chat-list .online-small-icon {
    right: 8px; }
  .chat-list .online-away-icon {
    right: 8px; }

@media screen and (max-width: 767px) {
  .ChatSection.bg-dark.h-100 {
    height: calc(100vh - 150px) !important;
    min-height: calc(100vh - 150px) !important;
    max-height: calc(100vh - 150px) !important;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    margin: 0 !important; }
  .customerlist-main-wrap {
    padding-left: 0 !important; } }

.EmojiSlider {
  position: absolute;
  bottom: auto;
  top: -60px;
  left: 0;
  z-index: 1000;
  filter: drop-shadow(2.77089px 2.77089px 8.31266px rgba(58, 65, 75, 0.3)); }
  @media (max-width: 767px) {
    .EmojiSlider {
      left: 0;
      right: 0; } }
  @media (max-width: 350px) {
    .EmojiSlider {
      left: 10px; } }
  .EmojiSlider .slick-slider {
    width: 100%; }
    .EmojiSlider .slick-slider .slick-list {
      width: 100%; }
      .EmojiSlider .slick-slider .slick-list .slick-slide {
        text-align: center;
        cursor: pointer; }
        .EmojiSlider .slick-slider .slick-list .slick-slide div {
          outline: none !important;
          border: none !important;
          box-shadow: none !important; }
          .EmojiSlider .slick-slider .slick-list .slick-slide div img {
            width: 36px;
            height: 36px;
            margin: 0 auto;
            border-radius: 0;
            outline: none !important;
            border: none !important;
            box-shadow: none !important; }
    .EmojiSlider .slick-slider .slick-prev, .EmojiSlider .slick-slider .slick-next {
      height: auto; }
      .EmojiSlider .slick-slider .slick-prev::before, .EmojiSlider .slick-slider .slick-next::before {
        content: '\F105';
        opacity: 1;
        color: #fff;
        border: none;
        border-radius: 50px;
        font-family: 'FontAwesome';
        width: auto;
        display: block;
        height: auto;
        line-height: 17px;
        text-align: center;
        position: relative;
        font-weight: 600;
        top: -4px;
        opacity: 0.5; }
    .EmojiSlider .slick-slider .slick-prev::before {
      content: '\F104';
      padding: 0 0;
      left: 11px; }
    .EmojiSlider .slick-slider .slick-next::before {
      content: '\F105';
      padding: 0 0;
      right: 11px; }
    .EmojiSlider .slick-slider .slick-dots {
      bottom: 2.5px;
      height: 6px;
      display: flex !important;
      justify-content: center;
      align-items: center; }
      .EmojiSlider .slick-slider .slick-dots li {
        width: 3px;
        height: 3px;
        vertical-align: middle;
        margin: 0 2px; }
        .EmojiSlider .slick-slider .slick-dots li button {
          width: 3px;
          height: 3px;
          margin: 0 auto;
          background: #ACACAC;
          border-radius: 50px;
          padding: 0; }
          .EmojiSlider .slick-slider .slick-dots li button::before {
            display: none; }
      .EmojiSlider .slick-slider .slick-dots li.slick-active {
        width: 4px;
        height: 4px; }
        .EmojiSlider .slick-slider .slick-dots li.slick-active button {
          width: 4px;
          height: 4px;
          background: #ffffff; }

.selectedEmoji {
  width: 32px;
  height: 32px;
  background: #282a2b;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.12));
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: auto;
  bottom: 23px;
  left: 6px;
  border: 1.2px solid #fff;
  cursor: pointer; }
  .selectedEmoji img {
    width: 22px !important;
    height: 22px !important;
    border-radius: 50px !important; }

.incoming-emoji-reaction {
  width: 32px;
  height: 32px;
  background: #282a2b;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.12));
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: auto;
  bottom: 23px;
  left: auto;
  right: 10px;
  border: 1.2px solid #fff;
  cursor: pointer; }
  .incoming-emoji-reaction .left-emoji-reaction {
    width: 22px !important;
    height: 22px !important;
    border-radius: 50px !important; }

.incoming-emoji-reaction.isnot-reacted {
  height: 0;
  border: none; }
  .incoming-emoji-reaction.isnot-reacted .left-emoji-reaction {
    height: 0 !important; }

.EmojiSelector {
  width: 17px;
  height: 17px;
  position: absolute;
  right: -25px;
  top: 0;
  cursor: pointer; }
  .EmojiSelector img {
    width: 100% !important;
    height: 100% !important; }

.sharePrivateMedia-modal.forwardMediaModal {
  max-width: 520px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  margin: 0 auto !important;
  width: 100% !important;
  position: absolute !important; }
  .sharePrivateMedia-modal.forwardMediaModal .text-stronger {
    font-weight: bold !important; }
  .sharePrivateMedia-modal.forwardMediaModal .audioPlayer {
    width: 100%;
    height: 46px; }
  .sharePrivateMedia-modal.forwardMediaModal audio::-webkit-media-controls-panel {
    background: #1E2020 !important;
    border-radius: 10px !important; }
  .sharePrivateMedia-modal.forwardMediaModal audio::-webkit-media-controls-enclosure {
    border-radius: 10px;
    background-color: #1E2020; }
  .sharePrivateMedia-modal.forwardMediaModal .modal-content {
    background: #333739;
    border-radius: 12px;
    height: auto; }
    .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body {
      width: 100%;
      padding: 0 30px 30px; }
      .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body .media-share-pop-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 17px;
        text-align: center;
        letter-spacing: -0.05em;
        color: #FFFFFF;
        display: block;
        margin: 20px 0 24px; }
      .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body .checkbox-container {
        display: block; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body .checkbox-container label {
          margin-bottom: 0px;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.05em;
          color: #B9B9B9; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body .checkbox-container input {
          padding: 0;
          height: initial;
          width: initial;
          margin-bottom: 0;
          display: none;
          cursor: pointer; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body .checkbox-container label {
          position: relative;
          cursor: pointer; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body .checkbox-container label:before {
          content: '';
          -webkit-appearance: none;
          background-color: transparent;
          border: 1px solid #ff7167;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
          padding: 7px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
          margin-right: 5px;
          border-radius: 4px;
          top: -1px; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body .checkbox-container input:checked + label:before {
          background-color: #ff7167;
          border-color: #ff7167; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body .checkbox-container input:checked + label:after {
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          left: 6px;
          width: 5px;
          height: 11px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg); }
      .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body .input-edit-icon-wrap {
        position: relative; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body .input-edit-icon-wrap img {
          position: absolute;
          top: 6px;
          right: 10px;
          cursor: pointer; }
      .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body .cost-to-customer span {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.05em;
        color: #FFFFFF; }
      .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body .cost-to-customer span.credit-usd {
        color: #fd7663;
        margin-left: 4px;
        font-weight: 700; }
      .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal {
        /* Firefox */ }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal .image-wrap {
          border-radius: 8px;
          background: rgba(21, 21, 22, 0.15);
          padding: 20px; }
          .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal .image-wrap img {
            max-width: 360px;
            width: 100%;
            max-height: 300px;
            object-fit: contain;
            border-radius: 8px; }
          .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal .image-wrap video {
            max-height: 300px; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal .modal-btn {
          margin: 0 !important;
          border: none;
          padding: 17px 20px;
          text-decoration: none;
          display: inline-block;
          position: relative;
          top: 0;
          width: 100%;
          background: #FD7663;
          border-radius: 10px;
          font-weight: 700;
          font-size: 16px;
          line-height: 12px !important;
          text-align: center;
          color: #FFFFFF;
          height: 50px; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal #creditsLabel {
          border: unset;
          background: rgba(21, 21, 22, 0.7);
          border-radius: 10px;
          height: 46px;
          padding: 2px 0 0 17px;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.05em;
          color: #FFFFFF; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal input#creditsLabel::-webkit-outer-spin-button,
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal input#creditsLabel::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal input#creditsLabel[type=number] {
          -moz-appearance: textfield; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal input#creditsLabel::-webkit-outer-spin-button,
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal input#creditsLabel::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal input#creditsLabel[type=number] {
          -moz-appearance: textfield; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal .custom-control-label {
          box-shadow: unset;
          left: -16px; }
          .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal .custom-control-label:before {
            left: -2.25rem;
            width: 50px;
            height: 24px;
            pointer-events: all;
            border-radius: 15px;
            top: 2px;
            border-color: transparent;
            transition: unset !important;
            box-shadow: unset !important; }
          .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal .custom-control-label:after {
            height: 20px;
            width: 20px;
            top: 4px;
            border-radius: 50%;
            transition: unset !important; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal .custom-control-input:checked ~ .custom-control-label::after {
          transform: translateX(1.5rem); }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal .custom-control-input:checked ~ .custom-control-label::before {
          background: #3BA520; }
    .sharePrivateMedia-modal.forwardMediaModal .modal-content .camModel .chat-modal-body {
      position: relative; }
    .sharePrivateMedia-modal.forwardMediaModal .modal-content .camModel .canvas {
      position: absolute;
      top: 16px;
      left: 20px;
      width: 333px;
      max-height: 250px; }
      .sharePrivateMedia-modal.forwardMediaModal .modal-content .camModel .canvas #canvas.active {
        visibility: visible; }
    .sharePrivateMedia-modal.forwardMediaModal .modal-content .camModel .videoElement {
      width: 100%;
      max-height: 250px; }
    .sharePrivateMedia-modal.forwardMediaModal .modal-content .camModel .no-camera {
      width: 100%;
      max-height: 250px;
      text-align: center; }
    .sharePrivateMedia-modal.forwardMediaModal .modal-content .camHeader {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom: none;
      padding: 30px 30px; }
      .sharePrivateMedia-modal.forwardMediaModal .modal-content .camHeader .close {
        margin-top: 0rem !important;
        color: #5D6569;
        font-weight: 300;
        font-size: 35px;
        position: absolute;
        top: 35px;
        right: 40px;
        padding: 0;
        box-shadow: none;
        text-shadow: none;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        opacity: 1; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .camHeader .close span {
          line-height: 1; }
    .sharePrivateMedia-modal.forwardMediaModal .modal-content .camFooter {
      background-color: transparent;
      height: auto;
      border-bottom-right-radius: 1rem;
      border-bottom-left-radius: 1rem;
      border: none;
      padding: 0; }
    .sharePrivateMedia-modal.forwardMediaModal .modal-content .btn-group {
      display: flex;
      width: 100%;
      margin: 25px auto 0;
      justify-content: center;
      flex-wrap: nowrap; }
    .sharePrivateMedia-modal.forwardMediaModal .modal-content .ar_active_customer_list {
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden; }
      .sharePrivateMedia-modal.forwardMediaModal .modal-content .ar_active_customer_list .ar_active_customer_single {
        padding: 10px 0;
        border-bottom: 1px solid rgba(130, 130, 130, 0.1); }
    .sharePrivateMedia-modal.forwardMediaModal .modal-content .ar-session-title {
      color: #FFF;
      font-family: Overpass;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: -0.7px; }
    .sharePrivateMedia-modal.forwardMediaModal .modal-content .ar-label-select-all {
      color: #FFF;
      font-family: Overpass;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.6px;
      margin: 0;
      opacity: 0.8;
      display: flex;
      align-items: center; }
    .sharePrivateMedia-modal.forwardMediaModal .modal-content .ar-session-host-name {
      margin-left: 12px;
      color: #FFF;
      font-family: Overpass;
      font-style: normal;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: -0.36px;
      text-transform: capitalize; }
    .sharePrivateMedia-modal.forwardMediaModal .modal-content .ar-checkbox {
      margin: 0; }
      .sharePrivateMedia-modal.forwardMediaModal .modal-content .ar-checkbox input[type="checkbox"] {
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        height: 16px;
        position: relative;
        width: 16px;
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        margin-left: 10px;
        top: -2px; }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .ar-checkbox input[type="checkbox"]::before {
          color: #fff;
          content: "\2713";
          font-size: 1em;
          position: absolute;
          right: -1px;
          top: -3px;
          visibility: hidden;
          width: 16px;
          height: 16px;
          text-align: center;
          transform: rotate(8deg); }
        .sharePrivateMedia-modal.forwardMediaModal .modal-content .ar-checkbox input[type="checkbox"]:checked {
          background: #FD7663;
          border-color: #FD7663; }
          .sharePrivateMedia-modal.forwardMediaModal .modal-content .ar-checkbox input[type="checkbox"]:checked::before {
            visibility: visible; }
  .sharePrivateMedia-modal.forwardMediaModal .enter-credit-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px; }
  @media (max-width: 767px) {
    .sharePrivateMedia-modal.forwardMediaModal .modal-content .modal-body.paid-pic-modal .image-wrap img {
      object-fit: unset;
      width: 250px;
      height: 300px;
      max-width: 300px;
      max-height: 300px; } }

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px; }

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0; }

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "$";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  background-image: url("/src/assets/svgs/dollar.svg");
  color: #cccccc;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600; }

.switch input:checked + .slider:before {
  color: #41a745; }

input:checked + .slider {
  background-color: var(--success); }

input:focus + .slider {
  box-shadow: 0 0 1px var(--success); }

input:checked + .slider:before {
  transform: translateX(26px); }

/* Rounded sliders */
.slider.round {
  border-radius: 34px; }

.slider.round:before {
  border-radius: 50%; }

.IntialScreen {
  margin: auto; }
  .IntialScreen svg {
    fill: #878c8f;
    height: auto; }

.camera-modal {
  /* Old shadow dom for play button */
  /* New shadow dom for play button */
  /* This one works! */ }
  .camera-modal video::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none; }
  .camera-modal video::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none; }
  .camera-modal video::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none; }
  .camera-modal .modal-content {
    border-radius: 1rem;
    height: auto;
    background-color: var(--dark); }
    .camera-modal .modal-content .camModel .chat-modal-body {
      position: relative; }
    .camera-modal .modal-content .camModel .canvas {
      position: absolute;
      top: 16px;
      left: 20px;
      width: 333px;
      max-height: 250px; }
      .camera-modal .modal-content .camModel .canvas #canvas.active {
        visibility: visible; }
    .camera-modal .modal-content .camModel .videoElement {
      width: 100%;
      max-height: 250px; }
    .camera-modal .modal-content .camModel .no-camera {
      width: 100%;
      max-height: 250px;
      text-align: center; }
    .camera-modal .modal-content .camModel .modal-btn {
      background: #3B4149;
      color: #fff;
      border: none;
      min-width: 120px;
      padding: 10px;
      border-radius: 15px;
      display: block;
      position: relative;
      top: 30px;
      box-shadow: 2px 14px 28px 1px rgba(0, 0, 0, 0.28);
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 1px;
      cursor: pointer; }
    .camera-modal .modal-content .camHeader {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      border-bottom: none;
      padding: 0 1rem 25px; }
      .camera-modal .modal-content .camHeader .close {
        margin-top: 0rem !important;
        color: #C4C4C4;
        font-weight: 300;
        font-size: 28px;
        padding-top: 7px;
        position: absolute;
        top: 0px;
        right: 15px; }
    .camera-modal .modal-content .camFooter {
      background-color: var(--primary);
      height: 50px;
      border-bottom-right-radius: 1rem;
      border-bottom-left-radius: 1rem; }
    .camera-modal .modal-content .btn-group {
      display: flex;
      width: 70%;
      margin: 0 auto;
      justify-content: space-evenly;
      flex-wrap: nowrap; }
    @media (max-width: 767px) {
      .camera-modal .modal-content .camModel.chat-modal-body {
        text-align: center;
        width: 100% !important; }
      .camera-modal .modal-content .camModel .canvas {
        width: 250px;
        height: 300px;
        max-height: unset;
        top: 16px;
        left: 50%;
        transform: translate(-50%, 0); }
      .camera-modal .modal-content .camModel .videoElement {
        max-width: 250px;
        width: 100%;
        max-height: 300px; } }

.chooseImageModal .modal-content {
  background-color: #333739; }
  .chooseImageModal .modal-content .modal-header {
    border-bottom: 0; }
    .chooseImageModal .modal-content .modal-header h5 {
      font-size: 18px; }
    .chooseImageModal .modal-content .modal-header .close {
      background-image: url(../../static/media/closeIcon.13378231.svg);
      font-size: 0;
      background-repeat: no-repeat;
      background-position: center center;
      margin-right: 0;
      margin-top: 0; }
  .chooseImageModal .modal-content .modal-body {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; }
  .chooseImageModal .modal-content .publicGallery {
    margin-bottom: 17px; }
  .chooseImageModal .modal-content .publicGallery, .chooseImageModal .modal-content .privateGallery {
    height: 195px;
    padding: 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23666666FF' strokeWidth='1' stroke-dasharray='8%2c 8' stroke-dashoffset='89' stroke-linecap='square'/%3e%3c/svg%3e"); }
    .chooseImageModal .modal-content .publicGallery input[type="file"], .chooseImageModal .modal-content .privateGallery input[type="file"] {
      display: none; }
    .chooseImageModal .modal-content .publicGallery .uploadText, .chooseImageModal .modal-content .privateGallery .uploadText {
      color: #666;
      text-align: center;
      margin-top: 10px;
      margin-bottom: 0;
      font-weight: 600; }
    .chooseImageModal .modal-content .publicGallery .bg-none, .chooseImageModal .modal-content .privateGallery .bg-none {
      background-color: none !important;
      border: 0; }
    .chooseImageModal .modal-content .publicGallery:hover, .chooseImageModal .modal-content .privateGallery:hover {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23FD7663FF' strokeWidth='1' stroke-dasharray='8%2c 8' stroke-dashoffset='89' stroke-linecap='square'/%3e%3c/svg%3e"); }
      .chooseImageModal .modal-content .publicGallery:hover svg, .chooseImageModal .modal-content .privateGallery:hover svg {
        fill: var(--primary);
        stroke: var(--primary); }
        .chooseImageModal .modal-content .publicGallery:hover svg path, .chooseImageModal .modal-content .privateGallery:hover svg path {
          fill: var(--primary); }
      .chooseImageModal .modal-content .publicGallery:hover .uploadText, .chooseImageModal .modal-content .privateGallery:hover .uploadText {
        color: var(--primary); }

@media screen and (device-aspect-ratio: 1366 / 768) {
  .chooseImageModal {
    padding-right: 10px; }
    .chooseImageModal .modal-body {
      max-height: 520px;
      overflow-x: hidden;
      overflow-y: scroll; } }

.private-gallery-grid {
  display: flex;
  flex-wrap: wrap; }

.privateModal .modal-content {
  background-color: #333739; }
  .privateModal .modal-content .modal-header {
    border-bottom: 0;
    padding-bottom: 0; }
    .privateModal .modal-content .modal-header h5 {
      font-size: 18px; }
    .privateModal .modal-content .modal-header .close {
      background-image: url(../../static/media/closeIcon.13378231.svg);
      font-size: 0;
      background-repeat: no-repeat;
      background-position: center center;
      margin-right: 0;
      margin-top: 0; }
  .privateModal .modal-content .modal-body {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; }
    .privateModal .modal-content .modal-body .private-gallery-grid {
      display: flex;
      flex-wrap: wrap;
      max-height: 433px;
      overflow-y: auto; }
    .privateModal .modal-content .modal-body .privatePhoto button {
      padding: 0;
      background: #000;
      border: 0;
      height: 144px;
      box-sizing: border-box;
      border: 1.5px solid #333739; }
      .privateModal .modal-content .modal-body .privatePhoto button:focus {
        outline: 0;
        border: 1.5px solid var(--primary); }
      .privateModal .modal-content .modal-body .privatePhoto button img {
        width: 144px;
        height: 144px;
        object-fit: contain; }
  .privateModal .modal-content .height-250 {
    height: 250px; }
  .privateModal .modal-content .modal-footer {
    border-top: 0;
    padding-top: 0;
    justify-content: center; }
    .privateModal .modal-content .modal-footer button {
      border-radius: 10px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      width: 230px;
      height: 50px; }

.sharePrivateMedia-modal {
  max-width: 400px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  margin: 0 auto !important;
  width: 100% !important;
  position: absolute !important; }
  .sharePrivateMedia-modal .audioPlayer {
    width: 100%;
    height: 46px; }
  .sharePrivateMedia-modal audio::-webkit-media-controls-panel {
    background: #1E2020 !important;
    border-radius: 10px !important; }
  .sharePrivateMedia-modal audio::-webkit-media-controls-enclosure {
    border-radius: 10px;
    background-color: #1E2020; }
  .sharePrivateMedia-modal .modal-content {
    background: #333739;
    border-radius: 12px;
    height: auto; }
    .sharePrivateMedia-modal .modal-content .modal-body {
      width: 100%;
      padding: 0 30px 30px; }
      .sharePrivateMedia-modal .modal-content .modal-body .media-share-pop-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 17px;
        text-align: center;
        letter-spacing: -0.05em;
        color: #FFFFFF;
        display: block;
        margin: 20px 0 24px; }
      .sharePrivateMedia-modal .modal-content .modal-body .checkbox-container {
        display: block; }
        .sharePrivateMedia-modal .modal-content .modal-body .checkbox-container label {
          margin-bottom: 0px;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.05em;
          color: #B9B9B9; }
        .sharePrivateMedia-modal .modal-content .modal-body .checkbox-container input {
          padding: 0;
          height: initial;
          width: initial;
          margin-bottom: 0;
          display: none;
          cursor: pointer; }
        .sharePrivateMedia-modal .modal-content .modal-body .checkbox-container label {
          position: relative;
          cursor: pointer; }
        .sharePrivateMedia-modal .modal-content .modal-body .checkbox-container label:before {
          content: '';
          -webkit-appearance: none;
          background-color: transparent;
          border: 1px solid #ff7167;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
          padding: 7px;
          display: inline-block;
          position: relative;
          vertical-align: middle;
          cursor: pointer;
          margin-right: 5px;
          border-radius: 4px;
          top: -1px; }
        .sharePrivateMedia-modal .modal-content .modal-body .checkbox-container input:checked + label:before {
          background-color: #ff7167;
          border-color: #ff7167; }
        .sharePrivateMedia-modal .modal-content .modal-body .checkbox-container input:checked + label:after {
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          left: 6px;
          width: 5px;
          height: 11px;
          border: solid #fff;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg); }
      .sharePrivateMedia-modal .modal-content .modal-body .input-edit-icon-wrap {
        position: relative; }
        .sharePrivateMedia-modal .modal-content .modal-body .input-edit-icon-wrap img {
          position: absolute;
          top: 6px;
          right: 10px;
          cursor: pointer; }
      .sharePrivateMedia-modal .modal-content .modal-body .cost-to-customer span {
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.05em;
        color: #FFFFFF; }
      .sharePrivateMedia-modal .modal-content .modal-body .cost-to-customer span.credit-usd {
        color: #fd7663;
        margin-left: 4px;
        font-weight: 700; }
      .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal {
        /* Firefox */ }
        .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .image-wrap {
          text-align: center; }
          .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .image-wrap img {
            max-width: 360px;
            width: 100%;
            max-height: 300px;
            object-fit: contain; }
          .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .image-wrap video {
            max-height: 300px; }
        .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .modal-btn {
          margin: 0 !important;
          border: none;
          padding: 17px 20px;
          text-decoration: none;
          display: inline-block;
          position: relative;
          top: 0;
          width: 100%;
          background: #FD7663;
          border-radius: 10px;
          font-weight: 700;
          font-size: 16px;
          line-height: 12px !important;
          text-align: center;
          color: #FFFFFF;
          height: 50px; }
        .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal #creditsLabel {
          border: unset;
          background: rgba(21, 21, 22, 0.7);
          border-radius: 10px;
          height: 46px;
          padding: 2px 0 0 17px;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: -0.05em;
          color: #FFFFFF; }
        .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal input#creditsLabel::-webkit-outer-spin-button,
        .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal input#creditsLabel::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0; }
        .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal input#creditsLabel[type=number] {
          -moz-appearance: textfield; }
        .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal input#creditsLabel::-webkit-outer-spin-button,
        .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal input#creditsLabel::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0; }
        .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal input#creditsLabel[type=number] {
          -moz-appearance: textfield; }
        .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .custom-control-label {
          box-shadow: unset;
          left: -16px; }
          .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .custom-control-label:before {
            left: -2.25rem;
            width: 50px;
            height: 24px;
            pointer-events: all;
            border-radius: 15px;
            top: 2px;
            border-color: transparent;
            transition: unset !important;
            box-shadow: unset !important; }
          .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .custom-control-label:after {
            height: 20px;
            width: 20px;
            top: 4px;
            border-radius: 50%;
            transition: unset !important; }
        .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .custom-control-input:checked ~ .custom-control-label::after {
          transform: translateX(1.5rem); }
        .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .custom-control-input:checked ~ .custom-control-label::before {
          background: #3BA520; }
    .sharePrivateMedia-modal .modal-content .camModel .chat-modal-body {
      position: relative; }
    .sharePrivateMedia-modal .modal-content .camModel .canvas {
      position: absolute;
      top: 16px;
      left: 20px;
      width: 333px;
      max-height: 250px; }
      .sharePrivateMedia-modal .modal-content .camModel .canvas #canvas.active {
        visibility: visible; }
    .sharePrivateMedia-modal .modal-content .camModel .videoElement {
      width: 100%;
      max-height: 250px; }
    .sharePrivateMedia-modal .modal-content .camModel .no-camera {
      width: 100%;
      max-height: 250px;
      text-align: center; }
    .sharePrivateMedia-modal .modal-content .camHeader {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom: none;
      padding: 30px 30px; }
      .sharePrivateMedia-modal .modal-content .camHeader .close {
        margin-top: 0rem !important;
        color: #5D6569;
        font-weight: 300;
        font-size: 35px;
        position: absolute;
        top: 35px;
        right: 40px;
        padding: 0;
        box-shadow: none;
        text-shadow: none;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        opacity: 1; }
        .sharePrivateMedia-modal .modal-content .camHeader .close span {
          line-height: 1; }
    .sharePrivateMedia-modal .modal-content .camFooter {
      background-color: transparent;
      height: auto;
      border-bottom-right-radius: 1rem;
      border-bottom-left-radius: 1rem;
      border: none;
      padding: 0; }
    .sharePrivateMedia-modal .modal-content .btn-group {
      display: flex;
      width: 100%;
      margin: 25px auto 0;
      justify-content: center;
      flex-wrap: nowrap; }
  .sharePrivateMedia-modal .enter-credit-label {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 3px; }
  @media (max-width: 767px) {
    .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .image-wrap img {
      object-fit: unset;
      width: 250px;
      height: 300px;
      max-width: 300px;
      max-height: 300px; } }

.chatMedia-gallery-grid {
  display: flex;
  flex-wrap: wrap; }

.ReactModal__Overlay {
  z-index: 999999 !important; }

.chatMediaModal .modal-content {
  background-color: #333739; }
  .chatMediaModal .modal-content .modal-header {
    border-bottom: 0;
    padding-bottom: 0; }
    .chatMediaModal .modal-content .modal-header h5 {
      font-size: 18px; }
    .chatMediaModal .modal-content .modal-header .close {
      background-image: url(../../static/media/closeIcon.13378231.svg);
      font-size: 0;
      background-repeat: no-repeat;
      background-position: center center;
      margin-right: 0;
      margin-top: 0; }
  .chatMediaModal .modal-content .modal-body {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; }
    .chatMediaModal .modal-content .modal-body .chatMedia-gallery-grid {
      display: flex;
      flex-wrap: wrap;
      max-height: 433px;
      overflow-y: auto; }
      .chatMediaModal .modal-content .modal-body .chatMedia-gallery-grid .chatMediaPhoto button {
        padding: 0;
        background: #000;
        border: 0;
        height: 144px;
        width: 144px;
        box-sizing: border-box;
        border: 1.5px solid #333739;
        position: relative; }
        .chatMediaModal .modal-content .modal-body .chatMedia-gallery-grid .chatMediaPhoto button:focus {
          outline: 0;
          border: 1.5px solid var(--primary); }
        .chatMediaModal .modal-content .modal-body .chatMedia-gallery-grid .chatMediaPhoto button .video_play_icon .play_back_circle {
          position: absolute;
          background: #000;
          width: 40px;
          height: 40px;
          content: '';
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          opacity: 0.6;
          border-radius: 50%;
          box-shadow: 2px 2px 9px #000; }
        .chatMediaModal .modal-content .modal-body .chatMedia-gallery-grid .chatMediaPhoto button .video_play_icon i {
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          /* box-shadow: -2px 0px 5px #fff; */
          border-radius: 50%;
          z-index: 99; }
        .chatMediaModal .modal-content .modal-body .chatMedia-gallery-grid .chatMediaPhoto button img {
          width: 140px;
          height: 144px;
          object-fit: contain; }
  .chatMediaModal .modal-content .chat-media-tabs-menu .nav-tabs {
    width: 250px;
    margin: 0 auto 20px;
    background: #666666;
    border-radius: 50px;
    border: none; }
    .chatMediaModal .modal-content .chat-media-tabs-menu .nav-tabs .nav-link:hover, .chatMediaModal .modal-content .chat-media-tabs-menu .nav-tabs .nav-link:focus {
      border-color: transparent; }
    .chatMediaModal .modal-content .chat-media-tabs-menu .nav-tabs .nav-link {
      color: #fff;
      cursor: pointer; }
    .chatMediaModal .modal-content .chat-media-tabs-menu .nav-tabs .nav-link.active, .chatMediaModal .modal-content .chat-media-tabs-menu .nav-tabs .nav-item.show .nav-link {
      color: #000000;
      background-color: #fff;
      border-color: transparent;
      border-radius: 50px; }
  .chatMediaModal .modal-content button.sendBtn {
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    width: 230px;
    height: 50px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px; }
  .chatMediaModal .modal-content .modal-footer {
    border-top: 0;
    padding-top: 0;
    justify-content: center; }
    .chatMediaModal .modal-content .modal-footer button {
      border-radius: 10px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      width: 230px;
      height: 50px; }

.sharePrivateMedia-modal .modal-content {
  border-radius: 1rem;
  height: auto;
  background-color: var(--dark); }
  .sharePrivateMedia-modal .modal-content .modal-body {
    width: 100%; }
    .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .image-wrap {
      text-align: center; }
      .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .image-wrap img {
        max-width: 360px;
        width: 100%;
        max-height: 300px;
        object-fit: contain; }
      .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .image-wrap video {
        max-height: 300px; }
    .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .modal-btn {
      margin: 0 !important;
      border: none;
      padding: 17px 20px;
      text-decoration: none;
      display: inline-block;
      position: relative;
      top: 0;
      width: 100%;
      background: #FD7663;
      border-radius: 10px;
      font-weight: 700;
      font-size: 16px;
      line-height: 12px !important;
      text-align: center;
      color: #FFFFFF;
      height: 50px; }
    .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal #creditsLabel {
      color: white;
      background: #151516;
      border: unset; }
    .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .custom-control-label {
      box-shadow: unset;
      left: -16px; }
      .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .custom-control-label:before {
        left: -2.25rem;
        width: 50px;
        height: 24px;
        pointer-events: all;
        border-radius: 15px;
        top: 2px;
        border-color: transparent;
        transition: unset !important;
        box-shadow: unset !important; }
      .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .custom-control-label:after {
        height: 20px;
        width: 20px;
        top: 4px;
        border-radius: 50%;
        transition: unset !important; }
    .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .custom-control-input:checked ~ .custom-control-label::after {
      transform: translateX(1.5rem); }
    .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .custom-control-input:checked ~ .custom-control-label::before {
      background: #3BA520; }
  .sharePrivateMedia-modal .modal-content .camModel .chat-modal-body {
    position: relative; }
  .sharePrivateMedia-modal .modal-content .camModel .canvas {
    position: absolute;
    top: 16px;
    left: 20px;
    width: 333px;
    max-height: 250px; }
    .sharePrivateMedia-modal .modal-content .camModel .canvas #canvas.active {
      visibility: visible; }
  .sharePrivateMedia-modal .modal-content .camModel .videoElement {
    width: 100%;
    max-height: 250px; }
  .sharePrivateMedia-modal .modal-content .camModel .no-camera {
    width: 100%;
    max-height: 250px;
    text-align: center; }
  .sharePrivateMedia-modal .modal-content .camModel .modal-btn {
    background: #3B4149;
    color: #fff;
    border: none;
    min-width: 120px;
    padding: 10px;
    border-radius: 15px;
    display: block;
    position: relative;
    top: 30px;
    box-shadow: 2px 14px 28px 1px rgba(0, 0, 0, 0.28);
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer; }
  .sharePrivateMedia-modal .modal-content .camHeader {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: none;
    padding: 30px 30px; }
    .sharePrivateMedia-modal .modal-content .camHeader .close {
      margin-top: 0rem !important;
      color: #5D6569;
      font-weight: 300;
      font-size: 35px;
      position: absolute;
      top: 35px;
      right: 40px;
      padding: 0;
      box-shadow: none;
      text-shadow: none;
      height: 25px;
      width: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1;
      opacity: 1; }
      .sharePrivateMedia-modal .modal-content .camHeader .close span {
        line-height: 1; }
  .sharePrivateMedia-modal .modal-content .camFooter {
    background-color: transparent;
    height: auto;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border: none;
    padding: 0; }
  .sharePrivateMedia-modal .modal-content .btn-group {
    display: flex;
    width: 100%;
    margin: 25px auto 0;
    justify-content: center;
    flex-wrap: nowrap; }

@media (max-width: 767px) {
  .sharePrivateMedia-modal .modal-content .modal-body.paid-pic-modal .image-wrap img {
    object-fit: unset;
    width: 250px;
    height: 300px;
    max-width: 300px;
    max-height: 300px; } }

@charset "UTF-8";
.chat-customer-review {
  display: flex;
  padding: 6px 0px;
  border-radius: 4px; }
  .chat-customer-review .chat-reviews {
    border-right: 0.6px solid #505050;
    padding: 0 15px;
    height: 30px; }
    .chat-customer-review .chat-reviews .title_container {
      flex-direction: column;
      display: flex;
      align-items: center;
      position: relative;
      top: -17px; }
    .chat-customer-review .chat-reviews:last-child {
      border-right: none; }
    .chat-customer-review .chat-reviews span i.fa.fa-star {
      color: #f6c71af2;
      margin: 0 5px 0 10px;
      font-size: 14px; }
    .chat-customer-review .chat-reviews span {
      font-size: 12px; }
      @media (max-width: 767px) {
        .chat-customer-review .chat-reviews span {
          font-size: 12px; } }

.image-wrap .online-small-icon {
  width: 13px !important;
  height: 13px !important;
  display: inline-block;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #2eb130;
  border-radius: 50%;
  border: 1px solid #ddd; }

.image-wrap .offline-small-icon {
  width: 13px !important;
  height: 13px !important;
  display: inline-block;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #9fa09f;
  border-radius: 50%;
  border: 1px solid #ddd; }

.image-wrap .online-away-icon {
  width: 13px !important;
  height: 13px !important;
  display: inline-block;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #ECAC19;
  border-radius: 50%;
  border: 1px solid #ddd; }

.write-footer .msg_history {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden; }
  @media screen and (max-width: 767px) {
    .write-footer .msg_history {
      height: calc(100% - 65px) !important;
      max-height: calc(100% - 65px) !important; } }

.write-footer .chat-pref-msg {
  color: #f07563;
  font-size: 13px;
  margin: 0;
  padding: 9px 14px;
  font-size: 13px; }
  @media screen and (max-width: 767px) {
    .write-footer .chat-pref-msg {
      position: absolute;
      top: -22px; } }

.type_msg {
  border-top: 1px solid #b9b9b926;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 16px;
  position: relative; }
  .type_msg .write_msgRecording::placeholder {
    color: #ff7766 !important; }
  .type_msg .max-seconds {
    font-size: 12px;
    position: absolute;
    bottom: 6px;
    color: #fff;
    opacity: 0.6;
    left: 53px; }

.imgBtn {
  background: unset; }
  .imgBtn img {
    box-shadow: none; }

.ar_host_chat_frame_box {
  height: calc(100vh - 130px); }

.msg_history {
  max-height: 523px;
  min-height: 300px;
  overflow-y: auto;
  padding: 20px 10px 0 5px;
  height: calc(100% - 170px); }
  @media screen and (max-width: 992px) {
    .msg_history {
      height: calc(100% - 100px) !important;
      max-height: calc(100% - 100px) !important; } }
  @media screen and (min-width: 993px) {
    .msg_history {
      min-height: unset;
      max-height: unset; } }
  .msg_history .sysMsgTime {
    display: flex;
    color: #c5c5c5;
    font-weight: 100;
    font-size: 11px;
    justify-content: flex-start;
    padding: 2px 8px; }
  .msg_history .dateTimeStampChat {
    font-size: 14px;
    color: grey;
    margin-bottom: 20px; }
  .msg_history .chat-day {
    color: #c5c5c5;
    font-weight: 100;
    font-size: 11px;
    margin-bottom: 3px; }
  .msg_history .system_msg_container {
    margin-bottom: 10px; }
    .msg_history .system_msg_container .system_msg {
      display: flex;
      justify-content: flex-start; }
      .msg_history .system_msg_container .system_msg p {
        border-radius: 30px;
        color: #B9B9B9;
        font-size: 13px;
        text-align: left;
        margin: 0;
        background-color: #666;
        padding: 10px 14px;
        border-bottom-left-radius: 5px; }
  .msg_history .incoming_msg {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px; }
    .msg_history .incoming_msg .received_msg {
      padding: 0;
      position: relative; }
      .msg_history .incoming_msg .received_msg .bluredImgBox {
        position: relative; }
      .msg_history .incoming_msg .received_msg .messageText {
        max-width: 345px;
        word-wrap: break-word; }
        @media (max-width: 767px) {
          .msg_history .incoming_msg .received_msg .messageText {
            max-width: 250px; } }
      .msg_history .incoming_msg .received_msg p {
        border-radius: 15px 15px 0px;
        color: #3a414b;
        font-size: 13px;
        margin: 0;
        padding: 10px 14px;
        width: 100%;
        border: 1px solid #3a414b; }
      .msg_history .incoming_msg .received_msg .chat-status {
        display: flex;
        color: #c5c5c5;
        font-weight: 100;
        font-size: 11px;
        justify-content: flex-end;
        padding: 2px 8px; }
        .msg_history .incoming_msg .received_msg .chat-status .failed-msg {
          color: #dc280e; }
      .msg_history .incoming_msg .received_msg .chat-status.isReacted {
        padding-top: 32px; }
      .msg_history .incoming_msg .received_msg .chatInMsgTime {
        display: flex;
        color: #c5c5c5;
        font-weight: 100;
        font-size: 11px;
        justify-content: flex-end;
        padding: 0px 2px; }
      .msg_history .incoming_msg .received_msg object {
        border-radius: 15px 15px 0;
        color: #3a414b;
        font-size: 13px;
        margin: 0;
        border: 1px solid #999;
        box-shadow: none;
        width: 265px;
        height: 223px;
        object-fit: contain;
        display: block; }
      .msg_history .incoming_msg .received_msg img {
        border-radius: 15px 15px 0;
        color: #3a414b;
        font-size: 13px;
        margin: 0;
        box-shadow: none;
        width: 265px;
        height: 223px;
        object-fit: contain; }
        .msg_history .incoming_msg .received_msg img.placeholder-obj {
          width: 255px !important; }
      .msg_history .incoming_msg .received_msg .creditValueClass {
        font-weight: 600;
        font-size: 13px;
        line-height: 8px;
        letter-spacing: -0.05em;
        color: #FFFFFF;
        position: absolute;
        bottom: -16px;
        left: auto;
        right: 200px; }
      .msg_history .incoming_msg .received_msg .audio-credits {
        font-weight: 600;
        font-size: 12px;
        line-height: 8px;
        letter-spacing: -0.05em;
        color: #FFFFFF;
        position: absolute;
        bottom: -16px;
        left: auto;
        width: 65px; }
        @media (max-width: 550px) {
          .msg_history .incoming_msg .received_msg .audio-credits {
            right: 190px; } }
      .msg_history .incoming_msg .received_msg .received_withd_msg audio {
        width: 265px; }
      .msg_history .incoming_msg .received_msg .received_withd_msg iframe {
        width: 265px;
        height: 265px;
        border-radius: 15px 15px 0 15px;
        border: none; }
      @media (max-width: 550px) {
        .msg_history .incoming_msg .received_msg .received_withd_msg audio {
          width: 255px; }
        .msg_history .incoming_msg .received_msg .received_withd_msg iframe {
          width: 255px;
          height: 255px; } }
  .msg_history .outgoing_msg {
    display: flex;
    justify-content: flex-start;
    margin: 20px 0; }
    .msg_history .outgoing_msg .sent_msg {
      position: relative;
      margin: 0;
      display: flex;
      flex-direction: column; }
      .msg_history .outgoing_msg .sent_msg p {
        border-radius: 20px 25px 25px 5px;
        color: #B9B9B9;
        font-size: 13px;
        margin: 0;
        padding: 9px 14px;
        width: 100%;
        border: 1px solid #666;
        font-size: 13px;
        line-height: 17px;
        max-width: 450px; }
        @media (max-width: 767px) {
          .msg_history .outgoing_msg .sent_msg p {
            max-width: 250px; } }
        @media (max-width: 350px) {
          .msg_history .outgoing_msg .sent_msg p {
            max-width: 235px; } }
      .msg_history .outgoing_msg .sent_msg .chatOutMsgTime {
        display: flex;
        color: #c5c5c5;
        font-weight: 100;
        font-size: 11px;
        justify-content: flex-start;
        padding: 2px 8px;
        order: 1; }
      .msg_history .outgoing_msg .sent_msg .chatOutMsgTime.isReacted {
        padding-top: 32px; }
      .msg_history .outgoing_msg .sent_msg object {
        border-radius: 15px 15px 0;
        color: #3a414b;
        font-size: 13px;
        margin: 0;
        border: 1px solid #999;
        box-shadow: none;
        width: 265px;
        height: 223px;
        object-fit: contain;
        display: block; }
      .msg_history .outgoing_msg .sent_msg img {
        border-radius: 15px 15px 0;
        color: #3a414b;
        font-size: 13px;
        margin: 0;
        box-shadow: none;
        width: 265px;
        height: 223px;
        object-fit: contain; }
        .msg_history .outgoing_msg .sent_msg img.placeholder-obj {
          width: 255px !important; }

.user-name {
  font-weight: 600;
  text-transform: capitalize; }

.ChatSection .conversion-header {
  border-bottom-color: #b9b9b926 !important;
  position: relative; }
  @media (max-width: 767px) {
    .ChatSection .conversion-header {
      padding-bottom: 20px !important; } }
  .ChatSection .conversion-header .ar-chat-head-left-info {
    display: flex;
    align-items: center; }
  .ChatSection .conversion-header .ar-chat-head-right-action {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px; }
    .ChatSection .conversion-header .ar-chat-head-right-action .fav-icon-wrap-call-chat {
      background: #0000008a;
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;
      z-index: 999999; }
    @media (max-width: 767px) {
      .ChatSection .conversion-header .ar-chat-head-right-action {
        grid-gap: 10px;
        gap: 10px;
        margin: -50px 0 0 -50px; } }
    .ChatSection .conversion-header .ar-chat-head-right-action div {
      cursor: pointer; }
      @media (max-width: 767px) {
        .ChatSection .conversion-header .ar-chat-head-right-action div {
          width: 30px;
          height: 30px; } }
      .ChatSection .conversion-header .ar-chat-head-right-action div img {
        margin-left: 0;
        margin: 0;
        border-radius: 0; }
        @media (max-width: 767px) {
          .ChatSection .conversion-header .ar-chat-head-right-action div img {
            width: 100%; } }
    @media (max-width: 767px) {
      .ChatSection .conversion-header .ar-chat-head-right-action div.ellipsis-menu {
        width: auto;
        height: auto; }
        .ChatSection .conversion-header .ar-chat-head-right-action div.ellipsis-menu div {
          width: auto;
          height: auto; } }
  .ChatSection .conversion-header .ellipsis-menu .btn-secondary {
    color: #fff;
    background-color: #292a2b;
    border-color: #292a2b; }
  .ChatSection .conversion-header .ellipsis-menu .btn-secondary:hover {
    color: #fff;
    background-color: #292a2b;
    border-color: #292a2b; }
  .ChatSection .conversion-header .ellipsis-menu .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #292a2b; }
  .ChatSection .conversion-header .ellipsis-menu .dropdown-item:hover {
    color: #fff;
    text-decoration: none;
    background-color: #292a2b; }
  .ChatSection .conversion-header .ellipsis-menu .dropdown-item:focus {
    outline: 0; }
  .ChatSection .conversion-header .dropdown-toggle::after {
    display: none !important; }
  .ChatSection .conversion-header .switch {
    margin: 0 22px 0 15px  !important; }
    @media (min-width: 1150px) {
      .ChatSection .conversion-header .switch {
        position: absolute;
        right: 30px; } }
  .ChatSection .conversion-header .online-user {
    position: absolute;
    bottom: 30px;
    left: 70px; }
    .ChatSection .conversion-header .online-user.online-user-chat {
      position: static; }
      .ChatSection .conversion-header .online-user.online-user-chat .title_container .rating_title {
        position: static; }

.modal-dialog.chat-modal.tip-Modal .modal-content {
  width: 95% !important;
  margin: 0 auto; }

.incoming_msg .messageText {
  background-color: var(--primary);
  color: var(--white) !important;
  border-radius: 25px 20px 5px 25px !important; }

.back_btn {
  display: none !important; }

@media screen and (max-width: 767px) {
  .ChatSection .conversion-header .ellipsis-menu .dropdown-menu .dropdown-item.none-in-mobile {
    display: none; }
  .chat-messaging {
    width: 100% !important;
    overflow: hidden !important; }
    .chat-messaging .inbox_msg .mesgs .online-user-details {
      width: calc(100% - 0px);
      margin-left: 6px; }
      .chat-messaging .inbox_msg .mesgs .online-user-details .d-flex {
        flex-basis: 70%; }
      .chat-messaging .inbox_msg .mesgs .online-user-details .phone-call {
        margin-top: 15px; }
      .chat-messaging .inbox_msg .mesgs .online-user-details .video-call {
        margin-top: 15px;
        margin-right: 25px; }
      .chat-messaging .inbox_msg .mesgs .online-user-details .close-chat {
        background: url(/static/images/arousr-sprites-general-V.3.0-apr-03.svg) no-repeat -271px -194px;
        width: 22px;
        height: 22px;
        display: inline-block;
        background-size: 700px; }
        .chat-messaging .inbox_msg .mesgs .online-user-details .close-chat:hover {
          background-position: -469px -378px; }
    .chat-messaging .inbox_msg .type_msg .input_msg_write {
      padding-right: 35px !important; }
      .chat-messaging .inbox_msg .type_msg .input_msg_write input {
        min-height: 70px !important; }
  .pl-xs-15 {
    padding-left: 15px !important; }
  .back_btn {
    display: block !important;
    background-color: transparent;
    border: unset; }
    .back_btn svg {
      width: 17px;
      height: 17px; } }

@media screen and (max-width: 480px) {
  .chat-messaging .inbox_msg .mesgs .online-user-details .chat_ib h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 0;
    width: 135px; }
  .online-user-details .back_btn {
    display: inline-block;
    align-self: center; }
    .online-user-details .back_btn .btn {
      border: none;
      color: grey;
      padding: 0;
      font-size: 16px;
      cursor: pointer;
      margin-right: 10px; }
    .online-user-details .back_btn .btn:before {
      content: "\276E";
      color: #d7d7d7;
      font-size: 19px; }
  .chat-messaging .inbox_msg .mesgs {
    padding: 0 0 80px;
    width: 100%;
    position: relative; }
    .chat-messaging .inbox_msg .mesgs .online-user-details .chat_img {
      width: 45px;
      height: 45px;
      min-width: 45px;
      min-height: 45px; }
      .chat-messaging .inbox_msg .mesgs .online-user-details .chat_img img {
        border-radius: 50%; }
    .chat-messaging .inbox_msg .mesgs .online-user-details .phone-call {
      margin-top: 6px;
      background: url(/static/images/arousr-sprites-general-V.3.0-apr-03.svg) no-repeat -220px -194px;
      width: 22px;
      height: 22px;
      margin-right: 10px;
      background-size: 600px; }
    .chat-messaging .inbox_msg .mesgs .online-user-details .video-call {
      margin-top: 6px;
      background: url(/static/images/arousr-sprites-general-V.3.0-apr-03.svg) no-repeat -245px -194px;
      width: 22;
      height: 22px;
      margin-right: 10px;
      background-size: 600px; }
  .chat-messaging .inbox_msg .type_msg {
    width: 100%; }
    .chat-messaging .inbox_msg .type_msg .input_msg_write {
      padding: 10px;
      padding-right: 20px !important;
      background: #3a414b; }
      .chat-messaging .inbox_msg .type_msg .input_msg_write input {
        order: 1;
        min-height: 36px !important;
        border-radius: 8px;
        width: 80% !important; }
      .chat-messaging .inbox_msg .type_msg .input_msg_write .take-pic {
        background-position: -418px -457px !important;
        width: 33px !important;
        height: 38px !important;
        background-size: 600px !important;
        margin-left: 0 !important;
        margin-right: 5px !important; }
      .chat-messaging .inbox_msg .type_msg .input_msg_write .msg-send-btn {
        background: url(/static/images/arousr-sprites-general-V.3.0-apr-03.svg) no-repeat -391px -245px;
        width: 40px;
        height: 29px;
        display: inline-block !important;
        background-size: 600px;
        order: 2;
        border: none;
        margin-left: 15px; }
      .chat-messaging .inbox_msg .type_msg .input_msg_write .upload-file .pic-file {
        margin-right: 8px; }
  .chat-messaging
.inbox_msg .mesgs .type_msg .input_msg_write .upload-file {
    margin-right: 0;
    left: -2px; }
  .chat-messaging
.inbox_msg .mesgs .type_msg .input_msg_write .msg-send-btn {
    background: url(/static/images/arousr-sprites-general-V.3.0-apr-03.svg) no-repeat -477px -300px !important;
    background-size: 735px !important;
    width: 68px;
    height: 34px;
    left: 0; }
    .chat-messaging
.inbox_msg .mesgs .type_msg .input_msg_write .msg-send-btn .btnText {
      display: none; }
  .chat-messaging
.inbox_msg .mesgs .type_msg .input_msg_write input {
    width: 100% !important; }
  .sharePrivateMedia-modal {
    max-width: 93% !important; }
  input.write_msg.write_msgRecording {
    top: -6px; }
  .type_msg .max-seconds {
    bottom: 6px; }
  .chatMediaModal .modal-content .modal-body .chatMedia-gallery-grid {
    justify-content: center; }
  .privateModal .modal-content .modal-body .private-gallery-grid {
    justify-content: center; }
  .sharePrivateMedia-modal .modal-content .modal-body {
    padding: 0 20px 20px; } }

.visualsWrapper {
  margin: 0 auto;
  text-align: center; }

.input_msg_write {
  display: flex;
  justify-content: space-between;
  min-height: 55px; }
  .input_msg_write canvas {
    position: relative;
    top: -5px;
    left: -25px; }
  .input_msg_write .input_msg_write_left_area {
    display: flex;
    align-items: center;
    width: calc(100% - 175px); }
    @media (max-width: 767px) {
      .input_msg_write .input_msg_write_left_area {
        width: 100%;
        grid-gap: 0;
        gap: 0; } }
    .input_msg_write .input_msg_write_left_area .write_msg {
      width: 100%; }
  @media (max-width: 767px) {
    .input_msg_write .input_msg_write_right_area {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto; } }
  @media (min-width: 1100px) and (max-width: 1299px) {
    .input_msg_write canvas {
      width: 195px;
      position: relative;
      top: 0px; } }
  @media (min-width: 1600px) {
    .input_msg_write {
      position: relative; }
      .input_msg_write .visualsWrapper {
        width: 100%;
        height: 30px;
        overflow: hidden; }
        .input_msg_write .visualsWrapper canvas {
          width: 70%;
          height: 100%; } }
  @media (max-width: 767px) {
    .input_msg_write canvas {
      display: none; } }

.outgoing_msg img {
  width: 265px;
  height: 223px;
  object-fit: contain; }

.incoming_msg img, .outgoing_msg img {
  width: 265px;
  height: 223px;
  object-fit: contain; }

.img-msg {
  border-radius: 15px 15px 0;
  color: #3a414b;
  font-size: 13px;
  margin: 0;
  border: 1px solid #999;
  box-shadow: 2px 2px 11px #999; }

.sendButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0; }
  @media (max-width: 767px) {
    .sendButton {
      width: 30px;
      height: 30px;
      position: relative;
      left: 5px; }
      .sendButton svg {
        width: 15px;
        height: 15px;
        margin-left: -3px; } }

@media (max-width: 767px) {
  .type_msg {
    background: #666;
    border-radius: 30px;
    position: relative;
    bottom: 0px;
    width: calc(100% - 16px);
    margin: 0 auto; }
    .type_msg.type_msg-press .upload-file.camera, .type_msg.type_msg-press .upload-file.chooseImage {
      display: none; }
    .type_msg.type_msg-press .microphone.recordingOff {
      display: none; }
    .type_msg.type_msg-press .sendButton {
      display: flex; }
    .type_msg.type_msg-press .write_msg {
      position: relative;
      left: 0px; }
    .type_msg .sendButton {
      display: none; }
    .type_msg .write_msg {
      width: 100%;
      position: relative;
      left: 0px;
      height: 50px; } }

.type_msg .customer-option {
  display: flex;
  vertical-align: middle;
  justify-content: center;
  position: relative;
  top: 7px; }
  .type_msg .customer-option .recordingCounter {
    color: #bfbfbf;
    margin-right: 5px;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px; }
  .type_msg .customer-option .microphone {
    margin-right: 5px; }
  @media (max-width: 767px) {
    .type_msg .customer-option {
      top: auto; }
      .type_msg .customer-option .recordingCounter {
        position: absolute;
        left: -141px;
        font-size: 15px;
        top: -6px; }
      .type_msg .customer-option .microphone {
        position: absolute;
        top: -12px;
        left: -95px; }
        .type_msg .customer-option .microphone.recordingOff img {
          width: 25px; }
        .type_msg .customer-option .microphone.recordingOn {
          top: -21px;
          left: -110px; }
          .type_msg .customer-option .microphone.recordingOn img {
            width: 40px; } }

.type_msg .send-msg-btn {
  width: 67px;
  height: 26px;
  background: #fff;
  border: 1px solid #3b4149;
  border-radius: 6px;
  position: relative;
  left: 0px;
  padding: 1px;
  color: #3b4149; }

@media (max-width: 767px) {
  .type_msg .upload-file {
    position: absolute;
    background: #f07563;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .type_msg .upload-file.camera {
      left: -67px;
      width: 30px;
      height: 30px; }
      .type_msg .upload-file.camera svg {
        width: 15px; }
    .type_msg .upload-file.chooseImage {
      left: -30px;
      margin: 0 !important;
      width: 30px;
      height: 30px; }
      .type_msg .upload-file.chooseImage svg {
        width: 15px; } }

.type_msg .upload-file .pic-file {
  margin-bottom: 0; }

.type_msg .upload-file .pic-file.defult-none {
  cursor: default; }

.type_msg .upload-file .pic-file.defult-none svg {
  opacity: 0.7; }

.type_msg .upload-file input {
  display: none; }

.type_msg .upload-file .pic-file {
  cursor: pointer; }

.type_msg .write_msg {
  background-color: transparent !important;
  color: #B9B9B9;
  font-weight: normal;
  max-height: 80px; }
  @media (max-width: 550px) {
    .type_msg .write_msg {
      padding-top: 15px !important; } }

@media (max-width: 767px) {
  .type_msg textarea.write_msg:read-only {
    padding-top: 8px !important; } }

.chooseImage {
  margin: 0 26px 0 14px !important; }
  .chooseImage button {
    display: none; }

.resume-chat-btn {
  border-top: 1px solid #b9b9b926;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 16px;
  padding-top: 2px;
  text-align: center; }

.resume-chat-btn button {
  background: #FD7663;
  border-radius: 10px;
  text-align: center;
  width: 250px;
  height: 50px;
  font-family: Overpass;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  letter-spacing: -0.06em;
  text-transform: uppercase;
  color: #FFFFFF; }

.resume-chat-btn button:disabled {
  opacity: 0.6;
  background: #f07563;
  color: #fff; }

.chat-customer-review.chat-customer-chat .bar-seprate-line-wrap {
  transform: rotate(106deg);
  z-index: 1;
  position: absolute;
  left: 81%;
  top: 14px; }
  .chat-customer-review.chat-customer-chat .bar-seprate-line-wrap .bar-seprate-line {
    height: 7px;
    width: 0.8px;
    background: #3E3E3E;
    position: absolute; }
  .chat-customer-review.chat-customer-chat .bar-seprate-line-wrap .bar-line-1 {
    left: 11px;
    transform: rotate(186deg);
    top: 30px; }
  .chat-customer-review.chat-customer-chat .bar-seprate-line-wrap .bar-line-2 {
    left: 0.5px;
    transform: rotate(43deg);
    top: 25px; }
  .chat-customer-review.chat-customer-chat .bar-seprate-line-wrap .bar-line-3 {
    left: -4.5px;
    transform: rotate(88deg);
    top: 12px; }
  .chat-customer-review.chat-customer-chat .bar-seprate-line-wrap .bar-line-4 {
    left: -2px;
    transform: rotate(125deg);
    top: 2px; }

audio::-webkit-media-controls-panel {
  background-color: #fd7663 !important; }

audio::-webkit-media-controls-panel {
  background-color: #fd7663 !important; }

audio::-webkit-media-controls-current-time-display {
  color: white !important; }

audio::-webkit-media-controls-time-remaining-display {
  color: white !important; }

audio::-webkit-media-controls-mute-button {
  display: none; }

audio::-webkit-media-controls-play-button {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%; }

audio::-webkit-media-controls-volume-control-container {
  display: none; }

.ar-chatLoader-absolute {
  position: absolute;
  z-index: 99;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(41, 42, 43, 0.5);
  top: 0;
  left: 0; }

.validImage {
  border-radius: 15px 15px 0;
  border: 1px solid #999;
  display: block;
  color: #3a414b;
  font-size: 13px;
  margin: 0;
  box-shadow: none;
  width: 265px;
  height: 223px;
  object-fit: contain; }

.mediaDeletedSVG {
  width: 100% !important;
  height: auto !important; }

.ar-whisperer-icon-wrapper {
  position: absolute;
  bottom: 80px;
  right: 40px;
  cursor: pointer; }
  .ar-whisperer-icon-wrapper .ar-whisperer-tooltip {
    position: absolute;
    top: -30px;
    width: 207px;
    right: 0;
    font-size: 12px;
    background: black;
    padding: 4px 4px;
    border-radius: 4px;
    line-height: 1;
    text-align: center;
    display: none;
    z-index: 99; }
    .ar-whisperer-icon-wrapper .ar-whisperer-tooltip::after {
      content: "";
      width: 10px;
      height: 10px;
      display: block;
      background: black;
      position: absolute;
      bottom: -5px;
      right: 23px;
      transform: rotate(45deg);
      z-index: 1; }
  .ar-whisperer-icon-wrapper .ar-whisperer-icon-wrapper-inner {
    width: 56px;
    height: 56px;
    background: #FFD9D3;
    border: 0.5px solid #F67765;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: end;
    position: relative;
    filter: drop-shadow(0 4px 4px rgba(253, 118, 99, 0.5)); }
    .ar-whisperer-icon-wrapper .ar-whisperer-icon-wrapper-inner img {
      height: 50px; }
  .ar-whisperer-icon-wrapper:hover .ar-whisperer-tooltip {
    display: block; }

.CustomerReview-Modal {
  max-width: 360px !important; }
  .CustomerReview-Modal .react-tel-input .form-control {
    width: 100%; }
  .CustomerReview-Modal .react-tel-input .country-list {
    top: 24px;
    left: 0; }
    .CustomerReview-Modal .react-tel-input .country-list .country {
      text-align: left; }
  .CustomerReview-Modal .rating-error {
    color: red;
    font-size: 14px;
    text-align: center;
    margin: 5px 0px; }
  .CustomerReview-Modal .contactErrorMsg {
    text-align: left;
    display: block;
    padding: 5px 0 0;
    color: #cc4646; }
  .CustomerReview-Modal .modal-body {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    padding: 1rem 0; }
    .CustomerReview-Modal .modal-body .fa.fa-phone {
      font-size: 50px;
      color: #f07563; }
  .CustomerReview-Modal .modal-content {
    position: relative !important;
    height: auto !important;
    font-size: 14px !important;
    line-height: 20px;
    font-weight: 500;
    font-family: var(--font-family) !important;
    background-color: var(--dark);
    border-radius: 1em;
    top: 70px; }
  .CustomerReview-Modal .chat-modal-header {
    border-bottom: none;
    padding: 10px 1rem; }
    .CustomerReview-Modal .chat-modal-header img {
      opacity: 1 !important; }
    .CustomerReview-Modal .chat-modal-header .close {
      margin-top: 0rem !important;
      color: #C4C4C4;
      font-weight: 300;
      font-size: 28px;
      padding-top: 7px;
      position: absolute;
      top: 0px;
      right: 15px; }
  .CustomerReview-Modal .modal-center-img {
    width: 115px;
    margin: 15px auto 0px auto;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  .CustomerReview-Modal .modal-title {
    flex: 1 1 100%;
    text-align: left;
    margin: 0 !important;
    font-size: 16px; }
  .CustomerReview-Modal h4 {
    font-size: 18px;
    font-weight: 700 !important;
    margin-bottom: 0.3em; }
  .CustomerReview-Modal p {
    font-weight: 400;
    opacity: 0.8; }
  .CustomerReview-Modal .chat-modal-body button.review-submit-btn {
    width: 100% !important;
    background-color: #ff7066;
    color: white !important;
    border-radius: 10px !important;
    height: 40px;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: var(--font-family) !important;
    font-weight: 400;
    margin-top: 15px; }
  .CustomerReview-Modal .chat-modal-body .form-group {
    margin-bottom: 35px !important; }
  .CustomerReview-Modal .chat-modal-body .img_with_crown {
    position: relative;
    width: 50px;
    margin: 0 auto; }
  .CustomerReview-Modal .chat-modal-body .img_with_crown img.is_premium_customer {
    position: absolute;
    left: -5px;
    top: 0; }
  .CustomerReview-Modal .chat-modal-body .user-name {
    margin: 10px 0;
    display: block; }

.modal-backdrop.show {
  opacity: 0.3 !important; }

.modal {
  background: transparent !important; }

body.modal-open #root {
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); }

.chatMedia-gallery-grid {
  display: flex;
  flex-wrap: wrap; }

.ReactModal__Overlay {
  z-index: 999999 !important; }

.chatMediaModal .modal-content {
  background-color: #333739; }
  .chatMediaModal .modal-content .modal-header {
    border-bottom: 0;
    padding-bottom: 0; }
    .chatMediaModal .modal-content .modal-header h5 {
      font-size: 18px; }
    .chatMediaModal .modal-content .modal-header .close {
      background-image: url(../../static/media/closeIcon.13378231.svg);
      font-size: 0;
      background-repeat: no-repeat;
      background-position: center center;
      margin-right: 0;
      margin-top: 0; }
  .chatMediaModal .modal-content .modal-body {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px; }
    .chatMediaModal .modal-content .modal-body .chatMedia-gallery-grid {
      display: flex;
      flex-wrap: wrap;
      max-height: 433px;
      overflow-y: auto; }
      .chatMediaModal .modal-content .modal-body .chatMedia-gallery-grid .chatMediaPhoto button {
        padding: 0;
        background: #000;
        border: 0;
        height: 144px;
        width: 144px;
        box-sizing: border-box;
        border: 1.5px solid #333739;
        position: relative; }
        .chatMediaModal .modal-content .modal-body .chatMedia-gallery-grid .chatMediaPhoto button:focus {
          outline: 0;
          border: 1.5px solid var(--primary); }
        .chatMediaModal .modal-content .modal-body .chatMedia-gallery-grid .chatMediaPhoto button .video_play_icon .play_back_circle {
          position: absolute;
          background: #000;
          width: 40px;
          height: 40px;
          content: '';
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          opacity: 0.6;
          border-radius: 50%;
          box-shadow: 2px 2px 9px #000; }
        .chatMediaModal .modal-content .modal-body .chatMedia-gallery-grid .chatMediaPhoto button .video_play_icon i {
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          /* box-shadow: -2px 0px 5px #fff; */
          border-radius: 50%;
          z-index: 99; }
        .chatMediaModal .modal-content .modal-body .chatMedia-gallery-grid .chatMediaPhoto button img {
          width: 140px;
          height: 144px;
          object-fit: contain; }
  .chatMediaModal .modal-content .chat-media-tabs-menu .nav-tabs {
    width: 250px;
    margin: 0 auto 20px;
    background: #666666;
    border-radius: 50px;
    border: none; }
    .chatMediaModal .modal-content .chat-media-tabs-menu .nav-tabs .nav-link:hover, .chatMediaModal .modal-content .chat-media-tabs-menu .nav-tabs .nav-link:focus {
      border-color: transparent; }
    .chatMediaModal .modal-content .chat-media-tabs-menu .nav-tabs .nav-link {
      color: #fff;
      cursor: pointer; }
    .chatMediaModal .modal-content .chat-media-tabs-menu .nav-tabs .nav-link.active, .chatMediaModal .modal-content .chat-media-tabs-menu .nav-tabs .nav-item.show .nav-link {
      color: #000000;
      background-color: #fff;
      border-color: transparent;
      border-radius: 50px; }
  .chatMediaModal .modal-content button.sendBtn {
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    width: 230px;
    height: 50px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px; }
  .chatMediaModal .modal-content .modal-footer {
    border-top: 0;
    padding-top: 0;
    justify-content: center; }
    .chatMediaModal .modal-content .modal-footer button {
      border-radius: 10px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      width: 230px;
      height: 50px; }

@media (min-width: 576px) {
  .modal-dialog.chatMediaDialog {
    min-width: 800px; } }

@charset "UTF-8";
.modal-dialog.end-Premium-Modal {
  max-width: 450px; }

.modal-content {
  width: auto !important;
  height: auto !important; }

.modal-body {
  width: 75%;
  margin: 0 auto; }

.radioButton {
  text-align: left;
  white-space: nowrap; }
  .radioButton .inputReportInput {
    margin-right: 5px; }

.video-Modal .modal-content {
  position: relative !important;
  height: auto !important;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-family) !important;
  background-color: var(--dark);
  border-radius: 1em; }

.video-Modal .chat-modal-header {
  border-bottom: none;
  padding: 0 1rem; }
  .video-Modal .chat-modal-header img {
    opacity: 1 !important; }
  .video-Modal .chat-modal-header .close {
    margin-top: 0rem !important;
    color: #C4C4C4;
    font-weight: 300;
    font-size: 28px;
    padding-top: 7px;
    position: absolute;
    top: 0px;
    right: 15px; }

.video-Modal .modal-center-img {
  width: 115px;
  margin: 15px auto 0px auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.video-Modal .modal-title {
  flex: 1 1 100%;
  text-align: center;
  margin: 0 !important; }

.video-Modal h4 {
  font-size: 18px;
  font-weight: 700 !important;
  margin-bottom: 0.3em; }

.video-Modal p {
  font-weight: 400;
  opacity: 0.8; }

.video-Modal .chat-modal-body button {
  line-height: 26px !important;
  z-index: 99;
  position: absolute;
  width: 70% !important;
  background-color: #3B4149;
  font-weight: 800 !important;
  color: white !important;
  border-radius: 20px !important;
  height: 35px;
  bottom: -17.5px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 1px;
  text-align: center;
  padding: 5px;
  text-transform: uppercase;
  box-shadow: 2px 14px 28px 1px rgba(0, 0, 0, 0.28);
  font-family: var(--font-family) !important;
  font-weight: 400; }

.video-Modal .chat-modal-body .form-group {
  margin-bottom: 35px !important; }

.video-Modal .chat-modal-footer {
  background-color: var(--primary);
  min-height: 60px;
  position: relative;
  padding: 0 1rem;
  justify-content: center;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em; }
  .video-Modal .chat-modal-footer p {
    cursor: pointer;
    color: white;
    margin-top: 25px;
    font-size: 12px !important;
    text-decoration: underline;
    font-family: var(--font-family) !important;
    width: 100%;
    text-align: center;
    font-weight: 600; }

.tip-Modal .modal-content {
  height: 242px !important; }

.modal-dialog.tip-Modal {
  margin: 1.75rem auto; }

.tip-Modal .form-group input[type=number] {
  margin: 0 auto;
  width: 80px; }

.tip-Modal .form-group input[type=number]::-webkit-inner-spin-button,
.tip-Modal .form-group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.tip-Modal .alert {
  width: 95%;
  margin: 10px auto; }

.tip-Modal .alert.alert-danger {
  padding: 0;
  border-radius: 5px;
  background-color: #FDF1F4;
  color: #E5A0A9;
  border-color: #FDF1F4; }

.modal-backdrop.show {
  opacity: 0.3 !important; }

.modal {
  background: transparent !important; }

body.modal-open #root {
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); }

.tip-Modal .chat-modal-footer {
  border-bottom-right-radius: 0.7em !important;
  border-bottom-left-radius: 0.7em !important; }

.tip-Modal .btn {
  font-family: var(--font-family) !important;
  font-weight: 400; }

.tip-Modal .alert.alert-danger {
  color: #CD5664 !important;
  font-size: 12px; }

.online-user-details .back_btn {
  display: none;
  align-self: center; }
  .online-user-details .back_btn .btn {
    border: none;
    color: grey;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px; }
  .online-user-details .back_btn .btn:before {
    content: "\276E";
    color: #d7d7d7;
    font-size: 19px; }

@charset "UTF-8";
.modal-dialog.end-Premium-Modal {
  max-width: 450px; }

.modal-content {
  width: auto !important;
  height: auto !important; }

.modal-body {
  width: 75%;
  margin: 0 auto; }

.radioButton {
  text-align: left;
  white-space: nowrap; }
  .radioButton .inputReportInput {
    margin-right: 5px; }

.EditInfo-Modal .err_Msg {
  display: block;
  color: #FD7663; }

.EditInfo-Modal .modal-body {
  text-align: center; }
  .EditInfo-Modal .modal-body .fa.fa-phone {
    font-size: 50px;
    color: #f07563; }

.EditInfo-Modal .modal-content {
  position: relative !important;
  height: auto !important;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-family) !important;
  background-color: var(--dark);
  border-radius: 1em; }

.EditInfo-Modal .chat-modal-header {
  border-bottom: none;
  padding: 0 1rem; }
  .EditInfo-Modal .chat-modal-header img {
    opacity: 1 !important; }
  .EditInfo-Modal .chat-modal-header .close {
    margin-top: 0rem !important;
    color: #C4C4C4;
    font-weight: 300;
    font-size: 28px;
    padding-top: 7px;
    position: absolute;
    top: 0px;
    right: 15px; }

.EditInfo-Modal .modal-center-img {
  width: 115px;
  margin: 15px auto 0px auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.EditInfo-Modal .modal-title {
  flex: 1 1 100%;
  text-align: center;
  margin: 0 !important; }

.EditInfo-Modal h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 10px; }

.EditInfo-Modal .verification-code-text {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #C8C8C8;
  margin-bottom: 5px; }

.EditInfo-Modal .verification-num-text {
  margin-bottom: 20px; }

.EditInfo-Modal .recieve-code-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.5);
  opacity: 1; }
  .EditInfo-Modal .recieve-code-text span {
    color: #FD7663;
    margin-left: 6px;
    cursor: pointer; }

.EditInfo-Modal .chat-modal-body button {
  line-height: 26px !important;
  z-index: 1;
  position: relative;
  width: 70% !important;
  background-color: #3B4149;
  font-weight: 800 !important;
  color: white !important;
  border-radius: 20px !important;
  height: 35px;
  bottom: auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 1px;
  text-align: center;
  padding: 5px;
  text-transform: uppercase;
  box-shadow: 2px 14px 28px 1px rgba(0, 0, 0, 0.28);
  font-family: var(--font-family) !important;
  font-weight: 400; }

.EditInfo-Modal .chat-modal-body .form-group {
  margin-bottom: 35px !important; }

.EditInfo-Modal .chat-modal-footer {
  background-color: var(--primary);
  min-height: 60px;
  position: relative;
  padding: 0 1rem;
  justify-content: center;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em; }
  .EditInfo-Modal .chat-modal-footer p {
    cursor: pointer;
    color: white;
    margin-top: 25px;
    font-size: 12px !important;
    text-decoration: underline;
    font-family: var(--font-family) !important;
    width: 100%;
    text-align: center;
    font-weight: 600; }

.tip-Modal .modal-content {
  height: 300px !important; }

.modal-dialog.tip-Modal {
  margin: 1.75rem auto; }

.tip-Modal .form-group input[type=number] {
  margin: 0 auto;
  width: 80px; }

.tip-Modal .form-group input[type=number]::-webkit-inner-spin-button,
.tip-Modal .form-group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.tip-Modal .alert {
  width: 95%;
  margin: 10px auto; }

.tip-Modal .alert.alert-danger {
  padding: 0;
  border-radius: 5px;
  background-color: #FDF1F4;
  color: #E5A0A9;
  border-color: #FDF1F4; }

.modal-backdrop.show {
  opacity: 0.3 !important; }

.modal {
  background: transparent !important; }

body.modal-open #root {
  -moz-filter: unset !important;
  -o-filter: unset !important;
  -ms-filter: unset !important;
  filter: unset !important; }

.tip-Modal .chat-modal-footer {
  border-bottom-right-radius: 0.7em !important;
  border-bottom-left-radius: 0.7em !important; }

.tip-Modal .btn {
  font-family: var(--font-family) !important;
  font-weight: 400; }

.tip-Modal .alert.alert-danger {
  color: #CD5664 !important;
  font-size: 12px; }

.online-user-details .back_btn {
  display: none;
  align-self: center; }
  .online-user-details .back_btn .btn {
    border: none;
    color: grey;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px; }
  .online-user-details .back_btn .btn:before {
    content: "\276E";
    color: #d7d7d7;
    font-size: 19px; }

.delete-number-modal button.btn-primary {
  background-color: #f07563 !important;
  border-color: #f07563 !important;
  border-radius: 6px !important;
  margin: 0 10px;
  font-size: 14px;
  text-transform: capitalize !important;
  font-weight: 500 !important; }

.EditInfo-Modal.RefundConfirmationModal {
  max-width: 500px !important;
  transform: translate(-50%, -50%) !important;
  top: 50%;
  left: 50%;
  margin: 0 !important; }
  .EditInfo-Modal.RefundConfirmationModal .modal-content {
    padding: 20px; }
    .EditInfo-Modal.RefundConfirmationModal .modal-content h4 {
      margin: 15px 0; }

.Whisperer-modal {
  max-width: 360px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  margin: 0 auto !important;
  width: 100% !important;
  position: absolute !important; }
  @media (max-width: 767px) {
    .Whisperer-modal {
      max-width: 300px !important; } }
  .Whisperer-modal .modal-content {
    background: #333739;
    border-radius: 12px;
    height: auto; }
    .Whisperer-modal .modal-content .modal-body {
      width: 100%;
      padding: 0 30px 30px; }
      .Whisperer-modal .modal-content .modal-body .modal-title {
        color: #FFF;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: -0.54px;
        margin-bottom: 17px; }
      .Whisperer-modal .modal-content .modal-body .whisperer-modal-reply-box {
        margin-bottom: 20px;
        position: relative; }
        .Whisperer-modal .modal-content .modal-body .whisperer-modal-reply-box .form-group {
          margin: 0; }
        .Whisperer-modal .modal-content .modal-body .whisperer-modal-reply-box label {
          color: rgba(255, 255, 255, 0.7);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px;
          display: block; }
        .Whisperer-modal .modal-content .modal-body .whisperer-modal-reply-box textarea {
          background-color: rgba(255, 255, 255, 0.05) !important;
          border: 0.8px solid rgba(253, 118, 99, 0.5);
          padding: 18px !important;
          color: #FFF;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 21px;
          min-height: 120px; }
          .Whisperer-modal .modal-content .modal-body .whisperer-modal-reply-box textarea::placeholder {
            color: #fff; }
        .Whisperer-modal .modal-content .modal-body .whisperer-modal-reply-box textarea.isWarningActive {
          background-color: rgba(36, 23, 21, 0.8) !important;
          padding-top: 60px !important; }
          @media (max-width: 767px) {
            .Whisperer-modal .modal-content .modal-body .whisperer-modal-reply-box textarea.isWarningActive {
              padding-top: 50px !important; } }
        .Whisperer-modal .modal-content .modal-body .whisperer-modal-reply-box span.WarningIcon {
          position: absolute;
          left: 0;
          right: 0;
          display: block;
          text-align: center;
          top: 50px; }
          @media (max-width: 767px) {
            .Whisperer-modal .modal-content .modal-body .whisperer-modal-reply-box span.WarningIcon {
              top: 40px; } }
        .Whisperer-modal .modal-content .modal-body .whisperer-modal-reply-box .refresh-suggestion-icon {
          position: absolute;
          bottom: 7px;
          z-index: 9;
          right: 7px;
          cursor: pointer; }
      .Whisperer-modal .modal-content .modal-body.paid-pic-modal .image-wrap {
        text-align: center;
        width: 100px;
        margin: 32px auto 18px;
        position: relative;
        height: 120px;
        overflow: hidden;
        padding-top: 20px; }
        .Whisperer-modal .modal-content .modal-body.paid-pic-modal .image-wrap img {
          width: 200px;
          object-fit: contain;
          position: absolute;
          left: -56%;
          right: 0;
          margin: 20px auto 0;
          z-index: 9;
          top: -25%; }
        .Whisperer-modal .modal-content .modal-body.paid-pic-modal .image-wrap .bot_bg_circle {
          background: rgba(253, 118, 99, 0.1);
          display: block;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          position: absolute;
          top: 20px;
          left: 0;
          right: 0;
          margin: 0 auto; }
      .Whisperer-modal .modal-content .modal-body.paid-pic-modal .modal-btn {
        border: none;
        padding: 17px 20px;
        text-decoration: none;
        display: inline-block;
        position: relative;
        top: 0;
        width: 100%;
        background: #FD7663;
        border-radius: 10px;
        font-weight: 600;
        font-size: 16px;
        line-height: 12px !important;
        text-align: center;
        color: #FFFFFF;
        height: 50px; }
      .Whisperer-modal .modal-content .modal-body.paid-pic-modal .copyBtn {
        background: #333739;
        border: 1px solid #FD7663;
        color: #FD7663; }
    .Whisperer-modal .modal-content .camHeader {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom: none;
      padding: 30px 30px; }
      .Whisperer-modal .modal-content .camHeader .close {
        margin-top: 0rem !important;
        color: #5D6569;
        font-weight: 300;
        font-size: 35px;
        position: absolute;
        top: 22px;
        right: 40px;
        padding: 0;
        box-shadow: none;
        text-shadow: none;
        height: 25px;
        width: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        opacity: 1;
        z-index: 999; }
        .Whisperer-modal .modal-content .camHeader .close span {
          line-height: 1; }
    .Whisperer-modal .modal-content .camFooter {
      background-color: transparent;
      height: auto;
      border-bottom-right-radius: 1rem;
      border-bottom-left-radius: 1rem;
      border: none;
      padding: 0; }
  .Whisperer-modal #noTextArea {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    /* Konqueror HTML */
    /* Firefox */
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
    pointer-events: none; }
  .Whisperer-modal .Overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; }

.Notes-modal {
  max-width: 400px !important; }
  .Notes-modal .modal-content .modal-body.paid-pic-modal {
    padding: 0 20px 20px; }
    .Notes-modal .modal-content .modal-body.paid-pic-modal .modal-title {
      text-align: left;
      display: block;
      color: #FFF;
      font-family: Overpass;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.8px;
      margin-bottom: 20px;
      padding-bottom: 16px;
      border-bottom: 1px solid #545454;
      margin-top: 17px; }
    .Notes-modal .modal-content .modal-body.paid-pic-modal .modal-note-box textarea {
      border-radius: 8px;
      background: #1F1F1F;
      border: none;
      padding: 20px 10px 35px 20px;
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 18px;
      min-height: 203px;
      resize: none; }
    .Notes-modal .modal-content .modal-body.paid-pic-modal .copyBtn {
      background: #FD7663;
      border: none;
      color: #FFFFFF; }
  .Notes-modal .modal-content .camHeader .close {
    font-size: 24px;
    top: 21px;
    right: 43px;
    height: 16px;
    width: 16px; }


.chat-card > .row {
  align-items: center; }

.chat-card .col-3 {
  padding-right: 0; }

.chat-card .col-9 {
  padding-left: 0; }

.chat-card .chat_ib {
  position: relative; }
  .chat-card .chat_ib .ellipsisData {
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 600; }
  .chat-card .chat_ib h5 {
    margin-bottom: 0; }
  .chat-card .chat_ib .lastmsg {
    font-size: 12px;
    margin: 0;
    color: #B9B9B9; }
  .chat-card .chat_ib .msg-time {
    position: absolute;
    top: 0;
    right: -7px;
    font-size: 10px;
    color: #8c8c8c; }

.chat-card .chat_img {
  position: relative; }

.chat-card .online-user {
  position: absolute;
  left: 65%;
  bottom: 2px; }

.chat-card:last-child .row {
  border-bottom: none; }

.card-header {
  background: transparent !important; }
  .card-header .col {
    font-size: 22px; }
    .card-header .col svg {
      margin-right: 6px; }

.card-body {
  padding-top: 0;
  padding-left: 10px;
  padding-top: 0; }

.conversion-header {
  border-bottom: 1px solid #f1f1f2 !important; }
  @media (max-width: 767px) {
    .conversion-header {
      border-bottom: 1px solid #f1f1f2 !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      padding-bottom: 0 !important;
      flex-direction: column; }
      .conversion-header .host-dp {
        place-self: flex-start; } }
  @media (max-width: 767px) {
    .conversion-header .switch {
      position: absolute;
      right: 0px;
      top: 10px; } }

.chatHeading {
  font-size: 1.25rem; }
  @media (max-width: 767px) {
    .chatHeading {
      display: none; } }

.dollar {
  float: right;
  position: relative;
  top: 20px; }

.user-name {
  margin-left: 14px; }

.write_msg {
  width: 85%;
  padding: 0;
  border: none;
  height: 54px;
  outline: none; }
  .write_msg::placeholder {
    color: #c4c4c4; }

.write-footer {
  min-height: 54px;
  padding-left: 17px; }
  @media screen and (max-width: 767px) {
    .write-footer {
      padding: 0 !important; } }

.no-chat {
  min-height: 322px;
  display: flex;
  align-items: center;
  justify-content: center; }

.CustomerListHeader {
  margin: 10px; }

@media (max-width: 767px) {
  .all-convo-main-wrap {
    display: none; } }

.video_status_loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background: rgba(0, 0, 0, 0.5); }
  .video_status_loader p {
    margin: 0 5px;
    text-align: center;
    font-size: 14px;
    color: #f07664; }

progress[value] {
  --color: #fd7663;
  /* the progress color */
  --background: lightgrey;
  /* the background color */
  -webkit-appearance: none;
  appearance: none;
  border: none;
  width: 300px;
  margin: 0 10px;
  border-radius: 10em;
  background: var(--background);
  height: 5px;
  margin-top: 10px; }

progress[value]::-webkit-progress-bar {
  border-radius: 10em;
  background: var(--background); }

progress[value]::-webkit-progress-value {
  border-radius: 10em;
  background: var(--color); }

progress[value]::-moz-progress-bar {
  border-radius: 10em;
  background: var(--color); }

@media (max-width: 767px) {
  .all-convo-main-wrap\.dis-block {
    display: block;
    padding: 0 !important; }
  .customerlist-main-wrap.dis-none {
    display: none; }
  .video_status_loader p {
    font-size: 14px; } }

.chatLogsLoading {
  position: absolute;
  z-index: 9;
  background: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  width: calc(100% - 260px);
  left: 260px;
  top: 0; }
  .chatLogsLoading .height-700 .text-center {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh !important; }
  .chatLogsLoading .ar-chatLoader-absolute {
    position: absolute;
    z-index: 99;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(41, 42, 43, 0.5);
    bottom: 0;
    left: 0;
    height: 100%; }


.AffiliateEarningCard {
  border-radius: 10px; }
  .AffiliateEarningCard .perf-cred {
    color: #2D313A;
    font-size: 1.25rem;
    text-transform: uppercase;
    letter-spacing: -0.05em; }
    .AffiliateEarningCard .perf-cred h3 {
      font-size: 2.5rem;
      font-weight: 700;
      margin-bottom: 0;
      line-height: 30px; }
  .AffiliateEarningCard .perf-amount {
    font-weight: 800;
    font-size: 20px; }

.sendText {
  font-size: 22px;
  line-height: 24px;
  opacity: 0.9; }
  .sendText span {
    color: var(--primary);
    display: inline-block;
    border: 1px solid var(--primary);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    font-size: 14px;
    line-height: 16px;
    margin-left: 5px; }

.linkText {
  font-size: 1rem;
  line-height: 24px; }
  .linkText a {
    color: var(--primary);
    font-weight: bold;
    opacity: 0.9; }

.aff-card-heading {
  font-size: 1.125rem;
  font-weight: 600; }

.popperClassName,
.innerClassName {
  width: 280px !important;
  max-width: 280px !important;
  padding: 0 !important;
  border-radius: 0;
  background-color: var(--dark) !important;
  text-align: left !important; }

.opacity {
  opacity: 1 !important;
  border: 1px solid #595E68;
  padding: 0 !important;
  border-radius: 10px; }

.arrowClassName:before,
.arrowClassName:before {
  border-left-color: var(--dark) !important; }

.quetionHiddenWrp {
  width: 280px;
  border-radius: 10px; }
  .quetionHiddenWrp ul {
    padding-left: 13px; }
    .quetionHiddenWrp ul li {
      color: var(--primary); }
      .quetionHiddenWrp ul li span {
        font-weight: 300;
        font-size: 0.75rem;
        opacity: 0.6;
        color: var(--white);
        display: block; }
  .quetionHiddenWrp .payout {
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 18px;
    border-bottom: 2px solid var(--primary);
    padding-bottom: 10px;
    display: inline-block; }
  .quetionHiddenWrp .supportText {
    color: #B9B9B9;
    opacity: 0.8;
    font-size: 0.75rem;
    line-height: 10px;
    font-weight: 300; }

@charset "UTF-8";
.modal-dialog.end-Premium-Modal {
  max-width: 450px; }

.modal-content {
  width: auto !important;
  height: auto !important; }

.modal-body {
  width: 75%;
  margin: 0 auto; }

.radioButton {
  text-align: left;
  white-space: nowrap; }
  .radioButton .inputReportInput {
    margin-right: 5px; }

.video-Modal .modal-content {
  position: relative !important;
  height: auto !important;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-family) !important;
  background-color: var(--dark);
  border-radius: 1em; }

.video-Modal .chat-modal-header {
  border-bottom: none;
  padding: 0 1rem; }
  .video-Modal .chat-modal-header img {
    opacity: 1 !important; }
  .video-Modal .chat-modal-header .close {
    margin-top: 0rem !important;
    color: #C4C4C4;
    font-weight: 300;
    font-size: 28px;
    padding-top: 7px;
    position: absolute;
    top: 0px;
    right: 15px; }

.video-Modal .modal-center-img {
  width: 115px;
  margin: 15px auto 0px auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.video-Modal .modal-title {
  flex: 1 1 100%;
  text-align: center;
  margin: 0 !important; }

.video-Modal h4 {
  font-size: 18px;
  font-weight: 700 !important;
  margin-bottom: 0.3em; }

.video-Modal p {
  font-weight: 400;
  opacity: 0.8; }

.video-Modal .chat-modal-body button {
  line-height: 26px !important;
  z-index: 99;
  position: absolute;
  width: 70% !important;
  background-color: #3B4149;
  font-weight: 800 !important;
  color: white !important;
  border-radius: 20px !important;
  height: 35px;
  bottom: -17.5px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 1px;
  text-align: center;
  padding: 5px;
  text-transform: uppercase;
  box-shadow: 2px 14px 28px 1px rgba(0, 0, 0, 0.28);
  font-family: var(--font-family) !important;
  font-weight: 400; }

.video-Modal .chat-modal-body .form-group {
  margin-bottom: 35px !important; }

.video-Modal .chat-modal-footer {
  background-color: var(--primary);
  min-height: 60px;
  position: relative;
  padding: 0 1rem;
  justify-content: center;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em; }
  .video-Modal .chat-modal-footer p {
    cursor: pointer;
    color: white;
    margin-top: 25px;
    font-size: 12px !important;
    text-decoration: underline;
    font-family: var(--font-family) !important;
    width: 100%;
    text-align: center;
    font-weight: 600; }

.tip-Modal .modal-content {
  height: 242px !important; }

.modal-dialog.tip-Modal {
  margin: 1.75rem auto; }

.tip-Modal .form-group input[type=number] {
  margin: 0 auto;
  width: 80px; }

.tip-Modal .form-group input[type=number]::-webkit-inner-spin-button,
.tip-Modal .form-group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.tip-Modal .alert {
  width: 95%;
  margin: 10px auto; }

.tip-Modal .alert.alert-danger {
  padding: 0;
  border-radius: 5px;
  background-color: #FDF1F4;
  color: #E5A0A9;
  border-color: #FDF1F4; }

.modal-backdrop.show {
  opacity: 0.3 !important; }

.modal {
  background: transparent !important; }

body.modal-open #root {
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); }

.tip-Modal .chat-modal-footer {
  border-bottom-right-radius: 0.7em !important;
  border-bottom-left-radius: 0.7em !important; }

.tip-Modal .btn {
  font-family: var(--font-family) !important;
  font-weight: 400; }

.tip-Modal .alert.alert-danger {
  color: #CD5664 !important;
  font-size: 12px; }

.online-user-details .back_btn {
  display: none;
  align-self: center; }
  .online-user-details .back_btn .btn {
    border: none;
    color: grey;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px; }
  .online-user-details .back_btn .btn:before {
    content: "\276E";
    color: #d7d7d7;
    font-size: 19px; }

.dropzone {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #333739;
  border-style: dashed;
  background-color: #292a2b;
  color: #bdbdbd;
  outline: none;
  border-radius: 10px;
  transition: border .24s ease-in-out; }
  .dropzone .cld-arr-icon {
    position: relative;
    margin: 35px auto;
    width: 50px; }
    .dropzone .cld-arr-icon .cloude-icon {
      position: absolute; }
    .dropzone .cld-arr-icon .arrow-icon {
      position: inherit;
      top: 18px;
      left: 16px; }
    .dropzone .cld-arr-icon p {
      color: #666662; }
  .dropzone button {
    border-radius: 10px;
    padding: 5px 83px; }
  .dropzone .addFilesBtn {
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center; }
    .dropzone .addFilesBtn svg {
      fill: var(--primary); }
    .dropzone .addFilesBtn:hover svg {
      fill: #fff; }

.file-name {
  color: #bdbdbd;
  font-size: 15px;
  /* text-decoration: none; */
  list-style-type: none; }

.dropArea {
  margin-top: 5px !important; }
  .dropArea > div {
    height: 100%; }
  .dropArea .dropzone {
    padding: 50px 0; }
  .dropArea .dzu-dropzone {
    height: 100%;
    border: 1px dashed #666666;
    border-radius: 10px;
    padding: 0 !important;
    justify-content: center;
    overflow: auto; }
    .dropArea .dzu-dropzone button {
      border-color: var(--primary);
      background-color: transparent;
      border-radius: 10px;
      color: var(--primary);
      margin-top: 88px; }
    .dropArea .dzu-dropzone label {
      color: var(--white);
      opacity: 0.6;
      font-size: 1rem;
      font-weight: 600; }

.myForm .form-group {
  margin-bottom: 0; }
  .myForm .form-group .invalid-feedback {
    font-size: 0.875rem; }

.addMediaDone {
  width: 230px;
  height: 50px;
  border-radius: 10px !important; }

.fillterRow .search-label {
  margin-bottom: 0; }
  .fillterRow .search-label input {
    background-color: var(--dark);
    border-radius: 10px;
    font-size: 0.875rem;
    padding: 12px 15px;
    border-color: var(--dark);
    height: 44px;
    width: 274px;
    color: var(--white);
    background-image: url(../../static/media/magnify.70aaf4c8.svg);
    background-repeat: no-repeat;
    background-position: left 10px center;
    text-indent: 20px;
    background-size: 18px; }
    @media (max-width: 1200px) {
      .fillterRow .search-label input {
        width: 100%; } }

.filterWrap {
  position: absolute;
  z-index: 999; }
  .filterWrap > label {
    margin-bottom: 0;
    margin-right: 20px;
    color: #B9B9B9;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 18px; }
    .filterWrap > label select {
      display: block;
      background-color: var(--dark);
      border-radius: 10px;
      font-size: 0.875rem;
      color: var(--white);
      height: 44px;
      width: 239px;
      padding: 8px 15px;
      margin-top: 6px;
      border-color: var(--dark); }
      @media (max-width: 1250px) {
        .filterWrap > label select {
          width: 220px; } }
  .filterWrap .filterBtn {
    width: 102px;
    border-radius: 10px;
    height: 44px; }
  .filterWrap .form-control:focus {
    display: block;
    background-color: var(--dark);
    border-radius: 10px;
    font-size: .875rem;
    color: var(--white);
    height: 44px;
    width: 239px;
    padding: 12px 15px;
    margin-top: 6px;
    border-color: var(--dark); }

.myTable {
  border-radius: 10px; }
  .myTable .iframe_video_container {
    position: relative;
    width: 65px;
    overflow: hidden;
    height: 65px; }
  .myTable .video_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100%;
    background: rgba(0, 0, 0, 0.1); }
  .myTable table {
    border: 0; }
    .myTable table thead th {
      color: #fff !important;
      opacity: 0.6;
      font-weight: 800;
      font-size: 0.875rem;
      line-height: 17px;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom-color: #ffffff26 !important; }
      .myTable table thead th:first-child {
        padding-left: 0 !important; }
    .myTable table tbody td {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom-color: #ffffff26 !important;
      color: #fff;
      font-size: 0.875rem;
      vertical-align: middle; }
      .myTable table tbody td .fileColumn {
        display: inline-flex;
        align-items: flex-start;
        flex-direction: column; }
        .myTable table tbody td .fileColumn .profileImg {
          margin-right: 1em; }
          .myTable table tbody td .fileColumn .profileImg img {
            object-fit: cover;
            border-radius: 5px; }
        .myTable table tbody td .fileColumn p.mb-0 {
          font-size: 0.95rem;
          font-weight: 600;
          line-height: 1.4;
          word-break: break-all; }
        .myTable table tbody td .fileColumn .userId {
          font-size: 0.875rem;
          opacity: 0.5;
          margin-bottom: 0; }
        .myTable table tbody td .fileColumn .copyText {
          text-transform: capitalize;
          font-size: 12px; }
      .myTable table tbody td .descriptionWrp {
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .myTable table tbody td .descriptionWrp > p {
          margin-right: 20px;
          margin-bottom: 0;
          word-break: break-all; }
        .myTable table tbody td .descriptionWrp button {
          min-width: 65px;
          font-weight: 600;
          font-size: 12px;
          line-height: 15px;
          height: 34px;
          text-transform: capitalize; }
      .myTable table tbody td:first-child {
        padding-left: 0 !important; }

@media (max-width: 1098px) {
  .mobileColumn {
    flex-direction: column; }
    .mobileColumn button:first-child {
      margin-bottom: 5px;
      margin-right: 0 !important; } }

@media (max-width: 1098px) {
  .mediaTable thead th:last-child {
    width: 34% !important; } }

.addMediaWraper {
  border-radius: 10px; }
  .addMediaWraper .arrowBtn:hover {
    background-color: transparent !important;
    box-shadow: none !important; }
  .addMediaWraper .arrowBtn:focus {
    background-color: transparent !important;
    box-shadow: none !important; }

.myForm .form-group label {
  font-size: 0.75rem;
  color: #B9B9B9; }

.myForm .form-group input, .myForm .form-group textarea, .myForm .form-group select {
  background-color: #333739;
  border-color: #333739;
  color: #fff;
  font-size: 0.875rem;
  border-radius: 10px; }
  .myForm .form-group input::placeholder, .myForm .form-group textarea::placeholder, .myForm .form-group select::placeholder {
    color: #666666; }

.myForm .form-group input, .myForm .form-group select {
  min-height: 44px; }

.myForm .form-group textarea {
  min-height: 82px; }

.doneBtn {
  width: 230px;
  border-radius: 10px;
  height: 50px;
  margin-top: 2em; }

.pagination {
  justify-content: flex-end; }

.no-data-available {
  display: flex;
  align-items: center;
  justify-content: center; }

.priceHeader {
  width: calc(28% - 87px); }
  @supports (-webkit-marquee-repetition: infinite) and (object-fit: fill) {
    .priceHeader {
      width: 20%; } }

.disable-roulette-btn-wrap {
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1; }
  .disable-roulette-btn-wrap .close-btn {
    background: transparent;
    border: unset;
    outline: none;
    cursor: pointer; }

.self_preview_video {
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: relative; }

.searchingText {
  margin-top: 5%; }

#sun {
  top: 40%;
  left: 47%;
  border-width: 0px;
  display: flex;
  padding: 5px;
  font-size: 24px;
  align-items: center;
  border-radius: 100%;
  width: 5vw;
  height: 5vw;
  color: #f77361;
  background: white;
  justify-content: center;
  margin: 0 auto; }

#sun img {
  width: 2vw; }

.innerCircleSmall {
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  border-width: 0;
  border-style: solid;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-color: #13131373; }

.innerCircle {
  width: 14vw;
  height: 14vw;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center; }

.outerCircle {
  width: 18vw;
  height: 18vw;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  transition: left 1s ease; }

.pulses-none, .pulses-none:before, .pulses-none:after {
  border: unset !important; }

.info-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  margin-top: 6%;
  transition: left 1s ease; }

.roullette_wrapper.QRcodeOn .hostEndVideo, .roullette_wrapper.QRcodeOn .outerCircle, .roullette_wrapper.QRcodeOn .info-text {
  left: 40%; }

.dr_code_container {
  position: relative; }

.refresh-loader {
  position: absolute;
  top: 0%;
  left: 21%;
  background: rgba(0, 0, 0, 0.6);
  width: 201px;
  height: 200px;
  text-align: center;
  line-height: 200px; }
  .refresh-loader i.fa-spin {
    color: #fff;
    font-size: 30px; }

.performer-search-circle {
  position: relative;
  height: 375px;
  width: 375px;
  background: #131313;
  border-radius: 50%;
  margin: 30px auto 0; }

.primary-button {
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #f77361;
  margin: 2px 8px;
  font-size: 16px;
  padding: 13px 35px;
  border-radius: 27px;
  color: #f77361;
  background: transparent;
  text-transform: uppercase;
  cursor: pointer; }

.performer-list-wrap h3 {
  font-family: Overpass;
  font-style: normal;
  font-weight: bold;
  font-size: 1.7vw;
  line-height: 2vw;
  text-align: center;
  color: #FFFFFF; }

.primary-button-fill {
  background: #f77361;
  color: white; }

.performer-search-wrap .calls-left i {
  margin-right: 8px; }

.celestial_body {
  position: absolute; }

.performer-round-img {
  border: 2px solid #fefefe;
  border-radius: 50%; }

.performer-search-circle {
  position: relative;
  height: 26vw;
  width: 26vw;
  background: #131313;
  border-radius: 50%;
  margin: 45px auto 30px; }
  @media (max-width: 900px) {
    .performer-search-circle {
      margin: 5px auto 10px; } }

.primary-button {
  width: -moz-fit-content;
  width: fit-content;
  border: 1px solid #f77361;
  margin: 2px 8px;
  font-size: 16px;
  padding: 13px 35px;
  border-radius: 3vw;
  color: #f77361;
  background: transparent;
  text-transform: uppercase;
  cursor: pointer; }

.primary-button-fill {
  background: #f77361;
  color: white; }

.performer-search-wrap .calls-left i {
  margin-right: 8px; }

.celestial_body {
  position: absolute; }

.circle-point-lg {
  position: absolute;
  right: 13%;
  top: 20%;
  width: 2.4vw;
  height: 2.4vw; }

.circle-point-md {
  position: absolute;
  right: 50%;
  top: 85%;
  width: 2vw;
  height: 2vw; }

.circle-point-sm {
  position: absolute;
  right: 10%;
  top: 52%;
  width: 1.25vw;
  height: 1.25vw; }

.circle-point-xm {
  position: absolute;
  right: 63%;
  top: 23%;
  width: 1vw;
  height: 1vw; }

.search-static-dots-1 {
  position: absolute;
  border-radius: 50%;
  top: 39%;
  left: 39%;
  width: 280px;
  height: 280px;
  margin-top: -100px;
  margin-left: -100px; }

.search-static-dots-2 {
  position: absolute;
  border-radius: 50%;
  left: 39%;
  top: 39%;
  width: 280px;
  height: 280px;
  margin-top: -100px;
  margin-left: -100px; }

.search-static-dots-3 {
  position: absolute;
  border-radius: 50%;
  position: absolute;
  left: 44%;
  bottom: 18%;
  width: 245px;
  height: 245px;
  margin-top: -100px;
  margin-left: -100px;
  /* border-color: #ffffff4a;
  border-width: 0.5pt;
  border-style: solid; */ }

#earth {
  top: 70%;
  left: 68%;
  height: 4vw;
  width: 4vw;
  border-radius: 50%; }

#earth-orbit {
  top: 50%;
  left: 50%;
  width: 370px; }

#mercury-orbit {
  top: 21%;
  left: 21%;
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  border-color: #ffffff0a;
  border-width: 0.5pt;
  border-style: solid;
  border-radius: 50%; }

#venus {
  /* Style your earth */
  top: 35%;
  left: 50%;
  height: 47.5px;
  width: 47.5px;
  margin-top: -18.75px;
  margin-left: -18.75px;
  background-color: black;
  border-radius: 50%; }

#venus-orbit {
  top: 30%;
  left: 30%;
  width: 10vw;
  height: 10vw;
  border-color: #ffffff21;
  border-width: 0.5pt;
  border-style: solid;
  border-radius: 50%; }

#custom-cel-body {
  top: 17%;
  left: 17%;
  height: 2.5vw;
  width: 2.5vw; }

.cel-body-md {
  left: 16% !important;
  top: 72% !important;
  height: 1.8vw !important;
  width: 1.8vw !important; }

#custom-orbit {
  top: 50%;
  left: 50%;
  width: 350px; }

.marker, .marker:before, .marker:after {
  display: block;
  width: 20px;
  height: 20px;
  border: 3vw solid #333739;
  border-radius: 50%; }

.marker:before, .marker:after {
  position: absolute;
  margin-left: -4px;
  margin-top: -4px;
  opacity: 0;
  border: 3vw solid #333739;
  animation: pulse 3s infinite ease-out; }

.marker:before {
  content: "";
  animation-delay: 0s; }

.marker:after {
  content: "";
  animation-delay: 1.5s; }

.hostEndVideo {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  top: 0;
  height: 100vh;
  width: 100%;
  transition: left 1s ease; }

.roullette_qr_code_box {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 400px;
  height: 100vh;
  background-color: #131313;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  padding: 25px; }

.qr_canvas {
  z-index: 9999;
  background: #fff;
  padding: 15px; }

.separater_or {
  font-size: 14px; }

.turnoff_chat_availability {
  font-size: 14px; }

.turnoff_chat_availability span {
  color: #f55819;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
  font-size: 14px; }

.qrcode_text span {
  font-size: 14px; }

.qr_bottom_note p {
  font-size: 12px; }

@keyframes pulse {
  from {
    transform: scale(1);
    opacity: .5; }
  to {
    transform: scale(3, 3);
    opacity: 0; } }

@media (max-width: 900px) and (orientation: landscape) {
  .performer-list-wraph3 {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px; } }

@media (max-width: 767px) {
  .performer-list-wrap h3 {
    font-size: 10px !important; } }

.feed-card-ul {
  margin-top: 20px;
  padding: 0;
  list-style: none;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap; }
  .feed-card-ul:after {
    content: '';
    display: table;
    clear: both; }
  .feed-card-ul .feed-card-li {
    width: 33%;
    max-width: 337px; }
    .feed-card-ul .feed-card-li .feed-card-wrap {
      border: none;
      border-radius: 0;
      max-height: 250px;
      height: 100%; }
      .feed-card-ul .feed-card-li .feed-card-wrap .imgBtn {
        height: 100%; }
      .feed-card-ul .feed-card-li .feed-card-wrap .feedCardImage {
        object-fit: cover;
        height: 100%; }
      .feed-card-ul .feed-card-li .feed-card-wrap .card-body {
        background: #333739;
        position: relative;
        min-height: 85px;
        padding: 10px; }
        .feed-card-ul .feed-card-li .feed-card-wrap .card-body .addWish {
          position: absolute;
          top: -35px;
          cursor: default;
          background-color: transparent;
          border: none;
          outline: none; }
        .feed-card-ul .feed-card-li .feed-card-wrap .card-body h5 {
          position: absolute;
          top: 4px;
          margin-left: 2rem;
          background-color: white;
          border-radius: 4px; }
        .feed-card-ul .feed-card-li .feed-card-wrap .card-body .feed-user-icon {
          position: absolute;
          right: 15px;
          top: -25px; }
        .feed-card-ul .feed-card-li .feed-card-wrap .card-body .card-title {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.02em;
          color: #ffffff;
          text-transform: capitalize;
          margin: 0; }
        .feed-card-ul .feed-card-li .feed-card-wrap .card-body .card-text {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 1.5em;
          height: 3em;
          letter-spacing: -0.02em;
          color: #ffffff;
          opacity: 0.7;
          overflow: hidden; }

.profile-feed-card-ul .card-body {
  display: none; }


.feed-card-ul {
  padding: 0;
  list-style: none;
  justify-content: flex-start;
  max-width: 1335px;
  margin: 20px auto;
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 1601px) {
    .feed-card-ul {
      max-width: 100%; } }
  .feed-card-ul:after {
    content: '';
    display: table;
    clear: both; }
  .feed-card-ul .feed-card-li {
    width: 25%;
    max-width: 337px;
    padding: 8px; }
    .feed-card-ul .feed-card-li .feed-card-wrap {
      border: none;
      border-radius: 0;
      position: relative; }
      .feed-card-ul .feed-card-li .feed-card-wrap .feedCardImage {
        object-fit: cover;
        border-radius: 0;
        width: 100%;
        min-height: 220px; }
      .feed-card-ul .feed-card-li .feed-card-wrap .inner-like-btn {
        position: absolute;
        bottom: 15px;
        left: 15px;
        padding: 0;
        cursor: default;
        background-color: transparent;
        border: none;
        outline: none; }
      .feed-card-ul .feed-card-li .feed-card-wrap .card-body {
        background: #333739;
        position: relative;
        min-height: 85px;
        padding: 10px; }
        .feed-card-ul .feed-card-li .feed-card-wrap .card-body .addWish {
          position: absolute;
          top: -38px;
          cursor: default;
          background-color: transparent;
          border: none;
          outline: none; }
          .feed-card-ul .feed-card-li .feed-card-wrap .card-body .addWish.like {
            position: absolute;
            background: transparent;
            border: unset; }
        .feed-card-ul .feed-card-li .feed-card-wrap .card-body h5 {
          position: absolute;
          top: 4px;
          margin-left: 2rem;
          background-color: white;
          border-radius: 4px; }
        .feed-card-ul .feed-card-li .feed-card-wrap .card-body .feed-user-icon {
          position: absolute;
          right: 15px;
          top: -25px; }
        .feed-card-ul .feed-card-li .feed-card-wrap .card-body .card-title {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.02em;
          color: #ffffff;
          text-transform: capitalize;
          margin: 0; }
        .feed-card-ul .feed-card-li .feed-card-wrap .card-body .card-text {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 1.5em;
          height: 3em;
          letter-spacing: -0.02em;
          color: #ffffff;
          opacity: 0.7;
          overflow: hidden; }

.dashboardFooterCls {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;
  justify-content: center;
  background: #E3E6EB;
  padding: 16px 0px; }
  .dashboardFooterCls p {
    margin-bottom: 0; }

.chat-customer-review {
  display: flex;
  padding: 6px 0px;
  border-radius: 4px;
  background-color: #333739; }
  .chat-customer-review.chat-customer-chat {
    background-color: unset; }
    .chat-customer-review.chat-customer-chat .chat-reviews {
      display: block;
      padding: 0 5px;
      position: relative; }
      .chat-customer-review.chat-customer-chat .chat-reviews .title_container {
        top: -12px; }
        .chat-customer-review.chat-customer-chat .chat-reviews .title_container span {
          font-size: 10px; }
  .chat-customer-review.chat-model {
    padding: 6px 0px 2px; }
    .chat-customer-review.chat-model .chat-reviews {
      padding: 0 10px 0 4px; }
      .chat-customer-review.chat-model .chat-reviews .title_container {
        top: -13px; }
      .chat-customer-review.chat-model .chat-reviews span {
        font-size: 10px; }
  @media (max-width: 767px) {
    .chat-customer-review {
      border-radius: 0px;
      width: 100%; } }
  .chat-customer-review .chat-reviews {
    border-right: 0.6px solid #505050;
    padding: 0 10px;
    display: flex;
    align-items: baseline; }
    @media (max-width: 767px) {
      .chat-customer-review .chat-reviews {
        padding: 0 10px; } }
    .chat-customer-review .chat-reviews:last-child {
      border-right: none; }
    .chat-customer-review .chat-reviews span i.fa.fa-star {
      color: #f6c71af2;
      margin: 0 5px 0 10px;
      font-size: 14px; }
    .chat-customer-review .chat-reviews span {
      font-size: 12px; }
      .chat-customer-review .chat-reviews span.rating_title {
        position: relative;
        top: 3px;
        left: 4px; }
      @media (max-width: 767px) {
        .chat-customer-review .chat-reviews span {
          font-size: 12px; } }

.modal .video_chat_modal.modal-dialog {
  max-width: 670px !important; }

.modal .video_chat_modal {
  margin: 0;
  margin-left: auto;
  margin-right: 20px;
  top: 100px; }
  .modal .video_chat_modal .modal-content {
    background-color: var(--dark); }
  .modal .video_chat_modal .close {
    color: var(--white); }
  .modal .video_chat_modal .p-absolute {
    position: absolute; }
  .modal .video_chat_modal .arousr_logo {
    width: 40px;
    height: 40px; }
  .modal .video_chat_modal .pr-0 {
    padding-right: 0px; }
  .modal .video_chat_modal .pl-0 {
    padding-left: 0px; }
  .modal .video_chat_modal .bdr-btm-none {
    border-bottom: none; }
  .modal .video_chat_modal .bdr-t-none {
    border-top: none;
    z-index: 99999;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 70px;
    justify-content: space-between; }
  .modal .video_chat_modal .c-888 {
    color: #888; }
  .modal .video_chat_modal .c-464646 {
    color: #464646; }
  .modal .video_chat_modal .modal-body {
    padding-bottom: 0px;
    position: fixed;
    width: auto; }
    .modal .video_chat_modal .modal-body.chat-request {
      width: 470px; }
  .modal .video_chat_modal .button-panel {
    justify-content: flex-end;
    margin-right: 0px;
    margin-top: 0px;
    display: flex; }
    .modal .video_chat_modal .button-panel button {
      width: 100px;
      height: 35px;
      margin: 0 5px;
      border-radius: 0.5rem; }

.bar-seprate-line-wrap {
  transform: rotate(118deg);
  z-index: 1;
  position: absolute;
  left: 90%;
  top: 16px; }

.bar-seprate-line {
  height: 7px;
  width: 0.8px;
  background: #3E3E3E;
  position: absolute; }

.bar-line-1 {
  left: 11px;
  transform: rotate(186deg);
  top: 30px; }

.bar-line-2 {
  left: 0.5px;
  transform: rotate(43deg);
  top: 25px; }

.bar-line-3 {
  left: -4px;
  transform: rotate(88deg);
  top: 12px; }

.bar-line-4 {
  left: -2px;
  transform: rotate(125deg);
  top: 2px; }

.customAlert {
  color: #ECECEC !important;
  background-color: #6C7A89 !important;
  text-align: center;
  border: none !important;
  border-radius: 0 !important; }

.arousr_LogoCls {
  margin: 0 auto; }

.transparentLogo {
  display: none; }

.videoCallLogo {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  top: 10px;
  z-index: 2; }

@media (max-width: 900px) {
  .arousr_LogoCls {
    display: none; }
  .transparentLogo {
    transform: translate(-50%, -50%);
    position: absolute;
    top: 12%;
    left: 50%;
    display: block !important; }
  .pageHeader_custom {
    background: transparent; }
  .pageHeader_custom.bg-dark {
    display: none !important; }
  #remote-media-div video {
    height: 100vh !important; }
  .pageHeader_custom {
    display: none; } }

.pageHeaderCls {
  position: sticky;
  top: 0;
  z-index: 999; }
  .pageHeaderCls .notificationIcon {
    position: relative;
    margin: 0 7px;
    padding: 10px; }
    .pageHeaderCls .notificationIcon img {
      width: 24px; }
    .pageHeaderCls .notificationIcon .badge {
      background: #9d2731;
      border-radius: 25px;
      min-width: 18px;
      height: 18px;
      margin-left: 0;
      position: absolute;
      left: 50%;
      top: 0; }
  .pageHeaderCls .logOut {
    line-height: normal;
    border-radius: 25px;
    min-width: 95px;
    padding: 13px 6px;
    font-weight: 600;
    font-size: 0.875rem;
    color: #fff;
    line-height: 1; }
    .pageHeaderCls .logOut:hover {
      opacity: 0.9; }
    @media (max-width: 767px) {
      .pageHeaderCls .logOut {
        display: none; } }
  .pageHeaderCls .hostBlog {
    line-height: normal;
    border-radius: 25px;
    min-width: 95px;
    padding: 13px 6px;
    font-weight: 600;
    font-size: 0.875rem;
    color: #fff;
    background-color: #fff;
    border-color: #fff;
    line-height: 1;
    margin-right: 10px; }
    .pageHeaderCls .hostBlog:hover {
      background-color: #ebebeb;
      border-color: #ebebeb; }
    .pageHeaderCls .hostBlog a {
      text-decoration: none; }
    @media (max-width: 767px) {
      .pageHeaderCls .hostBlog {
        display: none; } }
  @media (max-width: 767px) {
    .pageHeaderCls .companyLogoCls a img {
      width: 85px; } }

#sidebar {
  min-width: 260px;
  max-width: 260px;
  background: #1F1F1F;
  color: #fff;
  transition: all 0.3s; }
  #sidebar > .active {
    margin-left: -250px; }
  #sidebar .sidebar-header {
    padding: 20px 10px; }
    #sidebar .sidebar-header .media .media-left {
      position: relative; }
      #sidebar .sidebar-header .media .media-left img {
        height: 75px;
        width: 75px;
        min-width: 75px;
        min-height: 75px;
        max-width: 75px;
        max-height: 75px;
        background: #fff;
        border-radius: 50%;
        object-fit: cover;
        border: 2px solid #333739;
        overflow: hidden;
        box-sizing: content-box; }
      #sidebar .sidebar-header .media .media-left a {
        background: var(--white);
        height: 25px;
        width: 25px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #fff;
        position: absolute;
        bottom: 0;
        right: 0; }
        #sidebar .sidebar-header .media .media-left a svg {
          width: 12px;
          height: 12px; }
    #sidebar .sidebar-header .media .media-body {
      margin-top: 10px; }
      #sidebar .sidebar-header .media .media-body p, #sidebar .sidebar-header .media .media-body .media-heading {
        margin-bottom: 0;
        text-align: center; }
      #sidebar .sidebar-header .media .media-body h4 {
        font-size: 1.125rem; }
      #sidebar .sidebar-header .media .media-body p:first-child {
        font-size: 16px;
        font-weight: 400; }
      #sidebar .sidebar-header .media .media-body p:last-child {
        font-size: 0.813rem;
        margin-top: 3px;
        color: #CBCBCB; }
      #sidebar .sidebar-header .media .media-body .online {
        color: #26b934; }
      #sidebar .sidebar-header .media .media-body .offline {
        color: #991c20; }
  #sidebar .balaceLogo svg {
    max-width: unset;
    width: 27px;
    height: 27px; }
  #sidebar .dashBoard {
    padding: 10px;
    font-size: 1.1em;
    display: block;
    color: #878c8f; }

#sidebar ul p {
  color: #fff;
  padding: 10px; }

.whiteBadge {
  background-color: var(--white) !important;
  color: var(--primary) !important;
  min-width: 23px;
  min-height: 23px;
  line-height: 0 !important;
  padding: 2px !important;
  display: inline-flex !important;
  justify-content: center;
  align-items: center;
  padding-top: 5px !important; }

#sidebar ul li a {
  padding: 10px;
  font-size: 1.1em;
  display: block; }

#sidebar ul li a:hover {
  color: #fff;
  background: #33363d;
  text-decoration: none; }
  #sidebar ul li a:hover span {
    fill: #fff; }

a[data-toggle="collapse"] {
  position: relative; }

.dropdown-toggle::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%); }

ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc; }

ul.CTAs {
  padding: 20px; }

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px; }

a.download {
  background: #fff;
  color: #7386D5; }

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important; }

.p-0 {
  padding: 0px; }

.sidebar-body a:hover {
  text-decoration: none; }

.sidebar-body > a {
  max-height: 54px; }

.sidebar-body .row {
  align-items: center;
  max-height: 60px; }

.sidebar-body .stroke svg {
  fill: none !important;
  stroke: #595E68; }

.sidebar-body .phonebooksvg svg, .sidebar-body .testsetupsvg svg {
  fill: none !important; }
  .sidebar-body .phonebooksvg svg path, .sidebar-body .testsetupsvg svg path {
    stroke: #595E68 !important; }

.sidebar-body .settingicon svg {
  fill: none !important;
  stroke: none !important; }

.sidebar-body .row.active {
  color: #fff;
  font-weight: 600;
  background-color: var(--primary);
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px; }
  .sidebar-body .row.active:hover {
    background-color: var(--primary); }
  .sidebar-body .row.active svg {
    fill: var(--white); }
  .sidebar-body .row.active .phonebooksvg svg, .sidebar-body .row.active .testsetupsvg svg {
    fill: var(--white); }
    .sidebar-body .row.active .phonebooksvg svg path, .sidebar-body .row.active .testsetupsvg svg path {
      stroke: #fff !important; }
  .sidebar-body .row.active .settingicon svg {
    fill: var(--white);
    stroke: unset; }
    .sidebar-body .row.active .settingicon svg path {
      fill: #fff !important; }

.sidebar-body .row {
  padding: 15px 0;
  color: #595E68;
  margin-right: 0;
  padding-left: 10px; }
  .sidebar-body .row svg {
    fill: #595E68;
    height: auto;
    max-width: 25px; }
  .sidebar-body .row .arrow {
    width: 8px; }
  .sidebar-body .row a {
    color: #BBBCBE; }
  .sidebar-body .row .cursor-pointer {
    padding-left: 6px !important;
    margin-top: 3px;
    line-height: 1; }

.sidebar-body .row:hover {
  color: #fff;
  background: #33363d;
  text-decoration: none;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px; }
  .sidebar-body .row:hover svg {
    fill: #fff; }
  .sidebar-body .row:hover .phonebooksvg svg, .sidebar-body .row:hover .testsetupsvg svg {
    fill: var(--white); }
    .sidebar-body .row:hover .phonebooksvg svg path, .sidebar-body .row:hover .testsetupsvg svg path {
      stroke: #fff !important; }
  .sidebar-body .row:hover .settingicon svg {
    fill: var(--white); }
    .sidebar-body .row:hover .settingicon svg path {
      fill: #fff !important; }
  .sidebar-body .row:hover .stroke svg {
    stroke: #fff; }
  .sidebar-body .row:hover a {
    color: #FFFFFF;
    text-decoration: none; }

.cursor-pointer {
  cursor: pointer; }

.ar_position_badge {
  position: absolute;
  right: 10px;
  min-width: 20px;
  min-height: 20px; }

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {
  #sidebar {
    margin-left: -250px; }
  #sidebar.active {
    margin-left: 0; }
  #sidebarCollapse span {
    display: none; } }

.timer-popup-main-wrap .modal-content {
  background-color: transparent;
  border: unset; }
  .timer-popup-main-wrap .modal-content .modal-header {
    padding: 0;
    border: unset; }
    .timer-popup-main-wrap .modal-content .modal-header .Modal title {
      display: none; }
    .timer-popup-main-wrap .modal-content .modal-header .close {
      display: block !important;
      position: absolute;
      right: 40px;
      top: 32px;
      z-index: 999; }
      .timer-popup-main-wrap .modal-content .modal-header .close span {
        font-weight: 500;
        box-shadow: unset;
        font-size: 30px;
        text-shadow: unset;
        color: white; }
  .timer-popup-main-wrap .modal-content .modal-body {
    width: 100%; }
    .timer-popup-main-wrap .modal-content .modal-body .roulette-time-popup-wrap {
      background: #393b3e;
      padding: 40px 15px 50px;
      z-index: 999;
      border-radius: 10px;
      text-align: center;
      max-width: 500px;
      margin: 0 auto;
      width: 100%; }
      .timer-popup-main-wrap .modal-content .modal-body .roulette-time-popup-wrap .roulette-time-popup {
        max-width: 430px;
        margin: 0 auto; }
        .timer-popup-main-wrap .modal-content .modal-body .roulette-time-popup-wrap .roulette-time-popup .roulette-time-popup-img {
          margin-bottom: 20px; }
        .timer-popup-main-wrap .modal-content .modal-body .roulette-time-popup-wrap .roulette-time-popup h3 {
          font-family: Overpass !important;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 35px;
          text-align: center;
          margin-top: 20px; }
        .timer-popup-main-wrap .modal-content .modal-body .roulette-time-popup-wrap .roulette-time-popup .popup-divider {
          height: 1px;
          background: #BDBDBE;
          margin: 30px 0; }
        .timer-popup-main-wrap .modal-content .modal-body .roulette-time-popup-wrap .roulette-time-popup h4 {
          font-family: Overpass;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 26px;
          text-align: center;
          opacity: 0.7; }
        .timer-popup-main-wrap .modal-content .modal-body .roulette-time-popup-wrap .roulette-time-popup .launch-btn {
          font-family: Overpass;
          background: #fd7663;
          color: white;
          border: unset;
          padding: 13px 17px;
          text-align: center;
          margin-top: 20px;
          max-width: 278px;
          width: 100%;
          border-radius: 30px;
          margin-top: 30px;
          display: block;
          margin: 0 auto; }

.chat-customer-review {
  display: flex;
  padding: 6px 0px;
  border-radius: 4px;
  background-color: #333739; }
  .chat-customer-review.chat-customer-chat {
    background-color: unset; }
    .chat-customer-review.chat-customer-chat .chat-reviews {
      display: block;
      padding: 0 5px;
      position: relative; }
      .chat-customer-review.chat-customer-chat .chat-reviews .title_container {
        top: -12px; }
        .chat-customer-review.chat-customer-chat .chat-reviews .title_container span {
          font-size: 10px; }
  .chat-customer-review.chat-model {
    padding: 6px 0px 2px; }
    .chat-customer-review.chat-model .chat-reviews {
      padding: 0 10px 0 4px; }
      .chat-customer-review.chat-model .chat-reviews .title_container {
        top: -13px; }
      .chat-customer-review.chat-model .chat-reviews span {
        font-size: 10px; }
  @media (max-width: 767px) {
    .chat-customer-review {
      border-radius: 0px;
      width: 100%; } }
  .chat-customer-review .chat-reviews {
    border-right: 0.6px solid #505050;
    padding: 0 10px;
    display: flex;
    align-items: baseline; }
    @media (max-width: 767px) {
      .chat-customer-review .chat-reviews {
        padding: 0 10px; } }
    .chat-customer-review .chat-reviews:last-child {
      border-right: none; }
    .chat-customer-review .chat-reviews span i.fa.fa-star {
      color: #f6c71af2;
      margin: 0 5px 0 10px;
      font-size: 14px; }
    .chat-customer-review .chat-reviews span {
      font-size: 12px; }
      .chat-customer-review .chat-reviews span.rating_title {
        position: relative;
        top: 3px;
        left: 4px; }
      @media (max-width: 767px) {
        .chat-customer-review .chat-reviews span {
          font-size: 12px; } }

.modal .video_chat_modal.modal-dialog {
  max-width: 670px !important; }

.modal .video_chat_modal {
  margin: 0;
  margin-left: auto;
  margin-right: 20px;
  top: 100px; }
  .modal .video_chat_modal .modal-content {
    background-color: var(--dark); }
  .modal .video_chat_modal .close {
    color: var(--white); }
  .modal .video_chat_modal .p-absolute {
    position: absolute; }
  .modal .video_chat_modal .arousr_logo {
    width: 40px;
    height: 40px; }
  .modal .video_chat_modal .pr-0 {
    padding-right: 0px; }
  .modal .video_chat_modal .pl-0 {
    padding-left: 0px; }
  .modal .video_chat_modal .bdr-btm-none {
    border-bottom: none; }
  .modal .video_chat_modal .bdr-t-none {
    border-top: none;
    z-index: 99999;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 70px;
    justify-content: space-between; }
  .modal .video_chat_modal .c-888 {
    color: #888; }
  .modal .video_chat_modal .c-464646 {
    color: #464646; }
  .modal .video_chat_modal .modal-body {
    padding-bottom: 0px;
    position: fixed;
    width: auto; }
    .modal .video_chat_modal .modal-body.chat-request {
      width: 470px; }
  .modal .video_chat_modal .button-panel {
    justify-content: flex-end;
    margin-right: 0px;
    margin-top: 0px;
    display: flex; }
    .modal .video_chat_modal .button-panel button {
      width: 100px;
      height: 35px;
      margin: 0 5px;
      border-radius: 0.5rem; }

.bar-seprate-line-wrap {
  transform: rotate(118deg);
  z-index: 1;
  position: absolute;
  left: 90%;
  top: 16px; }

.bar-seprate-line {
  height: 7px;
  width: 0.8px;
  background: #3E3E3E;
  position: absolute; }

.bar-line-1 {
  left: 11px;
  transform: rotate(186deg);
  top: 30px; }

.bar-line-2 {
  left: 0.5px;
  transform: rotate(43deg);
  top: 25px; }

.bar-line-3 {
  left: -4px;
  transform: rotate(88deg);
  top: 12px; }

.bar-line-4 {
  left: -2px;
  transform: rotate(125deg);
  top: 2px; }

#customer-online-modal .chat-customer-review.chat-model {
  width: 100%; }

#customer-online-modal .modal-content {
  max-width: 330px;
  margin-left: auto;
  background: transparent; }
  #customer-online-modal .modal-content .modal-footer {
    margin-top: 0 !important;
    padding: 0px; }
  #customer-online-modal .modal-content button {
    width: 100%;
    border-radius: 10px;
    background: #fff;
    color: #FD7663;
    text-align: left;
    padding: 20px 20px;
    border-color: #fff;
    box-shadow: none;
    outline: none;
    font-size: 18px; }
  #customer-online-modal .modal-content .chat-customer-review {
    background: transparent;
    padding: 0;
    margin: 0; }
    #customer-online-modal .modal-content .chat-customer-review a {
      color: #fff;
      text-decoration: none !important; }

.modal.customer_online_status_modal {
  position: fixed;
  top: 100px;
  left: auto;
  z-index: 1050;
  display: none;
  width: 350px;
  height: 71px;
  overflow: hidden;
  outline: 0;
  right: 50px; }
  .modal.customer_online_status_modal #customer-online-modal .modal-content button {
    border-radius: 10px;
    background: #fff;
    color: #000000;
    text-align: left;
    padding: 0 13px;
    border: 5px solid #FD7663;
    border-width: 0 0 0 5px;
    outline: none;
    font-size: 16px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    position: fixed;
    top: 100px;
    right: 50px;
    left: auto;
    width: 350px !important;
    height: 71px;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .modal.customer_online_status_modal #customer-online-modal .modal-content button .icon {
      width: 40px;
      height: 40px;
      display: inline-flex;
      vertical-align: middle;
      background: #f77563;
      border-radius: 50px;
      margin-right: 10px;
      justify-content: center;
      align-items: center; }
      .modal.customer_online_status_modal #customer-online-modal .modal-content button .icon svg {
        fill: #fff; }
    .modal.customer_online_status_modal #customer-online-modal .modal-content button .notification_txt {
      width: calc(100% - 50px); }

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch; }

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.Toastify__toast-container.Toastify__toast-container--top-right a {
  color: #fff;
  text-decoration: none; }

#content {
  width: 100%;
  padding: 0px 20px 0;
  min-height: calc(100vh - 90px);
  transition: all 0.3s;
  background: #000; }

.BandWidthMessagetoast {
  background: #e74c3c;
  padding: 10px;
  position: fixed;
  top: 10px;
  right: 50px;
  z-index: 9;
  width: 300px;
  box-shadow: 0 3px 12px rgba(0, 0, 5, 0.7);
  border-radius: 8px; }

.BandWidthMessagetoast.dis-none {
  display: none; }

.modal .video_chat_modal .modal-content {
  border-radius: 10px;
  background: #FFF;
  box-shadow: -10px 10px 40px 0px rgba(0, 0, 0, 0.6); }

.modal .video_chat_modal .modal-footer .button-panel {
  margin: 0; }

.modal .video_chat_modal .arg-modal-title {
  color: #292A2B;
  font-size: 16px;
  font-weight: 800;
  margin: 0; }

.modal .video_chat_modal .arg-modal-desc {
  color: #292A2B;
  font-size: 14px;
  line-height: 18px;
  opacity: 0.6000000238418579; }

.modal .video_chat_modal .arousr_logo {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #333739;
  padding: 5px; }

.modal .video_chat_modal .button-panel button {
  width: 110px;
  height: 36px;
  margin: 0 5px;
  border-radius: 8px;
  padding: 12px 0;
  color: #FD7663;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 12px !important; }

.modal .video_chat_modal .button-panel .btn-outline-primary:hover {
  color: #fff; }

.modal .video_chat_modal .chat-customer-review {
  border-radius: 6px;
  background: #E8E8E8;
  padding: 6px 3px; }
  .modal .video_chat_modal .chat-customer-review .chat-reviews {
    border-color: #BCBCBC; }
    .modal .video_chat_modal .chat-customer-review .chat-reviews .bar-seprate-line-wrap {
      top: 20px; }
      .modal .video_chat_modal .chat-customer-review .chat-reviews .bar-seprate-line-wrap .bar-seprate-line {
        background: #CCCCCC; }
    .modal .video_chat_modal .chat-customer-review .chat-reviews .title_container {
      top: -10px; }
    .modal .video_chat_modal .chat-customer-review .chat-reviews span {
      color: #333739;
      font-weight: 600;
      line-height: 7.946px;
      letter-spacing: -0.255px; }
    .modal .video_chat_modal .chat-customer-review .chat-reviews .semicircle-container svg circle:first-child {
      stroke: #cccccc; }

.support {
  min-height: 700px; }
  .support .faq_text {
    font-size: 14px; }
    .support .faq_text a {
      text-decoration: underline; }
  .support .card-body {
    width: 100%; }
    .support .card-body div {
      position: relative; }
    .support .card-body .help_text {
      color: #868B91;
      padding-left: 0; }
    .support .card-body .support-description textarea.form-control {
      min-height: 279px;
      border-radius: 10px;
      height: 100%;
      padding-top: 22px;
      padding-left: 24px;
      border: unset; }
    .support .card-body .support-description .form-group {
      height: 100%; }
    .support .card-body .support-description .edit_icon {
      align-self: baseline; }
    .support .card-body .form-group {
      margin-top: 1rem; }
    .support .card-body .row_detail {
      margin-bottom: 15px; }
    .support .card-body .setting_details {
      margin: 3px auto; }
  .support .removeSpacing .form-group {
    margin-top: 0; }
    .support .removeSpacing .form-group select {
      min-height: 44px; }
    .support .removeSpacing .form-group > label.force_mb-5 {
      display: none; }
  .support .dropArea .container {
    padding-left: 0;
    padding-right: 0;
    height: 100%; }
    .support .dropArea .container .dropzone {
      height: 100%; }
  .support .fs-20 {
    font-size: 18px;
    line-height: 1.3;
    padding-left: 0; }
  .support .setting_description .form-group {
    margin-bottom: 0px; }
    .support .setting_description .form-group input, .support .setting_description .form-group textarea {
      border-color: #39424A; }
    .support .setting_description .form-group input {
      visibility: hidden;
      position: absolute; }
  .support .setting_description svg {
    height: 15px;
    width: 15px;
    cursor: pointer; }
  .support .issue-textarea {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
    .support .issue-textarea .form-group {
      margin-top: 0; }
  .support .supportForm select, .support .supportForm textarea {
    background-color: var(--dark);
    border-color: var(--dark);
    font-size: 0.857rem; }
  .support .supportForm select::placeholder, .support .supportForm select {
    color: var(--white); }
  .support .supportForm textarea {
    color: white; }
  .support .button-panel {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .support .button-panel .sendBtn {
      color: var(--white); }
    .support .button-panel button {
      flex: 0 0 48%;
      line-height: normal;
      border-radius: 7px;
      padding: 17px 6px 15px 6px; }
      .support .button-panel button
.support .button-panel button:first-child {
        margin-right: 10px; }
  .support .file-inputgroup div:first-child {
    width: 60%;
    flex: initial !important; }
  .support .ellipse-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 93% !important;
    height: 23px;
    display: block;
    flex: initial; }
  .support .send-btn:hover {
    color: #fff;
    background-color: #39424A !important; }
  .support .borderBottom {
    border-bottom: 2px solid #F5F6FA;
    margin-bottom: 15px; }

.cld-arr-icon .cloude-icon svg {
  height: 38px;
  width: 50px; }

.cld-arr-icon .arrow-icon svg {
  height: 30px;
  width: 18px; }

.chatlog-card-wrapper {
  display: flex;
  height: 600px; }
  .chatlog-card-wrapper .chatLogMsgs object {
    border-radius: 15px 15px 0;
    color: #3a414b;
    font-size: 13px;
    margin: 0;
    border: 1px solid #999;
    box-shadow: none;
    width: 265px;
    height: 223px;
    object-fit: contain;
    display: block; }
  .chatlog-card-wrapper .chatLogMsgs img {
    border-radius: 15px 15px 0;
    color: #3a414b;
    font-size: 13px;
    margin: 0;
    box-shadow: none;
    width: 265px;
    height: 223px;
    object-fit: contain; }
    .chatlog-card-wrapper .chatLogMsgs img.placeholder-obj {
      width: 255px !important; }
  .chatlog-card-wrapper .chatLogMsgs .emoji-reaction-wrap {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #282a2b;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.12));
    border-radius: 50px;
    border: 1.2px solid #fff;
    margin-top: -6px; }
    .chatlog-card-wrapper .chatLogMsgs .emoji-reaction-wrap .left-emoji-reaction {
      width: 24px;
      height: 24px;
      border-radius: 50px; }
  .chatlog-card-wrapper .no-convo-wrap {
    flex-direction: column;
    padding-top: 150px; }
    .chatlog-card-wrapper .no-convo-wrap p {
      margin-top: 20px;
      color: #666C73; }
  .chatlog-card-wrapper .col-lg-5 {
    margin-right: 20px; }
  .chatlog-card-wrapper .customer-name {
    font-family: Overpass;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: -0.05em;
    color: #FFFFFF;
    margin-left: 20px; }
  .chatlog-card-wrapper .card {
    background-color: #292a2b !important;
    padding: 0; }
    .chatlog-card-wrapper .card .card-header .cust_img_with_crown {
      position: relative; }
      .chatlog-card-wrapper .card .card-header .cust_img_with_crown img.is_premium_customer {
        position: absolute;
        left: 0;
        top: -20px; }
      .chatlog-card-wrapper .card .card-header .cust_img_with_crown .online-small-icon {
        width: 13px !important;
        height: 13px !important;
        display: inline-block;
        position: absolute;
        top: 24px;
        right: 5px;
        background-color: #2eb130;
        border-radius: 50%;
        border: 1px solid #ddd; }
      .chatlog-card-wrapper .card .card-header .cust_img_with_crown .online-away-icon {
        width: 13px !important;
        height: 13px !important;
        display: inline-block;
        position: absolute;
        top: 24px;
        right: 5px;
        background-color: #ECAC19;
        border-radius: 50%;
        border: 1px solid #ddd; }
      .chatlog-card-wrapper .card .card-header .cust_img_with_crown .offline-small-icon {
        width: 13px !important;
        height: 13px !important;
        display: inline-block;
        position: absolute;
        top: 24px;
        right: 5px;
        background-color: #9fa09f;
        border-radius: 50%;
        border: 1px solid #ddd; }
    .chatlog-card-wrapper .card .card-header img {
      border-radius: 50%; }
    .chatlog-card-wrapper .card .Sys2Char, .chatlog-card-wrapper .card .Sys2Cust, .chatlog-card-wrapper .card .SESSION_CMD {
      padding: 0;
      display: block; }
      .chatlog-card-wrapper .card .Sys2Char .message, .chatlog-card-wrapper .card .Sys2Cust .message, .chatlog-card-wrapper .card .SESSION_CMD .message {
        text-align: left;
        display: block;
        background: #666666;
        border-radius: 10px;
        padding: 10px 25px;
        border-radius: 20px 25px 25px 5px;
        width: max-content;
        max-width: 550px;
        font-size: 13px;
        line-height: 17px;
        font-weight: normal;
        letter-spacing: -0.02em;
        color: #B9B9B9;
        background-color: #666; }
      .chatlog-card-wrapper .card .Sys2Char .time, .chatlog-card-wrapper .card .Sys2Cust .time, .chatlog-card-wrapper .card .SESSION_CMD .time {
        display: block;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: -0.02em;
        color: #c5c5c5;
        margin: 5px 0px 10px; }
        .chatlog-card-wrapper .card .Sys2Char .time .chat-status, .chatlog-card-wrapper .card .Sys2Cust .time .chat-status, .chatlog-card-wrapper .card .SESSION_CMD .time .chat-status {
          color: #c5c5c5;
          font-size: 11px;
          justify-content: flex-end;
          padding: 2px; }
          .chatlog-card-wrapper .card .Sys2Char .time .chat-status .failed-msg, .chatlog-card-wrapper .card .Sys2Cust .time .chat-status .failed-msg, .chatlog-card-wrapper .card .SESSION_CMD .time .chat-status .failed-msg {
            color: #dc280e; }
    .chatlog-card-wrapper .card .Char2Cust, .chatlog-card-wrapper .card .Cust2Char {
      padding: 0;
      display: block; }
      .chatlog-card-wrapper .card .Char2Cust .message, .chatlog-card-wrapper .card .Cust2Char .message {
        border: 1px solid #666666;
        box-sizing: border-box;
        width: max-content;
        max-width: 550px;
        display: block;
        border-radius: 20px 25px 25px 5px;
        padding: 6px 15px;
        font-size: 13px;
        line-height: 17px;
        font-weight: normal;
        letter-spacing: -0.02em; }
      .chatlog-card-wrapper .card .Char2Cust .time, .chatlog-card-wrapper .card .Cust2Char .time {
        display: block;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: -0.02em;
        color: #c5c5c5;
        margin: 5px 0px 10px; }
        .chatlog-card-wrapper .card .Char2Cust .time .chat-status, .chatlog-card-wrapper .card .Cust2Char .time .chat-status {
          color: #c5c5c5;
          font-size: 11px;
          justify-content: flex-end;
          padding: 2px; }
          .chatlog-card-wrapper .card .Char2Cust .time .chat-status .failed-msg, .chatlog-card-wrapper .card .Cust2Char .time .chat-status .failed-msg {
            color: #dc280e; }
    .chatlog-card-wrapper .card .Cust2Char .message {
      background: transparent;
      color: #B9B9B9;
      padding: 10px 14px; }
    .chatlog-card-wrapper .card .Cust2Char object {
      border-radius: 15px 15px 15px 0; }
    .chatlog-card-wrapper .card .Char2Cust {
      text-align: right; }
      .chatlog-card-wrapper .card .Char2Cust .message {
        background-color: var(--primary);
        color: var(--white) !important;
        border-radius: 25px 20px 5px 25px !important;
        text-align: right;
        margin-left: auto;
        border: unset;
        padding: 10px 14px; }
      .chatlog-card-wrapper .card .Char2Cust object {
        margin-left: auto; }
      .chatlog-card-wrapper .card .Char2Cust .msgLogged {
        display: flex;
        justify-content: end; }
    .chatlog-card-wrapper .card ul {
      max-height: 450px;
      overflow-y: scroll;
      overflow-x: hidden;
      padding: 0;
      list-style: none; }
      .chatlog-card-wrapper .card ul li {
        padding: 15px 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative; }
        .chatlog-card-wrapper .card ul li.activeChat {
          background: #1D1D1D; }
        .chatlog-card-wrapper .card ul li span.chatIcon {
          background: black;
          width: 40px;
          height: 40px;
          display: block;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          text-align: center;
          line-height: 40px;
          margin-right: 15px; }
        .chatlog-card-wrapper .card ul li .date-wrap {
          display: flex;
          justify-content: space-between;
          width: calc(100% - 40px);
          align-items: center; }
          .chatlog-card-wrapper .card ul li .date-wrap p {
            margin: 0; }
            .chatlog-card-wrapper .card ul li .date-wrap p.time {
              font-size: 10px;
              line-height: 15px;
              color: #FFFFFF;
              opacity: 0.5; }
    .chatlog-card-wrapper .card .card-footer {
      text-align: center; }
      .chatlog-card-wrapper .card .card-footer button {
        background: #FD7663;
        border-radius: 10px;
        text-align: center;
        width: 250px;
        height: 50px;
        font-family: Overpass;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        text-align: center;
        letter-spacing: -0.06em;
        text-transform: uppercase;
        color: #FFFFFF; }

.not-found-page {
  min-height: 450px; }
  .not-found-page .text {
    font-size: 18px;
    margin-bottom: 10px;
    color: #3b414a;
    text-align: center; }
  .not-found-page .heading {
    font-size: 86px;
    color: #3b414a;
    text-align: center;
    font-weight: 700; }
    .not-found-page .heading svg {
      height: 95px;
      width: 100%; }

.header__not_for_mobile {
  height: 10vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center; }

.main__not_for_mobile {
  text-align: center;
  height: 50vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center; }
  .main__not_for_mobile .text__not_for_mobile {
    color: #C4C4C4;
    font-size: 16px;
    padding: 5px; }
    .main__not_for_mobile .text__not_for_mobile .link__not_for_mobile {
      color: #FD7663;
      text-decoration: underline; }

.use_app_video_wrapper {
  height: 30vh;
  display: flex;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-evenly;
  align-items: center; }
  .use_app_video_wrapper .use_app_video {
    height: 100%;
    width: auto; }

.continue-to-chat {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh; }

.logout_mobile_on_roulletteoff {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  text-align: center; }

.performer-img {
  width: 250px;
  height: 250px;
  box-shadow: 0 10px 20px rgba(252, 252, 252, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }

.my-info-table {
  width: 100%; }
  .my-info-table tr td {
    width: 50%;
    border-bottom: 1px solid #a6a6a694;
    text-align: left;
    padding: 8px; }

.download-btn {
  background: #f07563 !important;
  padding: 14px !important;
  border-radius: 12px !important;
  border: unset !important; }

.customCardHeaderList:not(:last-child) {
  border-bottom: none !important; }


.notification-row .content-text {
  margin-left: 18px; }
  .notification-row .content-text p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFFFFF;
    opacity: 0.6; }

.notification-row .custom-switch .custom-control-label {
  left: -28px;
  cursor: pointer; }

.notification-row .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(1.55rem);
  height: 20px;
  width: 20px;
  top: 4px;
  border-radius: 50%; }

.notification-row .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #f07563;
  background-color: #f07563;
  width: 50px;
  height: 24px;
  pointer-events: all;
  border-radius: 15px;
  top: 2px; }

.notification-row .custom-switch .custom-control-label::before {
  width: 50px;
  height: 24px;
  pointer-events: all;
  border-radius: 15px;
  top: 2px; }

.notification-row .custom-switch .custom-control-label::after {
  height: 20px;
  width: 20px;
  top: 4px;
  border-radius: 50%; }

.modal-dialog.end-Premium-Modal {
  max-width: 450px; }

.modal-content {
  width: auto !important;
  height: auto !important; }

.EditInfo-Modal .chat-modal-body.modal-body {
  width: 75%;
  margin: 0 auto;
  padding: 25px 0; }
  .EditInfo-Modal .chat-modal-body.modal-body .input-group input.form-control {
    width: 100%;
    border-radius: 6px;
    height: 42px;
    font-size: 14px; }
  .EditInfo-Modal .chat-modal-body.modal-body .input-group .eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9; }
    .EditInfo-Modal .chat-modal-body.modal-body .input-group .eye-icon svg {
      width: 25px;
      height: 25px;
      fill: #f77563; }
  .EditInfo-Modal .chat-modal-body.modal-body .doneBtn {
    font-weight: 600 !important; }

.doneBtn {
  font-weight: 600 !important;
  margin-top: 10px; }

.radioButton {
  text-align: left;
  white-space: nowrap; }
  .radioButton .inputReportInput {
    margin-right: 5px; }

.modal-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  position: absolute !important;
  min-width: 350px; }

.EditInfo-Modal .react-tel-input .form-control {
  width: 100%; }

.EditInfo-Modal .react-tel-input .country-list {
  top: 24px;
  left: 0; }
  .EditInfo-Modal .react-tel-input .country-list .country {
    text-align: left; }

.EditInfo-Modal .modal-body {
  text-align: center; }
  .EditInfo-Modal .modal-body .fa.fa-phone {
    font-size: 50px;
    color: #f07563; }

.EditInfo-Modal .modal-content {
  position: relative !important;
  height: auto !important;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-family) !important;
  background-color: var(--dark);
  border-radius: 1em; }

.EditInfo-Modal .chat-modal-header {
  border-bottom: none;
  padding: 0 1rem; }
  .EditInfo-Modal .chat-modal-header img {
    opacity: 1 !important; }
  .EditInfo-Modal .chat-modal-header .close {
    margin-top: 0rem !important;
    color: #C4C4C4;
    font-weight: 300;
    font-size: 28px;
    padding-top: 7px;
    position: absolute;
    top: 0px;
    right: 15px;
    outline: none; }

.EditInfo-Modal .modal-center-img {
  width: 115px;
  margin: 15px auto 0px auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.EditInfo-Modal .modal-title {
  flex: 1 1 100%;
  text-align: center;
  margin: 0 !important; }

.EditInfo-Modal h4 {
  font-size: 18px;
  font-weight: 700 !important;
  margin-bottom: 0.3em; }

.EditInfo-Modal p {
  font-weight: 400;
  opacity: 0.8; }

.EditInfo-Modal .chat-modal-body .form-group {
  margin-bottom: 0px !important; }

.EditInfo-Modal .chat-modal-footer {
  background-color: var(--primary);
  min-height: 60px;
  position: relative;
  padding: 0 1rem;
  justify-content: center;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em; }
  .EditInfo-Modal .chat-modal-footer p {
    cursor: pointer;
    color: white;
    margin-top: 25px;
    font-size: 12px !important;
    text-decoration: underline;
    font-family: var(--font-family) !important;
    width: 100%;
    text-align: center;
    font-weight: 600; }

.modal-backdrop.show {
  opacity: 0.3 !important; }

.modal {
  background: transparent !important; }

body.modal-open #root {
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); }

.InputContainer {
  display: grid;
  text-align: left;
  margin-bottom: 15px; }
  .InputContainer:last-child {
    margin-bottom: 0; }

.validation-error {
  color: #dc3545 !important;
  margin-top: 4px; }

.validation-success {
  color: #26d059 !important;
  margin-top: 4px; }

.validation-server-error {
  color: #db1a2e !important;
  margin-top: 10px;
  font-weight: bold; }

.validation-server-success {
  color: #26d059 !important;
  margin-top: 10px;
  font-weight: bold; }

#playVideo {
  width: 250px;
  margin-bottom: 20px; }

.test-microphone-wrap .recording-row-wrap .recording-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px; }
  .test-microphone-wrap .recording-row-wrap .recording-row .recording-line-wrap {
    width: 500px;
    margin: 0 20px; }
    .test-microphone-wrap .recording-row-wrap .recording-row .recording-line-wrap h4 {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: -0.03em;
      color: #FFFFFF; }
    .test-microphone-wrap .recording-row-wrap .recording-row .recording-line-wrap p {
      margin-bottom: 10px;
      font-size: 16px;
      line-height: 13px;
      text-align: center;
      letter-spacing: -0.03em;
      color: #FFFFFF;
      opacity: 0.8; }
    .test-microphone-wrap .recording-row-wrap .recording-row .recording-line-wrap #recordProgress {
      height: 5px; }
  .test-microphone-wrap .recording-row-wrap .recording-row .record-btn-wrap {
    display: flex;
    width: 60%; }

.test-microphone-wrap .btn {
  cursor: pointer;
  max-width: 200px;
  width: 100%;
  padding: 16px 10px;
  border-radius: 10px !important;
  margin-right: 20px; }
  .test-microphone-wrap .btn.btn-secondary {
    background-color: transparent;
    border: 1px solid #FD7663;
    color: #FD7663 !important; }
  .test-microphone-wrap .btn.reset-btn:disabled {
    cursor: unset; }

.test-notification-wrap .btn-notification {
  padding: 16px 20px !important;
  text-transform: uppercase;
  font-weight: 400 !important;
  border-radius: 10px !important; }

.headTitle {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: -0.02em;
  color: #FFFFFF; }

.ar_host_notification_setup_box summary.ar_host_notification_setup_btn {
  background: #1d1d1d;
  padding: 14px;
  border-radius: 10px;
  color: #fff;
  margin-bottom: 20px; }

.ar_host_notification_setup_box h3 {
  font-size: 18px;
  border-bottom: 1px solid #aaa;
  padding-bottom: 7px;
  margin-bottom: 15px;
  margin-top: 25px;
  padding-top: 8px;
  border-top: 1px solid #aaa; }

.text-orange {
  color: #FD7663; }

.ask-me-anything-info_section {
  position: absolute;
  background: #333739;
  border: 1px solid rgba(89, 94, 104, 0.8);
  box-sizing: border-box;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  padding: 16px;
  left: 30px;
  width: 350px;
  z-index: 99;
  top: 40px; }
  .ask-me-anything-info_section p {
    color: #FFF;
    font-family: Overpass;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    opacity: 0.7; }

.ask-me-anything-header {
  display: flex;
  align-items: center; }
  .ask-me-anything-header h2 {
    margin-right: 10px; }

.ask-me-anything-question-lists {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0 15px;
  border-bottom: 1px solid #393939; }
  .ask-me-anything-question-lists .ask-me-anything-questions .ask-me-anything-question-txt {
    color: #FFF;
    font-family: Overpass;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 7px; }
  .ask-me-anything-question-lists .ask-me-anything-questions .ask-me-anything-date {
    color: #FFF;
    font-family: Overpass;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    opacity: 0.6; }
  .ask-me-anything-question-lists .ask-me-anything-action .ar-ask-me-anything-submitted-btn {
    border: none;
    padding: 6px 14px;
    font-family: Overpass;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.6px;
    height: 28px;
    border-radius: 5px;
    background: #434343;
    min-width: 88px;
    text-transform: capitalize; }
    .ask-me-anything-question-lists .ask-me-anything-action .ar-ask-me-anything-submitted-btn.bg-yellow-light {
      color: #FFC107; }
    .ask-me-anything-question-lists .ask-me-anything-action .ar-ask-me-anything-submitted-btn.bg-green-light {
      color: #28A745; }
    .ask-me-anything-question-lists .ask-me-anything-action .ar-ask-me-anything-submitted-btn.bg-red-light {
      color: #DC3545; }
  .ask-me-anything-question-lists .ask-me-anything-action .ar-ask-me-anything-answer-btn {
    border: 1px solid #FD7663;
    background: transparent;
    border-radius: 50px;
    padding: 10px 33px;
    color: #FD7663;
    font-family: Overpass;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: -0.6px;
    height: 40px; }
    .ask-me-anything-question-lists .ask-me-anything-action .ar-ask-me-anything-answer-btn:hover {
      color: #ffffff;
      background: #FD7663; }
  .ask-me-anything-question-lists:last-child {
    border: none; }

.ask-me-anything-answer-form-file-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  background: #333739;
  padding: 10px;
  border-radius: 15px;
  border: none;
  margin: 10px 0; }
  .ask-me-anything-answer-form-file-section img {
    width: 36px;
    border-radius: 5px;
    height: 36px;
    margin-right: 10px;
    object-fit: cover;
    cursor: pointer; }
  .ask-me-anything-answer-form-file-section span {
    color: #FFF;
    font-family: Overpass;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    letter-spacing: -0.64px;
    text-transform: lowercase;
    width: 195px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle; }
  .ask-me-anything-answer-form-file-section button {
    border: none;
    background: rgba(255, 255, 255, 0.2);
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 8px; }

.ask-me-anything-submited-answer-box {
  padding: 20px 0; }

.ask-me-anything-answer-form-container {
  padding: 20px 0; }

.ask-me-anything-question-txt {
  color: #FFF;
  font-family: Overpass;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 7px; }

.ask-me-anything-question-date {
  color: #FFF;
  font-family: Overpass;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 128.571% */
  opacity: 0.6; }

.ask-me-anything-answer-txt {
  color: #FFF;
  font-family: Overpass;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 20px 0;
  word-break: break-word; }

.ask-me-anything-fill-btn {
  border-radius: 10px;
  border: 1px solid #FD7663;
  background: transparent;
  height: 45px;
  color: #fff;
  background: #FD7663;
  text-align: center;
  font-family: Overpass;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  /* 75% */
  letter-spacing: -0.48px;
  width: 170px; }

.ask-me-anything-outline-btn {
  border-radius: 10px;
  border: 1px solid #666;
  background: transparent;
  height: 45px;
  color: #666;
  text-align: center;
  font-family: Overpass;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  /* 75% */
  letter-spacing: -0.48px;
  width: 170px; }

.ask-me-anything-answer-form-file-choose label {
  border: dashed 2px #666;
  min-width: 350px;
  max-width: 350px;
  background: rgba(51, 55, 57, 0.4); }
  .ask-me-anything-answer-form-file-choose label svg {
    margin-right: 10px; }
    .ask-me-anything-answer-form-file-choose label svg path {
      fill: #6d6c6c; }
  .ask-me-anything-answer-form-file-choose label .hXAsBl span {
    color: rgba(255, 255, 255, 0.5);
    font-family: Overpass;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.7px; }
  .ask-me-anything-answer-form-file-choose label .dAjkaY span {
    text-decoration: underline;
    color: #FD7663;
    font-family: Overpass;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.7px; }

.all-ans-media-list {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 30px;
  flex-wrap: wrap; }
  .all-ans-media-list .answers-medias {
    width: auto;
    height: 162px;
    flex-shrink: 0;
    max-width: 243px;
    display: flex;
    justify-content: center;
    align-items: start; }
    .all-ans-media-list .answers-medias img {
      object-fit: contain;
      width: 100%;
      height: -webkit-fill-available; }

.ask-me-anything-answer-form-textarea {
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-direction: column;
  grid-gap: 5px;
  gap: 5px; }
  .ask-me-anything-answer-form-textarea textarea {
    width: 100%;
    border: 1px solid rgba(102, 102, 102, 0.4);
    background: #333739;
    padding: 20px;
    border-radius: 10px;
    margin: 0;
    height: 200px;
    resize: none;
    color: #fff;
    font-family: Overpass;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 19px; }
    .ask-me-anything-answer-form-textarea textarea:focus-visible {
      outline: none; }
  .ask-me-anything-answer-form-textarea .error {
    margin: 2px 0 0;
    color: #b70000;
    font-size: 14px; }

.modal-open.modal.fade {
  background: rgba(0, 0, 0, 0.7) !important; }

.ar-under-review-popup {
  width: 360px !important;
  height: 243px;
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important; }
  .ar-under-review-popup .modal-content {
    background: #333739;
    padding: 40px;
    text-align: center; }
    .ar-under-review-popup .modal-content .modal-header {
      display: none; }
    .ar-under-review-popup .modal-content .modal-body {
      margin: 0 auto;
      width: 100%;
      padding: 0; }
      .ar-under-review-popup .modal-content .modal-body img {
        width: 96.36px;
        height: 96.36px;
        flex-shrink: 0; }
      .ar-under-review-popup .modal-content .modal-body h3 {
        margin-bottom: 10px;
        color: #FFF;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.6px; }
      .ar-under-review-popup .modal-content .modal-body p {
        margin: 0;
        color: #C8C8C8;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        /* 125% */
        letter-spacing: -0.8px; }

:root {
  --font-family:'Overpass', sans-serif ; }

body {
  font-family: var(--font-family) !important; }

/* width */
::-webkit-scrollbar {
  width: 10px; }

/* Track */
::-webkit-scrollbar-track {
  background: transparent; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3E3F40;
  border-radius: 30px; }

.minDeskTopHeight {
  min-height: calc(100% - 80px); }
  .minDeskTopHeight .back-btn {
    border: 1px solid #FD7663;
    box-sizing: border-box;
    border-radius: 100px;
    width: 100px;
    height: 45px; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3E3F40; }

/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #fd7663;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #292A2B;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: left;
  background-color: #000; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #fd7663;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #fc3417;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 0.875em;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #000;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #fff; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fed9d3; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #feb8ae; }

.table-hover .table-primary:hover {
  background-color: #fdc3ba; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #fdc3ba; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c3c3c4; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #909091; }

.table-hover .table-dark:hover {
  background-color: #b6b6b7; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b6b6b7; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #ffe5e1;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(253, 118, 99, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
          appearance: none; }

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated select.form-control:valid, select.form-control.is-valid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated select.form-control:invalid, select.form-control.is-invalid {
  padding-right: 3rem !important;
  background-position: right 1.5rem center; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem) !important;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 30px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #fff;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(253, 118, 99, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #212529;
  background-color: #fd7663;
  border-color: #fd7663; }
  .btn-primary:hover {
    color: #fff;
    background-color: #fd553d;
    border-color: #fc4a31; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #fd553d;
    border-color: #fc4a31;
    box-shadow: 0 0 0 0.2rem rgba(220, 106, 90, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #fd7663;
    border-color: #fd7663; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #fc4a31;
    border-color: #fc3f24; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 106, 90, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
    box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #292A2B;
  border-color: #292A2B; }
  .btn-dark:hover {
    color: #fff;
    background-color: #161717;
    border-color: #101111; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #161717;
    border-color: #101111;
    box-shadow: 0 0 0 0.2rem rgba(73, 74, 75, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #292A2B;
    border-color: #292A2B; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #101111;
    border-color: #0a0a0a; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(73, 74, 75, 0.5); }

.btn-outline-primary {
  color: #fd7663;
  border-color: #fd7663; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #fd7663;
    border-color: #fd7663; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(253, 118, 99, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #fd7663;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #fd7663;
    border-color: #fd7663; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(253, 118, 99, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #292A2B;
  border-color: #292A2B; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #292A2B;
    border-color: #292A2B; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(41, 42, 43, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #292A2B;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #292A2B;
    border-color: #292A2B; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(41, 42, 43, 0.5); }

.btn-link {
  font-weight: 400;
  color: #fd7663;
  text-decoration: none; }
  .btn-link:hover {
    color: #fc3417;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }
  .collapsing.width {
    width: 0;
    height: auto;
    transition: width 0.35s ease; }
    @media (prefers-reduced-motion: reduce) {
      .collapsing.width {
        transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #fff;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #fd7663; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label,
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #fd7663;
    background-color: #fd7663; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(253, 118, 99, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #ffe5e1; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: white;
    border-color: white; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: 1px solid #adb5bd; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: 50% / 50% 50% no-repeat; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #fd7663;
  background-color: #fd7663; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(253, 118, 99, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(253, 118, 99, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(253, 118, 99, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(253, 118, 99, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #ffe5e1;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(253, 118, 99, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #ffe5e1;
    box-shadow: 0 0 0 0.2rem rgba(253, 118, 99, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: 0; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #000, 0 0 0 0.2rem rgba(253, 118, 99, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #000, 0 0 0 0.2rem rgba(253, 118, 99, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #000, 0 0 0 0.2rem rgba(253, 118, 99, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #fd7663;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: white; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #fd7663;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: white; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #fd7663;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: white; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    background-color: transparent;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      isolation: isolate;
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #000;
    border-color: #dee2e6 #dee2e6 #000; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #fd7663; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 30px; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }
  .card > .card-header + .list-group,
  .card > .list-group + .card-footer {
    border-top: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion {
  overflow-anchor: none; }
  .accordion > .card {
    overflow: hidden; }
    .accordion > .card:not(:last-of-type) {
      border-bottom: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }
    .accordion > .card:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .accordion > .card > .card-header {
      border-radius: 0;
      margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #fd7663;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #fc3417;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(253, 118, 99, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #fd7663;
  border-color: #fd7663; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #212529;
  background-color: #fd7663; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #212529;
    background-color: #fc4a31; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(253, 118, 99, 0.5); }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #fff;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e7e34; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #d39e00; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #292A2B; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #101111; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(41, 42, 43, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #843d33;
  background-color: #ffe4e0;
  border-color: #fed9d3; }
  .alert-primary hr {
    border-top-color: #fdc3ba; }
  .alert-primary .alert-link {
    color: #5f2c25; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #151616;
  background-color: #d4d4d5;
  border-color: #c3c3c4; }
  .alert-dark hr {
    border-top-color: #b6b6b7; }
  .alert-dark .alert-link {
    color: black; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #fd7663;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #fff;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #fd7663;
    border-color: #fd7663; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #843d33;
  background-color: #fed9d3; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #843d33;
    background-color: #fdc3ba; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #843d33;
    border-color: #843d33; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #151616;
  background-color: #c3c3c4; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #151616;
    background-color: #b6b6b7; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #151616;
    border-color: #151616; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #fff; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #fd7663 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #fc4a31 !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #292A2B !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #101111 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #fd7663 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #292A2B !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #fd7663 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #fc3417 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #292A2B !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #040404 !important; }

.text-body {
  color: #fff !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.bg-none {
  background-color: unset !important; }

.modal-content {
  border-radius: 10px; }

.btn-outline-primary:hover, .btn-outline-primary:focus {
  color: var(--white); }

.form-control {
  border-radius: 5px; }

.card {
  border-radius: 10px; }

.rowspacing-20 {
  margin-left: -10px !important;
  margin-right: -10px !important; }
  .rowspacing-20 > div {
    padding-left: 10px;
    padding-right: 10px; }

.rowspacing-20 {
  margin-left: -10px !important;
  margin-right: -10px !important; }
  .rowspacing-20 > div {
    padding-left: 10px;
    padding-right: 10px; }

.btn {
  line-height: 1 !important; }

.pagination .page-item.disabled svg {
  stroke: #292a2b; }

.pagination .page-item:not(.disabled) svg {
  stroke: var(--primary); }

.pagination li.active button {
  color: var(--primary) !important;
  font-weight: 700 !important; }

.dropzone p {
  font-size: 1rem;
  opacity: 0.6;
  font-weight: 500; }

.tablefirstColSpacing thead th:first-child {
  padding-left: 0 !important; }

.tablefirstColSpacing tbody td:first-child {
  padding-left: 0 !important; }

select {
  background-image: url(../../static/media/dropdawnarrow.cdcad0e7.svg);
  background-repeat: no-repeat;
  background-position: center right 15px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  font-weight: 600; }

.btn.btn-primary {
  font-weight: 800; }

