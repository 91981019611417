.disable-roulette-btn-wrap{
  display: flex;
  flex-direction: row-reverse;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 1;
  .close-btn{
    background: transparent;
    border: unset;
    outline: none;
    cursor: pointer;
  }
}

.self_preview_video{
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  position: relative;
}
.searchingText{
  margin-top: 5% ;
}
#sun {
  top: 40%;
  left: 47%;
  border-width: 0px;
  display: flex;
  padding: 5px;
  font-size: 24px;
  align-items: center;
  border-radius: 100%;
  width: 5vw;
  height: 5vw;
  color: #f77361;
  background: white;
  justify-content: center;
  margin: 0 auto;
}
#sun img{
  width:2vw;
}
.innerCircleSmall {
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  border-width: 0;
  border-style: solid;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  background-color:#13131373;
}
.innerCircle {
  width: 14vw;
  height: 14vw;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.outerCircle {
  width: 18vw;
  height: 18vw;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  transition: left 1s ease;
}
.pulses-none, .pulses-none:before, .pulses-none:after {
  border: unset !important;
}

.info-text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  margin-top: 6%;
  transition: left 1s ease;
}
.roullette_wrapper{
  &.QRcodeOn{
    .hostEndVideo,.outerCircle,.info-text{
      left:40%;
    }
  }
}
// ==============================
.dr_code_container {
  position: relative;
}
.refresh-loader {
  position: absolute;
  top: 0%;
  left: 21%;
  background: rgba(0,0,0,0.6);
  width: 201px;
  height: 200px;
  text-align: center;
  line-height: 200px;
  i.fa-spin {
    color: #fff;
    font-size: 30px;
  }
}



.performer-search-circle{
  position: relative;
  height: 375px;
  width: 375px;
  background: #131313;
  border-radius: 50%;
  margin: 30px auto 0;
}

.primary-button{
  width: fit-content;
  border: 1px solid #f77361;
  margin: 2px 8px;
  font-size:16px;
  padding: 13px 35px;
  border-radius: 27px;
  color: #f77361;
  background: transparent;
  text-transform: uppercase;
  cursor: pointer;
}
.performer-list-wrap h3{
  font-family: Overpass;
  font-style: normal;
  font-weight: bold;
  font-size: 1.7vw;
  line-height: 2vw;
  text-align: center;
  color: #FFFFFF;
}
.primary-button-fill {
  background: #f77361;
  color: white;
}

.performer-search-wrap .calls-left i{
  margin-right:8px;
}

.celestial_body {
  position: absolute;
}
.performer-round-img{
    border: 2px solid #fefefe;
    border-radius: 50%;
}

// 
.performer-search-circle{
  position: relative;
  height: 26vw;
  width: 26vw;
  background: #131313;
  border-radius: 50%;
  margin: 45px auto 30px;
  @media(max-width:900px){
    margin:5px auto 10px;
  }
}
.primary-button{
  width: fit-content;
  border: 1px solid #f77361;
  margin: 2px 8px;
  font-size:16px;
  padding: 13px 35px;
  border-radius: 3vw;
  color: #f77361;
  background: transparent;
  text-transform: uppercase;
  cursor: pointer;
}
.primary-button-fill {
  background: #f77361;
  color: white;
}
.performer-search-wrap .calls-left i{
  margin-right:8px;
}
.celestial_body {
  position: absolute;
  
}
.circle-point-lg{
  position: absolute;
  right: 13%;
  top: 20%;
  width: 2.4vw;
  height: 2.4vw;
}
.circle-point-md{
  position: absolute;
  right: 50%;
  top: 85%;
  width: 2vw;
  height: 2vw;
}
.circle-point-sm{
  position: absolute;
  right: 10%;
  top: 52%;
  width: 1.25vw;
  height: 1.25vw;
}
.circle-point-xm{
  position: absolute;
  right: 63%;
  top: 23%;
  width: 1vw;
  height: 1vw;
}
.search-static-dots-1 {
  position: absolute;
  border-radius: 50%;
  top: 39%;
  left: 39%;
  width: 280px;
  height: 280px;
  margin-top: -100px;
  margin-left: -100px;
  
}
.search-static-dots-2 {
  position: absolute;
  border-radius: 50%;
  left: 39%;
  top: 39%;
  width: 280px;
  height: 280px;
  margin-top: -100px;
  margin-left: -100px;
}
.search-static-dots-3 {
  position: absolute;
  border-radius: 50%;
  position: absolute; 
  left: 44%;
  bottom: 18%;
  width: 245px;
  height: 245px;
  margin-top: -100px;
  margin-left: -100px;
  /* border-color: #ffffff4a;
  border-width: 0.5pt;
  border-style: solid; */
}

#earth {
  top: 70%;
  left: 68%;
  height: 4vw;
  width: 4vw;
  border-radius: 50%;
}

#earth-orbit {
  top: 50%;
  left: 50%;
  width: 370px;
}

#mercury-orbit {
  top: 21%;
  left: 21%;
  width: 15vw;
  height: 15vw;
  border-radius: 50%;
  border-color: #ffffff0a;
  border-width: 0.5pt;
  border-style: solid;
  border-radius: 50%;
}

#venus {
  /* Style your earth */
  top: 35%;
  left: 50%;
  height: 47.5px;
  width: 47.5px;
  margin-top: -18.75px;
  margin-left: -18.75px;
  background-color: black;
  border-radius: 50%;

}
#venus-orbit {
  top: 30%;
  left: 30%;
  width: 10vw;
  height: 10vw;
  border-color: #ffffff21;
  border-width: 0.5pt;
  border-style: solid;
  border-radius: 50%;
}
#custom-cel-body{
  top: 17%;
  left: 17%;
  height: 2.5vw;
  width: 2.5vw;
}
.cel-body-md{
  left: 16% !important;
  top: 72% !important;
  height: 1.8vw !important;
  width: 1.8vw !important;
}
#custom-orbit {
  top: 50%;
  left: 50%;
  width: 350px;
}
.marker, .marker:before, .marker:after {
  display: block;
  width: 20px;
  height: 20px;
  border: 3vw solid #333739;
  border-radius: 50%;
  }
.marker:before, .marker:after {
  position: absolute;
  margin-left: -4px;
  margin-top: -4px;
  opacity: 0;
  border: 3vw solid #333739;
  animation: pulse 3s infinite ease-out;
  }
.marker:before {
  content:"";
  animation-delay: 0s;
  }
.marker:after {
  content:"";
  animation-delay: 1.5s;
  }
.hostEndVideo{
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
  top: 0;
  height: 100vh;
  width: 100%;
  transition: left 1s ease;
}
.roullette_qr_code_box {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 400px;
  height: 100vh;
  background-color: #131313;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  padding: 25px;
}
.qr_canvas {
  z-index: 9999;
  background: #fff;
  padding: 15px;
}
.separater_or {
  font-size: 14px;
}
.turnoff_chat_availability {
  font-size: 14px;
}
.turnoff_chat_availability span {
  color: #f55819;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
  font-size: 14px;
}
.qrcode_text span {
  font-size: 14px;
}
.qr_bottom_note p {
  font-size: 12px;
}
@keyframes pulse {
  from {
    transform: scale(1);
    opacity: .5;
  }
  to {
    transform: scale(3, 3);
    opacity: 0;
  }
}
@media  (max-width:900px) and (orientation: landscape) {
  .performer-list-wraph3{
    font-size:14px;
    line-height: 18px;
    margin-bottom: 5px;
  }
}
@media (max-width:767px){
  .performer-list-wrap h3 {
    font-size: 10px !important;
  }
}