@charset "UTF-8";
.chat-customer-review {
  display: flex;
  padding: 6px 0px;
  border-radius: 4px; }
  .chat-customer-review .chat-reviews {
    border-right: 0.6px solid #505050;
    padding: 0 15px;
    height: 30px; }
    .chat-customer-review .chat-reviews .title_container {
      flex-direction: column;
      display: flex;
      align-items: center;
      position: relative;
      top: -17px; }
    .chat-customer-review .chat-reviews:last-child {
      border-right: none; }
    .chat-customer-review .chat-reviews span i.fa.fa-star {
      color: #f6c71af2;
      margin: 0 5px 0 10px;
      font-size: 14px; }
    .chat-customer-review .chat-reviews span {
      font-size: 12px; }
      @media (max-width: 767px) {
        .chat-customer-review .chat-reviews span {
          font-size: 12px; } }

.image-wrap .online-small-icon {
  width: 13px !important;
  height: 13px !important;
  display: inline-block;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #2eb130;
  border-radius: 50%;
  border: 1px solid #ddd; }

.image-wrap .offline-small-icon {
  width: 13px !important;
  height: 13px !important;
  display: inline-block;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #9fa09f;
  border-radius: 50%;
  border: 1px solid #ddd; }

.image-wrap .online-away-icon {
  width: 13px !important;
  height: 13px !important;
  display: inline-block;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: #ECAC19;
  border-radius: 50%;
  border: 1px solid #ddd; }

.write-footer .msg_history {
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden; }
  @media screen and (max-width: 767px) {
    .write-footer .msg_history {
      height: calc(100% - 65px) !important;
      max-height: calc(100% - 65px) !important; } }

.write-footer .chat-pref-msg {
  color: #f07563;
  font-size: 13px;
  margin: 0;
  padding: 9px 14px;
  font-size: 13px; }
  @media screen and (max-width: 767px) {
    .write-footer .chat-pref-msg {
      position: absolute;
      top: -22px; } }

.type_msg {
  border-top: 1px solid #b9b9b926;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 16px;
  position: relative; }
  .type_msg .write_msgRecording::placeholder {
    color: #ff7766 !important; }
  .type_msg .max-seconds {
    font-size: 12px;
    position: absolute;
    bottom: 6px;
    color: #fff;
    opacity: 0.6;
    left: 53px; }

.imgBtn {
  background: unset; }
  .imgBtn img {
    box-shadow: none; }

.ar_host_chat_frame_box {
  height: calc(100vh - 130px); }

.msg_history {
  max-height: 523px;
  min-height: 300px;
  overflow-y: auto;
  padding: 20px 10px 0 5px;
  height: calc(100% - 170px); }
  @media screen and (max-width: 992px) {
    .msg_history {
      height: calc(100% - 100px) !important;
      max-height: calc(100% - 100px) !important; } }
  @media screen and (min-width: 993px) {
    .msg_history {
      min-height: unset;
      max-height: unset; } }
  .msg_history .sysMsgTime {
    display: flex;
    color: #c5c5c5;
    font-weight: 100;
    font-size: 11px;
    justify-content: flex-start;
    padding: 2px 8px; }
  .msg_history .dateTimeStampChat {
    font-size: 14px;
    color: grey;
    margin-bottom: 20px; }
  .msg_history .chat-day {
    color: #c5c5c5;
    font-weight: 100;
    font-size: 11px;
    margin-bottom: 3px; }
  .msg_history .system_msg_container {
    margin-bottom: 10px; }
    .msg_history .system_msg_container .system_msg {
      display: flex;
      justify-content: flex-start; }
      .msg_history .system_msg_container .system_msg p {
        border-radius: 30px;
        color: #B9B9B9;
        font-size: 13px;
        text-align: left;
        margin: 0;
        background-color: #666;
        padding: 10px 14px;
        border-bottom-left-radius: 5px; }
  .msg_history .incoming_msg {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px; }
    .msg_history .incoming_msg .received_msg {
      padding: 0;
      position: relative; }
      .msg_history .incoming_msg .received_msg .bluredImgBox {
        position: relative; }
      .msg_history .incoming_msg .received_msg .messageText {
        max-width: 345px;
        word-wrap: break-word; }
        @media (max-width: 767px) {
          .msg_history .incoming_msg .received_msg .messageText {
            max-width: 250px; } }
      .msg_history .incoming_msg .received_msg p {
        border-radius: 15px 15px 0px;
        color: #3a414b;
        font-size: 13px;
        margin: 0;
        padding: 10px 14px;
        width: 100%;
        border: 1px solid #3a414b; }
      .msg_history .incoming_msg .received_msg .chat-status {
        display: flex;
        color: #c5c5c5;
        font-weight: 100;
        font-size: 11px;
        justify-content: flex-end;
        padding: 2px 8px; }
        .msg_history .incoming_msg .received_msg .chat-status .failed-msg {
          color: #dc280e; }
      .msg_history .incoming_msg .received_msg .chat-status.isReacted {
        padding-top: 32px; }
      .msg_history .incoming_msg .received_msg .chatInMsgTime {
        display: flex;
        color: #c5c5c5;
        font-weight: 100;
        font-size: 11px;
        justify-content: flex-end;
        padding: 0px 2px; }
      .msg_history .incoming_msg .received_msg object {
        border-radius: 15px 15px 0;
        color: #3a414b;
        font-size: 13px;
        margin: 0;
        border: 1px solid #999;
        box-shadow: none;
        width: 265px;
        height: 223px;
        object-fit: contain;
        display: block; }
      .msg_history .incoming_msg .received_msg img {
        border-radius: 15px 15px 0;
        color: #3a414b;
        font-size: 13px;
        margin: 0;
        box-shadow: none;
        width: 265px;
        height: 223px;
        object-fit: contain; }
        .msg_history .incoming_msg .received_msg img.placeholder-obj {
          width: 255px !important; }
      .msg_history .incoming_msg .received_msg .creditValueClass {
        font-weight: 600;
        font-size: 13px;
        line-height: 8px;
        letter-spacing: -0.05em;
        color: #FFFFFF;
        position: absolute;
        bottom: -16px;
        left: auto;
        right: 200px; }
      .msg_history .incoming_msg .received_msg .audio-credits {
        font-weight: 600;
        font-size: 12px;
        line-height: 8px;
        letter-spacing: -0.05em;
        color: #FFFFFF;
        position: absolute;
        bottom: -16px;
        left: auto;
        width: 65px; }
        @media (max-width: 550px) {
          .msg_history .incoming_msg .received_msg .audio-credits {
            right: 190px; } }
      .msg_history .incoming_msg .received_msg .received_withd_msg audio {
        width: 265px; }
      .msg_history .incoming_msg .received_msg .received_withd_msg iframe {
        width: 265px;
        height: 265px;
        border-radius: 15px 15px 0 15px;
        border: none; }
      @media (max-width: 550px) {
        .msg_history .incoming_msg .received_msg .received_withd_msg audio {
          width: 255px; }
        .msg_history .incoming_msg .received_msg .received_withd_msg iframe {
          width: 255px;
          height: 255px; } }
  .msg_history .outgoing_msg {
    display: flex;
    justify-content: flex-start;
    margin: 20px 0; }
    .msg_history .outgoing_msg .sent_msg {
      position: relative;
      margin: 0;
      display: flex;
      flex-direction: column; }
      .msg_history .outgoing_msg .sent_msg p {
        border-radius: 20px 25px 25px 5px;
        color: #B9B9B9;
        font-size: 13px;
        margin: 0;
        padding: 9px 14px;
        width: 100%;
        border: 1px solid #666;
        font-size: 13px;
        line-height: 17px;
        max-width: 450px; }
        @media (max-width: 767px) {
          .msg_history .outgoing_msg .sent_msg p {
            max-width: 250px; } }
        @media (max-width: 350px) {
          .msg_history .outgoing_msg .sent_msg p {
            max-width: 235px; } }
      .msg_history .outgoing_msg .sent_msg .chatOutMsgTime {
        display: flex;
        color: #c5c5c5;
        font-weight: 100;
        font-size: 11px;
        justify-content: flex-start;
        padding: 2px 8px;
        order: 1; }
      .msg_history .outgoing_msg .sent_msg .chatOutMsgTime.isReacted {
        padding-top: 32px; }
      .msg_history .outgoing_msg .sent_msg object {
        border-radius: 15px 15px 0;
        color: #3a414b;
        font-size: 13px;
        margin: 0;
        border: 1px solid #999;
        box-shadow: none;
        width: 265px;
        height: 223px;
        object-fit: contain;
        display: block; }
      .msg_history .outgoing_msg .sent_msg img {
        border-radius: 15px 15px 0;
        color: #3a414b;
        font-size: 13px;
        margin: 0;
        box-shadow: none;
        width: 265px;
        height: 223px;
        object-fit: contain; }
        .msg_history .outgoing_msg .sent_msg img.placeholder-obj {
          width: 255px !important; }

.user-name {
  font-weight: 600;
  text-transform: capitalize; }

.ChatSection .conversion-header {
  border-bottom-color: #b9b9b926 !important;
  position: relative; }
  @media (max-width: 767px) {
    .ChatSection .conversion-header {
      padding-bottom: 20px !important; } }
  .ChatSection .conversion-header .ar-chat-head-left-info {
    display: flex;
    align-items: center; }
  .ChatSection .conversion-header .ar-chat-head-right-action {
    display: flex;
    align-items: center;
    gap: 10px; }
    .ChatSection .conversion-header .ar-chat-head-right-action .fav-icon-wrap-call-chat {
      background: #0000008a;
      vertical-align: middle;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin: 0 5px;
      cursor: pointer;
      z-index: 999999; }
    @media (max-width: 767px) {
      .ChatSection .conversion-header .ar-chat-head-right-action {
        gap: 10px;
        margin: -50px 0 0 -50px; } }
    .ChatSection .conversion-header .ar-chat-head-right-action div {
      cursor: pointer; }
      @media (max-width: 767px) {
        .ChatSection .conversion-header .ar-chat-head-right-action div {
          width: 30px;
          height: 30px; } }
      .ChatSection .conversion-header .ar-chat-head-right-action div img {
        margin-left: 0;
        margin: 0;
        border-radius: 0; }
        @media (max-width: 767px) {
          .ChatSection .conversion-header .ar-chat-head-right-action div img {
            width: 100%; } }
    @media (max-width: 767px) {
      .ChatSection .conversion-header .ar-chat-head-right-action div.ellipsis-menu {
        width: auto;
        height: auto; }
        .ChatSection .conversion-header .ar-chat-head-right-action div.ellipsis-menu div {
          width: auto;
          height: auto; } }
  .ChatSection .conversion-header .ellipsis-menu .btn-secondary {
    color: #fff;
    background-color: #292a2b;
    border-color: #292a2b; }
  .ChatSection .conversion-header .ellipsis-menu .btn-secondary:hover {
    color: #fff;
    background-color: #292a2b;
    border-color: #292a2b; }
  .ChatSection .conversion-header .ellipsis-menu .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #292a2b; }
  .ChatSection .conversion-header .ellipsis-menu .dropdown-item:hover {
    color: #fff;
    text-decoration: none;
    background-color: #292a2b; }
  .ChatSection .conversion-header .ellipsis-menu .dropdown-item:focus {
    outline: 0; }
  .ChatSection .conversion-header .dropdown-toggle::after {
    display: none !important; }
  .ChatSection .conversion-header .switch {
    margin: 0 22px 0 15px  !important; }
    @media (min-width: 1150px) {
      .ChatSection .conversion-header .switch {
        position: absolute;
        right: 30px; } }
  .ChatSection .conversion-header .online-user {
    position: absolute;
    bottom: 30px;
    left: 70px; }
    .ChatSection .conversion-header .online-user.online-user-chat {
      position: static; }
      .ChatSection .conversion-header .online-user.online-user-chat .title_container .rating_title {
        position: static; }

.modal-dialog.chat-modal.tip-Modal .modal-content {
  width: 95% !important;
  margin: 0 auto; }

.incoming_msg .messageText {
  background-color: var(--primary);
  color: var(--white) !important;
  border-radius: 25px 20px 5px 25px !important; }

.back_btn {
  display: none !important; }

@media screen and (max-width: 767px) {
  .ChatSection .conversion-header .ellipsis-menu .dropdown-menu .dropdown-item.none-in-mobile {
    display: none; }
  .chat-messaging {
    width: 100% !important;
    overflow: hidden !important; }
    .chat-messaging .inbox_msg .mesgs .online-user-details {
      width: calc(100% - 0px);
      margin-left: 6px; }
      .chat-messaging .inbox_msg .mesgs .online-user-details .d-flex {
        flex-basis: 70%; }
      .chat-messaging .inbox_msg .mesgs .online-user-details .phone-call {
        margin-top: 15px; }
      .chat-messaging .inbox_msg .mesgs .online-user-details .video-call {
        margin-top: 15px;
        margin-right: 25px; }
      .chat-messaging .inbox_msg .mesgs .online-user-details .close-chat {
        background: url(/static/images/arousr-sprites-general-V.3.0-apr-03.svg) no-repeat -271px -194px;
        width: 22px;
        height: 22px;
        display: inline-block;
        background-size: 700px; }
        .chat-messaging .inbox_msg .mesgs .online-user-details .close-chat:hover {
          background-position: -469px -378px; }
    .chat-messaging .inbox_msg .type_msg .input_msg_write {
      padding-right: 35px !important; }
      .chat-messaging .inbox_msg .type_msg .input_msg_write input {
        min-height: 70px !important; }
  .pl-xs-15 {
    padding-left: 15px !important; }
  .back_btn {
    display: block !important;
    background-color: transparent;
    border: unset; }
    .back_btn svg {
      width: 17px;
      height: 17px; } }

@media screen and (max-width: 480px) {
  .chat-messaging .inbox_msg .mesgs .online-user-details .chat_ib h5 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 0;
    width: 135px; }
  .online-user-details .back_btn {
    display: inline-block;
    align-self: center; }
    .online-user-details .back_btn .btn {
      border: none;
      color: grey;
      padding: 0;
      font-size: 16px;
      cursor: pointer;
      margin-right: 10px; }
    .online-user-details .back_btn .btn:before {
      content: "❮";
      color: #d7d7d7;
      font-size: 19px; }
  .chat-messaging .inbox_msg .mesgs {
    padding: 0 0 80px;
    width: 100%;
    position: relative; }
    .chat-messaging .inbox_msg .mesgs .online-user-details .chat_img {
      width: 45px;
      height: 45px;
      min-width: 45px;
      min-height: 45px; }
      .chat-messaging .inbox_msg .mesgs .online-user-details .chat_img img {
        border-radius: 50%; }
    .chat-messaging .inbox_msg .mesgs .online-user-details .phone-call {
      margin-top: 6px;
      background: url(/static/images/arousr-sprites-general-V.3.0-apr-03.svg) no-repeat -220px -194px;
      width: 22px;
      height: 22px;
      margin-right: 10px;
      background-size: 600px; }
    .chat-messaging .inbox_msg .mesgs .online-user-details .video-call {
      margin-top: 6px;
      background: url(/static/images/arousr-sprites-general-V.3.0-apr-03.svg) no-repeat -245px -194px;
      width: 22;
      height: 22px;
      margin-right: 10px;
      background-size: 600px; }
  .chat-messaging .inbox_msg .type_msg {
    width: 100%; }
    .chat-messaging .inbox_msg .type_msg .input_msg_write {
      padding: 10px;
      padding-right: 20px !important;
      background: #3a414b; }
      .chat-messaging .inbox_msg .type_msg .input_msg_write input {
        order: 1;
        min-height: 36px !important;
        border-radius: 8px;
        width: 80% !important; }
      .chat-messaging .inbox_msg .type_msg .input_msg_write .take-pic {
        background-position: -418px -457px !important;
        width: 33px !important;
        height: 38px !important;
        background-size: 600px !important;
        margin-left: 0 !important;
        margin-right: 5px !important; }
      .chat-messaging .inbox_msg .type_msg .input_msg_write .msg-send-btn {
        background: url(/static/images/arousr-sprites-general-V.3.0-apr-03.svg) no-repeat -391px -245px;
        width: 40px;
        height: 29px;
        display: inline-block !important;
        background-size: 600px;
        order: 2;
        border: none;
        margin-left: 15px; }
      .chat-messaging .inbox_msg .type_msg .input_msg_write .upload-file .pic-file {
        margin-right: 8px; }
  .chat-messaging
.inbox_msg .mesgs .type_msg .input_msg_write .upload-file {
    margin-right: 0;
    left: -2px; }
  .chat-messaging
.inbox_msg .mesgs .type_msg .input_msg_write .msg-send-btn {
    background: url(/static/images/arousr-sprites-general-V.3.0-apr-03.svg) no-repeat -477px -300px !important;
    background-size: 735px !important;
    width: 68px;
    height: 34px;
    left: 0; }
    .chat-messaging
.inbox_msg .mesgs .type_msg .input_msg_write .msg-send-btn .btnText {
      display: none; }
  .chat-messaging
.inbox_msg .mesgs .type_msg .input_msg_write input {
    width: 100% !important; }
  .sharePrivateMedia-modal {
    max-width: 93% !important; }
  input.write_msg.write_msgRecording {
    top: -6px; }
  .type_msg .max-seconds {
    bottom: 6px; }
  .chatMediaModal .modal-content .modal-body .chatMedia-gallery-grid {
    justify-content: center; }
  .privateModal .modal-content .modal-body .private-gallery-grid {
    justify-content: center; }
  .sharePrivateMedia-modal .modal-content .modal-body {
    padding: 0 20px 20px; } }

.visualsWrapper {
  margin: 0 auto;
  text-align: center; }

.input_msg_write {
  display: flex;
  justify-content: space-between;
  min-height: 55px; }
  .input_msg_write canvas {
    position: relative;
    top: -5px;
    left: -25px; }
  .input_msg_write .input_msg_write_left_area {
    display: flex;
    align-items: center;
    width: calc(100% - 175px); }
    @media (max-width: 767px) {
      .input_msg_write .input_msg_write_left_area {
        width: 100%;
        gap: 0; } }
    .input_msg_write .input_msg_write_left_area .write_msg {
      width: 100%; }
  @media (max-width: 767px) {
    .input_msg_write .input_msg_write_right_area {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto; } }
  @media (min-width: 1100px) and (max-width: 1299px) {
    .input_msg_write canvas {
      width: 195px;
      position: relative;
      top: 0px; } }
  @media (min-width: 1600px) {
    .input_msg_write {
      position: relative; }
      .input_msg_write .visualsWrapper {
        width: 100%;
        height: 30px;
        overflow: hidden; }
        .input_msg_write .visualsWrapper canvas {
          width: 70%;
          height: 100%; } }
  @media (max-width: 767px) {
    .input_msg_write canvas {
      display: none; } }

.outgoing_msg img {
  width: 265px;
  height: 223px;
  object-fit: contain; }

.incoming_msg img, .outgoing_msg img {
  width: 265px;
  height: 223px;
  object-fit: contain; }

.img-msg {
  border-radius: 15px 15px 0;
  color: #3a414b;
  font-size: 13px;
  margin: 0;
  border: 1px solid #999;
  box-shadow: 2px 2px 11px #999; }

.sendButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0; }
  @media (max-width: 767px) {
    .sendButton {
      width: 30px;
      height: 30px;
      position: relative;
      left: 5px; }
      .sendButton svg {
        width: 15px;
        height: 15px;
        margin-left: -3px; } }

@media (max-width: 767px) {
  .type_msg {
    background: #666;
    border-radius: 30px;
    position: relative;
    bottom: 0px;
    width: calc(100% - 16px);
    margin: 0 auto; }
    .type_msg.type_msg-press .upload-file.camera, .type_msg.type_msg-press .upload-file.chooseImage {
      display: none; }
    .type_msg.type_msg-press .microphone.recordingOff {
      display: none; }
    .type_msg.type_msg-press .sendButton {
      display: flex; }
    .type_msg.type_msg-press .write_msg {
      position: relative;
      left: 0px; }
    .type_msg .sendButton {
      display: none; }
    .type_msg .write_msg {
      width: 100%;
      position: relative;
      left: 0px;
      height: 50px; } }

.type_msg .customer-option {
  display: flex;
  vertical-align: middle;
  justify-content: center;
  position: relative;
  top: 7px; }
  .type_msg .customer-option .recordingCounter {
    color: #bfbfbf;
    margin-right: 5px;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px; }
  .type_msg .customer-option .microphone {
    margin-right: 5px; }
  @media (max-width: 767px) {
    .type_msg .customer-option {
      top: auto; }
      .type_msg .customer-option .recordingCounter {
        position: absolute;
        left: -141px;
        font-size: 15px;
        top: -6px; }
      .type_msg .customer-option .microphone {
        position: absolute;
        top: -12px;
        left: -95px; }
        .type_msg .customer-option .microphone.recordingOff img {
          width: 25px; }
        .type_msg .customer-option .microphone.recordingOn {
          top: -21px;
          left: -110px; }
          .type_msg .customer-option .microphone.recordingOn img {
            width: 40px; } }

.type_msg .send-msg-btn {
  width: 67px;
  height: 26px;
  background: #fff;
  border: 1px solid #3b4149;
  border-radius: 6px;
  position: relative;
  left: 0px;
  padding: 1px;
  color: #3b4149; }

@media (max-width: 767px) {
  .type_msg .upload-file {
    position: absolute;
    background: #f07563;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center; }
    .type_msg .upload-file.camera {
      left: -67px;
      width: 30px;
      height: 30px; }
      .type_msg .upload-file.camera svg {
        width: 15px; }
    .type_msg .upload-file.chooseImage {
      left: -30px;
      margin: 0 !important;
      width: 30px;
      height: 30px; }
      .type_msg .upload-file.chooseImage svg {
        width: 15px; } }

.type_msg .upload-file .pic-file {
  margin-bottom: 0; }

.type_msg .upload-file .pic-file.defult-none {
  cursor: default; }

.type_msg .upload-file .pic-file.defult-none svg {
  opacity: 0.7; }

.type_msg .upload-file input {
  display: none; }

.type_msg .upload-file .pic-file {
  cursor: pointer; }

.type_msg .write_msg {
  background-color: transparent !important;
  color: #B9B9B9;
  font-weight: normal;
  max-height: 80px; }
  @media (max-width: 550px) {
    .type_msg .write_msg {
      padding-top: 15px !important; } }

@media (max-width: 767px) {
  .type_msg textarea.write_msg:read-only {
    padding-top: 8px !important; } }

.chooseImage {
  margin: 0 26px 0 14px !important; }
  .chooseImage button {
    display: none; }

.resume-chat-btn {
  border-top: 1px solid #b9b9b926;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 16px;
  padding-top: 2px;
  text-align: center; }

.resume-chat-btn button {
  background: #FD7663;
  border-radius: 10px;
  text-align: center;
  width: 250px;
  height: 50px;
  font-family: Overpass;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  letter-spacing: -0.06em;
  text-transform: uppercase;
  color: #FFFFFF; }

.resume-chat-btn button:disabled {
  opacity: 0.6;
  background: #f07563;
  color: #fff; }

.chat-customer-review.chat-customer-chat .bar-seprate-line-wrap {
  transform: rotate(106deg);
  z-index: 1;
  position: absolute;
  left: 81%;
  top: 14px; }
  .chat-customer-review.chat-customer-chat .bar-seprate-line-wrap .bar-seprate-line {
    height: 7px;
    width: 0.8px;
    background: #3E3E3E;
    position: absolute; }
  .chat-customer-review.chat-customer-chat .bar-seprate-line-wrap .bar-line-1 {
    left: 11px;
    transform: rotate(186deg);
    top: 30px; }
  .chat-customer-review.chat-customer-chat .bar-seprate-line-wrap .bar-line-2 {
    left: 0.5px;
    transform: rotate(43deg);
    top: 25px; }
  .chat-customer-review.chat-customer-chat .bar-seprate-line-wrap .bar-line-3 {
    left: -4.5px;
    transform: rotate(88deg);
    top: 12px; }
  .chat-customer-review.chat-customer-chat .bar-seprate-line-wrap .bar-line-4 {
    left: -2px;
    transform: rotate(125deg);
    top: 2px; }

audio::-webkit-media-controls-panel {
  background-color: #fd7663 !important; }

audio::-webkit-media-controls-panel {
  background-color: #fd7663 !important; }

audio::-webkit-media-controls-current-time-display {
  color: white !important; }

audio::-webkit-media-controls-time-remaining-display {
  color: white !important; }

audio::-webkit-media-controls-mute-button {
  display: none; }

audio::-webkit-media-controls-play-button {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%; }

audio::-webkit-media-controls-volume-control-container {
  display: none; }

.ar-chatLoader-absolute {
  position: absolute;
  z-index: 99;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(41, 42, 43, 0.5);
  top: 0;
  left: 0; }

.validImage {
  border-radius: 15px 15px 0;
  border: 1px solid #999;
  display: block;
  color: #3a414b;
  font-size: 13px;
  margin: 0;
  box-shadow: none;
  width: 265px;
  height: 223px;
  object-fit: contain; }

.mediaDeletedSVG {
  width: 100% !important;
  height: auto !important; }

.ar-whisperer-icon-wrapper {
  position: absolute;
  bottom: 80px;
  right: 40px;
  cursor: pointer; }
  .ar-whisperer-icon-wrapper .ar-whisperer-tooltip {
    position: absolute;
    top: -30px;
    width: 207px;
    right: 0;
    font-size: 12px;
    background: black;
    padding: 4px 4px;
    border-radius: 4px;
    line-height: 1;
    text-align: center;
    display: none;
    z-index: 99; }
    .ar-whisperer-icon-wrapper .ar-whisperer-tooltip::after {
      content: "";
      width: 10px;
      height: 10px;
      display: block;
      background: black;
      position: absolute;
      bottom: -5px;
      right: 23px;
      transform: rotate(45deg);
      z-index: 1; }
  .ar-whisperer-icon-wrapper .ar-whisperer-icon-wrapper-inner {
    width: 56px;
    height: 56px;
    background: #FFD9D3;
    border: 0.5px solid #F67765;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: end;
    position: relative;
    filter: drop-shadow(0 4px 4px rgba(253, 118, 99, 0.5)); }
    .ar-whisperer-icon-wrapper .ar-whisperer-icon-wrapper-inner img {
      height: 50px; }
  .ar-whisperer-icon-wrapper:hover .ar-whisperer-tooltip {
    display: block; }
