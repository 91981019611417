.paginationComponent {
  .page-item.disabled .page-link {
    color: #C6C6C6 !important;  
    svg{
      stroke: #C6C6C6 ;
    }
  }
  .page-item.active {
    .page-link{
      background-color: #3A414A;
      border-color:#3A414A;
    }
  }
  .page-link{
    color:#3A414A;
    padding: 2px 0 0 0;
    min-width: 22px;
    min-height: 22px;
    line-height: 1;
  }
  .page-link:hover{
    color:#3A414A;
  }
  .page-item:last-child {
    .page-link{
      border-top-right-radius: .50rem;
      border-bottom-right-radius: .50rem;
    }
  }
  button[name="previous"]{
    border-top-left-radius: .50rem;
    border-bottom-left-radius: .50rem;
  }  
}

