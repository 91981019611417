@charset "UTF-8";
.modal-dialog.end-Premium-Modal {
  max-width: 450px; }

.modal-content {
  width: auto !important;
  height: auto !important; }

.modal-body {
  width: 75%;
  margin: 0 auto; }

.radioButton {
  text-align: left;
  white-space: nowrap; }
  .radioButton .inputReportInput {
    margin-right: 5px; }

.EditInfo-Modal .react-tel-input .form-control {
  width: 100%; }

.EditInfo-Modal .react-tel-input .country-list {
  top: 24px;
  left: 0; }
  .EditInfo-Modal .react-tel-input .country-list .country {
    text-align: left; }

.EditInfo-Modal .contactErrorMsg {
  text-align: left;
  display: block;
  padding: 5px 0 0;
  color: #cc4646; }

.EditInfo-Modal .modal-body {
  text-align: center; }
  .EditInfo-Modal .modal-body .fa.fa-phone {
    font-size: 50px;
    color: #f07563; }

.EditInfo-Modal .modal-content {
  position: relative !important;
  height: auto !important;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-family) !important;
  background-color: var(--dark);
  border-radius: 1em; }

.EditInfo-Modal .chat-modal-header {
  border-bottom: none;
  padding: 0 1rem; }
  .EditInfo-Modal .chat-modal-header img {
    opacity: 1 !important; }
  .EditInfo-Modal .chat-modal-header .close {
    margin-top: 0rem !important;
    color: #C4C4C4;
    font-weight: 300;
    font-size: 28px;
    padding-top: 7px;
    position: absolute;
    top: 0px;
    right: 15px; }

.EditInfo-Modal .modal-center-img {
  width: 115px;
  margin: 15px auto 0px auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.EditInfo-Modal .modal-title {
  flex: 1 1 100%;
  text-align: center;
  margin: 0 !important; }

.EditInfo-Modal h4 {
  font-size: 18px;
  font-weight: 700 !important;
  margin-bottom: 0.3em; }

.EditInfo-Modal p {
  font-weight: 400;
  opacity: 0.8; }

.EditInfo-Modal .chat-modal-body button {
  line-height: 26px !important;
  z-index: 1;
  position: relative;
  width: 70% !important;
  background-color: #3B4149;
  font-weight: 800 !important;
  color: white !important;
  border-radius: 20px !important;
  height: 35px;
  bottom: auto;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 1px;
  text-align: center;
  padding: 5px;
  text-transform: uppercase;
  -webkit-box-shadow: 2px 14px 28px 1px rgba(0, 0, 0, 0.28);
  -moz-box-shadow: 2px 14px 28px 1px rgba(0, 0, 0, 0.28);
  box-shadow: 2px 14px 28px 1px rgba(0, 0, 0, 0.28);
  font-family: var(--font-family) !important;
  font-weight: 400; }

.EditInfo-Modal .chat-modal-body .form-group {
  margin-bottom: 35px !important; }

.EditInfo-Modal .chat-modal-footer {
  background-color: var(--primary);
  min-height: 60px;
  position: relative;
  padding: 0 1rem;
  justify-content: center;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em; }
  .EditInfo-Modal .chat-modal-footer p {
    cursor: pointer;
    color: white;
    margin-top: 25px;
    font-size: 12px !important;
    text-decoration: underline;
    font-family: var(--font-family) !important;
    width: 100%;
    text-align: center;
    font-weight: 600; }

.tip-Modal .modal-content {
  height: 242px !important; }

.modal-dialog.tip-Modal {
  margin: 1.75rem auto; }

.tip-Modal .form-group input[type=number] {
  margin: 0 auto;
  width: 80px; }

.tip-Modal .form-group input[type=number]::-webkit-inner-spin-button,
.tip-Modal .form-group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.tip-Modal .alert {
  width: 95%;
  margin: 10px auto; }

.tip-Modal .alert.alert-danger {
  padding: 0;
  border-radius: 5px;
  background-color: #FDF1F4;
  color: #E5A0A9;
  border-color: #FDF1F4; }

.modal-backdrop.show {
  opacity: 0.3 !important; }

.modal {
  background: transparent !important; }

body.modal-open #root {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); }

.tip-Modal .chat-modal-footer {
  border-bottom-right-radius: 0.7em !important;
  border-bottom-left-radius: 0.7em !important; }

.tip-Modal .btn {
  font-family: var(--font-family) !important;
  font-weight: 400; }

.tip-Modal .alert.alert-danger {
  color: #CD5664 !important;
  font-size: 12px; }

.online-user-details .back_btn {
  display: none;
  align-self: center; }
  .online-user-details .back_btn .btn {
    border: none;
    color: grey;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px; }
  .online-user-details .back_btn .btn:before {
    content: "❮";
    color: #d7d7d7;
    font-size: 19px; }
