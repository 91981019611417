.chat-card {
    & > .row {
        align-items: center;
    }
    .col-3 {
        padding-right: 0;
    }
    .col-9 {
        padding-left: 0;
    }
    .chat_ib {
        position: relative;
        .ellipsisData {
            text-transform: capitalize;
            font-size:1rem;
            font-weight: 600;
        }
        h5 {
            margin-bottom: 0;
        }
        .lastmsg {
            font-size: 12px;
            margin: 0;
            color: #B9B9B9;
        }
        .msg-time {
            position: absolute;
            top: 0;
            right: -7px;
            font-size: 10px;
            color: #8c8c8c;
        }
    }
    .chat_img {
        position: relative;
    }
    .online-user {
        // width: 8px;
        // height: 8px;
        // background: #469864;
        // border-radius: 50%;
        position: absolute;
        left: 65%;
        bottom: 2px;
        // border: 1px solid #fff;
    }
    &:last-child .row{
        border-bottom: none;
    }
}
.card-header {
    background: transparent !important;
    // border-bottom: 1px solid #ddd !important;
    .col {
        // padding: 6px 39px;
        font-size: 22px;
        svg  {
            margin-right: 6px;
        }
    }
}
.card-body {
    padding-top: 0;
    padding-left: 10px;
    padding-top: 0;
}
.conversion-header {
    border-bottom: 1px solid #f1f1f2 !important;
    @media(max-width:767px){
        border-bottom: 1px solid #f1f1f2 !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
        padding-bottom: 0 !important;
        flex-direction: column;
        .host-dp{
            place-self: flex-start;
        }
    }
    .switch{
        @media(max-width:767px){
            position: absolute;
            right: 0px;
            top: 10px;
        }
    }
}
.chatHeading{
    font-size: 1.25rem;
    @media(max-width:767px){
        display: none;
    }
}
.dollar {
    float: right;
    position: relative;
    top: 20px;
}
.user-name {
    margin-left: 14px;
}
.write_msg {
    width: 85%;
    padding: 0;
    border: none;
    height: 54px;
    outline:none;
    &::placeholder {
        color:#c4c4c4;
    }
}
.write-footer {
    min-height: 54px;
    padding-left: 17px;
    @media screen and (max-width: 767px){
        padding: 0 !important;
    }
}
.no-chat {
    min-height: 322px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.CustomerListHeader {
    margin: 10px;
}

.all-convo-main-wrap{
    @media(max-width:767px){
        display: none;
    }
}

.video_status_loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    background: rgba(0,0,0,0.5);
    p {
        margin: 0 5px;
        text-align: center;
        font-size: 14px;
        color: #f07664;
    }
}

progress[value] {
    --color: #fd7663; /* the progress color */
    --background: lightgrey; /* the background color */
  
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    width: 300px;
    margin: 0 10px;
    border-radius: 10em;
    background: var(--background);
    height: 5px;
    margin-top: 10px;
  }
  progress[value]::-webkit-progress-bar {
    border-radius: 10em;
    background: var(--background);
  }
  progress[value]::-webkit-progress-value {
    border-radius: 10em;
    background: var(--color);
  }
  progress[value]::-moz-progress-bar {
    border-radius: 10em;
    background: var(--color);
  }

@media(max-width:767px){
    .all-convo-main-wrap\.dis-block{
        display: block;
        padding: 0 !important;
    }
    .customerlist-main-wrap.dis-none {
        display: none;
    }
    .video_status_loader {
        p {
            font-size: 14px;
        }
    }
}

.chatLogsLoading {
    position: absolute;
    z-index: 9;
    background: rgba(0,0,0,0.7);
    overflow: hidden;
    width: calc(100% - 260px);
    left: 260px;
    top:0;
.height-700 {
    .text-center {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh !important;
    }
}

.ar-chatLoader-absolute {
	position: absolute;
	z-index: 99;
	width: 100%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(41, 42, 43, .5);
	bottom: 0;
	left: 0;
    height: 100%;
}

}
