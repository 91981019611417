.hideMeBtn{
    font-size: 0.938rem !important;
    font-weight: 700 !important;
    border-radius: 10px !important;
    padding: 0.89em 2.9em !important;
}
.clearAllBtn{
    font-size: 0.875rem !important;
    font-weight: 700 !important;
    border-radius: 10px !important;
    padding: 0.79em 1.6em !important;
}
.exc-cntry-tag {
    padding: 0 10px;
    width: 230px;
    margin-bottom: 20px;
    .innerTag{
        border-radius: 10px;
        padding: 12px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        span{
            font-weight: 700;
            font-size: 14px;
            line-height: 21px;
        }
        .cursorPointer{
            cursor: pointer;
        }
    }
}
.taglistROw{
    margin-left: -10px !important;
    margin-right: -10px !important;
}
.country-list{
    background-color: #333739 !important;
    position: absolute !important;
    min-width: 350px !important;
    top: 11px;
    left: 154px;
    z-index: 1;
    .ftBtn{
        border-radius: 10px;
        height: 50px;
    }
    .card-header{
        border-bottom: 0;
        h6{
            font-weight: 700;
        }
        i{
            cursor: pointer;
            color: #5D6569;
        }
        .searchRegionField{
            border-radius: 30px;
            background-color: #333739;
            border-color: #66666680;
            font-size: 0.875rem;
            font-weight: normal;
            color: #fff;
            &:focus{
                background-color: #333739;
            }
        }
    }
    .checkBoxList{
        max-height: 150px;
        overflow-y: auto;
        .list-group-item{
            background: transparent;
            border-width: 0 0 1px 0;
            border-color: #66666680;
            padding-left: 0;
            .customCheckbox{
                position: relative;
                padding-left: 28px;
                input{
                    opacity: 0;
                }
                input:checked + label{
                    color: var(--primary);
                    &:before{
                        border-color: var(--primary);
                    }
                    &::after{
                        opacity: 1;
                    }
                }
                > label{
                    &:before{
                        border: 1px solid #666;
                        content: "";
                        height: 16px;
                        width: 16px;
                        position: absolute;
                        left: 0;
                        border-radius: 3px;
                        transition: .3s;
                    }
                    &:after{
                        transform: rotate(45deg);
                        height: 8px;
                        width: 4px;
                        border-bottom: 2px solid var(--primary);
                        border-right: 2px solid var(--primary);
                        content: "";
                        position: absolute;
                        left: 6px;
                        top: 5px;
                        opacity: 0;
                        transition: .3s;
                    }
                }
            }
            label{
                margin-bottom: 0;
                font-size: 0.875rem;
                font-weight: 600;
                color: #B9B9B9;
            }
        }
    }
    
}
.loderWraper{
    .height-700{
        .text-center{
            min-height: unset !important;
            padding-top: 17px;
        }
    }
}