.header__not_for_mobile {
  height: 10vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-around;
  align-items: center;
}

.main__not_for_mobile {
  text-align: center;
  height: 50vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  .text__not_for_mobile {
    color:#C4C4C4;
    font-size: 16px;
    padding: 5px;
    .link__not_for_mobile {
      color:#FD7663;
      text-decoration: underline;
    }
  }
}

.use_app_video_wrapper{
  height: 30vh;
  display: flex;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-evenly;
  align-items: center;
  .use_app_video {
    height: 100%;
    width: auto;
  }
}