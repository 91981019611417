.timer-popup-main-wrap{
  .modal-content{
    background-color:transparent ;
    border: unset;
    .modal-header{
      padding: 0;
      border: unset;
      .Modal title{
        display: none;
      }
      .close{
        display: block !important;
        position: absolute;
        right: 40px;
        top: 32px;
        z-index: 999;
        span{
          font-weight: 500;
          box-shadow: unset;
          font-size: 30px;
          text-shadow: unset;
          color: white;
        }
      }
    }
    .modal-body{
      width: 100%;
      .roulette-time-popup-wrap{
        background: #393b3e;
        padding: 40px 15px 50px;
        z-index: 999;
        border-radius: 10px;
        text-align: center;
        max-width: 500px;
        margin: 0 auto;
        width: 100%;
        .roulette-time-popup{
          max-width: 430px;
          margin: 0 auto;
          .roulette-time-popup-img{
            margin-bottom: 20px;
          }
          h3 {
            font-family: Overpass !important;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 35px;
            text-align: center;
            margin-top: 20px;
          }
          .popup-divider {
            height:1px ;
            background:#BDBDBE;
            margin: 30px 0;
          }
          h4 {
            font-family: Overpass;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            opacity: 0.7;
          }
          .launch-btn{
            font-family: Overpass;
            background: #fd7663;
            color: white;
            border: unset;
            padding: 13px 17px;
            text-align: center;
            margin-top: 20px;
            max-width: 278px;
            width: 100%;
            border-radius: 30px;
            margin-top: 30px;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
  }
}




