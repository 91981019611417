.chat-customer-review {
	display: flex;
	padding: 6px 0px;
	border-radius: 4px;
  background-color: #333739;
  &.chat-customer-chat{
    background-color: unset;
    .chat-reviews{
      display: block;
      padding: 0 5px;
      position: relative;
      .title_container{
        top: -12px;
        span{
          font-size: 10px;
        }
      }
    }
  }
  &.chat-model{
    padding: 6px 0px 2px;
    .chat-reviews{
      padding: 0 10px 0 4px;
      .title_container{
        top: -13px;
      }
      span{
        font-size: 10px;
      }
    }
  }
  @media(max-width:767px){
    border-radius: 0px;
    width: 100%;
  }
	.chat-reviews {
		border-right: 0.6px solid #505050;
		padding: 0 10px;
    display: flex;
    align-items: baseline;
    @media(max-width:767px){
      padding: 0 10px;
    }
		&:last-child {
			border-right: none;
		}
		span i.fa.fa-star {
			color: #f6c71af2;
			margin: 0 5px 0 10px;
			font-size: 14px;
		}
		span {
			font-size: 12px;
      &.rating_title{
        position: relative;
        top: 3px;
        left: 4px;
      }
      @media(max-width:767px){
			  font-size: 12px;
      }
		}
	}
}
.modal .video_chat_modal.modal-dialog {
  max-width: 670px !important;
}
.modal .video_chat_modal {
  margin: 0;
  margin-left: auto;
  margin-right: 20px;
  top: 100px;
  .modal-content{
    background-color: var(--dark);
  }
  .close{
    color: var(--white);
  }
  .p-absolute {
    position: absolute;
  }
  .arousr_logo {
    width: 40px;
    height: 40px;
  }
  .pr-0 {
    padding-right: 0px
  }
  .pl-0 {
    padding-left: 0px
  }
  // .popup-btn {
  //   background-color: white;
  //   color: #464646;
  //   border: none;
  // }
  // .popup-btn:hover {
  //   border-radius: 0.5rem;
  //   width: 100px;
  //   height: 35px;
  // }
  .bdr-btm-none {
    border-bottom: none;
  }
  .bdr-t-none {
    border-top: none;
    z-index: 99999;
    display: flex;
    flex-wrap: nowrap;
    margin-top: 70px;
    justify-content: space-between;
  }
  .c-888 {
    color: #888;
  }
  .c-464646 {
    color: #464646;
  }
  .modal-body {
    padding-bottom: 0px;
    position: fixed;
    width: auto;
    &.chat-request{
      width: 470px;
    }
  }
  .button-panel {
    justify-content: flex-end;
    margin-right: 0px;
    margin-top: 0px;
    display: flex;
    button {
      // background-color: white;
      // color: #3B414A;
      // border: none;
      width: 100px;
      height: 35px;
      margin: 0 5px;
      border-radius: 0.5rem;
    }
  }
}
.bar-seprate-line-wrap{
  transform: rotate(118deg);
  z-index: 1;
  position: absolute;
  left: 90%;
  top: 16px;
  }
  .bar-seprate-line {
	height: 7px;
	  width: 0.8px;
	  background: #3E3E3E;
	  position: absolute;
  }
  .bar-line-1{
    left: 11px;
    transform: rotate(186deg);
    top: 30px;
  }
  .bar-line-2{
	left: 0.5px;
	transform: rotate(43deg);
	top: 25px;
  }
  .bar-line-3{
	left: -4px;
	transform: rotate(88deg);
	top: 12px;
  }
  .bar-line-4{
    left: -2px;
    transform: rotate(125deg);
    top: 2px;
  }
  
#customer-online-modal{
    .chat-customer-review.chat-model {
      width: 100%;
    }
    .modal-content {
      max-width: 330px;
      margin-left: auto;
      background: transparent;
      .modal-footer{
        margin-top: 0 !important;
        padding: 0px;
      }
      button {
        width: 100%;
        border-radius: 10px;
        background: #fff;
        color: #FD7663;
        text-align: left;
        padding: 20px 20px;
        border-color: #fff;
        box-shadow: none;
        outline: none;
        font-size: 18px;
      }
      .chat-customer-review{
        background: transparent;
        padding: 0;
        margin: 0;
        a{
          color: #fff;
          text-decoration: none !important;
        }
      }
  }
}

.modal.customer_online_status_modal {
  position: fixed;
    top: 100px;
    left: auto;
    z-index: 1050;
    display: none;
    width: 350px;
    height: 71px;
    overflow: hidden;
    outline: 0;
    right: 50px;
    #customer-online-modal{
      .modal-content  {
        button {
          border-radius: 10px;
          background: #fff;
          color: #000000;
          text-align: left;
          padding: 0 13px;
          border: 5px solid #FD7663;
          border-width: 0 0 0 5px;
          outline: none;
          font-size: 16px;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
          position: fixed;
          top: 100px;
          right: 50px;
          left: auto;
          width: 350px !important;
          height: 71px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .icon {
            width: 40px;
            height: 40px;
            display: inline-flex;
            vertical-align: middle;
            background: #f77563;
            border-radius: 50px;
            margin-right: 10px;
            justify-content: center;
            align-items: center;
            svg {
                fill: #fff;
            }
          }
          .notification_txt{
            width: calc(100% - 50px);
          }
        }
      }
    }
}