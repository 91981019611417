.dropzone{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #333739;
  border-style: dashed;
  background-color:#292a2b;;
  color: #bdbdbd;
  outline: none;
  border-radius: 10px;
  transition: border .24s ease-in-out;
  .cld-arr-icon {
    position: relative;
    margin: 35px auto;
    width: 50px;
    .cloude-icon{
      position: absolute;
    }
    .arrow-icon{
      position: inherit;
      top: 18px;
      left: 16px;
    }
    p{
      color: #666662;
    }
  }
  button{
    border-radius: 10px;
    padding: 5px 83px;
  }
  .addFilesBtn{
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    svg{
      fill: var(--primary);
    }
    &:hover{
      svg{
        fill: #fff;
      }
    }
  }
}
.file-name {
  color: #bdbdbd;
  font-size: 15px;
  /* text-decoration: none; */
  list-style-type: none;
}