.fillterRow{
    .search-label{
        margin-bottom: 0;
        input{
            background-color: var(--dark);
            border-radius: 10px;
            font-size: 0.875rem;
            padding: 12px 15px;
            border-color: var(--dark);
            height: 44px;
            width: 274px;
            color: var(--white);
            background-image: url('../../../../assets/svgs/magnify.svg');
            background-repeat: no-repeat;
            background-position: left 10px center;
            text-indent: 20px;
            background-size: 18px;
            @media (max-width:1200px){
                width: 100%;
            }
        }
    }
}
.filterWrap{
    position: absolute;
    z-index: 999;
    & > label {
        margin-bottom: 0;
        margin-right: 20px;
        color: #B9B9B9;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 18px;
        select{
            display: block;
            background-color: var(--dark);
            border-radius: 10px;
            font-size: 0.875rem;
            color: var(--white);
            height: 44px;
            width: 239px;
            padding: 8px 15px;
            margin-top: 6px;
            border-color: var(--dark);
            @media (max-width:1250px){
                width: 220px;
            }
        }
    }
    .filterBtn{
        width: 102px;
        border-radius: 10px;
        height: 44px;
    }
    .form-control:focus {
        display: block;
        background-color: var(--dark);
        border-radius: 10px;
        font-size: .875rem;
        color: var(--white);
        height: 44px;
        width: 239px;
        padding: 12px 15px;
        margin-top: 6px;
        border-color: var(--dark);
    }
}