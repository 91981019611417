.ar_head_text {
    color: #FFF;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
}
.payout-card{
    border: unset !important;
    .card-body{
        padding-bottom: 0;
        .card-header{
            border:unset;
            padding: 0;
            h2{
                margin: 0;
            }
        }
    }
    .btn-outline-primary {
        padding: 16px 40px;
        border-radius: 10px;
        margin-left: 20px;
        background: transparent;
        &:hover{
            background: #FD7663;
        }
    }
}
.custom-dropdown{
    div[class*="-control"] {
        background: #2F3134;
        border: unset;
        height: 50px;
        span[class*="-indicatorSeparator"] {
            display: none;
        }
    }
    div[class*="-menu"] {
        background: #2F3134;
        border: unset;
        height: 50px;
        &>div {
            background: #2F3134;
            border-radius: 10px;
            padding: 0px;
            div[class$=-option] {
                background-color: transparent;
                border-bottom:1px solid #666666;
                font-family: 'Overpass';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: -0.05em;
                color: #B9B9B9;
                padding: 15px 15px;
                cursor: pointer;
                &:last-child{
                border-bottom:unset;
                }
                &:hover{
                    background-color: #666666;
                }
            }
        }
    }
    div[class*="-ValueContainer"] {
        align-self: center;
        div[class*="-Input"]{
            height: 50px;
        }
        div[class*="-singleValue"] {
            color: white;
            align-self: center;
        }
    }
    span[class*="-indicatorSeparator"]{
        display: none;
    }
    div[class*="-singleValue"] {
        color: white;
    }
}

.ar_host_payment_radio_box {
    margin-top: -15px;
    .form__radio-group{
        max-width: 234px;
        width: 100%;
        .form__radio-input{
            display: none;
            &:checked {
                + {
                    .form__radio-label {
                        .form__radio-button {
                            &:after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        .form__radio-label{
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            max-width: 220px;
            background: #333739;
            padding: 17px 16px;
            border-radius: 10px;
            margin: 0 14px 0 0;
            border: 1px solid transparent;
            transition: .3s;
            &:hover{
                border-color: #FD7663;
                transition: .3s;
            }
            &:focus{
                border-color: #FD7663;
                transition: .3s;
            }
            .form__radio-label-text{
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 12px;
            }
        }
        .form__radio-button{
            height: 16px;
            width: 16px;
            border: 1px solid #FD7663;
            border-radius: 50%;
            display: inline-block;
            position: relative;
            transform: translateY(-2px);
        
            &::after {
                content: "";
                display: block;
                height: 10px;
                width: 10px;
                position: absolute;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transition: opacity 0.1s;
                transform: translate(-50%, -50%);
                background-color: #FD7663;
                opacity: 0;
            }
        }
    }
  }

  .ar_payment_form_box{
    margin-top: -20px;
    .ar_payment_form_title{
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .ar_payment_form_label{
        color: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        opacity: 0.6;
        margin-bottom: 0;
    }
    .ar_payment_form_input {
        border-radius: 5px;
        background: rgba(51, 55, 57, 0.7);
        border: none;
        max-width: 480px;
        width: 100%;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        height: 50px;
        padding-left: 20px;
        &:focus{
            background-color:  rgba(51, 55, 57, 0.7);
            border-color: transparent;
            outline: 0;
            box-shadow: none;
            color: #fff;
        }
    }
    .text-danger{
        font-size: 12px;
    }
    .btn-outline-primary {
        padding: 16px 40px;
        border-radius: 10px;
        margin-left: 20px;
        background: transparent;
        &:hover{
            background: #FD7663;
        }
    }
  }