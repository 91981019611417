.subheadingText{
    color: #fff;
    opacity: 0.8;
    font-size: 16px;
}
.subText{
    color:#fff;
    font-size: 14px;
    opacity: 0.6;
}
.topHead{
    margin-top: 25px;
    font-size: 20px;
}
.headingWrap{
    display: flex;
    img{
        position: relative;
        top: 8px;
        left: 15px;
    }
}
.back-btn-wrap{
    margin-top: 25px;
    cursor: pointer;
    padding: 0 0 0 8px;
}
// feed profile
.profileHead{
    display: flex;
    align-items: center;
    padding: 8px;
    .profile-content-wrap{
        margin-left: 20px;
        .profile-username{
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 10px;
            letter-spacing: -0.02em;
            color: #FFFFFF;
            text-transform: capitalize;
        }
        .profile-user-about{
            font-size: 14px;
            line-height: 19px;
            letter-spacing: -0.02em;
            color: #FFFFFF;
            opacity: 0.7;
        }
    }
    .profileImage{
        width: 130px;
        height: 130px;
        border-radius: 50%;
        object-fit: cover;
        border:2px solid #484C54;
    }
    .card-title{
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        text-transform: capitalize;
        margin: 0;
    }
    .card-text{
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        opacity: 0.7;
        max-width: 350px;
    }
    .like-btn-wrap{
        display: flex;
        flex-direction: column;
        .like-all-btn{
            background: transparent;
            color: #f07563;
            width: 135px;
            height: 45px;
            border: 1px solid #f07563;
            margin: 10px 0;
            &:hover , &:focus{
                color: var(--white);
                background-color: #f07563;
                border-color: #f07563;
                svg{
                    path{
                        fill:white;
                    }
                }
            }
            a{
                margin-right: 10px;
            }
            span{
                position: relative;
                top: 2px;
            }
            svg{
                margin-right: 10px;
                margin-top: 2px;
            }
        }
    }
}
.profile-feed-card-ul{
    .feed-card-li{
        .card{
            border: unset;
        }
        .feedCardImage{
           
            object-fit: cover;
        }
    }
}