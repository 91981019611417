.top_separator {
  border-top: 1px;
  border-top-style: outset;
}

.status_row {
  padding: 10px;
}
.customCardHeaderList{
  &:not(:last-child){
  border-bottom: 1px solid #ffffff1a;
  }
}