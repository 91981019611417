.camera-modal{
    video::-webkit-media-controls-panel {
        display: none!important;
        -webkit-appearance: none;
        }

        /* Old shadow dom for play button */
        video::-webkit-media-controls-play-button {
            display: none!important;
            -webkit-appearance: none;
        }

        /* New shadow dom for play button */
        /* This one works! */
        video::-webkit-media-controls-start-playback-button {
            display: none!important;
            -webkit-appearance: none;
        }  
  .modal-content{
      border-radius: 1rem;
      height: auto;
      background-color: var(--dark);
      .camModel {            
          .chat-modal-body {
              position: relative;
          }
          .canvas {
              position: absolute;
              top: 16px;
              left: 20px;
              width: 333px;
              max-height: 250px;
              #canvas.active {
                  visibility: visible;
              }
          }
          .videoElement {
              width: 100%;
              max-height: 250px;
          }
          .no-camera {
              width: 100%;
              max-height: 250px;
              text-align: center;
          }
          .modal-btn {
              background: #3B4149;
              color: #fff;
              border: none;
              min-width: 120px;
              padding: 10px;
              border-radius: 15px;
            //   margin: 0 auto;
              display: block;
              position: relative;
              top: 30px;
              box-shadow: 2px 14px 28px 1px rgba(0, 0, 0, 0.28);
              text-transform: uppercase;
              font-weight: 500;
              letter-spacing: 1px;
              cursor: pointer;
          }
      }
      .camHeader {
          border-top-left-radius: 1rem;
          border-top-right-radius: 1rem;
          border-bottom: none;
          padding: 0 1rem 25px;
          .close{
              margin-top: 0rem !important;
              color: #C4C4C4;
              font-weight: 300;
              font-size: 28px;
              padding-top: 7px;
              position: absolute;
              top: 0px;
              right: 15px;
          }
      }
      .camFooter {
          background-color: var(--primary);
          height: 50px;
          border-bottom-right-radius: 1rem;
          border-bottom-left-radius: 1rem;
      }
      .btn-group{
        display: flex;
        width: 70%;
        margin: 0 auto;
        justify-content: space-evenly;
        flex-wrap: nowrap;
      }
      @media(max-width:767px){
        .camModel {            
            &.chat-modal-body {
                text-align: center;
                width:100% !important;
            }
            .canvas {
                width: 250px;
                height: 300px;
                max-height: unset;
                top: 16px;
                left: 50%;
                transform: translate(-50%, 0);
            }
            .videoElement {
                max-width: 250px;
                width: 100%;
                max-height: 300px;
            }
        }
      }
  }
}