.searchCustomers{
    margin-top: 12px;
    input {
        background-color: var(--dark);
        border-radius: 10px;
        font-size: 0.875rem;
        padding: 12px 15px;
        border-color: var(--dark);
        height: 44px;
        width: 274px;
        color: var(--white);
        background-image: url(/static/media/magnify.70aaf4c8.svg);
        background-repeat: no-repeat;
        background-position: left 10px center;
        text-indent: 20px;
        background-size: 18px;
        &:focus{
            background-color: var(--dark);
            border-color: var(--dark);
            color: var(--white);
        }
    }
}
.noteTextArea {
    width: 100%;
    textarea {
        margin-bottom: 10px;
        resize: none;
    }
}
.phoneBookCard{
    background-color: #292a2b !important;
    min-height: 500px;
    ul{
        padding: 20px;
        overflow: auto;
        .customer-section {
            display: flex;
            border-bottom: 1px solid #3E3F40;
            padding-bottom: 20px;
            margin-bottom: 20px;
            justify-content: space-between;
            .customerImg{
                display: flex;
                align-items: center;
                position: relative;
                .customerImgInner {
                    position: relative;
                }
                .online-small-icon {
                    width: 13px !important;
                    height: 13px !important;
                    display: inline-block;
                    position: absolute;
                    top: 25px;
                    left: 25px;
                    background-color: #2eb130;
                    border-radius: 50%;
                    border: 1px solid #ddd;
                }
                .online-away-icon {
                    width: 13px !important;
                    height: 13px !important;
                    display: inline-block;
                    position: absolute;
                    top: 25px;
                    left: 25px;
                    background-color: #ECAC19;
                    border-radius: 50%;
                    border: 1px solid #ddd;
                }
                .offline-small-icon {
                    width: 13px !important;
                    height: 13px !important;
                    display: inline-block;
                    position: absolute;
                    top: 25px;
                    left: 25px;
                    background-color: #9fa09f;
                    border-radius: 50%;
                    border: 1px solid #ddd;
                }
                img{
                    width: 38px;
                    height: 38px;
                    border: 2px solid #f07563;
                    border-radius: 50%;
                    margin-right: 15px;
                    cursor: pointer;
                }
                img.is_premium_customer {
                    position: absolute;
                    top: -3px;
                    left: -6px;
                    width: 20px !important;
                    height: 20px !important;
                    border: none !important;
                }
                .customerInfo{
                    display: flex;
                    flex-direction: column;
                    a{
                        font-family: Overpass;
                        font-style: normal;
                        font-weight: 800;
                        font-size: 16px;
                        line-height: 25px;
                        text-decoration-line: none;
                        color: #FFFFFF;
                        text-transform: capitalize;
                    }
                    p{
                        font-family: Overpass;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 18px;
                        color: #FFFFFF;
                        opacity: 0.6;
                        margin-bottom: 0;
                        max-width: calc(100% - 50px);
                        width: 100%;
                        min-width: fit-content;
                    }
                }
            }
            .customerImg.customerImgPhonebook {
                max-width: calc(100% - 440px);
            }
            .phonebook-option{
                justify-content: end;
                display: flex;
                align-items: center;
                max-width: 450px;
                width: 100%;
                button {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 18px;
                    color: #FD7663;
                    padding: 10px;
                    min-width: 110px;
                    margin-right: 20px;
                    height: 42px;
                    svg{
                        margin-right: 5px;
                    }
                    &:hover{
                        color: #fff;
                        svg{
                            path{
                                fill:#fff;
                            }
                        }
                    }
                    &:nth-child(3){
                        margin-right: 0;
                    }
                }
            }
        }
    }
 }   

 .phonebook-wrap {
    .card-body {
        background-color: #242628;
        border-radius: 0;
    }
    .nav-tabs {
        border-bottom: none;
    }
    .nav-item {
        max-width: 200px;
        margin-bottom: -2px;
    }
    .nav-item .nav-link {
        padding: 20px 4px 15px;
        border: none;
        cursor: pointer;
        color: #fff;
    }
    .nav-item .nav-link:hover {
        color: #fd7663;
    }
    .nav-item .nav-link.active {
        color: #fd7663;
        background-color: #242628;
        border: unset;
        padding: 20px 4px 15px;
    }
 }