.customCard.Credits-customCard{
  .btn{
    line-height: normal;
    border-radius: 30px !important;
    min-width: 180px;
    padding: 8px 6px;
    margin: 0 0 10px;
  }
  .Credits_btn{
    margin-top: -30px;
    .btn{
      &:hover{
        background: #3a414b;
      }
    }
    .btn-secondary{
      background: #3a414b;
    }
  }
  .card-body{
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .credits-no{
        background: #eee;
        padding: 4px 8px;
        margin-bottom: 15px;
        margin-left: -5px;
      }
  }
  .credit-update-info{
    font-size: 12px;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 12px;
  }
  .pl-40{
    padding-left: 40px;
  }
  .customCardHeader{
    h2{
      img{
        margin-left: 5px;
        width: 14px;
        transform: scaleX(-1) rotate(60deg);
      }
    }
  }
}
.amtTitle {
  font-size: 1rem;
  opacity: 0.8;
}
.refreshBtn{
  font-size: 0.75rem !important;
  display: flex !important;
  justify-content: center !important;
  vertical-align: middle !important;
  align-items: center;
  // position: absolute;
  right: 0;
}
.mb-10{
  margin-bottom: 10px !important;
}
.creditBtn{
  color: #fff !important;
  background-color: transparent !important;
  border-color: #fff !important;
  min-width: unset !important;
  margin-left: auto !important;
}
.cursor-pointer {
  cursor: pointer;
}
.amount{
  font-size: 1.89rem;
  font-weight: 800;
  position: relative;
  sub {
    position: absolute;
    bottom: 15px;
    margin-left: 2px;
    small {
      font-weight: bold;
    }
  }
  @media (max-width:1230px){
  font-size: 1.49rem;
  }
}
.creditamount{
  font-weight: 800;
}
.css-loader {
  border: 2px solid #f3f3f3;
  border-radius: 50%;
  border-top: 1px solid #292a2b;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}