.customer_reviews {
  background: #373737;
  border-radius: 10px;
  padding: 15px 20px;
}
.customer_reviews h4 {
  font-size: 16px;
}
.customer_reviews ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.customer_reviews ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
.customer_reviews ul li:last-child {
  border-bottom: none;
}

.yellowStar {
  color: #ffa500;
}
.greyStar {
  color: #c3bfbf;
}