.reviews.card {
  // height: 33.8rem;
}
.rating {
  svg {
    .cls-1 {
      fill-rule: evenodd;
    }
    height: 15px;
    width: 15px;
    margin-right: 4px;
  }
}

.screen-name {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
  // max-width: 65px;
  img.is_premium_customer {
    position: absolute;
    top: 0;
    left: -8px;
  }
  .online-small-icon {
    width: 10px !important;
    height: 10px !important;
    display: inline-block;
    position: absolute;
    top: 22px;
    left: 20px;
    background-color: #2eb130;
    border-radius: 50%;
    border: 1px solid #ddd;
  }
  .offline-small-icon {
    width: 10px !important;
    height: 10px !important;
    display: inline-block;
    position: absolute;
    top: 22px;
    left: 20px;
    background-color: #9fa09f;
    border-radius: 50%;
    border: 1px solid #ddd;
  }
  .online-away-icon {
    width: 10px !important;
    height: 10px !important;
    display: inline-block;
    position: absolute;
    top: 22px;
    left: 20px;
    background-color: #ECAC19;
    border-radius: 50%;
    border: 1px solid #ddd;
  }
  svg {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background: #f5f5f5;
    overflow: hidden;
    margin-right: 10px;
  }
  .cust-profile-pic{
    border-radius: 50%;
    // border: 1px solid #6a6b6b;
    height: 30px;
    width: 30px;
  }
}

.commentBody {
  font-weight: 200 !important;
}
.headTitle{
  font-size: 1.25rem;
  font-weight: 600;
}
.customCardHeader {
  padding-bottom: 0;
  background: #fff !important;
  .headerBorderBottom{
    min-height: 2px;
    background: #F5F6FA;
    margin-left: 20px;
  }
  h2 {
    // border-left: 3px solid darkred;
    // padding-left: 10px;
    font-size: 16px;
    line-height: 30px;
    
    svg {
      width:25px;
      height:25px;
      margin-right: 10px;
      fill:#2D313A;
      margin-bottom: 10px;
    }
    .headTitle {
      font-size:20px;
      align-self: flex-end;
    }
  }
  .badge-secondary{
    background: #9d2731;
    border-radius: 25px;
    min-width: 18px;
    height: 18px;
    margin-left: 5px;
  }

}

// .tableWidthController {
//   .react-bootstrap-table {
//     th.sortable {
//       width: 3% !important;
//     }
//     table td {
//       vertical-align: middle;
//     }
//   }
// }