.pageHeaderCls{
  position: sticky;
  top: 0;
  z-index: 999;
  .notificationIcon{
    position: relative;
    margin: 0 7px;
    padding: 10px;
    img{
        width: 24px;
    }
    .badge{
      background: #9d2731;
      border-radius: 25px;
      min-width: 18px;
      height: 18px;
      margin-left: 0;
      position: absolute;
      left: 50%;
      top: 0;
    }
  }
  .logOut{
    line-height: normal;
    border-radius: 25px;
    min-width:95px;
    padding: 13px 6px;
    font-weight: 600;
    font-size: 0.875rem;
    color: #fff;
    line-height: 1;
    &:hover{
      opacity: 0.9;
    }
    @media(max-width:767px){
      display: none;
    }
  }
  .hostBlog{
    line-height: normal;
    border-radius: 25px;
    min-width:95px;
    padding: 13px 6px;
    font-weight: 600;
    font-size: 0.875rem;
    color: #fff;
    background-color: #fff;
    border-color: #fff;
    line-height: 1;
    margin-right: 10px;
    &:hover {
      background-color: #ebebeb;
      border-color: #ebebeb;
    }
    a {
      text-decoration: none;
    }
    @media(max-width:767px){
      display: none;
    }
  }

  .companyLogoCls a img{
    @media(max-width:767px){
      width:85px;
    }
  }
}