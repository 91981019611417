#playVideo{
    width: 250px;
    margin-bottom: 20px;
}
.test-microphone-wrap{
    .recording-row-wrap{
        .recording-row{
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .recording-line-wrap{
                width: 500px;
                margin: 0 20px;
                h4{
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 16px;
                    letter-spacing: -0.03em;
                    color: #FFFFFF;
                }
                p{
                    margin-bottom: 10px;
                    font-size: 16px;
                    line-height: 13px;
                    text-align: center;
                    letter-spacing: -0.03em;
                    color: #FFFFFF;
                    opacity: 0.8;
                }
                #recordProgress{
                    height: 5px;
                }
            }
            .record-btn-wrap{
                display: flex;
                width: 60%;
            }
            
        }
    }
    .btn{
        cursor: pointer;
        max-width: 200px;
        width: 100%;
        padding: 16px 10px;
        border-radius: 10px !important;
        margin-right: 20px;
        &.btn-secondary{
            background-color: transparent;
            border: 1px solid #FD7663;
            color: #FD7663 !important;
        }
        &.reset-btn{
            &:disabled{
                cursor: unset;
            }
        }
    }
}
.test-notification-wrap{
    .btn-notification{
        padding: 16px 20px !important;
        text-transform: uppercase;
        font-weight: 400 !important;
        border-radius: 10px !important;
    }
}

.headTitle{
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}
.ar_host_notification_setup_box{
    summary.ar_host_notification_setup_btn {
        background: #1d1d1d;
        padding: 14px;
        border-radius: 10px;
        color: #fff;
        margin-bottom: 20px;
    }
    h3 {
        font-size: 18px;
        border-bottom: 1px solid #aaa;
        padding-bottom: 7px;
        margin-bottom: 15px;
        margin-top: 25px;
        padding-top: 8px;
        border-top: 1px solid #aaa;
    }
}

.text-orange {
    color: #FD7663;
}