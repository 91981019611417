.feed-card-ul {
  margin-top: 20px;
  padding: 0;
  list-style: none;
  justify-content: space-between;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  display: flex;
  flex-wrap: wrap;
  .feed-card-li {
    width: 33%;
    max-width: 337px;
    .feed-card-wrap {
      border: none;
      border-radius: 0;
      max-height: 250px;
      height: 100%;
      .imgBtn {
        height: 100%;
      }
      .feedCardImage {
        object-fit: cover;
        height: 100%;
      }
      .card-body {
        background: #333739;
        position: relative;
        min-height: 85px;
        padding: 10px;
        .addWish {
          position: absolute;
          top: -35px;
          cursor: default;
          background-color: transparent;
          border: none;
          outline: none;
        }
        h5 {
          position: absolute;
          top: 4px;
          margin-left: 2rem;
          background-color: white;
          border-radius: 4px;
        }
        .feed-user-icon {
          position: absolute;
          right: 15px;
          top: -25px;
        }
        .card-title {
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          letter-spacing: -0.02em;
          color: #ffffff;
          text-transform: capitalize;
          margin: 0;
        }
        .card-text {
          font-style: normal;
          font-weight: normal;
          font-size: 14px ;
          line-height: 1.5em;
          height: 3em;
          letter-spacing: -0.02em;
          color: #ffffff;
          opacity: 0.7;
          overflow: hidden;
        }
      }
    }
  }
}
.profile-feed-card-ul{
  .card-body{
    display: none;
  }
}