/* ---------------------------------------------------
    CARD CONTENT STYLE
----------------------------------------------------- */

.card{
  border-radius: 0;    
}
.card-body{
}
.customCard{
  height: 100%;
  .customCardHeader {
    padding-bottom: 0;
    background: transparent;
    border: 0;
      h2{
      // padding-left: 15px;
      font-size: 1.125rem;
      line-height: 30px;
      font-weight: bold
    }
    .badge-secondary{
      background: #9d2731;
      border-radius: 25px;
      min-width: 18px;
      height: 18px;
      margin-left: 5px;
    }
  }
  .card-body{
    padding: 0 1.25rem;
    .customCardHeaderList{
      border-top: 0px !important;
      padding: 10px 0;
      margin-left: 0;
      margin-right: 0;
      & > div{
        padding-left: 0;
        padding-right: 0;
      }
      &:last-child{
        padding-bottom: 0;
        margin-bottom: 15px;
      }
      .custom-switch .custom-control-label::after{
        height: 20px;
        width: 20px;
        top: 4px;
        background-color: #fff;
      }
      .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
          transform: translateX(1.5rem);
      }
      .custom-switch .custom-control-label::after {
        left: calc(-2.25rem + 3px);
      }
      .custom-switch .custom-control-label::before{
        width: 50px;
        height: 24px;
        pointer-events: all;
        border-radius: 15px;
        top: 2px;
        background-color:#545758;
      }
      .custom-switch .custom-control-label{
        cursor: pointer;
        width: 14px;
      }
      .custom-control-input:checked~.custom-control-label::before {
        color: #fff;
        border-color: #3BA520;
        background-color: #3BA520;
      }
      .custom-control-input:focus ~ .custom-control-label::before{
        box-shadow: none;
      }
      .custom-control-input:focus:not(:checked) ~ .custom-control-label::before,
      .custom-control-label::before {
        border-color: #545758;
      }
    }
  }

}  
.topHeaderRanking{
  margin-right: -10px !important;
  margin-left: -10px !important;
  & > div {
    padding-right: 10px;
    padding-left: 10px;
  }
  .rounded {
    border-radius: 10px !important;
  }
}
.dashboard-notify-alert {
  color: #fd7663 !important;
  background-color: #f2f0f3 !important;
  border-color: #f2f0f3 !important;
}
.text-danger.text-underline {
  text-decoration: underline;
}