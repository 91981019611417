
.arousr_LogoCls{
    margin:0 auto;
}
.transparentLogo{
    display:none;
}
.videoCallLogo{
    position: absolute;
    left: 50%;
    transform: translate(-50%, 50%);
    top: 10px;
    z-index: 2;
}
@media(max-width:900px) {
    .arousr_LogoCls{
        display: none;
    }
    .transparentLogo{
        transform: translate(-50%, -50%);
        position: absolute;
        top: 12%;
        left: 50%;
        display: block !important;
    }
    .pageHeader_custom{
        background: transparent;
    }
    .pageHeader_custom.bg-dark{
        display: none !important;
    }
    #remote-media-div {
        video {
          height: 100vh !important;
        }
      }
      .pageHeader_custom{
          display: none;
      }
}