.Whisperer-modal{
    max-width: 360px !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 auto !important;
    width: 100% !important;
    position: absolute !important;
    @media (max-width:767px) {
        max-width: 300px !important;
    }
    .modal-content{
        background: #333739;
        border-radius: 12px;
        height: auto;
        .modal-body {
            width: 100%;
            padding: 0 30px 30px;
            .modal-title {
                color: #FFF;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 17px;
                letter-spacing: -0.54px;
                margin-bottom: 17px;
            }
            .whisperer-modal-reply-box {
                margin-bottom: 20px;
                position: relative;
                .form-group {
                    margin: 0;
                }
                label {
                    color: rgba(255, 255, 255, 0.70);
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 18px;
                    display: block;
                }
                textarea {
                    background-color: rgba(255, 255, 255, 0.05) !important;
                    border: 0.8px solid rgba(253, 118, 99, 0.50);
                    padding: 18px !important;
                    color: #FFF;
                    text-align: center;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 21px;
                    min-height: 120px;
                    &::placeholder{
                        color: #fff;
                    }
                }
                textarea.isWarningActive {
                    background-color: rgba(36, 23, 21, 0.80) !important;
                    padding-top: 60px !important;
                    @media (max-width:767px) {
                        padding-top: 50px !important;    
                    }
                }
                span.WarningIcon {
                    position: absolute;
                    left: 0;
                    right: 0;
                    display: block;
                    text-align: center;
                    top: 50px;
                    @media (max-width:767px) {
                        top: 40px;
                    }
                }
                .refresh-suggestion-icon {
                    position: absolute;
                    bottom: 7px;
                    z-index: 9;
                    right: 7px;
                    cursor: pointer;
                }
            }
            &.paid-pic-modal{
                .image-wrap{
                    text-align: center;
                    width: 100px;
                    margin: 32px auto 18px;
                    position: relative;
                    height: 120px;
                    overflow: hidden;
                    padding-top: 20px;
                    img{
                        width: 200px;
                        object-fit: contain;
                        position: absolute;
                        left: -56%;
                        right: 0;
                        margin: 20px auto 0;
                        z-index: 9;
                        top: -25%;
                    }
                    .bot_bg_circle {
                        background: rgba(253, 118, 99, 0.1);
                        display: block;
                        width: 100px;
                        height: 100px;
                        border-radius: 50%;
                        position: absolute;
                        top: 20px;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                    }
                }
                .modal-btn{
                    border: none;
                    padding: 17px 20px;
                    text-decoration: none;
                    display: inline-block;
                    position: relative;
                    top: 0;
                    width: 100%;
                    background: #FD7663;
                    border-radius: 10px;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 12px !important;
                    text-align: center;
                    color: #FFFFFF;
                    height: 50px;
                }
                .copyBtn{
                    background: #333739;
                    border: 1px solid #FD7663;
                    color: #FD7663;
                }
            }
        }
        .camHeader {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-bottom: none;
            padding: 30px 30px;
            .close{
                margin-top: 0rem !important;
                color: #5D6569;
                font-weight: 300;
                font-size: 35px;
                position: absolute;
                top: 22px;
                right: 40px;
                padding: 0;
                box-shadow: none;
                text-shadow: none;
                height: 25px;
                width: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                line-height: 1;
                opacity: 1;
                z-index: 999;
                span{
                    line-height: 1;
                }
            }
        }
        .camFooter {
            background-color: transparent;
            height: auto;
            border-bottom-right-radius: 1rem;
            border-bottom-left-radius: 1rem;
            border: none;
            padding: 0;
        }
    }
    #noTextArea {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
        pointer-events: none;
    }
    .Overlay{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
    }
  }