.modal-dialog.end-Premium-Modal {
  max-width: 450px;
}

.modal-content {
width: auto !important;
height: auto !important;
}

.EditInfo-Modal{
  .chat-modal-body.modal-body {
    width: 75%;
    margin: 0 auto;
    padding: 25px 0 ;
    .input-group{
      input.form-control{
        width: 100%;
        border-radius: 6px;
        height: 42px;
        font-size: 14px;

      }
      .eye-icon {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 9;
        svg {
          width: 25px;
          height: 25px;
          fill: #f77563;
        }
      }
    }
    .doneBtn{
      font-weight: 600 !important;
    }
  }
}
.doneBtn{
  font-weight: 600 !important;
  margin-top: 10px;
}
.radioButton{
text-align: left;
white-space: nowrap;
  .inputReportInput {
      margin-right: 5px;
  }
}
.modal-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  position: absolute !important;
  min-width: 350px;
}
.EditInfo-Modal {   
  .react-tel-input {
    .form-control {
      width: 100%;
    }
    .country-list {
      top: 24px;
      left: 0;
      .country {
        text-align: left;
      }
    }
  }
  
  .modal-body {
    text-align: center;
    .fa.fa-phone {
      font-size: 50px;
      color: #f07563;
    }
  }
  .modal-content {
      position: relative !important;
      height:auto !important;
      font-size: 14px !important;
      line-height: 20px;
      font-weight: 500;
      font-family: var(--font-family) !important;
      background-color: var(--dark);
      border-radius: 1em;
  }
  
  .chat-modal-header {
      border-bottom: none;        
      padding:0 1rem;

      img {
          opacity: 1 !important;
      }

      .close {
          margin-top:0rem !important;
          color:#C4C4C4;
          font-weight: 300;
          font-size: 28px;
          padding-top: 7px;
          position: absolute;
          top: 0px;
          right: 15px;
          outline: none;
      }
  }
  .modal-center-img {
      width: 115px;
      margin: 15px auto 0px auto;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
  }
  .modal-title {
      flex: 1 1 100%;        
      text-align: center;
      margin:0 !important;
  }
  h4 {
      font-size: 18px;       
      font-weight: 700 !important;
      margin-bottom: 0.3em;
  }
  p{
      font-weight: 400;
      opacity: 0.8;
  }
  .chat-modal-body {
      .form-group {
          margin-bottom: 0px !important;
      }
  }
 
  .chat-modal-footer {
      background-color: var(--primary);
      min-height: 60px;
      position: relative;
      padding:0 1rem;
      justify-content: center;
      border-bottom-right-radius: 1em; 
      border-bottom-left-radius: 1em;
      
      p {
          cursor: pointer;
          color:white;
          margin-top:25px;
          font-size:12px !important;
          text-decoration: underline;
          font-family: var(--font-family) !important;
          width: 100%;
          text-align: center;
          font-weight: 600;
      }
  }    
}

.modal-backdrop.show {
  opacity: 0.3 !important;
}
.modal {
  background: transparent !important;
}
body.modal-open #root {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}

.InputContainer {
  display: grid;
    text-align: left;
    margin-bottom: 15px;
    &:last-child{
      margin-bottom: 0;
    }
}

.validation-error {
  color: #dc3545 !important;
  margin-top: 4px;
}

.validation-success {
  color: #26d059 !important;
  margin-top: 4px;
}

.validation-server-error {
    color: #db1a2e !important;
    margin-top: 10px;
    font-weight: bold;
}
.validation-server-success {
  color: #26d059 !important;
  margin-top: 10px;
  font-weight: bold;
}