.not-found-page {
    min-height: 450px;
    .text {
        font-size: 18px;
        margin-bottom: 10px;
        color: #3b414a;
        text-align: center;
    }
    .heading {
        font-size: 86px;
        color: #3b414a;
        text-align: center;
        font-weight: 700;
        svg {
            height: 95px;
            width: 100%;
        }
    }
}