.customCard.reviewCard{
  margin-bottom: 1rem !important;
  height: auto;
  &.engagementCard{
    .engage-score-head{
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      h2{
        margin-bottom: 0;
      }
      svg{
        margin-left: 10px;
        position: relative;
        top: -1px;
      }
    }
    .card-body{
      padding:0;
      max-height: max-content;
      height: auto;
    }
    .column-heads{
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      text-align: center;
      letter-spacing: -0.03em;
      text-transform: capitalize;
      color: #FD8876;
      span.board{
      margin-right: 15px;
      }
      span.bonus{
        margin-right: 35px;
      }
    }
    .selfRankingCard{
      position: unset;
      border-radius: 0;
    }
    .ranking-note{
      position: unset;
    }
  }
  .customCardHeader {
    cursor: pointer;
    button {
      padding: 0px !important;
      color: none !important;
      border: none !important;
      border-style: none !important;
      font-weight: bold;
    }
    .bg-none{
      background-color: transparent !important;
    }
  }

  .card-body{    
    overflow: hidden;
    overflow-y: auto;
    max-height: 250px;
  }
  .reviewDetails{
    padding: 15px 0;
    margin: 0px;
    border-bottom: none !important;
    padding-bottom: 0 !important;
    .ranks {
      margin-right: 10px;
      background: transparent;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      span{
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 1;
        letter-spacing: -0.03em;
        color: #FF6D56;
        margin-top: 3px;
      }
    }
    .reviewHead{
      font-size: 1rem;
      font-weight: 300;
      text-transform: capitalize;
      h6{
        font-size: 1rem;
      font-weight: 300;
      }
    }
    .reviewRating{
      padding-right: 20px;

      svg {
        .cls-1 {
          fill-rule: evenodd;
        }
        height: 15px;
        width: 15px;
        margin-right: 4px;
      }
    }
    .reviewDesc{
      color: #fff;
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 18px;
      opacity: 0.6;
    }
    &.engagementListRow{
      padding: 6px 0px;
      margin: 0 25px;
      .hostName{
        font-size: 14px;
        line-height: 16px;
      }
      .reviewHead {
        h6{
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: -0.02em;
          color: #F2F2F2;
        }
        span{
          font-size: 10px;
          line-height: 13px;
          letter-spacing: -0.03em;
          color: #BDBDBD;

        }
      }
      &:last-child{
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
  .info_section {
    position: absolute;
    background: #333739;
    border: 1px solid rgba(89, 94, 104, 0.8);
    box-sizing: border-box;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 20px;
    left: -35px;
    width: 300px;
    z-index: 99;
    top: 50px;
    .winner-text{
      font-size: 12px;
      line-height: 17px;
      color: #FFFFFF;
      opacity: 0.9;
      margin-bottom: 6px;
    }
    .dots{
      font-size: 12px;
      line-height: 15px;
      color: #B9B9B9;
      position: relative;
      margin-bottom: 6px;
      &:before{
        content: "";
        height: 5px;
        width: 5px;
        position: absolute;
        background: #f07563;
        border-radius: 50%;
        left: -12px;
        top: 4px;
      }
    }
  }
  .no-data-available{
    display: flex;
    min-height: 250px;
    align-items: center;
    justify-content: center;
  }
  .no-data-available.engagement_No_data{
    min-height: 100px;
  }
  .mr-10{
    margin-right: 10px;
  }
  .pl-12{
    padding-left: 12px;
  }
}

.review-profile{
  border-radius: 50%;
  border: 1px solid #504d4e;
  width: 40px;
  height: 40px;
}
.ranking-note{
  font-weight: 600;
  font-size: 11px;
  line-height: 14px;
  text-align: center;
  padding: 6px;
  margin: 0;
  letter-spacing: -0.03em;
  color: #333333;
  background-color: #F2F0F3;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  position: relative;
  top: 16px;
}

.selfRankingCard {
  display: flex;
  justify-content: space-between;
  background: #f07563;
  margin: 0px!important;
  padding: 5px 20px!important;
  position: absolute;
  width: 100%;
  bottom: 20px;
  color: #333;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-weight: bolder;
}
.no-rank-para {
  font-size: 11px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  padding: 0;
  background: #f07563;
  color: #333;
}