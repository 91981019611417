.customCard.systemMsg{
  svg {
    .cls-1 {
      fill-rule: evenodd;
    }
    height: 36px;
    vertical-align: text-bottom;
    width: 36px;
  }
  height: auto;
  .card-body{    
    overflow: hidden;
    overflow-y: auto;
    max-height: 572px;
    min-height: 572px;
    .notificationBox{
      border-bottom:1px solid #ffffff1a;
      .notificationMnotificationBox sg{
        line-height: 300;
        padding: 6px 10px 9px;
      }
      .pera{
        font-size: 0.875rem;
        opacity: 0.6;
        font-weight:300;
        margin-top: 3px;
        margin-bottom: 0;
        p{
          margin-bottom: 0;
          line-height: 18px;
        }
      }
      h6{
        // color: #6c757d;
        font-size: 1rem;
        font-weight: 300;
        text-transform: capitalize;
      }
      .latestNotificationText{
        // color: #212529;
      }
    }
  }
 .pl-12{
   padding-left: 12px;
   max-width: calc(100% - 48px);
 }
 .mr-10{
   margin-right: 10px;
 }
 .notificationBox {
   margin-left: 0;
   margin-right: 0;
   & > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
   }
 }
}
.customBadge{
  width: 26px !important;
  height: 26px !important;
  border-radius: 50% !important;
  display: inline-flex !important;
  align-items: center ;
  justify-content: center;
  padding: 0;
  background-color: #FBE4E7 !important;
  margin-left: 10px !important;
}