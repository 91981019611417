.AffiliateEarningCard{
    border-radius: 10px;
    .perf-cred{
        color: #2D313A;
        font-size: 1.25rem;
        text-transform: uppercase;
        letter-spacing: -0.05em;
        h3{
            font-size: 2.5rem;
            font-weight: 700;
            margin-bottom: 0;
            line-height: 30px;
        }
    }
    .perf-amount{
        font-weight: 800;
        font-size: 20px;
    }
    
}
.sendText{
    font-size: 22px;
    line-height: 24px;
    opacity: 0.9;
    span{
        color: var(--primary);
        display: inline-block;
        border: 1px solid var(--primary);
        border-radius: 50%;
        width: 16px;
        height: 16px;
        font-size: 14px;
        line-height: 16px;
        margin-left: 5px;
    }
}
.linkText{
    font-size: 1rem;
    line-height: 24px;
    a{
        color: var(--primary);
        font-weight: bold;
        opacity: 0.9;
    }
}
.aff-card-heading{
    font-size: 1.125rem;
    font-weight: 600;
}
.popperClassName,
.innerClassName{
    width: 280px !important;
    max-width: 280px !important;
    padding: 0 !important;
    border-radius: 0;
    background-color: var(--dark) !important;
    text-align: left !important;
}
.opacity{
    opacity: 1 !important ;
    border: 1px solid #595E68;
    padding: 0 !important;
    border-radius: 10px;
}
.arrowClassName:before,
.arrowClassName:before{ 
    border-left-color: var(--dark) !important;
}
.quetionHiddenWrp{
    width: 280px;
    border-radius: 10px;
    ul{
        padding-left: 13px;
        li{
            color: var(--primary);
           span{
            font-weight: 300;
            font-size: 0.75rem;
            opacity: 0.6;
            color: var(--white);
            display: block;
           }
        }
    }
    .payout{
        font-size: 0.75rem;
        font-weight: bold;
        line-height: 18px;
        border-bottom: 2px solid var(--primary);
        padding-bottom: 10px;
        display: inline-block;
    }
    .supportText{
        color: #B9B9B9;
        opacity: 0.8;
        font-size: 0.75rem;
        line-height: 10px;
        font-weight: 300;
    }
}
