.leftCol{
    height: 100vh;
    background-image: url('../../assets/images/arousrgirls-splash(1).jpg');
    background-size: cover;
    background-repeat: no-repeat;
}
.performer-login-base{
    height: 100vh !important;
    overflow-y: auto;
}
.performer-login{
    max-width: 492px;
    .loginBtn{
        border-radius: 10px;
        color: #fff;
        font-size: 0.875;
        font-weight: 900;
        height: 50px;
        text-transform: capitalize;
    }

    .perf-login-head {
        h3{
            font-size: 1.875rem;
            line-height: 46px;
            opacity: 0.85;
            font-weight: 700;
        }
        p{
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 31px;
            opacity: 0.6;
        }
    }
    .formWraper{
        .form-control{
            background-color: var(--dark);
            border-color: var(--dark);
            font-weight: 800;
            font-size: 0.875rem;
            line-height: 21px;
            color: var(--white);
        }
        .form-group{
            padding-bottom: 19px;
            margin-bottom: 0;
            position: relative;
            .form-control{
                & + div {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                }
            }
            label{
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                color: #B9B9B9;
            }
        }
        .perf-login-password{
            position: relative;
            .passwordEye{
                position: absolute;
                top: 40px;
                right: 10px;
                color: #f07563;
            }
        }
    }
}
