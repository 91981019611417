.sharePrivateMedia-modal{
    .modal-content{
        border-radius: 1rem;
        height: auto;
        background-color: var(--dark);
        .modal-body {
            width: 100%;
            &.paid-pic-modal{
                .image-wrap{
                    text-align: center;
                    img{
                        max-width: 360px;
                        width:100%;
                        max-height:300px;
                        object-fit: contain;
                    }
                    video{
                        max-height:300px;
                    }
                }
                .modal-btn{
                      margin: 0 !important;
                      border: none;
                      padding: 17px 20px;
                      text-decoration: none;
                      display: inline-block;
                      position: relative;
                      top: 0;
                      width: 100%;
                      background: #FD7663;
                      border-radius: 10px;
                      font-weight: 700;
                      font-size: 16px;
                      line-height: 12px !important;
                      text-align: center;
                      color: #FFFFFF;
                      height: 50px;
                }
                #creditsLabel{
                    color: white;
                    background: #151516;
                    border: unset;
                }
                .custom-control-label{
                    box-shadow: unset;
                    left: -16px;
                    &:before{
                        left: -2.25rem;
                        width: 50px;
                        height: 24px;
                        pointer-events: all;
                        border-radius: 15px;
                        top: 2px;
                        border-color: transparent;
                        transition: unset !important;
                        box-shadow: unset !important;
                    }
                    &:after{
                        height: 20px;
                        width: 20px;
                        top: 4px;
                        border-radius: 50%;
                        transition: unset !important;
                    }
                }
                .custom-control-input:checked ~ .custom-control-label::after{
                    transform: translateX(1.5rem);
                }
                .custom-control-input:checked ~ .custom-control-label::before{
                    background: #3BA520;
                }
            }
        }
        .camModel {            
            .chat-modal-body {
                position: relative;
            }
            .canvas {
                position: absolute;
                top: 16px;
                left: 20px;
                width: 333px;
                max-height: 250px;
                #canvas.active {
                    visibility: visible;
                }
            }
            .videoElement {
                width: 100%;
                max-height: 250px;
            }
            .no-camera {
                width: 100%;
                max-height: 250px;
                text-align: center;
            }
            .modal-btn {
                background: #3B4149;
                color: #fff;
                border: none;
                min-width: 120px;
                padding: 10px;
                border-radius: 15px;
              //   margin: 0 auto;
                display: block;
                position: relative;
                top: 30px;
                box-shadow: 2px 14px 28px 1px rgba(0, 0, 0, 0.28);
                text-transform: uppercase;
                font-weight: 500;
                letter-spacing: 1px;
                cursor: pointer;
            }
        }
        .camHeader {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom: none;
          padding: 30px 30px;
          .close{
              margin-top: 0rem !important;
              color: #5D6569;
              font-weight: 300;
              font-size: 35px;
              position: absolute;
              top: 35px;
              right: 40px;
              padding: 0;
              box-shadow: none;
              text-shadow: none;
              height: 25px;
              width: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              line-height: 1;
              opacity: 1;
              span{
                  line-height: 1;
              }
          }
      }
        .camFooter {
              background-color: transparent;
              height: auto;
              border-bottom-right-radius: 1rem;
              border-bottom-left-radius: 1rem;
              border: none;
              padding: 0;
          }
          .btn-group{
          display: flex;
          width: 100%;
          margin: 25px auto 0;
          justify-content: center;
          flex-wrap: nowrap;
          }
    }
    @media(max-width:767px){
            .modal-content {
                .modal-body.paid-pic-modal {
                    .image-wrap {
                        img{
                            object-fit: unset;
                            width: 250px;
                            height: 300px;
                            max-width: 300px;
                            max-height: 300px;
                        }
                    }
                }
            }
    }
  }