
.CustomerReview-Modal {
  max-width: 360px !important;   
  .react-tel-input {
    .form-control {
      width: 100%;
    }
    .country-list {
      top: 24px;
      left: 0;
      .country {
        text-align: left;
      }
    }
  }
  .rating-error {
    color: red;
    font-size: 14px;
    text-align: center;
    margin: 5px 0px;
  }
  .contactErrorMsg {
    text-align: left;
    display: block;
    padding: 5px 0 0;
    color: #cc4646;
  } 
  .modal-body {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    padding: 1rem 0;
    .fa.fa-phone {
      font-size: 50px;
      color: #f07563;
    }
  }
  .modal-content {
      position: relative !important;
      height:auto !important;
      font-size: 14px !important;
      line-height: 20px;
      font-weight: 500;
      // font-family: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"';
      // color: #3b414a;   
      font-family: var(--font-family) !important;
      background-color: var(--dark);
      border-radius: 1em;
      top:70px;
  }
  
  .chat-modal-header {
      border-bottom: none;        
      padding:10px 1rem;

      img {
          opacity: 1 !important;
      }

      .close {
          margin-top:0rem !important;
          color:#C4C4C4;
          font-weight: 300;
          font-size: 28px;
          padding-top: 7px;
          position: absolute;
          top: 0px;
          right: 15px;
      }
  }
  .modal-center-img {
      width: 115px;
      // height: 47px;
      margin: 15px auto 0px auto;
      position: relative;
      // border-radius: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      // border: 2px solid #ffffff;
      // -webkit-box-shadow: 2px 14px 14px -11px rgba(0, 0, 0, 0.98);
      // -moz-box-shadow: 2px 14px 14px -11px rgba(0, 0, 0, 0.98);
      // box-shadow: 2px 14px 14px -11px rgba(0, 0, 0, 0.98);
  }
  .modal-title {
      flex: 1 1 100%;        
      text-align: left;
      margin:0 !important;
      font-size: 16px;
  }
  h4 {
      font-size: 18px;       
      font-weight: 700 !important;
      margin-bottom: 0.3em;
  }
  p{
      font-weight: 400;
      opacity: 0.8;
  }
  .chat-modal-body {
      button.review-submit-btn {
        width: 100% !important;
        background-color: #ff7066;
        color: white !important;
        border-radius: 10px !important;
        height: 40px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-family: var(--font-family) !important;
        font-weight: 400;
        margin-top: 15px
      }
      .form-group {
          margin-bottom: 35px !important;
      }
      .img_with_crown {
        position: relative;
        width: 50px;
        margin: 0 auto;
      }
      .img_with_crown img.is_premium_customer {
        position: absolute;
        left: -5px;
        top: 0;
      }
      .user-name {
        margin: 10px 0;
        display: block;
      }
  }
 
     
}

.modal-backdrop.show {
  opacity: 0.3 !important;
}
.modal {
  background: transparent !important;
}
body.modal-open #root {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}
