.logout_mobile_on_roulletteoff {
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    text-align: center;
}