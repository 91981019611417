.myTable{
    border-radius: 10px;

    .iframe_video_container {
        position: relative;
        width: 65px;
        overflow: hidden;
        height: 65px;
    }

    .video_overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100px;
        height: 100%;
        background: rgba(0,0,0,0.1);
    }

    table{
        border: 0;
        thead{
            th{
                color: #fff !important;
                opacity: 0.6;
                font-weight: 800;
                font-size: 0.875rem;
                line-height: 17px;
                border-top:0 ;
                border-left:0 ;
                border-right:0 ;
                border-bottom-color: #ffffff26 !important;
                &:first-child{
                    padding-left: 0 !important;
                }
            }
        }
        tbody{
            td{
                border-top:0 ;
                border-left:0 ;
                border-right:0 ;
                border-bottom-color: #ffffff26 !important;
                color: #fff;
                font-size: 0.875rem;
                vertical-align: middle;
                .fileColumn{
                    display: inline-flex;
                    align-items: flex-start;
                    flex-direction: column;
                    .profileImg{
                        margin-right: 1em;
                        img{
                            object-fit: cover;
                            border-radius: 5px;
                        }
                    }
                    p.mb-0{
                        font-size: 0.95rem;
                        font-weight: 600;
                        line-height: 1.4;
                        word-break: break-all;
                    }
                    .userId{
                        font-size: 0.875rem;
                        opacity: 0.5;
                        margin-bottom: 0;
                    }
                    .copyText{
                        text-transform: capitalize;
                        font-size: 12px;
                    }
                }
                .descriptionWrp{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    & > p{
                        margin-right: 20px;
                        margin-bottom: 0;
                        word-break: break-all;
                    }
                    button{
                        min-width: 65px;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 15px;
                        height: 34px;
                        text-transform: capitalize;
                    }
                }
                &:first-child{
                    padding-left: 0 !important;
                }
            }
        }
    }
   
}
.mobileColumn{

    @media (max-width:1098px){
        flex-direction: column;
        button:first-child{
            margin-bottom: 5px;
            margin-right: 0 !important;
        }
    }
}
.mediaTable{
    thead{
        th{
            &:last-child{
                @media (max-width:1098px){
                    width: 34% !important;
                }
            }
        }
    }
}
.addMediaWraper{
    border-radius: 10px;
    .arrowBtn{
        &:hover{
            background-color: transparent !important;
            box-shadow: none !important;
        }
        &:focus{
            background-color: transparent !important;
            box-shadow: none !important;
        }
    }
}
.myForm{
    .form-group{
        label{
            font-size: 0.75rem;
            color: #B9B9B9;
        }
        input,textarea,select{
            background-color: #333739;
            border-color: #333739;
            color: #fff;
            font-size: 0.875rem;
            border-radius: 10px;
            &::placeholder{
                color: #666666;
            }
        }
        input,select{
            min-height: 44px;
        }
        textarea{
            min-height: 82px;
        }
    } 
}
.doneBtn{
    width: 230px;
    border-radius: 10px;
    height: 50px;
    margin-top: 2em;
}
.pagination{
    justify-content: flex-end;
}

.no-data-available {
    display: flex;
    align-items: center;
    justify-content: center;
}
.priceHeader{
    width: calc(28% - 87px);
    @supports (-webkit-marquee-repetition:infinite) and (object-fit:fill) {
        width: 20%;
    }
}