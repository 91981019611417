$body-bg: #000;
$body-color: #fff;
$theme-colors: (
  "primary": #fd7663,
  "dark":#292A2B
);

$btn-border-radius: 30px;

:root {
    --font-family:'Overpass', sans-serif ;
}
body{
    font-family: var(--font-family) !important;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3E3F40;
  border-radius: 30px
}
.minDeskTopHeight{
  // min-height:calc(100vh - 90px);
  min-height: calc(100% - 80px);
  .back-btn{
    border: 1px solid #FD7663;
    box-sizing: border-box;
    border-radius: 100px;
    width: 100px;
    height: 45px;
  }
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3E3F40;
}
@import "~bootstrap/scss/bootstrap";
.bg-none{
  background-color: unset !important;
}
.modal-content {
  border-radius: 10px;
}
.btn-outline-primary:hover,.btn-outline-primary:focus {
  color: var(--white);
}
.form-control{
  border-radius: 5px;
}
.card{
  border-radius: 10px;
}
.rowspacing-20{
  margin-left: -10px !important;
  margin-right: -10px !important;
  & > div  {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.rowspacing-20{
  margin-left: -10px !important;
  margin-right: -10px !important;
  & > div {
      padding-left: 10px;
      padding-right: 10px;
  }
}
.btn{
  line-height: 1 !important;
}
.btnTextalignment{
}
.pagination{
  .page-item.disabled{
    svg{
      stroke: #292a2b;
    }
  }
  .page-item:not(.disabled){
    svg{
      stroke: var(--primary);
    }
  }
  li.active{
    button{
      color: var(--primary) !important;
      font-weight: 700 !important;
    }
  }
}
.dropzone{
  p{
    font-size: 1rem;
    opacity: 0.6;
    font-weight: 500;
  }
}
.tablefirstColSpacing{
  thead{
    th{
      &:first-child{
        padding-left: 0 !important;
      }
    }
  }
  tbody{
    td{
      &:first-child{
        padding-left: 0 !important;
      }
    }
  }
}
select{
  background-image: url('../svgs/dropdawnarrow.svg');
  background-repeat: no-repeat;
  background-position: center right 15px;
  appearance: none;
  -moz-appearance: none;
-webkit-appearance: none;
font-weight: 600;
}
.btn.btn-primary{
  font-weight: 800;
}