.private-gallery-grid{
  display: flex;
  flex-wrap: wrap;
}

.privateModal{
  .modal-content{
    background-color: #333739;
    .modal-header{
      border-bottom: 0;
      padding-bottom: 0;
      h5{
          font-size: 18px;
      }
      .close{
          background-image: url('../../../../assets/svgs/closeIcon.svg');
          font-size: 0;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 0;
          margin-top: 0;
      }
    }
    .modal-body {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      .private-gallery-grid {
        display: flex;
        flex-wrap: wrap;
        max-height: 433px;
        overflow-y: auto;
      }
      .privatePhoto {
        // padding:1.5px;
        button{
          padding: 0;
          background: #000;
          border: 0;
          height: 144px;
          box-sizing: border-box;
          border: 1.5px solid #333739;
          &:focus{
            outline: 0;
            border: 1.5px solid var(--primary);
          }
          img{
            width: 144px;
            height: 144px;
            object-fit: contain;
          }
        }
      }
    }
    .height-250{
      height: 250px;
    }
    .modal-footer{
      border-top: 0;
      padding-top: 0;
      justify-content: center;
      button{
        border-radius: 10px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        width: 230px;
        height: 50px;
      }
    }
  }
}