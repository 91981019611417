.chatlog-card-wrapper{
    display: flex;
    height: 600px;
    .chatLogMsgs{
        object {
            border-radius: 15px 15px 0;
            color: #3a414b;
            font-size: 13px;
            margin: 0;
            border: 1px solid #999;
            box-shadow: none;
            width: 265px;
            height: 223px;
            object-fit: contain;
            display: block;
        }
        img{
            border-radius: 15px 15px 0;
            color: #3a414b;
            font-size: 13px;
            margin: 0;
            box-shadow: none;
            width: 265px;
            height: 223px;
            object-fit: contain;
            &.placeholder-obj{
                width: 255px !important;
            }
        }
        .emoji-reaction-wrap {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #282a2b;
            -webkit-filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.12));
            filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.12));
            border-radius: 50px;
            border: 1.2px solid #fff;
            margin-top: -6px;
            .left-emoji-reaction {
                width: 24px;
                height: 24px;
                border-radius: 50px;
            }
        }
    }
    .no-convo-wrap{
        flex-direction: column;
        padding-top: 150px;
        p{
            margin-top: 20px;
            color: #666C73;
        }
    }
    .col-lg-5{
        margin-right: 20px;
    }
    .customer-name{
        font-family: Overpass;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: -0.05em;
        color: #FFFFFF;
        margin-left: 20px;
    }
    .card{
        background-color: #292a2b !important;
        padding: 0;
        .card-header{
            .cust_img_with_crown {
                position: relative;
                img.is_premium_customer {
                    position:absolute;
                    left: 0;
                    top: -20px;
                }
                .online-small-icon {
                    width: 13px !important;
                    height: 13px !important;
                    display: inline-block;
                    position: absolute;
                    top: 24px;
                    right: 5px;
                    background-color: #2eb130;
                    border-radius: 50%;
                    border: 1px solid #ddd;
                }
                .online-away-icon {
                  width: 13px !important;
                  height: 13px !important;
                  display: inline-block;
                  position: absolute;
                  top: 24px;
                  right: 5px;
                  background-color: #ECAC19;
                  border-radius: 50%;
                  border: 1px solid #ddd;
              }
                .offline-small-icon {
                    width: 13px !important;
                    height: 13px !important;
                    display: inline-block;
                    position: absolute;
                    top: 24px;
                    right: 5px;
                    background-color: #9fa09f;
                    border-radius: 50%;
                    border: 1px solid #ddd;
                }
            }
            img{
                border-radius: 50%;
            }
        }
        .Sys2Char , .Sys2Cust, .SESSION_CMD{
            padding: 0;
            display: block;
            .message{
                text-align: left;
                display: block;
                background: #666666;
                border-radius: 10px;
                padding: 10px 25px;
                border-radius: 20px 25px 25px 5px;
                width: max-content;
                max-width: 550px;
                font-size: 13px;
                line-height: 17px;
                font-weight: normal;
                letter-spacing: -0.02em;
                color: #B9B9B9;
                background-color: #666;
            }
            .time{
                display: block;
                font-weight: normal;
                font-size: 11px;
                line-height: 13px;
                letter-spacing: -0.02em;
                color: #c5c5c5;
                margin: 5px 0px 10px;
                .chat-status{
                    color: #c5c5c5;
                    font-size: 11px;
                    justify-content: flex-end;
                    padding: 2px;
                    .failed-msg {
                        color: #dc280e;
                    }
                }
            }
        }
        .Char2Cust , .Cust2Char{
            padding: 0;
            display: block;
            .message{
                border: 1px solid #666666;
                box-sizing: border-box;
                width: max-content;
                max-width: 550px;
                display: block;
                border-radius: 20px 25px 25px 5px;
                padding: 6px 15px;
                font-size: 13px;
                line-height: 17px;
                font-weight: normal;
                letter-spacing: -0.02em;
            }
            .time{
                display: block;
                font-weight: normal;
                font-size: 11px;
                line-height: 13px;
                letter-spacing: -0.02em;
                color: #c5c5c5;
                margin: 5px 0px 10px;
                .chat-status{
                    color: #c5c5c5;
                    font-size: 11px;
                    justify-content: flex-end;
                    padding: 2px;
                    .failed-msg {
                        color: #dc280e;
                    }
                }
            }
        }
        .Cust2Char {
            .message{
                background: transparent;
                color: #B9B9B9;
                padding: 10px 14px;
            }
            object{
                border-radius: 15px 15px 15px 0;
            }
        }
        .Char2Cust {
            text-align: right;
            .message{
                background-color: var(--primary);
                color: var(--white) !important;
                border-radius: 25px 20px 5px 25px !important;
                text-align: right;
                margin-left: auto;
                border: unset;
                padding: 10px 14px;
            }
            object{
                margin-left: auto;
            }
            .msgLogged{
                display: flex;
                justify-content: end;
            }
        }
        ul{
            max-height: 450px;
            overflow-y: scroll;
            overflow-x: hidden;
            padding: 0;
            list-style: none;
            li{
                padding: 15px 20px;
                display: flex;
                align-items: center;
                cursor: pointer;
                position: relative;
                &.activeChat{
                    background: #1D1D1D;
                }
                span.chatIcon{
                    background: black;
                    width: 40px;
                    height: 40px;
                    display: block;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    text-align: center;
                    line-height: 40px;
                    margin-right: 15px;
                }
                .date-wrap{
                    display: flex;
                    justify-content: space-between;
                    width: calc(100% - 40px);
                    align-items: center;
                    p{
                        margin: 0;
                        &.time{
                            font-size: 10px;
                            line-height: 15px;
                            color: #FFFFFF;
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
        .card-footer{
            text-align: center;
            button{
                background: #FD7663;
                border-radius: 10px;
                text-align: center;
                width: 250px;
                height: 50px;
                font-family: Overpass;
                font-style: normal;
                font-weight: bold;
                font-size: 15px;
                text-align: center;
                letter-spacing: -0.06em;
                text-transform: uppercase;
                color: #FFFFFF;
            }
        }
    }
}