.topHeaderRanking{
    svg{
        height: 35px;
        vertical-align: text-bottom;
        width: 35px;
        margin-right: 6px;
    }
    .cls-1 {
        fill-rule: evenodd;
    }
    span {
        font-size: 0.75rem;
        opacity: 0.8;
    }
    h5 {
        margin-top: 2px;
        margin-bottom: 5px;
        font-weight: 400;
            font-size: 1rem;
    }
    > .col{
        // border-right: 1px solid #cbccce;
        // padding-left: 45px;
        &:first-child{
            // padding-left: 15px;
        }
        &:last-child{
            border: none
        }
        @media (max-width:1366px) {
            // padding-left: 25px;
        }
    }
    .iconDiv{
        width: 32px;
    }
    .iconTextWrp{
        width: calc(100% - 32px);
        padding-left: 15px;
    }
}
