.ChatSection {
	min-height: 515px;
	// max-height: 515px;
}
// .ChatSection.ChatSectionCustList{
// 	max-height: 515px;
// }
.ChatSection.ChatSectionMsgArea{
	.height-700{
		.text-center{
			min-height: 395px;
			padding: 0;
			max-height: 395px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	} 
}
.chat-card{
	padding: 8px 0;
}
.active-bg-color {
	background-color: #000;
}
.conversion-header {
	.img_with_crown {
		position: relative;
		img.is_premium_customer {
			position: absolute;
			left: -5px;
			top: 0;
		}
		.online-small-icon {
			width: 13px !important;
			height: 13px !important;
			display: inline-block;
			position: absolute;
			top: 60px;
    		right: 5px;
			background-color: #2eb130;
			border-radius: 50%;
			border: 1px solid #ddd;
		}
		.offline-small-icon {
			width: 13px !important;
			height: 13px !important;
			display: inline-block;
			position: absolute;
			top: 60px;
			right: 5px;
			background-color: #9fa09f;
			border-radius: 50%;
			border: 1px solid #ddd;
			@media (max-width:767px) {
				top: 40px;
			}
		}
		.online-away-icon {
			width: 13px !important;
			height: 13px !important;
			display: inline-block;
			position: absolute;
			top: 60px;
			right: 5px;
			background-color: #ECAC19;
			border-radius: 50%;
			border: 1px solid #ddd;
		}
	}
	svg {
		&.avatar-image{
			margin: 15px 0 15px 10px;
			@media (max-width:767px) {
				margin-left: 0;
				width: 40px;
				height: 40px;
			}
		}
	}
	img{
		margin-left: 10px;
		margin: 15px 0 15px 10px;
		border-radius: 50%;
	}
	.switch{
        @media(max-width:767px){
            position: absolute;
            right: 18px;
            top: 33px;
        }
    }
}

.online-small-icon {
	width: 13px !important;
	height: 13px !important;
	display: inline-block;
	position: absolute;
	top: 40px;
	right: 17px;
	background-color: #2eb130;
	border-radius: 50%;
	border: 1px solid #ddd;
}
.offline-small-icon {
	width: 13px !important;
	height: 13px !important;
	display: inline-block;
	position: absolute;
	top: 40px;
	right: 17px;
	background-color: #9fa09f;
	border-radius: 50%;
	border: 1px solid #ddd;
}

.online-away-icon {
	width: 13px !important;
	height: 13px !important;
	display: inline-block;
	position: absolute;
	top: 40px;
	right: 17px;
	background-color: #ECAC19;
	border-radius: 50%;
	border: 1px solid #ddd;
}


.customerSearch {
		border-color: #333739 !important;
		border-radius: 7px !important;
		background-color: #333739 !important;
		color: #BCBCBC !important;
		background-image: url('../../../../assets/svgs/magnify.svg');
		background-repeat: no-repeat;
		background-position:left 10px center;
		padding-left: 37px !important;
		font-size: 14px !important;
		font-weight: 400 !important;
	}
.CustomerListHeader {
  text-align: center;
  margin: auto;
  padding: 18px 0px !important;
}
.chat_date {
	font-size: 11px;
	float: right;
	color: #afafaf;
	font-weight: 300;
	min-width: 90px;
}
.chat_img {
	position: relative;
	svg{
		border-radius: 50%;
	}
	img{
		border-radius: 50%;
	}
	img.is_premium_customer {
		position: absolute;
    left: 6px;
    top: 0;
	}
}
.numberOfMsg{
	background-color: var(--white) !important;
	color: var(--primary) !important;
	min-width: 1rem;
	min-height: 1rem;
	padding: 0 !important;
    justify-content: center;
    align-items: center;
    display: inline-flex !important;
	line-height: 1 !important;
	padding-top: 4px !important;
}
.lastmsg {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 19px;
}
.SenderName{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 19px;
}
.cust-list{
	height: 350px;
	overflow-y: auto;
	overflow-x: hidden;
	@media screen and (max-width: 767px) {
	    height: calc(100vh - 225px) !important;
		min-height: calc(100vh - 225px) !important;
		max-height: calc(100vh - 225px) !important;
	}
}
.chat-list{
	width: 60px;
	margin: 0 auto;
	&.chat_img img.is_premium_customer{
		left: -1px;
	}
	.offline-small-icon{
		right: 8px;
	}
	.online-small-icon{
		right: 8px;
	}
	.online-away-icon {
		right: 8px;
	}
}
@media screen and (max-width: 767px) {
	.ChatSection.bg-dark.h-100 {
	    height: calc(100vh - 150px) !important;
		min-height: calc(100vh - 150px) !important;
		max-height: calc(100vh - 150px) !important;
		border-bottom-left-radius: 30px;
		border-bottom-right-radius: 30px;
		margin: 0 !important;
	}
	.customerlist-main-wrap{
		padding-left: 0 !important;
	}
}