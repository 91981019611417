.customCard.Credits-customCard{
  .cardAmount{
    font-size: 1.75rem;
    font-weight: bold;
    line-height: 35px;
    position: relative;
    sub {
      position: absolute;
      bottom: 15px;
      margin-left: 2px;
      small {
        font-weight: bold;
      }
    }
    @media (max-width:1280px){
      font-size: 1.3rem;
    }
  }
  .cardPera{
    font-weight: 500;
    font-size: 1rem;
    line-height: 12px;
    @media (max-width:1280px){
      font-size: 0.8rem;
    }
  }
  .cardSubAmnt{
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    line-height: 20px;
  }
  .balanceCard{
    min-height: 100px;
    border-radius: 10px !important;
    max-width: 520px;
    margin-left: auto;
    .flex-1{
      flex: 1;
    }
    .balance-card-row{
      position: relative;
      top: -15px;
    }
    .how-wepay-link {
      position: absolute;
      right: 10px;
      bottom: -15px;
      cursor: pointer;
      font-size: 12px;
    }
    .cardBtn {
      padding-left: 0 !important;
      padding-right: 0 !important;
      text-align: center;
      width: 100%;
      max-width: 130px;
      &.request-pay{
        position: relative;
        left: -30px;
        top: -2px;
        margin-bottom: 5px !important;
      }
    }
  }
  .refreshIcon{
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .css-loader {
    position: absolute;
    right: 10px;
    top: 10px;
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 1px solid #292a2b;
    width: 15px;
    height: 15px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .btn{
    line-height: normal;
    border-radius: 5px;
    padding: 8px 6px;
    margin: 0 0 10px;
    min-width: unset !important;
    margin-bottom: 0 !important;
  }
  .cardBtn{
    font-size: 0.875rem !important;
    font-weight: normal !important;
    @media (max-width:1350px){
    font-size: 0.775rem !important;
    padding-left: 14px !important;
    padding-right: 14px !important;
    }
  }
  .Credits_btn{
    margin-top: -30px;
    .btn{
      &:hover{
        background: #3a414b;
      }
    }
    .btn-secondary{
      background: #3a414b;
    }
  }
  .card-body{
      padding: 0 15px;
      .credits-no{
        background: #eee;
        padding: 4px 8px;
        margin-bottom: 15px;
        margin-left: -5px;
      }
  }
  .creditsHeader{
    img{
      margin-left: 5px;
      width: 14px;
      transform: scaleX(-1) rotate(60deg);
    }
  }
}
.mb-10{
  margin-bottom: 10px !important;
}

.width-15{
  width: 15px;
}

.cursor-pointer {
  cursor: pointer;
}
ul.tabbedLink{  
    display: inline-flex;
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    margin-bottom:0;
    font-weight: 500;
      li{
        margin-right: 10px;
        font-size: 1rem;
      }
      :hover{
        cursor: pointer;
        color:#9d2731;
        text-decoration: underline;
      }
      .active{
        cursor: pointer;
        font-weight: 600;
      }
  }

  .bottomPagination .page-link:hover{
    background-color: #3a414b;
    color:var(--white);
  }
  .tableWidthController {
    width: 100% !important;
    .react-bootstrap-table {
      overflow-x: scroll;
      // max-width: 951px;
      max-width: calc(100vw - 342px);
    }

  }
  .balanceTabs{
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: -8px;
    & > ul{
      & > li {
        height: 55px;
        display: inline-flex;
        align-items: center;
        padding: 0 13px;
        color: var(--white);
        opacity: 0.3;
        min-width: 165px;
        justify-content: center;
        &.active{
          background-color: var(--dark);
          border-top-right-radius: 10px;
          border-top-left-radius: 10px;
          opacity: 1;
          color: var(--white);
          text-decoration: none;
        }
        &:hover{
          color: var(--white);
          text-decoration: none;
        }
      }
    }
  }
  .pagination{
    li{
      margin: 0 5px;
      button{
        background-color:transparent !important;
        border: none;
        color: var(--white) !important;
        &[name="previous"],&[name="next"]{
          color: var(--primary) !important;
        }
      }
      &.active{
        border: 1.5px solid var(--primary);
        border-radius: 3px;
        background-color: transparent !important;
        overflow: hidden;
        color: var(--primary) !important;
        font-weight: 500 !important;
      }
    }
  }
  .payout-bg-white{
    background: #ffffff;
    border-radius: 10px;
    font-weight: 700;
    color: #292a2b;
    padding: 12px 10px;
    margin-bottom: 20px;
      .border-bottom-style{
        border-bottom: 1px solid #d9d9d9;
        padding: 5px;
        margin-bottom: 10px;
        &:nth-child(3){
          border-bottom: unset;
          margin-bottom: 0px;
        }
      }
  }
  .modal-dialog.chat-modal.tip-Modal.height-disabled{
  .modal-content{
    height: unset !important;
  }
}
