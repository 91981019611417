.video-call {
  background-color: hsl(0, 0%, 0%);

  .cust-voice-media {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .img-with-crown {
      .profile-img {
        width: 100px;
      }
      .is_premium_customer {
        left: 12px;
      }
    }
  }
  .img-with-crown {
    position: relative;
    img.is_premium_customer {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .cust-img-rating-wrap {
    background-color: #212222;
    border-radius: 10px;
    padding: 7px;
    
  }
  .rating-error {
    font-size: 14px !important;
    color: red;
    margin: 5px 0;
    text-align: center;
  }
  .chat-videocall-customer-review {
    display: flex;
    align-items: center;
    padding: 0px 0px 10px;
    .chat-reviews {
      border-right: 0.6px solid #505050;
      padding: 0 10px;
      height: 45px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center; 
      position: relative;
      .rating_title, .rating_number {
        font-size: 12px;
      }
      .rating_number {
        position: relative;
        top: 4px;
      }
      .semicircle-container {
        position: relative;
        top: 5px;
      }
      .title_container {
        flex-direction: column;
        display: flex;
        align-items: center;
        position: relative;
        top: -14px;
      }
      &:last-child {
        border-right: none;
      }
      .rating-title {
        margin-right: 10px;
        font-family: 'Overpass';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 9px;
        letter-spacing: -0.03em;
        color: rgba(255, 255, 255, 0.8);

      }
      span{
        svg{
          margin-right: 2px;
        }
      }
    }
  }
  .report_underage_btn {
    position: fixed;
    left: 150px;
    bottom: 45px;
    border: 1px solid white !important;
    padding: 10px 15px;
    border-radius: 25px;
    font-size: 18px;
    background-color: transparent;
    color: #fff;
  }
  .report_underage_btn.normal-call-btn {
    left: 20px;
  }
  .skip_Call_wrap{
    position: fixed;
    bottom: 20px;
    left: 20px;
    display: flex;
    color: white;
    z-index: 999;
    align-items: flex-end;
    &.skip-btn-wrap{
      bottom: 45px !important; 
    }
    .skip-wrap{
      margin-right: 20px;
      .skip-btn {
        cursor: pointer;
        display: flex;
        background: #333739;
        width: 99px;
        padding-top:22px;
        padding-bottom:22px;
        justify-content:center;
        border-radius: 38px;
        border:1px solid #fff;
        img {
          margin-right: 10px;
        }
      }
      p{
        font-family: Overpass;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 10px;
        text-align: center;
        color: #FFFFFF;
        margin: 0;
        position: relative;
        top: 2px;
        @media(max-width:900px){
          font-size: 14px;
        }
      }
    }
    .callWrap{
      color: white;
      z-index: 999;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-content: flex-start;
      .paidCall{
        display: flex;
        padding: 16px 15px;
        background: #333739;
        font-size: 12px;
        background: #FD7663;
        border-radius: 10px;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        @media  (max-width:900px) and (orientation: landscape) {
          font-size: 10px;
          padding: 10px 6px;
        }
        img{
          margin-right:6px;
        }
      }
      .freeCall{
        padding: 16px 19px;
        background: #333739;
        font-size: 12px;
        background: #333739;
        border-radius: 10px;
        position: absolute;
        left: 0;
        top: -70px;
        @media  (max-width:900px) and (orientation: landscape) {
        font-size: 10px;
        padding: 12px 6px;
        }
        p{
          font-size: 12px;
          @media  (max-width:900px) and (orientation: landscape) {
            font-size: 10px;
          }
        }
      }
      p{
        margin: 10px 0 0 0;
        text-align: center;
        font-size: 12px;
        margin: 0;
        @media(max-width:900px){
          font-size: 10px;
        }
      }
    }
  }
  .payment-info-text{
    font-size: 12px;
    height: 25px;
    padding-top: 10px;
    display: flex;
    align-content: space-between;
    justify-content: space-around;
    align-items: stretch;
  }
  .performer-screen {
    .volumeIcon {
      cursor: pointer;
      width: 60px;
      height: 60px;
      text-align: center;
      color: #fff;
      font-size: 25px;
      background-color: #0000007a;
      padding: 8px;
      border-radius: 50%;
      transform: none;
      position: unset;
      bottom: 25px;
      left: 47%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      @media(max-width:900px){
        width: 35px;
        height: 35px;
        font-size: 18px;
        bottom:3%;  
      }
    }
    .volumeIcon.voice-call {
      cursor: pointer;
      width: 60px;
      height: 60px;
      text-align: center;
      color: #fff;
      font-size: 25px;
      background-color: #0000007a;
      padding: 8px;
      border-radius: 50%;
      transform: none;
      position: unset;
      bottom: 25px;
      left: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media(max-width:900px){
        width: 35px;
        height: 35px;
        font-size: 18px;
        bottom:3%;
      }
    }
    .fa-microphone-slash{
      color:#92A9B5;
    }
    .fullScreenIconWrap {
      cursor: pointer;
      width: 60px;
      height: 60px;
      text-align: center;
      color: #fff;
      font-size: 25px;
      background-color: #0000007a;
      padding: 8px;
      border-radius: 50%;
      transform: none;
      position: unset;
      bottom: 25px;
      left: 53%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 5px;
      @media(max-width:900px){
      width: 35px;
      height: 35px;
      font-size: 18px;
      bottom:3%;  
      left:55%
      }
      img{
        width: 60%;
      }
    }
    .fa.fa-volume-down.volumeIcon {
      padding: 8px 12px;
    }

    .fa.fa-volume-off.volumeIcon {
      padding: 8px 14px;
    }

    .input-range {
      height: 1rem;
      position: fixed;
      bottom: 40px;
      width: 15%;
      left: 48%;
      border: 1px solid #333;
      background-color: #333;
      padding: 10px;
      border-radius: 15px;

      .input-range__label {
        display: none;
      }

      .input-range__track--background {
        left: 16px;
        right: 10px;
      }
      .input-range__track {
        background-color: #888;
        height: 0.2rem;

        .input-range__track--active {
          background-color: #fff;
        }
      }

      .input-range__slider {
        background-color: #fff;
        border-color: #fff;
        height: 0.8rem;
        width: 0.8rem;
        margin-top: -0.4rem;
      }
    }
  }

  #remote-media-div {
    video {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      margin: 0;
      box-sizing: border-box;
      width: 100%;
      height: 100vh; 
      object-fit: contain;
      background-color: #1f1f1f;
    }
  }

  .chat-header {
    position: fixed;
    width: 100%;
    padding: 0px;
    z-index: 1;
    top:20px;
    align-items: end;
    @media(max-width:900px){
      padding: 0;
    }
  }


  .no-camera {
    margin: 20px;
    text-align: center;
  }

  .customerName {
    text-transform: capitalize;
    color: #fff;
    font-size: 20px;
    margin: 20px 0;
    @media(max-width:900px){
      margin:10px 0;
      font-size:14px;
    }
  }

  .closeChatIcon {
    cursor: pointer;
    padding: 0;
    font-size: 20px;
    border: 2px solid lightgrey;
    background: lightgrey;
    color: #fff;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .videoElement {
    background: aliceblue;
    position: fixed;
    width: 200px;
    right: 25px;
    bottom: 25px;
    @media(max-width:900px){
      width: 120px;
    }
  }

  .arousr_text_logo{
    border: 2px solid white;
    border-radius: 50%;
  }
  .performer-name{
    margin:0 0 0 15px;
    text-transform: capitalize;
    color: #fff;
    font-size: 18px;
    @media(max-width:900px){
      margin: 0 0 0 10px;
      font-size: 14px;
    }
  }
  .counterWrap{
    position: relative;
    right: 45px;
    text-align: right;
    @media(max-width:900px){
      right: 35px;
    }
    .close-btn{
      background: transparent;
      border: unset;
      outline: none;
    }
  }
  .timer-wrap {
    letter-spacing: 3px;
    padding: 10px 15px;
    border: 1px solid white;
    border-radius: 25px;
    color: #fff;
    margin: 20px 0 0 0;
    @media(max-width:900px){
      padding: 5px 8px;
    font-size: 14px;
    }
    .timer-icon{
      margin-right: 10px;
    }
  }
  #counter {
    color: #fff;
  }
  .arousr_logo {
    width: 180px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .profile-img{
    border: 2px solid white;
    border-radius: 50%;
    width: 60px;
    @media(max-width:900px){
      width: 45px;
    }
  }
  .call-loading-text {
    text-align: center !important;
    h3 {
      margin-top: 20px;
      font-weight: 300;
    }
    p {
      font-size: 18px;
      font-weight: 300;
    }
  }

  .arousr_logo_big {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 0;

    .arousr_logo {
      height: auto;
    }
  }

  .close-chat-button {
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    background: #535353;
    border-radius: 2rem;
    border: white 2px solid;
    line-height: 40px;
    font-size: 20px;
    text-transform: uppercase;
    width: 25%;
    justify-content: center;
    color: #fd7663;
    background: transparent;
    border: 2px solid #fd7663;
    color: #fd7663;
    text-align: center;
    padding: 15px;
    width: 100%;
  }
  .close-chat-button:hover {
    background: #fd7663;
    color: white;
  }

  .duration-info {
    text-align: end;
    border-right: white 1px solid;
    padding: 5px 15px;
  }

  .earned-info {
    text-align: start; 
    padding: 5px 15px;
  }

  .mt-20 {
    margin: 20px 0px;
    justify-content: center;
  }
}

.loading-screen {
  z-index: 9;
  background-color: #292929;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .call-loading-text {
    img {
        position: relative;
        top: -4px;
        height: 57px;
    }
    p {
      margin-bottom: 0;
      color: #dcdcdc;
      font-size: 14px;
    }
    h3 {
      color: #dcdcdc;
      opacity: 1;
    }
  }
}
.arousr_logo {
  width: 40px;
  height: 40px;
}

.c-888 {
  color: #888;
}
.cross-icon{
  width: 20px;
  height: 20px;
  opacity: 0.5;
  cursor: pointer;
}
.stop-icon{
  cursor: pointer;
}
.bar-seprate-line-wrap.videocall{
  transform: rotate(109deg) !important;
  z-index: 1;
  position: absolute;
  left: 76% !important;
  top: 20px !important;
  &.creativity{
    left: 74% !important;
  }
  .bar-seprate-line {
    height: 7px;
      width: 0.8px;
      background: #3E3E3E;
      position: absolute;
    }
    .bar-line-1{
      left: 11px;
      transform: rotate(186deg);
      top: 29.4px !important;
    }
    .bar-line-2{
      left: 1px !important;
      transform: rotate(43deg);
      top: 25px !important;
    }
    .bar-line-3{
      left: -4.55px;
      transform: rotate(88deg);
      top: 11px;
    }
    .bar-line-4{
      left: -1px;
      transform: rotate(125deg);
      top: 1px;
    }
  }
  .icons-container {
    display: flex;
    justify-content: center;
    position: fixed;
    width: 100%;
    bottom: 39px;
    left: 0;
    flex-direction: row-reverse;
  }
  .fav-icon-wrap-call {
    background: #0000008a;
    width: 60px;
    height: 60px;
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
    z-index: 999999;
    position: unset;
    bottom: 25px;
    left: 41%;
    transform: none;
  }
  .enable-fav svg path {
    fill-opacity: 1;
    stroke: #fd7663;
  }