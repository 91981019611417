.Notes-modal {
    max-width: 400px !important;
    .modal-content{
        .modal-body.paid-pic-modal{
            padding: 0 20px 20px;
            .modal-title{
                text-align: left;
                display: block;
                color: #FFF;
                font-family: Overpass;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.8px;
                margin-bottom: 20px;
                padding-bottom: 16px;
                border-bottom: 1px solid #545454;
                margin-top: 17px;
            }
            .modal-note-box{
                textarea{
                    border-radius: 8px;
                    background: #1F1F1F;
                    border: none;
                    padding: 20px 10px 35px 20px;
                    color: rgba(255, 255, 255, 0.8);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 18px;
                    min-height: 203px;
                    resize: none;
                }
            }
            .copyBtn{
                background: #FD7663;
                border: none;
                color: #FFFFFF;
            }
        }
        .camHeader{
            .close{
                font-size: 24px;
                top: 21px;
                right: 43px;
                height: 16px;
                width: 16px;
            }
        }
    }
}