@charset "UTF-8";
.modal-content {
  width: auto !important;
  height: auto !important; }

.view-able-info-modal .modal-body {
  width: 100%;
  padding: 0px 15px; }

.view-able-info-modal .modal-content {
  position: relative !important;
  height: auto !important;
  font-size: 14px !important;
  line-height: 20px;
  font-weight: 500;
  font-family: var(--font-family) !important;
  background-color: #333739;
  border-radius: 1em; }

.view-able-info-modal .chat-modal-header {
  border-bottom: none;
  padding: 0 1rem; }
  .view-able-info-modal .chat-modal-header img {
    opacity: 1 !important; }
  .view-able-info-modal .chat-modal-header .close {
    margin-top: 0rem !important;
    color: #C4C4C4;
    font-weight: 300;
    font-size: 28px;
    padding-top: 7px;
    position: absolute;
    top: 0px;
    right: 15px; }

.view-able-info-modal .modal-center-img {
  width: 115px;
  margin: 15px auto 0px auto;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.view-able-info-modal .modal-title {
  flex: 1 1 100%;
  text-align: left;
  margin: 15px 0 15px 0px !important; }

.view-able-info-modal h4 {
  font-weight: 700;
  font-size: 17px;
  line-height: 13px;
  color: #FFFFFF; }

.view-able-info-modal p {
  font-weight: 400;
  opacity: 0.8;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #D9D9D9; }

.view-able-info-modal h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF; }

.view-able-info-modal .chat-modal-footer {
  background-color: var(--primary);
  min-height: 60px;
  position: relative;
  padding: 0 1rem;
  justify-content: center;
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em; }
  .view-able-info-modal .chat-modal-footer p {
    cursor: pointer;
    color: white;
    margin-top: 25px;
    font-size: 12px !important;
    text-decoration: underline;
    font-family: var(--font-family) !important;
    width: 100%;
    text-align: center;
    font-weight: 600; }

.view-able-info-modal .viewable-info-table .view-info-table-wrap {
  margin-bottom: 15px; }

.view-able-info-modal .viewable-info-table table {
  background: transparent;
  border-radius: 6px;
  margin-bottom: 20px;
  border-collapse: collapse;
  border-radius: 1em;
  overflow: hidden;
  margin: auto;
  padding: 2px;
  border-collapse: separate;
  border-spacing: 0; }

.view-able-info-modal .viewable-info-table tr {
  border: 1px solid #E0607E; }

.view-able-info-modal .viewable-info-table th {
  border: 0.5px solid #393939;
  padding: 5px;
  background-color: #303030;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #FFFFFF; }

.view-able-info-modal .viewable-info-table td {
  border: 0.5px solid #393939;
  font-size: 10px;
  padding: 5px;
  background: #1E1F21; }

.view-able-info-modal .viewable-info-table tr:first-child th:first-child {
  border-top-left-radius: 10px; }

.view-able-info-modal .viewable-info-table tr:first-child th:last-child {
  border-top-right-radius: 10px; }

.view-able-info-modal .viewable-info-table tr:first-child td:first-child {
  border-top-left-radius: 10px; }

.view-able-info-modal .viewable-info-table tr:last-child td:first-child {
  border-bottom-left-radius: 10px; }

.view-able-info-modal .viewable-info-table tr:first-child td:last-child {
  border-top-right-radius: 10px; }

.view-able-info-modal .viewable-info-table tr:last-child td:last-child {
  border-bottom-right-radius: 10px; }

.view-able-info-modal .modal-content {
  height: auto !important; }

.modal-dialog.view-able-info-modal {
  margin: 1.75rem auto; }

.view-able-info-modal .form-group input[type=number] {
  margin: 0 auto;
  width: 80px; }

.view-able-info-modal .form-group input[type=number]::-webkit-inner-spin-button,
.view-able-info-modal .form-group input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.view-able-info-modal .alert {
  width: 95%;
  margin: 10px auto; }

.view-able-info-modal .alert.alert-danger {
  padding: 0;
  border-radius: 5px;
  background-color: #FDF1F4;
  color: #E5A0A9;
  border-color: #FDF1F4; }

.modal-backdrop.show {
  opacity: 0.3 !important; }

.modal {
  background: transparent !important; }

body.modal-open #root {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px); }

.view-able-info-modal .chat-modal-footer {
  border-bottom-right-radius: 0.7em !important;
  border-bottom-left-radius: 0.7em !important; }

.view-able-info-modal .btn {
  font-family: var(--font-family) !important;
  font-weight: 400; }

.view-able-info-modal .alert.alert-danger {
  color: #CD5664 !important;
  font-size: 12px; }

.online-user-details .back_btn {
  display: none;
  align-self: center; }
  .online-user-details .back_btn .btn {
    border: none;
    color: grey;
    padding: 0;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px; }
  .online-user-details .back_btn .btn:before {
    content: "❮";
    color: #d7d7d7;
    font-size: 19px; }
