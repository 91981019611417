.chatMedia-gallery-grid{
  display: flex;
  flex-wrap: wrap;
}

.ReactModal__Overlay {
  z-index: 999999 !important;
}


.chatMediaModal{
  .modal-content{
    background-color: #333739;
    .modal-header{
      border-bottom: 0;
      padding-bottom: 0;
      h5{
          font-size: 18px;
      }
      .close{
          background-image: url('../../../../assets/svgs/closeIcon.svg');
          font-size: 0;
          background-repeat: no-repeat;
          background-position: center center;
          margin-right: 0;
          margin-top: 0;
      }
    }
    .modal-body {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      .chatMedia-gallery-grid {
        display: flex;
        flex-wrap: wrap;
        max-height: 433px;
        overflow-y: auto;
        .chatMediaPhoto {
          // padding:1.5px;
          button{
            padding: 0;
            background: #000;
            border: 0;
            height: 144px;
            width: 144px;
            box-sizing: border-box;
            border: 1.5px solid #333739;
            position: relative;
            &:focus{
              outline: 0;
              border: 1.5px solid var(--primary);
            }
            .video_play_icon {
              .play_back_circle {
                position: absolute;
                background: #000;
                width: 40px;
                height: 40px;
                content: '';
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                opacity: 0.6;
                border-radius: 50%;
                box-shadow: 2px 2px 9px #000;
              }
              i {
                color: #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                /* box-shadow: -2px 0px 5px #fff; */
                border-radius: 50%;
                z-index: 99;
              }
            }
            
            img{
              width: 140px;
              height: 144px;
              object-fit: contain;
            }
          }
        }
      }
      
    }
    .chat-media-tabs-menu {
      .nav-tabs {
        width: 250px;
        margin: 0 auto 20px;
        background: #666666;
        border-radius: 50px;
        border: none;
        .nav-link:hover, .nav-link:focus {
          border-color: transparent;
        }
        .nav-link {
          color: #fff;
          cursor: pointer;
        }
        .nav-link.active, .nav-item.show .nav-link {
          color: #000000;
          background-color: #fff;
          border-color: transparent;
          border-radius: 50px;
        }
      }
    }


    button.sendBtn{
      border-radius: 10px;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      width: 230px;
      height: 50px;
      margin: 0 auto;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
    .modal-footer{
      border-top: 0;
      padding-top: 0;
      justify-content: center;
      button{
        border-radius: 10px;
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        width: 230px;
        height: 50px;
      }
    }
  }
}

@media (min-width: 576px) {
  .modal-dialog.chatMediaDialog {
    min-width: 800px;
  }

}
