.anderAge-Modal{
    margin: 50px auto !important;
    transform: translate(-50%, -50%) !important;
    left: 50%;
    position: absolute !important;
    top: 35%;
  .modal-content{
      background-color: #333739;
      max-width: 380px !important;
      margin: 0 auto;
      .modal-title{
          font-size: 1rem;
          line-height: 25px;
      }
      .modal-header{
          justify-content: center;
          position: relative;
          border-bottom: 0;
          button{
              padding: 10px;
              margin: 0;
              position: absolute;
              right: 0;
              top: 0;
              span{
                  color: #5D6569;
                  text-shadow: none;                    
              }
          }
      }
      .modal-body{
          width: 100%;
          padding-left: 30px;
          padding-right: 30px;
          h4 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 25px;
            text-align: center;
            color: #FFFFFF;
          }
          .modal-center-img {
            margin-bottom: 1rem;
          }
          figure{
              text-align: center;
              img{
              width: 110px !important;
              height: 110px !important;
              object-fit: cover;
              border-radius: 5px;
              }
          }
          .doneBtn{
            width: 230px;
            border-radius: 10px;
            height: 50px;
            margin-top: 0em;
        }
      }
  }
}