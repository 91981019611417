.chooseImageModal{
    .modal-content{
        background-color: #333739;
        .modal-header{
            border-bottom: 0;
            h5{
                font-size: 18px;
            }
            .close{
                background-image: url('../../../../assets/svgs/closeIcon.svg');
                font-size: 0;
                background-repeat: no-repeat;
                background-position: center center;
                margin-right: 0;
                margin-top: 0;
            }
        }
        .modal-body {
            width: 100%;
            padding-left: 20px;
            padding-right: 20px;
        }
        .publicGallery{
            margin-bottom: 17px;
        }
        .publicGallery,.privateGallery{
            height: 195px;
            padding: 10px;
            // border: 1px dashed #666666;
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 10px;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23666666FF' strokeWidth='1' stroke-dasharray='8%2c 8' stroke-dashoffset='89' stroke-linecap='square'/%3e%3c/svg%3e");
            input[type="file"]{
                display: none;
            }
            .uploadText{
                color: #666;
                text-align: center;
                margin-top: 10px;
                margin-bottom: 0;
                font-weight: 600;
            }
            .bg-none{
                background-color: none !important;
                border: 0;
            }
            &:hover{
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23FD7663FF' strokeWidth='1' stroke-dasharray='8%2c 8' stroke-dashoffset='89' stroke-linecap='square'/%3e%3c/svg%3e");
            svg{
                fill: var(--primary) ;
                stroke: var(--primary);
                path{
                fill: var(--primary) ;

                }
            }    
            .uploadText{
                    color: var(--primary)
                }
            }
        }
    }

}
@media screen and (device-aspect-ratio: 1366/768) {
    .chooseImageModal{
        padding-right: 10px;
        .modal-body {
            max-height: 520px;
            overflow-x: hidden;
            overflow-y: scroll;
        }
    }
}