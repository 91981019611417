.bgPlaceholder {
  width: 120px;
  height: 102px;
  position: absolute;
  top: 93px;
  left: 0;
  right: 0;
  margin: auto;
}
.updateBtn{
  border-radius: 10px !important;
  color: var(--white) !important;
  opacity: 1 !important;
  width: 230px;
  height: 50px;
}
.btn-outline-secondary {
  color: #6c757d !important;
  background: #fff !important;
  border-color: #6c757d !important;
}

.btn-outline-secondary:hover {
  color: #fff !important;
  background: #6c757d !important;
  border-color: #6c757d !important;
}

.chatRateContainer {
  position: relative;
  min-height: 50px;
  background-color: #292A2B;
  border-color: #292A2B;
  color: var(--white);
  font-size: 0.875rem;
}
.chatRateContainer span {
  position: absolute;
  top: 15px;
  left: 10px;
  opacity: 0.6;
}
.chatRateContainer img {
  position: absolute;
  top: 11px;
  right: 8px;
}

#editPrimaryPicModal {
  .modal-header {
    background-color: #33363d;
    color: #fff;
    padding: 0.3rem;

    .modal-title {
      margin-left: 15px;
      font-weight: 400;
    }
  }
  .modal-content {
    background-color: var(--dark);
    .modal-body {
      text-align: center;

      .badge {
        margin-left: -330px;
        margin-top: 240px;
        opacity: 0.8;
        position: absolute;
        padding: 5px;
        border: 1px solid #6c757d;
        color: #fff;
        background-color: #6c757d80;

        i {
          padding: 3px;
        }
      }
    }

    .modal-footer {
      .fa.fa-minus, .fa.fa-plus {
        cursor: pointer;
        font-size: 10px;
        line-height: 10px;
        border: 2px solid grey;
        color: grey;
        border-radius: 50%;
        padding: 2px 3px;
        display: inline-table;
      }

      .btn-secondary {
        padding: 4px 10px;
        font-size: 15px;
        background-color: #3B414A;
      }

      .ml120 {
        margin-left: 120px;
      }

      .input-range {
        width: 100px;
        margin: 0 12px;

        .input-range__label {
          display: none;
        }
        .input-range__slider {
          background: none;
          border: 3.5px solid grey;
        }

        .input-range__track--active {
          background: grey;
        }
      }
    }
  }
}

.profile {
  display: block;
  .card-body {
    width: 100%;
    padding: 1rem;

    div {
      align-self: baseline;
    }

    .help_text {
      color: #868B91;
      padding-left: 0;
    }

    .user-name {
      padding-left: 15px;
    }

    .profile-description {
      textarea.form-control {
        height: 150px;
      }

      .edit_icon{
        align-self: baseline;
      }
    }

    .form-group {
      // margin-top: 1rem;
    }

    .row_detail {
      margin-left: -7.5px !important;
      margin-right: -7.5px !important;
      & > div {
        padding-left: 7.5px;
        padding-right: 7.5px;
      }
      input{
        min-height: 50px;
        background-color: #292A2B;
        border-color: #292A2B;
        color: var(--white);
        font-size: 0.875rem;
      }
      select{
        min-height: 50px;
        background-color: #292A2B;
        border-color: #292A2B;
        color: var(--white);
        font-size: 0.875rem;
      }
      textarea{
        background-color: #292A2B;
        border-color: #292A2B;
        color: var(--white);
        min-height: 102px;
        font-size: 0.875rem;

      }
      label{
        margin-bottom: 5px;
        font-size: 0.75rem;
        opacity: 0.6;
      }
    }

    .setting_details {
      margin: 3px auto;
    }

    .imagePanel {
      margin-bottom: 15px;
      border-radius: 15px;
      background-color: transparent;
      .card-header {
        color: #F5F6F9;
        width: 100%;
        justify-content: center;

        canvas {
          position: absolute;
          left: 0px;
          top: 0px;
          border-radius: 15px;
        }
      }

      .card-body {
        padding: 0.6rem;
        button {
          padding: 4px 10px;
          font-size: 15px;
        }

        .form-group {
          margin: 0;
        }

        .backgroundPic {
          border: 1px solid #fff;
          width: 154px;
          height: 40px;
          cursor: pointer;
          font-size: 14px;
          padding: 8px 1px;
          display: inline-flex;
          align-items: center;
          justify-content: space-evenly;
          color: #fff;
          border-radius: 30px;
          margin-bottom: 0;
          position: absolute;
          right: 20px;
          bottom: 20px;
          font-weight: 600;
          label {
            margin-bottom: 0;
          }

          svg {
            height: 20px;
          }
        }
        .absoluteImgGroup{
          position: absolute;
          right: 20px;
          bottom: 20px;
          button{
            padding: 10px 14px !important;
            font-size: 14px;
          }
        }
        .profilePic {
          width: 144px;
          height: 144px;
          border: 4px solid #2D313A;
          border-radius: 50%;
          position: absolute;
          background: #F5F6F9;
          bottom: 0;
          left: 60px;
          // overflow: hidden;
          transform: translateY(50%);

          .fa.fa-spinner {
            margin-top: 55px;
            margin-right: 55px;
            font-size: 30px;
            color: #fff;
          }

          .primaryPic {
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 50%;
            background-size: cover !important;
            background-repeat: no-repeat;
          }

          .primaryPic.blurImage {
            opacity: 0.5;
          }

          .profile-placeholder {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            background-size: contain;
            left: 0;
            background: url("../../assets/svgs/profile_pic_placeholder.svg") center center no-repeat;
          }

          .updateProfilePic label {
            cursor: pointer;
            bottom: 0;
            margin: auto;
            right: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 0px;
            background: var(--primary);
            width: 37px;
            height: 37px;
            border-radius: 50%;
            color: #fff;
            position: absolute;
            svg {
              position: absolute;
              margin: auto;
              height: 20px;
              left: 0;
              right: 0;
              // opacity: 0.5;
              transition: all 0.3s ease-in-out;
              top: 9px;
            }
            span {
              // opacity: 0;
              font-size: 14px;
              margin-top: 20px;
              transition: all 0.3s ease-in-out;
            }
            * {
              transition: all 0.2s ease-in-out;
            }
          }
        
          // &:hover .updateProfilePic label {
          //   background: rgba(0, 0, 0, 0.27);
          //   span, svg {
          //     opacity: 1;
          //   }
          // }           
        }
      }

      .picture {
        text-align: center;
        background-size: cover;
        height: 300px;
        &::after{
          content: "";
          background: #00000066;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          pointer-events: none;
        }
        .backgroundPic {
          height: 300px;
          background-size: contain !important;
          background-repeat: no-repeat;
          border-radius: 15px;
          background-position: center;
          background-color: #fff;
        }

        .fa.fa-spinner {
          bottom: 150px;
          position: relative;
          font-size: 30px;
        }

        .backgroundPic.blurImage {
          opacity: 0.5;
        }

        .badge {
          opacity: 0.8;
          position: relative;
          margin-left: -65px;
          padding: 5px;
          margin-top: 130px;
          border: 1px solid #6c757d;
          color: #fff;
          background-color: #6c757d80;
          i {
            padding: 3px;
          }
        }
  
        .placeholder {
          svg {
            display: -webkit-inline-flex;
            display: inline-flex;
            object-fit: contain;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-align-items: center;
            align-items: center;
            background-repeat: no-repeat;
            background-position: center;
            height: 140px;
            width: 120px;
          }
        }
      }
    }
  }

  .borderBottom {
    border-bottom: 2px solid #F5F6FA;
    margin-bottom: 15px;
  }
  
  .fs-20 {
    font-size: 18px;
    line-height: 1.3;
    padding-left: 0;
  }
  .setting_description {
    align-self: baseline;

    .form-group {
      margin-bottom: 0px;

      input, textarea{
        border-color: #39424A;
      }
    }

    .edit_icon {
      text-align: end;
      span {
        padding: 0.4rem;
      }
    }

    .bg_picture{
      img{
        max-height:80px ;
      }
    }

    svg {
      height: 15px;
      width: 15px;
      cursor: pointer;
    }
    .btn-outline-secondary {
      width: 200px;
    }
  }
}
.profile-wrap{
  .nav.nav-tabs{
    border:unset;
    .nav-item{
      max-width: 200px;
      margin-bottom: -2px;
      .nav-link{
        padding-top: 16px;
        &:hover{
          border: unset !important;
          color: #f07563 !important;
          cursor: pointer;
        }
      }
      .nav-link.active{
        color: white;
        background-color: #242628;
        border:unset;
        padding: 20px 4px 15px;
      }
    }
  }
  .card-body{
    background-color: #242628;
    border-radius: 8px;
  }
  .customCard {
    padding: 0 !important;
    .updateBtn.addBtn {
      background-color: unset;
      padding: 8px !important;
      color: #f07563!important;
    }
  }
}