.dashboardFooterCls{
  width: 100%;
  margin-left: 0!important;
  margin-right: 0!important;
  justify-content: center;
  background: #E3E6EB;
  padding: 16px 0px;
  p{
    margin-bottom: 0;
  }
}