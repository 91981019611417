.notification-row{
    .content-text{
        margin-left: 18px;
        p{
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #FFFFFF;
            opacity: 0.6;
        }
    }
    .custom-switch{
        .custom-control-label{
            left: -28px;
            cursor: pointer;
        }
        .custom-control-input:checked~.custom-control-label::after {
            background-color: #fff;
            transform: translateX(1.55rem);
            height: 20px;
            width: 20px;
            top: 4px;
            border-radius: 50%;
        }
        .custom-control-input:checked~.custom-control-label::before {
            color: #fff;
            border-color: #f07563;
            background-color: #f07563;
            width: 50px;
            height: 24px;
            pointer-events: all;
            border-radius: 15px;
            top: 2px;
        }
         .custom-control-label::before {
            width: 50px;
            height: 24px;
            pointer-events: all;
            border-radius: 15px;
            top: 2px;
        }
        .custom-control-label::after {
            height: 20px;
            width: 20px;
            top: 4px;
            border-radius: 50%;
        }
    }
}