// Emoji CSS Start
.EmojiSlider {
  position: absolute;
  bottom: auto;
  top: -60px;
  left: 0;
  z-index: 1000;
  -webkit-filter: drop-shadow(2.77089px 2.77089px 8.31266px rgba(58, 65, 75, 0.3));
  filter: drop-shadow(2.77089px 2.77089px 8.31266px rgba(58, 65, 75, 0.3));
  @media (max-width:767px) {
    left: 0;
    right: 0;
  }
  @media (max-width:350px) {
    left: 10px;
  }
  .slick-slider{
      width: 100%;
      .slick-list{
          width: 100%;
          .slick-slide{
              text-align: center;
              cursor: pointer;
              div{
                outline: none !important;
                border: none !important;
                box-shadow: none !important;
                img {
                  width: 36px;
                  height: 36px;
                  margin: 0 auto;
                  border-radius: 0;
                  outline: none !important;
                  border: none !important;
                  box-shadow: none !important;
                }
              }
          }
      }
      .slick-prev, .slick-next{
        height: auto;
        &::before{
          content: '\f105';
          opacity: 1;
          color: #fff;
          border: none;
          border-radius: 50px;
          font-family: 'FontAwesome';
          width: auto;
          display: block;
          height: auto;
          line-height: 17px;
          text-align: center;
          position: relative;
          font-weight: 600;
          top: -4px;
          opacity: 0.5;
        }
      }
      .slick-prev{
        &::before{
          content: '\f104';
          padding: 0 0;
          left: 11px;
        }
      }
      .slick-next{
        &::before{
          content: '\f105';
          padding: 0 0;
          right: 11px;
        }
      }
      .slick-dots{
          bottom: 2.5px;
          height: 6px;
          display: flex !important;
          justify-content: center;
          align-items: center;
          li{
              width: 3px;
              height: 3px;
              vertical-align: middle;
              margin: 0 2px;
              button{
                  width: 3px;
                  height: 3px;
                  margin: 0 auto;
                  background: #ACACAC;
                  border-radius: 50px;
                  padding: 0;
                  &::before{
                      display: none;
                  }
              }
          }
          li.slick-active{
              width: 4px;
              height: 4px;
              button{
                  width: 4px;
                  height: 4px;
                  background: #ffffff;
              }
          }
      }
  }
}
.selectedEmoji{
  width: 32px;
  height: 32px;
  background: #282a2b;
  -webkit-filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.12));
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.12));
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: auto;
  bottom: 23px;
  left: 6px;
  border: 1.2px solid #fff;
  cursor: pointer;
  img{
    width: 22px !important;
    height: 22px !important;
    border-radius: 50px !important;
  }
}
.incoming-emoji-reaction {
  width: 32px;
  height: 32px;
  background: #282a2b;
  -webkit-filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.12));
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.12));
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: auto;
  bottom: 23px;
  left: auto;
  right: 10px;
  border: 1.2px solid #fff;
  cursor: pointer;
  .left-emoji-reaction {
    width: 22px !important;
    height: 22px !important;
    border-radius: 50px !important;
  }
}
.incoming-emoji-reaction.isnot-reacted {
  height: 0;
  border: none;
  .left-emoji-reaction {
    height: 0 !important;
  }
}
.EmojiSelector{
  width: 17px;
  height: 17px;
  position: absolute;
  right: -25px;
  top: 0;
  cursor: pointer;
  img{
    width: 100% !important;
    height: 100% !important;
  }
}