.dropArea{
    & > div {
        height: 100%;
    }
    margin-top: 5px !important;
    .dropzone{
        padding: 50px 0;
    }
    .dzu-dropzone{
        height: 100%;
        border:  1px dashed #666666;
        border-radius: 10px;
        padding: 0 !important;
        justify-content: center;
        overflow: auto;
        button{
            border-color: var(--primary);
            background-color: transparent;
            border-radius: 10px;
            color: var(--primary);
            margin-top: 88px;
        }
        label{
            color: var(--white);
            opacity: 0.6;
            font-size: 1rem;
            font-weight: 600;
        }
    }
}
.myForm{
    .form-group{
        margin-bottom: 0;
        .invalid-feedback{
            font-size: 0.875rem;
        }
    }
}
.addMediaDone{
    width: 230px;
    height: 50px;
    border-radius: 10px !important;
}